/*
 CanvasJS HTML5 & JavaScript Charts - v2.3.2 GA - https://canvasjs.com/ 
 Copyright 2018 fenopix

  --------------------- License Information --------------------
 CanvasJS is a commercial product which requires purchase of license. Without a commercial license you can use it for evaluation purposes for upto 30 days. Please refer to the following link for further details.
     https://canvasjs.com/license/

*/
/*eslint-disable*/
/*jshint ignore:start*/
(function() {
  function pa(l, p) {
    l.prototype = db(p.prototype);
    l.prototype.constructor = l;
    l.base = p.prototype;
  }
  function db(l) {
    function p() {}
    p.prototype = l;
    return new p();
  }
  function Ya(l, p, I) {
    "millisecond" === I
      ? l.setMilliseconds(l.getMilliseconds() + 1 * p)
      : "second" === I
      ? l.setSeconds(l.getSeconds() + 1 * p)
      : "minute" === I
      ? l.setMinutes(l.getMinutes() + 1 * p)
      : "hour" === I
      ? l.setHours(l.getHours() + 1 * p)
      : "day" === I
      ? l.setDate(l.getDate() + 1 * p)
      : "week" === I
      ? l.setDate(l.getDate() + 7 * p)
      : "month" === I
      ? l.setMonth(l.getMonth() + 1 * p)
      : "year" === I && l.setFullYear(l.getFullYear() + 1 * p);
    return l;
  }
  function aa(l, p) {
    var I = !1;
    0 > l && ((I = !0), (l *= -1));
    l = "" + l;
    for (p = p ? p : 1; l.length < p; ) l = "0" + l;
    return I ? "-" + l : l;
  }
  function Ha(l) {
    if (!l) return l;
    l = l.replace(/^\s\s*/, "");
    for (var p = /\s/, I = l.length; p.test(l.charAt(--I)); );
    return l.slice(0, I + 1);
  }
  function Da(l) {
    l.roundRect = function(l, I, s, u, E, C, y, w) {
      y && (this.fillStyle = y);
      w && (this.strokeStyle = w);
      "undefined" === typeof E && (E = 5);
      this.lineWidth = C;
      this.beginPath();
      this.moveTo(l + E, I);
      this.lineTo(l + s - E, I);
      this.quadraticCurveTo(l + s, I, l + s, I + E);
      this.lineTo(l + s, I + u - E);
      this.quadraticCurveTo(l + s, I + u, l + s - E, I + u);
      this.lineTo(l + E, I + u);
      this.quadraticCurveTo(l, I + u, l, I + u - E);
      this.lineTo(l, I + E);
      this.quadraticCurveTo(l, I, l + E, I);
      this.closePath();
      y && this.fill();
      w && 0 < C && this.stroke();
    };
  }
  function Sa(l, p) {
    return l - p;
  }
  function Ta(l, p, I) {
    if (l && p && I) {
      I = I + "." + p;
      var s = "image/" + p;
      l = l.toDataURL(s);
      var u = !1,
        E = document.createElement("a");
      E.download = I;
      E.href = l;
      if ("undefined" !== typeof Blob && new Blob()) {
        for (
          var C = l.replace(/^data:[a-z\/]*;base64,/, ""),
            C = atob(C),
            y = new ArrayBuffer(C.length),
            y = new Uint8Array(y),
            w = 0;
          w < C.length;
          w++
        )
          y[w] = C.charCodeAt(w);
        p = new Blob([y.buffer], { type: "image/" + p });
        try {
          window.navigator.msSaveBlob(p, I), (u = !0);
        } catch (L) {
          (E.dataset.downloadurl = [s, E.download, E.href].join(":")),
            (E.href = window.URL.createObjectURL(p));
        }
      }
      if (!u)
        try {
          (event = document.createEvent("MouseEvents")),
            event.initMouseEvent("click", !0, !1, window, 0, 0, 0, 0, 0, !1, !1, !1, !1, 0, null),
            E.dispatchEvent ? E.dispatchEvent(event) : E.fireEvent && E.fireEvent("onclick");
        } catch (H) {
          (p = window.open()),
            p.document.write(
              "<img src='" +
                l +
                "'></img><div>Please right click on the image and save it to your device</div>"
            ),
            p.document.close();
        }
    }
  }
  function P(l) {
    var p = ((l & 16711680) >> 16).toString(16),
      I = ((l & 65280) >> 8).toString(16);
    l = ((l & 255) >> 0).toString(16);
    p = 2 > p.length ? "0" + p : p;
    I = 2 > I.length ? "0" + I : I;
    l = 2 > l.length ? "0" + l : l;
    return "#" + p + I + l;
  }
  function eb(l, p) {
    var I = this.length >>> 0,
      s = Number(p) || 0,
      s = 0 > s ? Math.ceil(s) : Math.floor(s);
    for (0 > s && (s += I); s < I; s++) if (s in this && this[s] === l) return s;
    return -1;
  }
  function u(l) {
    return null === l || "undefined" === typeof l;
  }
  function Ea(l) {
    l.indexOf || (l.indexOf = eb);
    return l;
  }
  function fb(l) {
    if (Ia.fSDec)
      l[ia("`eeDwdouMhrudods")](ia("e`u`@ohl`uhnoHuds`uhnoDoe"), function() {
        Ia._fTWm && Ia._fTWm(l);
      });
  }
  function Za(l, p, I) {
    I = I || "normal";
    var s = l + "_" + p + "_" + I,
      u = $a[s];
    if (isNaN(u)) {
      try {
        l =
          "position:absolute; left:0px; top:-20000px; padding:0px;margin:0px;border:none;white-space:pre;line-height:normal;font-family:" +
          l +
          "; font-size:" +
          p +
          "px; font-weight:" +
          I +
          ";";
        if (!sa) {
          var E = document.body;
          sa = document.createElement("span");
          sa.innerHTML = "";
          var C = document.createTextNode("Mpgyi");
          sa.appendChild(C);
          E.appendChild(sa);
        }
        sa.style.display = "";
        sa.setAttribute("style", l);
        u = Math.round(sa.offsetHeight);
        sa.style.display = "none";
      } catch (y) {
        u = Math.ceil(1.1 * p);
      }
      u = Math.max(u, p);
      $a[s] = u;
    }
    return u;
  }
  function Q(l, p) {
    var I = [];
    if (
      (I = {
        solid: [],
        shortDash: [3, 1],
        shortDot: [1, 1],
        shortDashDot: [3, 1, 1, 1],
        shortDashDotDot: [3, 1, 1, 1, 1, 1],
        dot: [1, 2],
        dash: [4, 2],
        dashDot: [4, 2, 1, 2],
        longDash: [8, 2],
        longDashDot: [8, 2, 1, 2],
        longDashDotDot: [8, 2, 1, 2, 1, 2]
      }[l || "solid"])
    )
      for (var s = 0; s < I.length; s++) I[s] *= p;
    else I = [];
    return I;
  }
  function N(l, p, I, s, u) {
    s = s || [];
    u = u || !1;
    s.push([l, p, I, u]);
    return l.addEventListener
      ? (l.addEventListener(p, I, u), I)
      : l.attachEvent
      ? ((s = function(p) {
          p = p || window.event;
          p.preventDefault =
            p.preventDefault ||
            function() {
              p.returnValue = !1;
            };
          p.stopPropagation =
            p.stopPropagation ||
            function() {
              p.cancelBubble = !0;
            };
          I.call(l, p);
        }),
        l.attachEvent("on" + p, s),
        s)
      : !1;
  }
  function ab(l, p, I) {
    l *= ka;
    p *= ka;
    l = I.getImageData(l, p, 2, 2).data;
    p = !0;
    for (I = 0; 4 > I; I++)
      if ((l[I] !== l[I + 4]) | (l[I] !== l[I + 8]) | (l[I] !== l[I + 12])) {
        p = !1;
        break;
      }
    return p ? (l[0] << 16) | (l[1] << 8) | l[2] : 0;
  }
  function ma(l, p, I) {
    return l in p ? p[l] : I[l];
  }
  function Oa(l, p, I) {
    if (s && bb) {
      var u = l.getContext("2d");
      Pa =
        u.webkitBackingStorePixelRatio ||
        u.mozBackingStorePixelRatio ||
        u.msBackingStorePixelRatio ||
        u.oBackingStorePixelRatio ||
        u.backingStorePixelRatio ||
        1;
      ka = Ua / Pa;
      l.width = p * ka;
      l.height = I * ka;
      Ua !== Pa && ((l.style.width = p + "px"), (l.style.height = I + "px"), u.scale(ka, ka));
    } else (l.width = p), (l.height = I);
  }
  function gb(l) {
    if (!hb) {
      var p = !1,
        I = !1;
      "undefined" === typeof xa.Chart.creditHref
        ? ((l.creditHref = ia("iuuqr;..b`ow`rkr/bnl.")), (l.creditText = ia("B`ow`rKR/bnl")))
        : ((p = l.updateOption("creditText")), (I = l.updateOption("creditHref")));
      if (l.creditHref && l.creditText) {
        l._creditLink ||
          ((l._creditLink = document.createElement("a")),
          l._creditLink.setAttribute("class", "canvasjs-chart-credit"),
          l._creditLink.setAttribute("title", "JavaScript Charts"),
          l._creditLink.setAttribute(
            "style",
            "outline:none;margin:0px;position:absolute;right:2px;top:" +
              (l.height - 14) +
              "px;color:dimgrey;text-decoration:none;font-size:11px;font-family: Calibri, Lucida Grande, Lucida Sans Unicode, Arial, sans-serif"
          ),
          l._creditLink.setAttribute("tabIndex", -1),
          l._creditLink.setAttribute("target", "_blank"));
        if (0 === l.renderCount || p || I)
          l._creditLink.setAttribute("href", l.creditHref),
            (l._creditLink.innerHTML = l.creditText);
        l._creditLink && l.creditHref && l.creditText
          ? (l._creditLink.parentElement || l._canvasJSContainer.appendChild(l._creditLink),
            (l._creditLink.style.top = l.height - 14 + "px"))
          : l._creditLink.parentElement && l._canvasJSContainer.removeChild(l._creditLink);
      }
    }
  }
  function ua(l, p) {
    Ja && ((this.canvasCount |= 0), window.console.log(++this.canvasCount));
    var I = document.createElement("canvas");
    I.setAttribute("class", "canvasjs-chart-canvas");
    Oa(I, l, p);
    s || "undefined" === typeof G_vmlCanvasManager || G_vmlCanvasManager.initElement(I);
    return I;
  }
  function ra(l, p, I) {
    for (var s in I) p.style[s] = I[s];
  }
  function va(l, p, s) {
    p.getAttribute("state") ||
      ((p.style.backgroundColor = l.toolbar.backgroundColor),
      (p.style.color = l.toolbar.fontColor),
      (p.style.border = "none"),
      ra(l, p, {
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none"
      }));
    p.getAttribute("state") !== s &&
      (p.setAttribute("state", s),
      p.setAttribute("type", "button"),
      ra(l, p, {
        padding: "5px 12px",
        cursor: "pointer",
        float: "left",
        width: "40px",
        height: "25px",
        outline: "0px",
        verticalAlign: "baseline",
        lineHeight: "0"
      }),
      p.setAttribute("title", l._cultureInfo[s + "Text"]),
      (p.innerHTML =
        "<img style='height:95%; pointer-events: none;' src='" +
        ib[s].image +
        "' alt='" +
        l._cultureInfo[s + "Text"] +
        "' />"));
  }
  function Qa() {
    for (var l = null, p = 0; p < arguments.length; p++)
      (l = arguments[p]), l.style && (l.style.display = "inline");
  }
  function wa() {
    for (var l = null, p = 0; p < arguments.length; p++)
      (l = arguments[p]) && l.style && (l.style.display = "none");
  }
  function U(l, p, s, qa, w) {
    this._defaultsKey = l;
    this._themeOptionsKey = p;
    this._index = qa;
    this.parent = w;
    this._eventListeners = [];
    l = {};
    this.theme && u(p) && u(qa)
      ? (l = u(ya[this.theme]) ? ya.light1 : ya[this.theme])
      : this.parent &&
        this.parent.themeOptions && this.parent.themeOptions[p] &&
        (null === qa
          ? (l = this.parent.themeOptions[p])
          : 0 < this.parent.themeOptions[p].length &&
            ((qa = Math.min(this.parent.themeOptions[p].length - 1, qa)),
            (l = this.parent.themeOptions[p][qa])));
    this.themeOptions = l;
    this.options = s ? s : { _isPlaceholder: !0 };
    this.setOptions(this.options, l);
  }
  function Fa(l, p, s, u, w) {
    "undefined" === typeof w && (w = 0);
    this._padding = w;
    this._x1 = l;
    this._y1 = p;
    this._x2 = s;
    this._y2 = u;
    this._rightOccupied = this._leftOccupied = this._bottomOccupied = this._topOccupied = this._padding;
  }
  function ja(l, p) {
    ja.base.constructor.call(this, "TextBlock", null, p, null, null);
    this.ctx = l;
    this._isDirty = !0;
    this._wrappedText = null;
    this._initialize();
  }
  function Va(l, p) {
    Va.base.constructor.call(this, "Toolbar", "toolbar", p, null, l);
    this.chart = l;
    this.canvas = l.canvas;
    this.ctx = this.chart.ctx;
    this.optionsName = "toolbar";
  }
  function za(l, p) {
    za.base.constructor.call(this, "Title", "title", p, null, l);
    this.chart = l;
    this.canvas = l.canvas;
    this.ctx = this.chart.ctx;
    this.optionsName = "title";
    if (u(this.options.margin) && l.options.subtitles)
      for (var s = l.options.subtitles, qa = 0; qa < s.length; qa++)
        if (
          ((u(s[qa].horizontalAlign) && "center" === this.horizontalAlign) ||
            s[qa].horizontalAlign === this.horizontalAlign) &&
          ((u(s[qa].verticalAlign) && "top" === this.verticalAlign) ||
            s[qa].verticalAlign === this.verticalAlign) &&
          !s[qa].dockInsidePlotArea === !this.dockInsidePlotArea
        ) {
          this.margin = 0;
          break;
        }
    "undefined" === typeof this.options.fontSize &&
      (this.fontSize = this.chart.getAutoFontSize(this.fontSize));
    this.height = this.width = null;
    this.bounds = { x1: null, y1: null, x2: null, y2: null };
  }
  function Ka(l, p, s) {
    Ka.base.constructor.call(this, "Subtitle", "subtitles", p, s, l);
    this.chart = l;
    this.canvas = l.canvas;
    this.ctx = this.chart.ctx;
    this.optionsName = "subtitles";
    this.isOptionsInArray = !0;
    "undefined" === typeof this.options.fontSize &&
      (this.fontSize = this.chart.getAutoFontSize(this.fontSize));
    this.height = this.width = null;
    this.bounds = { x1: null, y1: null, x2: null, y2: null };
  }
  function Wa() {
    this.pool = [];
  }
  function La(l) {
    var p;
    l && Ma[l] && (p = Ma[l]);
    La.base.constructor.call(this, "CultureInfo", null, p, null, null);
  }
  var Ja = !1,
    Ia = {},
    s = !!document.createElement("canvas").getContext,
    xa = {
      Chart: {
        width: 500,
        height: 400,
        zoomEnabled: !1,
        zoomType: "x",
        backgroundColor: "white",
        theme: "light1",
        animationEnabled: !1,
        animationDuration: 1200,
        dataPointWidth: null,
        dataPointMinWidth: null,
        dataPointMaxWidth: null,
        colorSet: "colorSet1",
        culture: "en",
        creditHref: "",
        creditText: "CanvasJS",
        interactivityEnabled: !0,
        exportEnabled: !1,
        exportFileName: "Chart",
        rangeChanging: null,
        rangeChanged: null,
        publicProperties: {
          title: "readWrite",
          subtitles: "readWrite",
          toolbar: "readWrite",
          toolTip: "readWrite",
          legend: "readWrite",
          axisX: "readWrite",
          axisY: "readWrite",
          axisX2: "readWrite",
          axisY2: "readWrite",
          data: "readWrite",
          options: "readWrite",
          bounds: "readOnly",
          container: "readOnly"
        }
      },
      Title: {
        padding: 0,
        text: null,
        verticalAlign: "top",
        horizontalAlign: "center",
        fontSize: 20,
        fontFamily: "Calibri",
        fontWeight: "normal",
        fontColor: "black",
        fontStyle: "normal",
        borderThickness: 0,
        borderColor: "black",
        cornerRadius: 0,
        backgroundColor: s ? "transparent" : null,
        margin: 5,
        wrap: !0,
        maxWidth: null,
        dockInsidePlotArea: !1,
        publicProperties: { options: "readWrite", bounds: "readOnly", chart: "readOnly" }
      },
      Subtitle: {
        padding: 0,
        text: null,
        verticalAlign: "top",
        horizontalAlign: "center",
        fontSize: 14,
        fontFamily: "Calibri",
        fontWeight: "normal",
        fontColor: "black",
        fontStyle: "normal",
        borderThickness: 0,
        borderColor: "black",
        cornerRadius: 0,
        backgroundColor: null,
        margin: 2,
        wrap: !0,
        maxWidth: null,
        dockInsidePlotArea: !1,
        publicProperties: { options: "readWrite", bounds: "readOnly", chart: "readOnly" }
      },
      Toolbar: {
        backgroundColor: "white",
        backgroundColorOnHover: "#2196f3",
        borderColor: "#2196f3",
        borderThickness: 1,
        fontColor: "black",
        fontColorOnHover: "white",
        publicProperties: { options: "readWrite", chart: "readOnly" }
      },
      Legend: {
        name: null,
        verticalAlign: "center",
        horizontalAlign: "right",
        fontSize: 14,
        fontFamily: "calibri",
        fontWeight: "normal",
        fontColor: "black",
        fontStyle: "normal",
        cursor: null,
        itemmouseover: null,
        itemmouseout: null,
        itemmousemove: null,
        itemclick: null,
        dockInsidePlotArea: !1,
        reversed: !1,
        backgroundColor: s ? "transparent" : null,
        borderColor: s ? "transparent" : null,
        borderThickness: 0,
        cornerRadius: 0,
        maxWidth: null,
        maxHeight: null,
        markerMargin: null,
        itemMaxWidth: null,
        itemWidth: null,
        itemWrap: !0,
        itemTextFormatter: null,
        publicProperties: { options: "readWrite", bounds: "readOnly", chart: "readOnly" }
      },
      ToolTip: {
        enabled: !0,
        shared: !1,
        animationEnabled: !0,
        content: null,
        contentFormatter: null,
        reversed: !1,
        backgroundColor: s ? "rgba(255,255,255,.9)" : "rgb(255,255,255)",
        borderColor: null,
        borderThickness: 2,
        cornerRadius: 5,
        fontSize: 14,
        fontColor: "black",
        fontFamily: "Calibri, Arial, Georgia, serif;",
        fontWeight: "normal",
        fontStyle: "italic",
        publicProperties: { options: "readWrite", chart: "readOnly" }
      },
      Axis: {
        minimum: null,
        maximum: null,
        viewportMinimum: null,
        viewportMaximum: null,
        interval: null,
        intervalType: null,
        reversed: !1,
        logarithmic: !1,
        logarithmBase: 10,
        title: null,
        titleFontColor: "black",
        titleFontSize: 20,
        titleFontFamily: "arial",
        titleFontWeight: "normal",
        titleFontStyle: "normal",
        titleWrap: !0,
        titleMaxWidth: null,
        titleBackgroundColor: s ? "transparent" : null,
        titleBorderColor: s ? "transparent" : null,
        titleBorderThickness: 0,
        titleCornerRadius: 0,
        labelAngle: 0,
        labelFontFamily: "arial",
        labelFontColor: "black",
        labelFontSize: 12,
        labelFontWeight: "normal",
        labelFontStyle: "normal",
        labelAutoFit: !0,
        labelWrap: !0,
        labelMaxWidth: null,
        labelFormatter: null,
        labelBackgroundColor: s ? "transparent" : null,
        labelBorderColor: s ? "transparent" : null,
        labelBorderThickness: 0,
        labelCornerRadius: 0,
        labelPlacement: "outside",
        prefix: "",
        suffix: "",
        includeZero: !0,
        tickLength: 5,
        tickColor: "black",
        tickThickness: 1,
        lineColor: "black",
        lineThickness: 1,
        lineDashType: "solid",
        gridColor: "A0A0A0",
        gridThickness: 0,
        gridDashType: "solid",
        interlacedColor: s ? "transparent" : null,
        valueFormatString: null,
        margin: 2,
        publicProperties: {
          options: "readWrite",
          stripLines: "readWrite",
          scaleBreaks: "readWrite",
          crosshair: "readWrite",
          bounds: "readOnly",
          chart: "readOnly"
        }
      },
      StripLine: {
        value: null,
        startValue: null,
        endValue: null,
        color: "orange",
        opacity: null,
        thickness: 2,
        lineDashType: "solid",
        label: "",
        labelPlacement: "inside",
        labelAlign: "far",
        labelWrap: !0,
        labelMaxWidth: null,
        labelBackgroundColor: null,
        labelBorderColor: s ? "transparent" : null,
        labelBorderThickness: 0,
        labelCornerRadius: 0,
        labelFontFamily: "arial",
        labelFontColor: "orange",
        labelFontSize: 12,
        labelFontWeight: "normal",
        labelFontStyle: "normal",
        labelFormatter: null,
        showOnTop: !1,
        publicProperties: {
          options: "readWrite",
          axis: "readOnly",
          bounds: "readOnly",
          chart: "readOnly"
        }
      },
      ScaleBreaks: {
        autoCalculate: !1,
        collapsibleThreshold: "25%",
        maxNumberOfAutoBreaks: 2,
        spacing: 8,
        type: "straight",
        color: "#FFFFFF",
        fillOpacity: 0.9,
        lineThickness: 2,
        lineColor: "#E16E6E",
        lineDashType: "solid",
        publicProperties: {
          options: "readWrite",
          customBreaks: "readWrite",
          axis: "readOnly",
          autoBreaks: "readOnly",
          bounds: "readOnly",
          chart: "readOnly"
        }
      },
      Break: {
        startValue: null,
        endValue: null,
        spacing: 8,
        type: "straight",
        color: "#FFFFFF",
        fillOpacity: 0.9,
        lineThickness: 2,
        lineColor: "#E16E6E",
        lineDashType: "solid",
        publicProperties: {
          options: "readWrite",
          scaleBreaks: "readOnly",
          bounds: "readOnly",
          chart: "readOnly"
        }
      },
      Crosshair: {
        enabled: !1,
        snapToDataPoint: !1,
        color: "grey",
        opacity: null,
        thickness: 2,
        lineDashType: "solid",
        label: "",
        labelWrap: !0,
        labelMaxWidth: null,
        labelBackgroundColor: s ? "grey" : null,
        labelBorderColor: s ? "grey" : null,
        labelBorderThickness: 0,
        labelCornerRadius: 0,
        labelFontFamily: s ? "Calibri, Optima, Candara, Verdana, Geneva, sans-serif" : "calibri",
        labelFontSize: 12,
        labelFontColor: "#fff",
        labelFontWeight: "normal",
        labelFontStyle: "normal",
        labelFormatter: null,
        valueFormatString: null,
        publicProperties: {
          options: "readWrite",
          axis: "readOnly",
          bounds: "readOnly",
          chart: "readOnly"
        }
      },
      DataSeries: {
        name: null,
        dataPoints: null,
        label: "",
        bevelEnabled: !1,
        highlightEnabled: !0,
        cursor: "default",
        indexLabel: "",
        indexLabelPlacement: "auto",
        indexLabelOrientation: "horizontal",
        indexLabelFontColor: "black",
        indexLabelFontSize: 12,
        indexLabelFontStyle: "normal",
        indexLabelFontFamily: "Arial",
        indexLabelFontWeight: "normal",
        indexLabelBackgroundColor: null,
        indexLabelLineColor: "gray",
        indexLabelLineThickness: 1,
        indexLabelLineDashType: "solid",
        indexLabelMaxWidth: null,
        indexLabelWrap: !0,
        indexLabelFormatter: null,
        lineThickness: 2,
        lineDashType: "solid",
        connectNullData: !1,
        nullDataLineDashType: "dash",
        color: null,
        lineColor: null,
        risingColor: "white",
        fallingColor: "red",
        fillOpacity: null,
        startAngle: 0,
        radius: null,
        innerRadius: null,
        neckHeight: null,
        neckWidth: null,
        reversed: !1,
        valueRepresents: null,
        linkedDataSeriesIndex: null,
        whiskerThickness: 2,
        whiskerDashType: "solid",
        whiskerColor: null,
        whiskerLength: null,
        stemThickness: 2,
        stemColor: null,
        stemDashType: "solid",
        upperBoxColor: "white",
        lowerBoxColor: "white",
        type: "column",
        xValueType: "number",
        axisXType: "primary",
        axisYType: "primary",
        axisXIndex: 0,
        axisYIndex: 0,
        xValueFormatString: null,
        yValueFormatString: null,
        zValueFormatString: null,
        percentFormatString: null,
        showInLegend: null,
        legendMarkerType: null,
        legendMarkerColor: null,
        legendText: null,
        legendMarkerBorderColor: s ? "transparent" : null,
        legendMarkerBorderThickness: 0,
        markerType: "circle",
        markerColor: null,
        markerSize: null,
        markerBorderColor: s ? "transparent" : null,
        markerBorderThickness: 0,
        mouseover: null,
        mouseout: null,
        mousemove: null,
        click: null,
        toolTipContent: null,
        visible: !0,
        publicProperties: {
          options: "readWrite",
          axisX: "readWrite",
          axisY: "readWrite",
          chart: "readOnly"
        }
      },
      TextBlock: {
        x: 0,
        y: 0,
        width: null,
        height: null,
        maxWidth: null,
        maxHeight: null,
        padding: 0,
        angle: 0,
        text: "",
        horizontalAlign: "center",
        fontSize: 12,
        fontFamily: "calibri",
        fontWeight: "normal",
        fontColor: "black",
        fontStyle: "normal",
        borderThickness: 0,
        borderColor: "black",
        cornerRadius: 0,
        backgroundColor: null,
        textBaseline: "top"
      },
      CultureInfo: {
        decimalSeparator: ".",
        digitGroupSeparator: ",",
        zoomText: "Zoom",
        panText: "Pan",
        resetText: "Reset",
        menuText: "More Options",
        saveJPGText: "Save as JPEG",
        savePNGText: "Save as PNG",
        printText: "Print",
        days: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
        shortDays: "Sun Mon Tue Wed Thu Fri Sat".split(" "),
        months: "January February March April May June July August September October November December".split(
          " "
        ),
        shortMonths: "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ")
      }
    },
    Ma = { en: {} },
    w = s ? "Trebuchet MS, Helvetica, sans-serif" : "Arial",
    Ga = s ? "Impact, Charcoal, sans-serif" : "Arial",
    Aa = {
      colorSet1: "#4F81BC #C0504E #9BBB58 #23BFAA #8064A1 #4AACC5 #F79647 #7F6084 #77A033 #33558B #E59566".split(
        " "
      ),
      colorSet2: "#6D78AD #51CDA0 #DF7970 #4C9CA0 #AE7D99 #C9D45C #5592AD #DF874D #52BCA8 #8E7AA3 #E3CB64 #C77B85 #C39762 #8DD17E #B57952 #FCC26C".split(
        " "
      ),
      colorSet3: "#8CA1BC #36845C #017E82 #8CB9D0 #708C98 #94838D #F08891 #0366A7 #008276 #EE7757 #E5BA3A #F2990B #03557B #782970".split(
        " "
      )
    },
    G,
    ga,
    S,
    ha,
    W;
  ga = "#333333";
  S = "#000000";
  G = "#666666";
  W = ha = "#000000";
  var ba = 20,
    H = 14,
    Xa = {
      colorSet: "colorSet1",
      backgroundColor: "#FFFFFF",
      title: {
        fontFamily: Ga,
        fontSize: 32,
        fontColor: ga,
        fontWeight: "normal",
        verticalAlign: "top",
        margin: 5
      },
      subtitles: [
        {
          fontFamily: Ga,
          fontSize: H,
          fontColor: ga,
          fontWeight: "normal",
          verticalAlign: "top",
          margin: 5
        }
      ],
      data: [
        {
          indexLabelFontFamily: w,
          indexLabelFontSize: H,
          indexLabelFontColor: ga,
          indexLabelFontWeight: "normal",
          indexLabelLineThickness: 1
        }
      ],
      axisX: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: ga,
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: S,
          labelFontWeight: "normal",
          lineThickness: 1,
          lineColor: G,
          tickThickness: 1,
          tickColor: G,
          gridThickness: 0,
          gridColor: G,
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FF7300",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FF7300",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: W,
            color: ha,
            thickness: 1,
            lineDashType: "dash"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      axisX2: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: ga,
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: S,
          labelFontWeight: "normal",
          lineThickness: 1,
          lineColor: G,
          tickThickness: 1,
          tickColor: G,
          gridThickness: 0,
          gridColor: G,
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FF7300",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FF7300",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: W,
            color: ha,
            thickness: 1,
            lineDashType: "dash"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      axisY: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: ga,
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: S,
          labelFontWeight: "normal",
          lineThickness: 1,
          lineColor: G,
          tickThickness: 1,
          tickColor: G,
          gridThickness: 1,
          gridColor: G,
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FF7300",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FF7300",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: W,
            color: ha,
            thickness: 1,
            lineDashType: "dash"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      axisY2: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: ga,
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: S,
          labelFontWeight: "normal",
          lineThickness: 1,
          lineColor: G,
          tickThickness: 1,
          tickColor: G,
          gridThickness: 1,
          gridColor: G,
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FF7300",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FF7300",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: W,
            color: ha,
            thickness: 1,
            lineDashType: "dash"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      legend: {
        fontFamily: w,
        fontSize: 14,
        fontColor: ga,
        fontWeight: "bold",
        verticalAlign: "bottom",
        horizontalAlign: "center"
      },
      toolTip: {
        fontFamily: w,
        fontSize: 14,
        fontStyle: "normal",
        cornerRadius: 0,
        borderThickness: 1
      }
    };
  S = ga = "#F5F5F5";
  G = "#FFFFFF";
  ha = "#40BAF1";
  W = "#F5F5F5";
  var ba = 20,
    H = 14,
    cb = {
      colorSet: "colorSet2",
      title: {
        fontFamily: w,
        fontSize: 33,
        fontColor: "#3A3A3A",
        fontWeight: "bold",
        verticalAlign: "top",
        margin: 5
      },
      subtitles: [
        {
          fontFamily: w,
          fontSize: H,
          fontColor: "#3A3A3A",
          fontWeight: "normal",
          verticalAlign: "top",
          margin: 5
        }
      ],
      data: [
        {
          indexLabelFontFamily: w,
          indexLabelFontSize: H,
          indexLabelFontColor: "#666666",
          indexLabelFontWeight: "normal",
          indexLabelLineThickness: 1
        }
      ],
      axisX: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: "#666666",
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#666666",
          labelFontWeight: "normal",
          lineThickness: 1,
          lineColor: "#BBBBBB",
          tickThickness: 1,
          tickColor: "#BBBBBB",
          gridThickness: 1,
          gridColor: "#BBBBBB",
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FFA500",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FFA500",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: "black",
            color: "black",
            thickness: 1,
            lineDashType: "dot"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      axisX2: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: "#666666",
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#666666",
          labelFontWeight: "normal",
          lineThickness: 1,
          lineColor: "#BBBBBB",
          tickColor: "#BBBBBB",
          tickThickness: 1,
          gridThickness: 1,
          gridColor: "#BBBBBB",
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FFA500",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FFA500",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: "black",
            color: "black",
            thickness: 1,
            lineDashType: "dot"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      axisY: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: "#666666",
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#666666",
          labelFontWeight: "normal",
          lineThickness: 0,
          lineColor: "#BBBBBB",
          tickColor: "#BBBBBB",
          tickThickness: 1,
          gridThickness: 1,
          gridColor: "#BBBBBB",
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FFA500",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FFA500",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: "black",
            color: "black",
            thickness: 1,
            lineDashType: "dot"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      axisY2: [
        {
          titleFontFamily: w,
          titleFontSize: ba,
          titleFontColor: "#666666",
          titleFontWeight: "normal",
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#666666",
          labelFontWeight: "normal",
          lineThickness: 0,
          lineColor: "#BBBBBB",
          tickColor: "#BBBBBB",
          tickThickness: 1,
          gridThickness: 1,
          gridColor: "#BBBBBB",
          stripLines: [
            {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#FFA500",
              labelFontWeight: "normal",
              labelBackgroundColor: null,
              color: "#FFA500",
              thickness: 1
            }
          ],
          crosshair: {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#EEEEEE",
            labelFontWeight: "normal",
            labelBackgroundColor: "black",
            color: "black",
            thickness: 1,
            lineDashType: "dot"
          },
          scaleBreaks: {
            type: "zigzag",
            spacing: "2%",
            lineColor: "#BBBBBB",
            lineThickness: 1,
            lineDashType: "solid"
          }
        }
      ],
      legend: {
        fontFamily: w,
        fontSize: 14,
        fontColor: "#3A3A3A",
        fontWeight: "bold",
        verticalAlign: "bottom",
        horizontalAlign: "center"
      },
      toolTip: {
        fontFamily: w,
        fontSize: 14,
        fontStyle: "normal",
        cornerRadius: 0,
        borderThickness: 1
      }
    };
  S = ga = "#F5F5F5";
  G = "#FFFFFF";
  ha = "#40BAF1";
  W = "#F5F5F5";
  ba = 20;
  H = 14;
  Ga = {
    colorSet: "colorSet12",
    backgroundColor: "#2A2A2A",
    title: {
      fontFamily: Ga,
      fontSize: 32,
      fontColor: ga,
      fontWeight: "normal",
      verticalAlign: "top",
      margin: 5
    },
    subtitles: [
      {
        fontFamily: Ga,
        fontSize: H,
        fontColor: ga,
        fontWeight: "normal",
        verticalAlign: "top",
        margin: 5
      }
    ],
    toolbar: {
      backgroundColor: "#666666",
      backgroundColorOnHover: "#FF7372",
      borderColor: "#FF7372",
      borderThickness: 1,
      fontColor: "#F5F5F5",
      fontColorOnHover: "#F5F5F5"
    },
    data: [
      {
        indexLabelFontFamily: w,
        indexLabelFontSize: H,
        indexLabelFontColor: S,
        indexLabelFontWeight: "normal",
        indexLabelLineThickness: 1
      }
    ],
    axisX: [
      {
        titleFontFamily: w,
        titleFontSize: ba,
        titleFontColor: S,
        titleFontWeight: "normal",
        labelFontFamily: w,
        labelFontSize: H,
        labelFontColor: S,
        labelFontWeight: "normal",
        lineThickness: 1,
        lineColor: G,
        tickThickness: 1,
        tickColor: G,
        gridThickness: 0,
        gridColor: G,
        stripLines: [
          {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#FF7300",
            labelFontWeight: "normal",
            labelBackgroundColor: null,
            color: "#FF7300",
            thickness: 1
          }
        ],
        crosshair: {
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#000000",
          labelFontWeight: "normal",
          labelBackgroundColor: W,
          color: ha,
          thickness: 1,
          lineDashType: "dash"
        },
        scaleBreaks: {
          type: "zigzag",
          spacing: "2%",
          lineColor: "#777777",
          lineThickness: 1,
          lineDashType: "solid",
          color: "#111111"
        }
      }
    ],
    axisX2: [
      {
        titleFontFamily: w,
        titleFontSize: ba,
        titleFontColor: S,
        titleFontWeight: "normal",
        labelFontFamily: w,
        labelFontSize: H,
        labelFontColor: S,
        labelFontWeight: "normal",
        lineThickness: 1,
        lineColor: G,
        tickThickness: 1,
        tickColor: G,
        gridThickness: 0,
        gridColor: G,
        stripLines: [
          {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#FF7300",
            labelFontWeight: "normal",
            labelBackgroundColor: null,
            color: "#FF7300",
            thickness: 1
          }
        ],
        crosshair: {
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#000000",
          labelFontWeight: "normal",
          labelBackgroundColor: W,
          color: ha,
          thickness: 1,
          lineDashType: "dash"
        },
        scaleBreaks: {
          type: "zigzag",
          spacing: "2%",
          lineColor: "#777777",
          lineThickness: 1,
          lineDashType: "solid",
          color: "#111111"
        }
      }
    ],
    axisY: [
      {
        titleFontFamily: w,
        titleFontSize: ba,
        titleFontColor: S,
        titleFontWeight: "normal",
        labelFontFamily: w,
        labelFontSize: H,
        labelFontColor: S,
        labelFontWeight: "normal",
        lineThickness: 1,
        lineColor: G,
        tickThickness: 1,
        tickColor: G,
        gridThickness: 1,
        gridColor: G,
        stripLines: [
          {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#FF7300",
            labelFontWeight: "normal",
            labelBackgroundColor: null,
            color: "#FF7300",
            thickness: 1
          }
        ],
        crosshair: {
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#000000",
          labelFontWeight: "normal",
          labelBackgroundColor: W,
          color: ha,
          thickness: 1,
          lineDashType: "dash"
        },
        scaleBreaks: {
          type: "zigzag",
          spacing: "2%",
          lineColor: "#777777",
          lineThickness: 1,
          lineDashType: "solid",
          color: "#111111"
        }
      }
    ],
    axisY2: [
      {
        titleFontFamily: w,
        titleFontSize: ba,
        titleFontColor: S,
        titleFontWeight: "normal",
        labelFontFamily: w,
        labelFontSize: H,
        labelFontColor: S,
        labelFontWeight: "normal",
        lineThickness: 1,
        lineColor: G,
        tickThickness: 1,
        tickColor: G,
        gridThickness: 1,
        gridColor: G,
        stripLines: [
          {
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: "#FF7300",
            labelFontWeight: "normal",
            labelBackgroundColor: null,
            color: "#FF7300",
            thickness: 1
          }
        ],
        crosshair: {
          labelFontFamily: w,
          labelFontSize: H,
          labelFontColor: "#000000",
          labelFontWeight: "normal",
          labelBackgroundColor: W,
          color: ha,
          thickness: 1,
          lineDashType: "dash"
        },
        scaleBreaks: {
          type: "zigzag",
          spacing: "2%",
          lineColor: "#777777",
          lineThickness: 1,
          lineDashType: "solid",
          color: "#111111"
        }
      }
    ],
    legend: {
      fontFamily: w,
      fontSize: 14,
      fontColor: ga,
      fontWeight: "bold",
      verticalAlign: "bottom",
      horizontalAlign: "center"
    },
    toolTip: {
      fontFamily: w,
      fontSize: 14,
      fontStyle: "normal",
      cornerRadius: 0,
      borderThickness: 1,
      fontColor: S,
      backgroundColor: "rgba(0, 0, 0, .7)"
    }
  };
  G = "#FFFFFF";
  S = ga = "#FAFAFA";
  ha = "#40BAF1";
  W = "#F5F5F5";
  var ba = 20,
    H = 14,
    ya = {
      light1: Xa,
      light2: cb,
      dark1: Ga,
      dark2: {
        colorSet: "colorSet2",
        backgroundColor: "#32373A",
        title: {
          fontFamily: w,
          fontSize: 32,
          fontColor: ga,
          fontWeight: "normal",
          verticalAlign: "top",
          margin: 5
        },
        subtitles: [
          {
            fontFamily: w,
            fontSize: H,
            fontColor: ga,
            fontWeight: "normal",
            verticalAlign: "top",
            margin: 5
          }
        ],
        toolbar: {
          backgroundColor: "#666666",
          backgroundColorOnHover: "#FF7372",
          borderColor: "#FF7372",
          borderThickness: 1,
          fontColor: "#F5F5F5",
          fontColorOnHover: "#F5F5F5"
        },
        data: [
          {
            indexLabelFontFamily: w,
            indexLabelFontSize: H,
            indexLabelFontColor: S,
            indexLabelFontWeight: "normal",
            indexLabelLineThickness: 1
          }
        ],
        axisX: [
          {
            titleFontFamily: w,
            titleFontSize: ba,
            titleFontColor: S,
            titleFontWeight: "normal",
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: S,
            labelFontWeight: "normal",
            lineThickness: 1,
            lineColor: G,
            tickThickness: 1,
            tickColor: G,
            gridThickness: 0,
            gridColor: G,
            stripLines: [
              {
                labelFontFamily: w,
                labelFontSize: H,
                labelFontColor: "#FF7300",
                labelFontWeight: "normal",
                labelBackgroundColor: null,
                color: "#FF7300",
                thickness: 1
              }
            ],
            crosshair: {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#000000",
              labelFontWeight: "normal",
              labelBackgroundColor: W,
              color: ha,
              thickness: 1,
              lineDashType: "dash"
            },
            scaleBreaks: {
              type: "zigzag",
              spacing: "2%",
              lineColor: "#777777",
              lineThickness: 1,
              lineDashType: "solid",
              color: "#111111"
            }
          }
        ],
        axisX2: [
          {
            titleFontFamily: w,
            titleFontSize: ba,
            titleFontColor: S,
            titleFontWeight: "normal",
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: S,
            labelFontWeight: "normal",
            lineThickness: 1,
            lineColor: G,
            tickThickness: 1,
            tickColor: G,
            gridThickness: 0,
            gridColor: G,
            stripLines: [
              {
                labelFontFamily: w,
                labelFontSize: H,
                labelFontColor: "#FF7300",
                labelFontWeight: "normal",
                labelBackgroundColor: null,
                color: "#FF7300",
                thickness: 1
              }
            ],
            crosshair: {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#000000",
              labelFontWeight: "normal",
              labelBackgroundColor: W,
              color: ha,
              thickness: 1,
              lineDashType: "dash"
            },
            scaleBreaks: {
              type: "zigzag",
              spacing: "2%",
              lineColor: "#777777",
              lineThickness: 1,
              lineDashType: "solid",
              color: "#111111"
            }
          }
        ],
        axisY: [
          {
            titleFontFamily: w,
            titleFontSize: ba,
            titleFontColor: S,
            titleFontWeight: "normal",
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: S,
            labelFontWeight: "normal",
            lineThickness: 0,
            lineColor: G,
            tickThickness: 1,
            tickColor: G,
            gridThickness: 1,
            gridColor: G,
            stripLines: [
              {
                labelFontFamily: w,
                labelFontSize: H,
                labelFontColor: "#FF7300",
                labelFontWeight: "normal",
                labelBackgroundColor: null,
                color: "#FF7300",
                thickness: 1
              }
            ],
            crosshair: {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#000000",
              labelFontWeight: "normal",
              labelBackgroundColor: W,
              color: ha,
              thickness: 1,
              lineDashType: "dash"
            },
            scaleBreaks: {
              type: "zigzag",
              spacing: "2%",
              lineColor: "#777777",
              lineThickness: 1,
              lineDashType: "solid",
              color: "#111111"
            }
          }
        ],
        axisY2: [
          {
            titleFontFamily: w,
            titleFontSize: ba,
            titleFontColor: S,
            titleFontWeight: "normal",
            labelFontFamily: w,
            labelFontSize: H,
            labelFontColor: S,
            labelFontWeight: "normal",
            lineThickness: 0,
            lineColor: G,
            tickThickness: 1,
            tickColor: G,
            gridThickness: 1,
            gridColor: G,
            stripLines: [
              {
                labelFontFamily: w,
                labelFontSize: H,
                labelFontColor: "#FF7300",
                labelFontWeight: "normal",
                labelBackgroundColor: null,
                color: "#FF7300",
                thickness: 1
              }
            ],
            crosshair: {
              labelFontFamily: w,
              labelFontSize: H,
              labelFontColor: "#000000",
              labelFontWeight: "normal",
              labelBackgroundColor: W,
              color: ha,
              thickness: 1,
              lineDashType: "dash"
            },
            scaleBreaks: {
              type: "zigzag",
              spacing: "2%",
              lineColor: "#777777",
              lineThickness: 1,
              lineDashType: "solid",
              color: "#111111"
            }
          }
        ],
        legend: {
          fontFamily: w,
          fontSize: 14,
          fontColor: ga,
          fontWeight: "bold",
          verticalAlign: "bottom",
          horizontalAlign: "center"
        },
        toolTip: {
          fontFamily: w,
          fontSize: 14,
          fontStyle: "normal",
          cornerRadius: 0,
          borderThickness: 1,
          fontColor: S,
          backgroundColor: "rgba(0, 0, 0, .7)"
        }
      },
      theme1: Xa,
      theme2: cb,
      theme3: Xa
    },
    R = {
      numberDuration: 1,
      yearDuration: 314496e5,
      monthDuration: 2592e6,
      weekDuration: 6048e5,
      dayDuration: 864e5,
      hourDuration: 36e5,
      minuteDuration: 6e4,
      secondDuration: 1e3,
      millisecondDuration: 1,
      dayOfWeekFromInt: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" ")
    },
    $a = {},
    sa = null,
    jb = function() {
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.backgroundColor &&
        ((this.ctx.fillStyle = this.backgroundColor),
        this.ctx.fillRect(0, 0, this.width, this.height));
    },
    kb = function(l, p, s) {
      p = Math.min(this.width, this.height);
      return Math.max("theme4" === this.theme ? 0 : 300 <= p ? 12 : 10, Math.round(p * (l / 400)));
    },
    Ba = (function() {
      var l = /D{1,4}|M{1,4}|Y{1,4}|h{1,2}|H{1,2}|m{1,2}|s{1,2}|f{1,3}|t{1,2}|T{1,2}|K|z{1,3}|"[^"]*"|'[^']*'/g,
        p = "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
        s = "Sun Mon Tue Wed Thu Fri Sat".split(" "),
        u = "January February March April May June July August September October November December".split(
          " "
        ),
        w = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" "),
        E = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
        C = /[^-+\dA-Z]/g;
      return function(y, H, L) {
        var Q = L ? L.days : p,
          G = L ? L.months : u,
          P = L ? L.shortDays : s,
          N = L ? L.shortMonths : w;
        L = "";
        var R = !1;
        y = y && y.getTime ? y : y ? new Date(y) : new Date();
        if (isNaN(y)) throw SyntaxError("invalid date");
        "UTC:" === H.slice(0, 4) && ((H = H.slice(4)), (R = !0));
        L = R ? "getUTC" : "get";
        var U = y[L + "Date"](),
          S = y[L + "Day"](),
          M = y[L + "Month"](),
          Y = y[L + "FullYear"](),
          a = y[L + "Hours"](),
          d = y[L + "Minutes"](),
          b = y[L + "Seconds"](),
          c = y[L + "Milliseconds"](),
          e = R ? 0 : y.getTimezoneOffset();
        return (L = H.replace(l, function(g) {
          switch (g) {
            case "D":
              return U;
            case "DD":
              return aa(U, 2);
            case "DDD":
              return P[S];
            case "DDDD":
              return Q[S];
            case "M":
              return M + 1;
            case "MM":
              return aa(M + 1, 2);
            case "MMM":
              return N[M];
            case "MMMM":
              return G[M];
            case "Y":
              return parseInt(String(Y).slice(-2));
            case "YY":
              return aa(String(Y).slice(-2), 2);
            case "YYY":
              return aa(String(Y).slice(-3), 3);
            case "YYYY":
              return aa(Y, 4);
            case "h":
              return a % 12 || 12;
            case "hh":
              return aa(a % 12 || 12, 2);
            case "H":
              return a;
            case "HH":
              return aa(a, 2);
            case "m":
              return d;
            case "mm":
              return aa(d, 2);
            case "s":
              return b;
            case "ss":
              return aa(b, 2);
            case "f":
              return String(c).slice(0, 1);
            case "ff":
              return aa(String(c).slice(0, 2), 2);
            case "fff":
              return aa(String(c).slice(0, 3), 3);
            case "t":
              return 12 > a ? "a" : "p";
            case "tt":
              return 12 > a ? "am" : "pm";
            case "T":
              return 12 > a ? "A" : "P";
            case "TT":
              return 12 > a ? "AM" : "PM";
            case "K":
              return R ? "UTC" : (String(y).match(E) || [""]).pop().replace(C, "");
            case "z":
              return (0 < e ? "-" : "+") + Math.floor(Math.abs(e) / 60);
            case "zz":
              return (0 < e ? "-" : "+") + aa(Math.floor(Math.abs(e) / 60), 2);
            case "zzz":
              return (
                (0 < e ? "-" : "+") + aa(Math.floor(Math.abs(e) / 60), 2) + aa(Math.abs(e) % 60, 2)
              );
            default:
              return g.slice(1, g.length - 1);
          }
        }));
      };
    })(),
    fa = function(l, p, s) {
      if (null === l) return "";
      if (!isFinite(l)) return l;
      l = Number(l);
      var u = 0 > l ? !0 : !1;
      u && (l *= -1);
      var w = s ? s.decimalSeparator : ".",
        E = s ? s.digitGroupSeparator : ",",
        C = "";
      p = String(p);
      var C = 1,
        y = (s = ""),
        H = -1,
        L = [],
        Q = [],
        G = 0,
        P = 0,
        R = 0,
        N = !1,
        U = 0,
        y = p.match(/"[^"]*"|'[^']*'|[eE][+-]*[0]+|[,]+[.]|\u2030|./g);
      p = null;
      for (var S = 0; y && S < y.length; S++)
        if (((p = y[S]), "." === p && 0 > H)) H = S;
        else {
          if ("%" === p) C *= 100;
          else if ("\u2030" === p) {
            C *= 1e3;
            continue;
          } else if ("," === p[0] && "." === p[p.length - 1]) {
            C /= Math.pow(1e3, p.length - 1);
            H = S + p.length - 1;
            continue;
          } else ("E" !== p[0] && "e" !== p[0]) || "0" !== p[p.length - 1] || (N = !0);
          0 > H
            ? (L.push(p), "#" === p || "0" === p ? G++ : "," === p && R++)
            : (Q.push(p), ("#" !== p && "0" !== p) || P++);
        }
      N &&
        ((p = Math.floor(l)),
        (y = -Math.floor(Math.log(l) / Math.LN10 + 1)),
        (U = 0 === l ? 0 : 0 === p ? -(G + y) : String(p).length - G),
        (C /= Math.pow(10, U)));
      0 > H && (H = S);
      C = (l * C).toFixed(P);
      p = C.split(".");
      C = (p[0] + "").split("");
      l = (p[1] + "").split("");
      C && "0" === C[0] && C.shift();
      for (N = y = S = P = H = 0; 0 < L.length; )
        if (((p = L.pop()), "#" === p || "0" === p))
          if ((H++, H === G)) {
            var M = C,
              C = [];
            if ("0" === p) for (p = G - P - (M ? M.length : 0); 0 < p; ) M.unshift("0"), p--;
            for (; 0 < M.length; )
              (s = M.pop() + s), N++, 0 === N % y && S === R && 0 < M.length && (s = E + s);
          } else
            0 < C.length ? ((s = C.pop() + s), P++, N++) : "0" === p && ((s = "0" + s), P++, N++),
              0 === N % y && S === R && 0 < C.length && (s = E + s);
        else
          ("E" !== p[0] && "e" !== p[0]) || "0" !== p[p.length - 1] || !/[eE][+-]*[0]+/.test(p)
            ? "," === p
              ? (S++, (y = N), (N = 0), 0 < C.length && (s = E + s))
              : (s =
                  1 < p.length &&
                  (('"' === p[0] && '"' === p[p.length - 1]) ||
                    ("'" === p[0] && "'" === p[p.length - 1]))
                    ? p.slice(1, p.length - 1) + s
                    : p + s)
            : ((p = 0 > U ? p.replace("+", "").replace("-", "") : p.replace("-", "")),
              (s += p.replace(/[0]+/, function(l) {
                return aa(U, l.length);
              })));
      E = "";
      for (L = !1; 0 < Q.length; )
        (p = Q.shift()),
          "#" === p || "0" === p
            ? 0 < l.length && 0 !== Number(l.join(""))
              ? ((E += l.shift()), (L = !0))
              : "0" === p && ((E += "0"), (L = !0))
            : 1 < p.length &&
              (('"' === p[0] && '"' === p[p.length - 1]) ||
                ("'" === p[0] && "'" === p[p.length - 1]))
            ? (E += p.slice(1, p.length - 1))
            : ("E" !== p[0] && "e" !== p[0]) || "0" !== p[p.length - 1] || !/[eE][+-]*[0]+/.test(p)
            ? (E += p)
            : ((p = 0 > U ? p.replace("+", "").replace("-", "") : p.replace("-", "")),
              (E += p.replace(/[0]+/, function(l) {
                return aa(U, l.length);
              })));
      s += (L ? w : "") + E;
      return u ? "-" + s : s;
    },
    Ra = function(l) {
      var p = 0,
        s = 0;
      l = l || window.event;
      l.offsetX || 0 === l.offsetX
        ? ((p = l.offsetX), (s = l.offsetY))
        : l.layerX || 0 == l.layerX
        ? ((p = l.layerX), (s = l.layerY))
        : ((p = l.pageX - l.target.offsetLeft), (s = l.pageY - l.target.offsetTop));
      return { x: p, y: s };
    },
    bb = !0,
    Ua = window.devicePixelRatio || 1,
    Pa = 1,
    ka = bb ? Ua / Pa : 1,
    V = function(l, p, s, u, w, E, C, y, H, L, Q, P, N) {
      "undefined" === typeof N && (N = 1);
      C = C || 0;
      y = y || "black";
      var G = 15 < u - p && 15 < w - s ? 8 : 0.35 * Math.min(u - p, w - s);
      l.beginPath();
      l.moveTo(p, s);
      l.save();
      l.fillStyle = E;
      l.globalAlpha = N;
      l.fillRect(p, s, u - p, w - s);
      l.globalAlpha = 1;
      0 < C &&
        ((N = 0 === C % 2 ? 0 : 0.5),
        l.beginPath(),
        (l.lineWidth = C),
        (l.strokeStyle = y),
        l.moveTo(p, s),
        l.rect(p - N, s - N, u - p + 2 * N, w - s + 2 * N),
        l.stroke());
      l.restore();
      !0 === H &&
        (l.save(),
        l.beginPath(),
        l.moveTo(p, s),
        l.lineTo(p + G, s + G),
        l.lineTo(u - G, s + G),
        l.lineTo(u, s),
        l.closePath(),
        (C = l.createLinearGradient((u + p) / 2, s + G, (u + p) / 2, s)),
        C.addColorStop(0, E),
        C.addColorStop(1, "rgba(255, 255, 255, .4)"),
        (l.fillStyle = C),
        l.fill(),
        l.restore());
      !0 === L &&
        (l.save(),
        l.beginPath(),
        l.moveTo(p, w),
        l.lineTo(p + G, w - G),
        l.lineTo(u - G, w - G),
        l.lineTo(u, w),
        l.closePath(),
        (C = l.createLinearGradient((u + p) / 2, w - G, (u + p) / 2, w)),
        C.addColorStop(0, E),
        C.addColorStop(1, "rgba(255, 255, 255, .4)"),
        (l.fillStyle = C),
        l.fill(),
        l.restore());
      !0 === Q &&
        (l.save(),
        l.beginPath(),
        l.moveTo(p, s),
        l.lineTo(p + G, s + G),
        l.lineTo(p + G, w - G),
        l.lineTo(p, w),
        l.closePath(),
        (C = l.createLinearGradient(p + G, (w + s) / 2, p, (w + s) / 2)),
        C.addColorStop(0, E),
        C.addColorStop(1, "rgba(255, 255, 255, 0.1)"),
        (l.fillStyle = C),
        l.fill(),
        l.restore());
      !0 === P &&
        (l.save(),
        l.beginPath(),
        l.moveTo(u, s),
        l.lineTo(u - G, s + G),
        l.lineTo(u - G, w - G),
        l.lineTo(u, w),
        (C = l.createLinearGradient(u - G, (w + s) / 2, u, (w + s) / 2)),
        C.addColorStop(0, E),
        C.addColorStop(1, "rgba(255, 255, 255, 0.1)"),
        (l.fillStyle = C),
        C.addColorStop(0, E),
        C.addColorStop(1, "rgba(255, 255, 255, 0.1)"),
        (l.fillStyle = C),
        l.fill(),
        l.closePath(),
        l.restore());
    },
    ia = function(l) {
      for (var p = "", s = 0; s < l.length; s++)
        p += String.fromCharCode(Math.ceil(l.length / 57 / 5) ^ l.charCodeAt(s));
      return p;
    },
    hb =
      window &&
      window[ia("mnb`uhno")] &&
        window[ia("mnb`uhno")].href &&
        window[ia("mnb`uhno")].href.indexOf &&
        (-1 !== window[ia("mnb`uhno")].href.indexOf(ia("b`ow`rkr/bnl")) ||
          -1 !== window[ia("mnb`uhno")].href.indexOf(ia("gdonqhy/bnl")) ||
          -1 !== window[ia("mnb`uhno")].href.indexOf(ia("gheemd"))) &&
      -1 === window[ia("mnb`uhno")].href.indexOf(ia("gheemd")),
    ib = {
      reset: {
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAPjSURBVFhHxVdJaFNRFP1J/jwkP5MxsbaC1WJEglSxOFAXIsFpVRE3ggi1K90obioRRBA33XXnQnciirhQcMCdorgQxBkXWlREkFKsWkv5npvckp/XnzRpKh64kLw733fffe9L/wrL0+mVUdO8uTSZ3MBL/we2qg4rkuSpodCELstXE46ziVkLQ6FQcGOmeSSq6wd4aV50d3drWjj8kQKZJTUc9kxFGenv79dZrDksTSTWWJp2QYtEPiErysyzdX0LsxsCQR8keX8gs6RHIk8ysdgKFg2G53mhuOPsshTlBjKaFo1g7SqLNoShKLdFXT8huQ/paLSbxatYnc2mHMM4hr18Vi8TIvCmXF3vYrW6cF23gGTOk0M1wA4RKvOmq6vLZRVJipvmSWT6tZ6CSEYkco5V50VPT4+D7RwOqi6RiSZm0fJ+vggSqkeoypdsNmuyelNwbXsbgvkWYMtzDWNvWaijoyOBqE+hVK8abcssUeXQ/YfKyi0gFYv1Ipgfoj34fYGTJLOYJA0ODirok32GLN8XhUWCwSes1hIwBg6LydJ/tEeRRapAdUp+wSAiZchtZZWWgAZ+JNpD8peYXQVK9UwUxNpzOK8pq97kURZhYTCKBwPD7h2zK+js7Myi7D8Fod+0TkMI8+EMAngLGc/WtBFWawkFHFnoj/t9KLgGmF0B3QfkxC+EarxkdhnFYlFLY06USqUwL7UMjICHfh/wOc2sCqhpxGbCkLvL7EUDbF73+6DkmVWB6zi7xUDQSLeYvWjAILvm9zEnkJhlbRcDQZcv6Kg2AipyT/Axw6wKlqVSqxDdjF8Izfod13qURdrG/nxehY+xGh+h0CSzKygGvSNQIcc097BI24jb9hax6kj2E7OrMFX1il+ICEf2NrPbhiXLl+fYl+U7zK4iYdsDcyLGf+ofFlkwcN+s10KhmpuYhhtm0hCLVIFL0MDsqNlDIqy9x2CLs1jL6OvrI7vPRbtohXG6eFmsFnHDGAp6n9AgyuVySRZrGvROxRgIfLXhzjrNYnNBUxNX/dMgRWT1mt4XLDovaApD53E9W3ilNX5M55LJHpRtIsgAvciR4WWcgK2Dvb1YqgXevmF8z2zEBTcKG39EfSKsT9EbhVUaI2FZO+oZIqImxol6j66/hcAu4sSN4vc1ZPoKeoE6RGhYL2YYA+ymOSSi0Z0wWntbtkGUWCvfSDXIxONraZ/FY90KUfNTpfC5spnNLgxoYNnR9RO4F8ofXEHOgogCQE99w+fF2Xw+b7O59rEOsyRqGEfpVoaDMQQ1CZrG46bcM6AZ0C/wPqNfHliqejyTySxh9TqQpL+xmbIlkB9SlAAAAABJRU5ErkJggg=="
      },
      pan: {
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAICSURBVEhLxZbPahNRGMUn/5MpuAiBEAIufQGfzr5E40YptBXajYzudCEuGqS+gGlrFwquDGRTutBdYfydzJ3LzeQmJGZue+Dw/Z17Mnfmu5Pof9Hr9Z61Wq0bWZMKj263O6xWq99wU9lOpzPMKgEhEcRucNOcioOK+0RzBhNvt9tPV4nmVF19+OWhVqt9xXgFXZq+8lCv119UKpUJ7iX2FmvFTKz8RH34YdBsNk8wVtjE4fGYwm8wrrDi3WBG5oKXZGRSS9hGuNFojLTe2lFz5xThWZIktayyiE2FdT3rzXBXz7krKiL8c17wAKFDjCus2AvW+YGZ9y2JF0VFRuMPfI//rsCE/C+s26s4gQu9ul7r4NteKx7H8XOC724xNNGbaNu++IrBqbOV7Tj3FgMRvc/YKOr3+3sE47wgEt/Bl/gaK5cHbNU11vYSXylfpK7XOvjuumPp4Wcoipu30Qsez2uMXYz4lfI+mOmwothY+SLiXJy7mKVpWs3Si0CoOMfeI9Od43Wic+jO+ZVv+crsm9QSNhUW9LXSeoPBYLXopthGuFQgdIxxhY+UDwlt1x5CZ1hX+NTUdt/OIvjKaDSmuOJfaIVNPKX+W18j/PLA2/kR44p5Sd8HbHngT/yTfNRWUXX14ZcL3wmX0+TLf8YO7CGT8yFE5zB3/gney25/OETRP9CtPDFe5jShAAAAAElFTkSuQmCC"
      },
      zoom: {
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAALWSURBVEhLvZZLaBNRFIabyftBIgEfqCCBoCC6MYqiXYiIj4U76U4X7sUHbhQhUBfixhZEUBDB16YuFERaUaQLK7ooCOJj4UKtYEFU0EptShO/A9Ph3js3k8lo/eHnP7n3nP/M3LlzMz1hkUwmNziOcyKRSFyFt+LxeD/c2Wq1Ym7Kv0M2m11Os1OxWGycn1OwZXCGuXfwIhezkd9/jRgNT2L4ldhs1pbkX5OLJe4euVxuGQaPCa3mnUjtJx7BDuKusJTCV6jVVGHTMuYRjxma7yIOhTgFY6jNaAKew2xPKpVay9ganmkvj+M448/MfJdT5K5Gg4HJacRngPFgqVRaRNwW1B4i7yehWfsEDdz1K+A01AoxPIqGAiuwGfkOTY8+1A6u7AyiFTB2Hu0KPIrdiOnzHLWDybeImvy+Wq2mZa5bUHsD0Zpz+KxHdWQymV6kAb1ElqeORgJLvgnRdj1+R1AfzkIvSUjxVjQSarVakrueIPT8+H1F5jSUy+WXiJrUYBVWyVxU4PEU8TzhfaijUqnMIWrjaY492eWRwdKOIqrnIxnXwLLeRLwk2GQzrEMjg0avEbXxkIxr4OoOImpj2QwyFgms1koa/SZUG8s+0iGnEhNfCNXEhzIXBVz0McTzEvJ+70P9oNFtxEzei3aFYrFYxmuSUPWSv9Yi9IMm2xE1We56Mp1OV4nDwqFmBDV9gk9AEh4gZtFHNt8W4kAUCoXF5MorY9Z/kDni9nDv7hc0i2fhgLvTtX8a99PoMPPagTFPxofRzmDJ9yM+AyEmTfgGysYbQcfhDzPPJDmX0c7gDg4gs9BqFIWhm/Nct5H8gtBq1I7UfIbtvmIuoaGQcp+fdpbbSM43eEH5wrwLbXmhm/fU63VHXjcuok7hEByFY/AeHGC8L5/PL3HT5xGH1uYwfPOICGo+CBcU0vwO1BqzUqILDl/z/9VYIMfpddiAc47jDP8BsUpb13wOLRwAAAAASUVORK5CYII="
      },
      menu: {
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeCAYAAABE4bxTAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADoSURBVFhH7dc9CsJAFATgRxIIBCwCqZKATX5sbawsY2MvWOtF9AB6AU8gguAJbD2AnZ2VXQT/Ko2TYGCL2OYtYQc+BuYA+1hCtnCVwMm27SGaXpDJIAiCvCkVR05hGOZNN3HkFMdx3nQRR06+76/R1IcFLJlNQEWlmWlBTwJtKLKHynehZqnjOGM0PYWRVXk61C37p7xlZ3Hk5HneCk1dmMH811xGoKLSzDiQwIBZB4ocoPJdqNkDt2yKlueWRVGUtzy3rPwo3sWRU3nLjuLI6OO67oZM00wMw3hrmpZx0XU9syxrR0T0BeMpb9dneSR2AAAAAElFTkSuQmCC"
      },
      handle: {
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAAXNSR0IArs4c6QAAAAZiS0dEANAAzwDP4Z7KegAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB9sHGw0cMqdt1UwAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAaElEQVQoz+3SsRFAQBCF4Z9WJM8KCDVwownl6YXsTmCUsyKGkZzcl7zkz3YLkypgAnreFmDEpHkIwVOMfpdi9CEEN2nGpFdwD03yEqDtOgCaun7sqSTDH32I1pQA2Pb9sZecAxc5r3IAb21d6878xsAAAAAASUVORK5CYII="
      }
    };
  U.prototype.setOptions = function(l, p) {
    if (xa[this._defaultsKey]) {
      var s = xa[this._defaultsKey],
        u;
      for (u in s)
        "publicProperties" !== u &&
          s.hasOwnProperty(u) &&
          (this[u] = l && u in l ? l[u] : p && u in p ? p[u] : s[u]);
    } else Ja && window.console && console.log("defaults not set");
  };
  U.prototype.get = function(l) {
    var p = xa[this._defaultsKey];
    if ("options" === l) return this.options && this.options._isPlaceholder ? null : this.options;
    if (p.hasOwnProperty(l) || (p.publicProperties && p.publicProperties.hasOwnProperty(l)))
      return this[l];
    window.console &&
      window.console.log('Property "' + l + "\" doesn't exist. Please check for typo.");
  };
  U.prototype.set = function(l, p, s) {
    s = "undefined" === typeof s ? !0 : s;
    var u = xa[this._defaultsKey];
    if ("options" === l) this.createUserOptions(p);
    else if (
      u.hasOwnProperty(l) ||
      (u.publicProperties &&
        u.publicProperties.hasOwnProperty(l) &&
        "readWrite" === u.publicProperties[l])
    )
      this.options._isPlaceholder && this.createUserOptions(), (this.options[l] = p);
    else {
      window.console &&
        (u.publicProperties &&
        u.publicProperties.hasOwnProperty(l) &&
        "readOnly" === u.publicProperties[l]
          ? window.console.log('Property "' + l + '" is read-only.')
          : window.console.log('Property "' + l + "\" doesn't exist. Please check for typo."));
      return;
    }
    s && (this.stockChart || this.chart || this).render();
  };
  U.prototype.addTo = function(l, p, s, u) {
    u = "undefined" === typeof u ? !0 : u;
    var w = xa[this._defaultsKey];
    w.hasOwnProperty(l) ||
    (w.publicProperties &&
      w.publicProperties.hasOwnProperty(l) &&
      "readWrite" === w.publicProperties[l])
      ? (this.options._isPlaceholder && this.createUserOptions(),
        "undefined" === typeof this.options[l] && (this.options[l] = []),
        (l = this.options[l]),
        (s = "undefined" === typeof s || null === s ? l.length : s),
        l.splice(s, 0, p),
        u && (this.chart || this).render())
      : window.console &&
        (w.publicProperties &&
        w.publicProperties.hasOwnProperty(l) &&
        "readOnly" === w.publicProperties[l]
          ? window.console.log('Property "' + l + '" is read-only.')
          : window.console.log('Property "' + l + "\" doesn't exist. Please check for typo."));
  };
  U.prototype.createUserOptions = function(l) {
    if ("undefined" !== typeof l || this.options._isPlaceholder)
      if (
        (this.parent.options._isPlaceholder && this.parent.createUserOptions(),
        this.isOptionsInArray)
      ) {
        this.parent.options[this.optionsName] || (this.parent.options[this.optionsName] = []);
        var p = this.parent.options[this.optionsName],
          s = p.length;
        this.options._isPlaceholder || (Ea(p), (s = p.indexOf(this.options)));
        this.options = "undefined" === typeof l ? {} : l;
        p[s] = this.options;
      } else
        (this.options = "undefined" === typeof l ? {} : l),
          (l = this.parent.options),
          this.optionsName
            ? (p = this.optionsName)
            : (p = this._defaultsKey) && 0 !== p.length
            ? ((s = p.charAt(0).toLowerCase()), 1 < p.length && (s = s.concat(p.slice(1))), (p = s))
            : (p = void 0),
          (l[p] = this.options);
  };
  U.prototype.remove = function(l) {
    l = "undefined" === typeof l ? !0 : l;
    if (this.isOptionsInArray) {
      var p = this.parent.options[this.optionsName];
      Ea(p);
      var s = p.indexOf(this.options);
      0 <= s && p.splice(s, 1);
    } else delete this.parent.options[this.optionsName];
    l && (this.chart || this).render();
  };
  U.prototype.updateOption = function(l) {
    !xa[this._defaultsKey] && Ja && window.console && console.log("defaults not set");
    var p = xa[this._defaultsKey],
      s = {},
      w = this[l],
      H = this._themeOptionsKey,
      E = this._index;
    this.theme && u(H) && u(E)
      ? (s = u(ya[this.theme]) ? ya.light1 : ya[this.theme])
      : this.parent &&
        this.parent.themeOptions && this.parent.themeOptions[H] &&
        (null === E
          ? (s = this.parent.themeOptions[H])
          : 0 < this.parent.themeOptions[H].length &&
            ((s = Math.min(this.parent.themeOptions[H].length - 1, E)),
            (s = this.parent.themeOptions[H][s])));
    this.themeOptions = s;
    l in p && (w = l in this.options ? this.options[l] : s && l in s ? s[l] : p[l]);
    if (w === this[l]) return !1;
    this[l] = w;
    return !0;
  };
  U.prototype.trackChanges = function(l) {
    if (!this.sessionVariables) throw "Session Variable Store not set";
    this.sessionVariables[l] = this.options[l];
  };
  U.prototype.isBeingTracked = function(l) {
    this.options._oldOptions || (this.options._oldOptions = {});
    return this.options._oldOptions[l] ? !0 : !1;
  };
  U.prototype.hasOptionChanged = function(l) {
    if (!this.sessionVariables) throw "Session Variable Store not set";
    return this.sessionVariables[l] !== this.options[l];
  };
  U.prototype.addEventListener = function(l, p, s) {
    l &&
      p &&
      ((this._eventListeners[l] = this._eventListeners[l] || []),
      this._eventListeners[l].push({ context: s || this, eventHandler: p }));
  };
  U.prototype.removeEventListener = function(l, p) {
    if (l && p && this._eventListeners[l])
      for (var s = this._eventListeners[l], u = 0; u < s.length; u++)
        if (s[u].eventHandler === p) {
          s[u].splice(u, 1);
          break;
        }
  };
  U.prototype.removeAllEventListeners = function() {
    this._eventListeners = [];
  };
  U.prototype.dispatchEvent = function(l, p, s) {
    if (l && this._eventListeners[l]) {
      p = p || {};
      for (var u = this._eventListeners[l], w = 0; w < u.length; w++)
        u[w].eventHandler.call(u[w].context, p);
    }
    "function" === typeof this[l] && this[l].call(s || this.chart, p);
  };
  Fa.prototype.registerSpace = function(l, p) {
    "top" === l
      ? (this._topOccupied += p.height)
      : "bottom" === l
      ? (this._bottomOccupied += p.height)
      : "left" === l
      ? (this._leftOccupied += p.width)
      : "right" === l && (this._rightOccupied += p.width);
  };
  Fa.prototype.unRegisterSpace = function(l, p) {
    "top" === l
      ? (this._topOccupied -= p.height)
      : "bottom" === l
      ? (this._bottomOccupied -= p.height)
      : "left" === l
      ? (this._leftOccupied -= p.width)
      : "right" === l && (this._rightOccupied -= p.width);
  };
  Fa.prototype.getFreeSpace = function() {
    return {
      x1: this._x1 + this._leftOccupied,
      y1: this._y1 + this._topOccupied,
      x2: this._x2 - this._rightOccupied,
      y2: this._y2 - this._bottomOccupied,
      width: this._x2 - this._x1 - this._rightOccupied - this._leftOccupied,
      height: this._y2 - this._y1 - this._bottomOccupied - this._topOccupied
    };
  };
  Fa.prototype.reset = function() {
    this._rightOccupied = this._leftOccupied = this._bottomOccupied = this._topOccupied = this._padding;
  };
  pa(ja, U);
  ja.prototype._initialize = function() {
    u(this.padding) || "object" !== typeof this.padding
      ? (this.topPadding = this.rightPadding = this.bottomPadding = this.leftPadding =
          Number(this.padding) | 0)
      : ((this.topPadding = u(this.padding.top) ? 0 : Number(this.padding.top) | 0),
        (this.rightPadding = u(this.padding.right) ? 0 : Number(this.padding.right) | 0),
        (this.bottomPadding = u(this.padding.bottom) ? 0 : Number(this.padding.bottom) | 0),
        (this.leftPadding = u(this.padding.left) ? 0 : Number(this.padding.left) | 0));
  };
  ja.prototype.render = function(l) {
    if (0 !== this.fontSize) {
      l && this.ctx.save();
      var p = this.ctx.font;
      this.ctx.textBaseline = this.textBaseline;
      var s = 0;
      this._isDirty && this.measureText(this.ctx);
      this.ctx.translate(this.x, this.y + s);
      "middle" === this.textBaseline && (s = -this._lineHeight / 2);
      this.ctx.font = this._getFontString();
      this.ctx.rotate((Math.PI / 180) * this.angle);
      var u = 0,
        w = this.topPadding,
        E = null;
      this.ctx.roundRect || Da(this.ctx);
      ((0 < this.borderThickness && this.borderColor) || this.backgroundColor) &&
        this.ctx.roundRect(
          0,
          s,
          this.width,
          this.height,
          this.cornerRadius,
          this.borderThickness,
          this.backgroundColor,
          this.borderColor
        );
      this.ctx.fillStyle = this.fontColor;
      for (s = 0; s < this._wrappedText.lines.length; s++)
        (E = this._wrappedText.lines[s]),
          "right" === this.horizontalAlign
            ? (u =
                (this.width - (this.leftPadding + this.rightPadding)) / 2 -
                E.width / 2 +
                this.leftPadding)
            : "left" === this.horizontalAlign
            ? (u = this.leftPadding)
            : "center" === this.horizontalAlign &&
              (u =
                (this.width - (this.leftPadding + this.rightPadding)) / 2 -
                E.width / 2 +
                this.leftPadding),
          this.ctx.fillText(E.text, u, w),
          (w += E.height);
      this.ctx.font = p;
      l && this.ctx.restore();
    }
  };
  ja.prototype.setText = function(l) {
    this.text = l;
    this._isDirty = !0;
    this._wrappedText = null;
  };
  ja.prototype.measureText = function() {
    this._lineHeight = Za(this.fontFamily, this.fontSize, this.fontWeight);
    if (null === this.maxWidth) throw "Please set maxWidth and height for TextBlock";
    this._wrapText(this.ctx);
    this._isDirty = !1;
    return { width: this.width, height: this.height };
  };
  ja.prototype._getLineWithWidth = function(l, p, s) {
    l = String(l);
    if (!l) return { text: "", width: 0 };
    var u = (s = 0),
      w = l.length - 1,
      E = Infinity;
    for (this.ctx.font = this._getFontString(); u <= w; ) {
      var E = Math.floor((u + w) / 2),
        C = l.substr(0, E + 1);
      s = this.ctx.measureText(C).width;
      if (s < p) u = E + 1;
      else if (s > p) w = E - 1;
      else break;
    }
    s > p && 1 < C.length && ((C = C.substr(0, C.length - 1)), (s = this.ctx.measureText(C).width));
    p = !0;
    if (C.length === l.length || " " === l[C.length]) p = !1;
    p &&
      ((l = C.split(" ")),
      1 < l.length && l.pop(),
      (C = l.join(" ")),
      (s = this.ctx.measureText(C).width));
    return { text: C, width: s };
  };
  ja.prototype._wrapText = function() {
    var l = new String(Ha(String(this.text))),
      p = [],
      s = this.ctx.font,
      u = 0,
      w = 0;
    this.ctx.font = this._getFontString();
    if (0 === this.frontSize) w = u = 0;
    else
      for (; 0 < l.length; ) {
        var E = this.maxHeight - (this.topPadding + this.bottomPadding),
          C = this._getLineWithWidth(l, this.maxWidth - (this.leftPadding + this.rightPadding), !1);
        C.height = this._lineHeight;
        p.push(C);
        var y = w,
          w = Math.max(w, C.width),
          u = u + C.height,
          l = Ha(l.slice(C.text.length, l.length));
        E && u > E && ((C = p.pop()), (u -= C.height), (w = y));
      }
    this._wrappedText = { lines: p, width: w, height: u };
    this.width = w + (this.leftPadding + this.rightPadding);
    this.height = u + (this.topPadding + this.bottomPadding);
    this.ctx.font = s;
  };
  ja.prototype._getFontString = function() {
    var l;
    l = "" + (this.fontStyle ? this.fontStyle + " " : "");
    l += this.fontWeight ? this.fontWeight + " " : "";
    l += this.fontSize ? this.fontSize + "px " : "";
    var p = this.fontFamily ? this.fontFamily + "" : "";
    !s && p && ((p = p.split(",")[0]), "'" !== p[0] && '"' !== p[0] && (p = "'" + p + "'"));
    return (l += p);
  };
  pa(Va, U);
  pa(za, U);
  za.prototype.setLayout = function() {
    if (this.text) {
      var l = this.dockInsidePlotArea ? this.chart.plotArea : this.chart,
        p = l.layoutManager.getFreeSpace(),
        s = p.x1,
        w = p.y1,
        H = 0,
        E = 0,
        C =
          this.chart._menuButton && this.chart.exportEnabled && "top" === this.verticalAlign
            ? 22
            : 0,
        y,
        G;
      "top" === this.verticalAlign || "bottom" === this.verticalAlign
        ? (null === this.maxWidth &&
            (this.maxWidth = p.width - 4 - C * ("center" === this.horizontalAlign ? 2 : 1)),
          (E = 0.5 * p.height - this.margin - 2),
          (H = 0))
        : "center" === this.verticalAlign &&
          ("left" === this.horizontalAlign || "right" === this.horizontalAlign
            ? (null === this.maxWidth && (this.maxWidth = p.height - 4),
              (E = 0.5 * p.width - this.margin - 2))
            : "center" === this.horizontalAlign &&
              (null === this.maxWidth && (this.maxWidth = p.width - 4), (E = 0.5 * p.height - 4)));
      var L;
      u(this.padding) || "number" !== typeof this.padding
        ? u(this.padding) ||
          "object" !== typeof this.padding ||
          ((L = this.padding.top
            ? this.padding.top
            : this.padding.bottom
            ? this.padding.bottom
            : 0),
          (L += this.padding.bottom
            ? this.padding.bottom
            : this.padding.top
            ? this.padding.top
            : 0),
          (L *= 1.25))
        : (L = 2.5 * this.padding);
      this.wrap || (E = Math.min(E, Math.max(1.5 * this.fontSize, this.fontSize + L)));
      E = new ja(this.ctx, {
        fontSize: this.fontSize,
        fontFamily: this.fontFamily,
        fontColor: this.fontColor,
        fontStyle: this.fontStyle,
        fontWeight: this.fontWeight,
        horizontalAlign: this.horizontalAlign,
        verticalAlign: this.verticalAlign,
        borderColor: this.borderColor,
        borderThickness: this.borderThickness,
        backgroundColor: this.backgroundColor,
        maxWidth: this.maxWidth,
        maxHeight: E,
        cornerRadius: this.cornerRadius,
        text: this.text,
        padding: this.padding,
        textBaseline: "top"
      });
      L = E.measureText();
      "top" === this.verticalAlign || "bottom" === this.verticalAlign
        ? ("top" === this.verticalAlign
            ? ((w = p.y1 + 2), (G = "top"))
            : "bottom" === this.verticalAlign && ((w = p.y2 - 2 - L.height), (G = "bottom")),
          "left" === this.horizontalAlign
            ? (s = p.x1 + 2)
            : "center" === this.horizontalAlign
            ? (s = p.x1 + p.width / 2 - L.width / 2)
            : "right" === this.horizontalAlign && (s = p.x2 - 2 - L.width - C),
          (y = this.horizontalAlign),
          (this.width = L.width),
          (this.height = L.height))
        : "center" === this.verticalAlign &&
          ("left" === this.horizontalAlign
            ? ((s = p.x1 + 2),
              (w = p.y2 - 2 - (this.maxWidth / 2 - L.width / 2)),
              (H = -90),
              (G = "left"),
              (this.width = L.height),
              (this.height = L.width))
            : "right" === this.horizontalAlign
            ? ((s = p.x2 - 2),
              (w = p.y1 + 2 + (this.maxWidth / 2 - L.width / 2)),
              (H = 90),
              (G = "right"),
              (this.width = L.height),
              (this.height = L.width))
            : "center" === this.horizontalAlign &&
              ((w = l.y1 + (l.height / 2 - L.height / 2)),
              (s = l.x1 + (l.width / 2 - L.width / 2)),
              (G = "center"),
              (this.width = L.width),
              (this.height = L.height)),
          (y = "center"));
      E.x = s;
      E.y = w;
      E.angle = H;
      E.horizontalAlign = y;
      this._textBlock = E;
      l.layoutManager.registerSpace(G, {
        width: this.width + ("left" === G || "right" === G ? this.margin + 2 : 0),
        height: this.height + ("top" === G || "bottom" === G ? this.margin + 2 : 0)
      });
      this.bounds = { x1: s, y1: w, x2: s + this.width, y2: w + this.height };
      this.ctx.textBaseline = "top";
    }
  };
  za.prototype.render = function() {
    this._textBlock && this._textBlock.render(!0);
  };
  pa(Ka, U);
  Ka.prototype.setLayout = za.prototype.setLayout;
  Ka.prototype.render = za.prototype.render;
  Wa.prototype.get = function(l, p) {
    var s = null;
    0 < this.pool.length ? ((s = this.pool.pop()), Oa(s, l, p)) : (s = ua(l, p));
    return s;
  };
  Wa.prototype.release = function(l) {
    this.pool.push(l);
  };
  pa(La, U);
  var Na = {
    addTheme: function(l, p) {
      ya[l] = p;
    },
    addColorSet: function(l, p) {
      Aa[l] = p;
    },
    addCultureInfo: function(l, p) {
      Ma[l] = p;
    },
    formatNumber: function(l, p, s) {
      s = s || "en";
      if (Ma[s]) return fa(l, p || "#,##0.##", new La(s));
      throw "Unknown Culture Name";
    },
    formatDate: function(l, p, s) {
      s = s || "en";
      if (Ma[s]) return Ba(l, p || "DD MMM YYYY", new La(s));
      throw "Unknown Culture Name";
    }
  };
  "undefined" !== typeof module && "undefined" !== typeof module.exports
    ? (module.exports = Na)
    : "function" === typeof define && define.amd
    ? define([], function() {
        return Na;
      })
    : (window.CanvasJS = Na);
  Na.Chart = (function() {
    function l(a, d) {
      return a.x - d.x;
    }
    function p(a, d) {
      d = d || {};
      this.theme = u(d.theme) || u(ya[d.theme]) ? "light1" : d.theme;
      p.base.constructor.call(this, "Chart", null, d, null, null);
      var b = this;
      this._containerId = a;
      this._objectsInitialized = !1;
      this.overlaidCanvasCtx = this.ctx = null;
      this._indexLabels = [];
      this._panTimerId = 0;
      this._lastTouchEventType = "";
      this._lastTouchData = null;
      this.isAnimating = !1;
      this.renderCount = 0;
      this.disableToolTip = this.animatedRender = !1;
      this.canvasPool = new Wa();
      this.allDOMEventHandlers = [];
      this.panEnabled = !1;
      this._defaultCursor = "default";
      this.plotArea = { canvas: null, ctx: null, x1: 0, y1: 0, x2: 0, y2: 0, width: 0, height: 0 };
      this._dataInRenderedOrder = [];
      if (
        (this.container =
          "string" === typeof this._containerId
            ? document.getElementById(this._containerId)
            : this._containerId)
      ) {
        this.container.innerHTML = "";
        var c = 0,
          e = 0,
          c = this.options.width
            ? this.width
            : 0 < this.container.clientWidth
            ? this.container.clientWidth
            : this.width,
          e = this.options.height
            ? this.height
            : 0 < this.container.clientHeight
            ? this.container.clientHeight
            : this.height;
        this.width = c;
        this.height = e;
        this.x1 = this.y1 = 0;
        this.x2 = this.width;
        this.y2 = this.height;
        this._selectedColorSet =
          "undefined" !== typeof Aa[this.colorSet] ? Aa[this.colorSet] : Aa.colorSet1;
        this._canvasJSContainer = document.createElement("div");
        this._canvasJSContainer.setAttribute("class", "canvasjs-chart-container");
        this._canvasJSContainer.style.position = "relative";
        this._canvasJSContainer.style.textAlign = "left";
        this._canvasJSContainer.style.cursor = "auto";
        s || (this._canvasJSContainer.style.height = "0px");
        this.container.appendChild(this._canvasJSContainer);
        this.canvas = ua(c, e);
        this._preRenderCanvas = ua(c, e);
        this.canvas.style.position = "absolute";
        this.canvas.style.WebkitUserSelect = "none";
        this.canvas.style.MozUserSelect = "none";
        this.canvas.style.msUserSelect = "none";
        this.canvas.style.userSelect = "none";
        this.canvas.getContext &&
          (this._canvasJSContainer.appendChild(this.canvas),
          (this.ctx = this.canvas.getContext("2d")),
          (this.ctx.textBaseline = "top"),
          Da(this.ctx),
          (this._preRenderCtx = this._preRenderCanvas.getContext("2d")),
          (this._preRenderCtx.textBaseline = "top"),
          Da(this._preRenderCtx),
          s
            ? (this.plotArea.ctx = this.ctx)
            : ((this.plotArea.canvas = ua(c, e)),
              (this.plotArea.canvas.style.position = "absolute"),
              this.plotArea.canvas.setAttribute("class", "plotAreaCanvas"),
              this._canvasJSContainer.appendChild(this.plotArea.canvas),
              (this.plotArea.ctx = this.plotArea.canvas.getContext("2d"))),
          (this.overlaidCanvas = ua(c, e)),
          (this.overlaidCanvas.style.position = "absolute"),
          (this.overlaidCanvas.style.webkitTapHighlightColor = "transparent"),
          (this.overlaidCanvas.style.WebkitUserSelect = "none"),
          (this.overlaidCanvas.style.MozUserSelect = "none"),
          (this.overlaidCanvas.style.msUserSelect = "none"),
          (this.overlaidCanvas.style.userSelect = "none"),
          this.overlaidCanvas.getContext &&
            (this._canvasJSContainer.appendChild(this.overlaidCanvas),
            (this.overlaidCanvasCtx = this.overlaidCanvas.getContext("2d")),
            (this.overlaidCanvasCtx.textBaseline = "top"),
            Da(this.overlaidCanvasCtx)),
          (this._eventManager = new ha(this)),
          (this.windowResizeHandler = N(
            window,
            "resize",
            function() {
              b._updateSize() && b.render();
            },
            this.allDOMEventHandlers
          )),
          (this._toolBar = document.createElement("div")),
          this._toolBar.setAttribute("class", "canvasjs-chart-toolbar"),
          (this._toolBar.style.cssText = "position: absolute; right: 1px; top: 1px;"),
          this._canvasJSContainer.appendChild(this._toolBar),
          (this.bounds = { x1: 0, y1: 0, x2: this.width, y2: this.height }),
          N(
            this.overlaidCanvas,
            "click",
            function(a) {
              b._mouseEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            "mousemove",
            function(a) {
              b._mouseEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            "mouseup",
            function(a) {
              b._mouseEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            "mousedown",
            function(a) {
              b._mouseEventHandler(a);
              wa(b._dropdownMenu);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            "mouseout",
            function(a) {
              b._mouseEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            window.navigator.msPointerEnabled ? "MSPointerDown" : "touchstart",
            function(a) {
              b._touchEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            window.navigator.msPointerEnabled ? "MSPointerMove" : "touchmove",
            function(a) {
              b._touchEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            window.navigator.msPointerEnabled ? "MSPointerUp" : "touchend",
            function(a) {
              b._touchEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          N(
            this.overlaidCanvas,
            window.navigator.msPointerEnabled ? "MSPointerCancel" : "touchcancel",
            function(a) {
              b._touchEventHandler(a);
            },
            this.allDOMEventHandlers
          ),
          (this.toolTip = new aa(this, this.options.toolTip)),
          (this.data = null),
          (this.axisX = []),
          (this.axisX2 = []),
          (this.axisY = []),
          (this.axisY2 = []),
          (this.sessionVariables = { axisX: [], axisX2: [], axisY: [], axisY2: [] }));
      } else
        window.console &&
          window.console.log(
            'CanvasJS Error: Chart Container with id "' + this._containerId + '" was not found'
          );
    }
    function w(a, d) {
      for (var b = [], c, e = 0; e < a.length; e++)
        if (0 == e) b.push(a[0]);
        else {
          var g, h, k;
          k = e - 1;
          g = 0 === k ? 0 : k - 1;
          h = k === a.length - 1 ? k : k + 1;
          c =
            (Math.abs((a[h].x - a[g].x) / (0 === a[h].x - a[k].x ? 0.01 : a[h].x - a[k].x)) *
              (d - 1)) /
              2 +
            1;
          var v = (a[h].x - a[g].x) / c;
          c = (a[h].y - a[g].y) / c;
          b[b.length] =
            (a[k].x > a[g].x && 0 < v) || (a[k].x < a[g].x && 0 > v)
              ? { x: a[k].x + v / 3, y: a[k].y + c / 3 }
              : { x: a[k].x, y: a[k].y + c / 9 };
          k = e;
          g = 0 === k ? 0 : k - 1;
          h = k === a.length - 1 ? k : k + 1;
          c =
            (Math.abs((a[h].x - a[g].x) / (0 === a[k].x - a[g].x ? 0.01 : a[k].x - a[g].x)) *
              (d - 1)) /
              2 +
            1;
          v = (a[h].x - a[g].x) / c;
          c = (a[h].y - a[g].y) / c;
          b[b.length] =
            (a[k].x > a[g].x && 0 < v) || (a[k].x < a[g].x && 0 > v)
              ? { x: a[k].x - v / 3, y: a[k].y - c / 3 }
              : { x: a[k].x, y: a[k].y - c / 9 };
          b[b.length] = a[e];
        }
      return b;
    }
    function H(a, d, b, c, e, g, h, k, v, m) {
      var r = 0;
      m ? ((h.color = g), (k.color = g)) : (m = 1);
      r = v ? Math.abs(e - b) : Math.abs(c - d);
      r = 0 < h.trimLength ? Math.abs((r * h.trimLength) / 100) : Math.abs(r - h.length);
      v ? ((b += r / 2), (e -= r / 2)) : ((d += r / 2), (c -= r / 2));
      var r = 1 === Math.round(h.thickness) % 2 ? 0.5 : 0,
        q = 1 === Math.round(k.thickness) % 2 ? 0.5 : 0;
      a.save();
      a.globalAlpha = m;
      a.strokeStyle = k.color || g;
      a.lineWidth = k.thickness || 2;
      a.setLineDash && a.setLineDash(Q(k.dashType, k.thickness));
      a.beginPath();
      v && 0 < k.thickness
        ? (a.moveTo(c - h.thickness / 2, Math.round((b + e) / 2) - q),
          a.lineTo(d + h.thickness / 2, Math.round((b + e) / 2) - q))
        : 0 < k.thickness &&
          (a.moveTo(Math.round((d + c) / 2) - q, b + h.thickness / 2),
          a.lineTo(Math.round((d + c) / 2) - q, e - h.thickness / 2));
      a.stroke();
      a.strokeStyle = h.color || g;
      a.lineWidth = h.thickness || 2;
      a.setLineDash && a.setLineDash(Q(h.dashType, h.thickness));
      a.beginPath();
      v && 0 < h.thickness
        ? (a.moveTo(c - r, b), a.lineTo(c - r, e), a.moveTo(d + r, b), a.lineTo(d + r, e))
        : 0 < h.thickness &&
          (a.moveTo(d, b + r), a.lineTo(c, b + r), a.moveTo(d, e - r), a.lineTo(c, e - r));
      a.stroke();
      a.restore();
    }
    function G(a, d, b, c, e) {
      if (null === a || "undefined" === typeof a) return "undefined" === typeof b ? d : b;
      a = parseFloat(a.toString()) * (0 <= a.toString().indexOf("%") ? d / 100 : 1);
      "undefined" !== typeof c &&
        ((a = Math.min(c, a)), "undefined" !== typeof e && (a = Math.max(e, a)));
      return !isNaN(a) && a <= d && 0 <= a ? a : "undefined" === typeof b ? d : b;
    }
    function E(a, d) {
      E.base.constructor.call(this, "Legend", "legend", d, null, a);
      this.chart = a;
      this.canvas = a.canvas;
      this.ctx = this.chart.ctx;
      this.ghostCtx = this.chart._eventManager.ghostCtx;
      this.items = [];
      this.optionsName = "legend";
      this.height = this.width = 0;
      this.orientation = null;
      this.dataSeries = [];
      this.bounds = { x1: null, y1: null, x2: null, y2: null };
      "undefined" === typeof this.options.fontSize &&
        (this.fontSize = this.chart.getAutoFontSize(this.fontSize));
      this.lineHeight = Za(this.fontFamily, this.fontSize, this.fontWeight);
      this.horizontalSpacing = this.fontSize;
    }
    function C(a, d, b, c) {
      C.base.constructor.call(this, "DataSeries", "data", d, b, a);
      this.chart = a;
      this.canvas = a.canvas;
      this._ctx = a.canvas.ctx;
      this.index = b;
      this.noDataPointsInPlotArea = 0;
      this.id = c;
      this.chart._eventManager.objectMap[c] = {
        id: c,
        objectType: "dataSeries",
        dataSeriesIndex: b
      };
      a = d.dataPoints ? d.dataPoints.length : 0;
      this.dataPointEOs = [];
      for (d = 0; d < a; d++) this.dataPointEOs[d] = {};
      this.dataPointIds = [];
      this.plotUnit = [];
      this.axisY = this.axisX = null;
      this.optionsName = "data";
      this.isOptionsInArray = !0;
      null === this.fillOpacity &&
        (this.type.match(/area/i) ? (this.fillOpacity = 0.7) : (this.fillOpacity = 1));
      this.axisPlacement = this.getDefaultAxisPlacement();
      "undefined" === typeof this.options.indexLabelFontSize &&
        (this.indexLabelFontSize = this.chart.getAutoFontSize(this.indexLabelFontSize));
    }
    function y(a, d, b, c, e, g) {
      y.base.constructor.call(this, "Axis", d, b, c, a);
      this.chart = a;
      this.canvas = a.canvas;
      this.ctx = a.ctx;
      this.intervalStartPosition = this.maxHeight = this.maxWidth = 0;
      this.labels = [];
      this.dataSeries = [];
      this._stripLineLabels = this._ticks = this._labels = null;
      this.dataInfo = {
        min: Infinity,
        max: -Infinity,
        viewPortMin: Infinity,
        viewPortMax: -Infinity,
        minDiff: Infinity
      };
      this.isOptionsInArray = !0;
      "axisX" === e
        ? ("left" === g || "bottom" === g
            ? ((this.optionsName = "axisX"),
              u(this.chart.sessionVariables.axisX[c]) &&
                (this.chart.sessionVariables.axisX[c] = {}),
              (this.sessionVariables = this.chart.sessionVariables.axisX[c]))
            : ((this.optionsName = "axisX2"),
              u(this.chart.sessionVariables.axisX2[c]) &&
                (this.chart.sessionVariables.axisX2[c] = {}),
              (this.sessionVariables = this.chart.sessionVariables.axisX2[c])),
          this.options.interval || (this.intervalType = null))
        : "left" === g || "bottom" === g
        ? ((this.optionsName = "axisY"),
          u(this.chart.sessionVariables.axisY[c]) && (this.chart.sessionVariables.axisY[c] = {}),
          (this.sessionVariables = this.chart.sessionVariables.axisY[c]))
        : ((this.optionsName = "axisY2"),
          u(this.chart.sessionVariables.axisY2[c]) && (this.chart.sessionVariables.axisY2[c] = {}),
          (this.sessionVariables = this.chart.sessionVariables.axisY2[c]));
      "undefined" === typeof this.options.titleFontSize &&
        (this.titleFontSize = this.chart.getAutoFontSize(this.titleFontSize));
      "undefined" === typeof this.options.labelFontSize &&
        (this.labelFontSize = this.chart.getAutoFontSize(this.labelFontSize));
      this.type = e;
      "axisX" !== e || (b && "undefined" !== typeof b.gridThickness) || (this.gridThickness = 0);
      this._position = g;
      this.lineCoordinates = { x1: null, y1: null, x2: null, y2: null, width: null };
      this.labelAngle = ((this.labelAngle % 360) + 360) % 360;
      90 < this.labelAngle && 270 > this.labelAngle
        ? (this.labelAngle -= 180)
        : 270 <= this.labelAngle && 360 >= this.labelAngle && (this.labelAngle -= 360);
      this.options.scaleBreaks &&
        (this.scaleBreaks = new S(
          this.chart,
          this.options.scaleBreaks,
          ++this.chart._eventManager.lastObjectId,
          this
        ));
      this.stripLines = [];
      if (this.options.stripLines && 0 < this.options.stripLines.length)
        for (a = 0; a < this.options.stripLines.length; a++)
          this.stripLines.push(
            new ba(
              this.chart,
              this.options.stripLines[a],
              a,
              ++this.chart._eventManager.lastObjectId,
              this
            )
          );
      this.options.crosshair && (this.crosshair = new ga(this.chart, this.options.crosshair, this));
      this._titleTextBlock = null;
      this.hasOptionChanged("viewportMinimum") &&
        null === this.viewportMinimum &&
        ((this.options.viewportMinimum = void 0), (this.sessionVariables.viewportMinimum = null));
      this.hasOptionChanged("viewportMinimum") ||
      isNaN(this.sessionVariables.newViewportMinimum) ||
      null === this.sessionVariables.newViewportMinimum
        ? (this.sessionVariables.newViewportMinimum = null)
        : (this.viewportMinimum = this.sessionVariables.newViewportMinimum);
      this.hasOptionChanged("viewportMaximum") &&
        null === this.viewportMaximum &&
        ((this.options.viewportMaximum = void 0), (this.sessionVariables.viewportMaximum = null));
      this.hasOptionChanged("viewportMaximum") ||
      isNaN(this.sessionVariables.newViewportMaximum) ||
      null === this.sessionVariables.newViewportMaximum
        ? (this.sessionVariables.newViewportMaximum = null)
        : (this.viewportMaximum = this.sessionVariables.newViewportMaximum);
      null !== this.minimum &&
        null !== this.viewportMinimum &&
        (this.viewportMinimum = Math.max(this.viewportMinimum, this.minimum));
      null !== this.maximum &&
        null !== this.viewportMaximum &&
        (this.viewportMaximum = Math.min(this.viewportMaximum, this.maximum));
      this.trackChanges("viewportMinimum");
      this.trackChanges("viewportMaximum");
    }
    function S(a, d, b, c) {
      S.base.constructor.call(this, "ScaleBreaks", "scaleBreaks", d, null, c);
      this.id = b;
      this.chart = a;
      this.ctx = this.chart.ctx;
      this.axis = c;
      this.optionsName = "scaleBreaks";
      this.isOptionsInArray = !1;
      this._appliedBreaks = [];
      this.customBreaks = [];
      this.autoBreaks = [];
      "string" === typeof this.spacing
        ? ((this.spacing = parseFloat(this.spacing)),
          (this.spacing = isNaN(this.spacing) ? 8 : (10 < this.spacing ? 10 : this.spacing) + "%"))
        : "number" !== typeof this.spacing && (this.spacing = 8);
      this.autoCalculate && (this.maxNumberOfAutoBreaks = Math.min(this.maxNumberOfAutoBreaks, 5));
      if (this.options.customBreaks && 0 < this.options.customBreaks.length) {
        for (a = 0; a < this.options.customBreaks.length; a++)
          this.customBreaks.push(
            new L(
              this.chart,
              "customBreaks",
              this.options.customBreaks[a],
              a,
              ++this.chart._eventManager.lastObjectId,
              this
            )
          ),
            "number" === typeof this.customBreaks[a].startValue &&
              "number" === typeof this.customBreaks[a].endValue &&
                this.customBreaks[a].endValue !== this.customBreaks[a].startValue &&
              this._appliedBreaks.push(this.customBreaks[a]);
        this._appliedBreaks.sort(function(a, c) {
          return a.startValue - c.startValue;
        });
        for (a = 0; a < this._appliedBreaks.length - 1; a++)
          this._appliedBreaks[a].endValue >= this._appliedBreaks[a + 1].startValue &&
            ((this._appliedBreaks[a].endValue = Math.max(
              this._appliedBreaks[a].endValue,
              this._appliedBreaks[a + 1].endValue
            )),
            window.console &&
              window.console.log(
                "CanvasJS Error: Breaks " + a + " and " + (a + 1) + " are overlapping."
              ),
            this._appliedBreaks.splice(a, 2),
            a--);
      }
    }
    function L(a, d, b, c, e, g) {
      L.base.constructor.call(this, "Break", d, b, c, g);
      this.id = e;
      this.chart = a;
      this.ctx = this.chart.ctx;
      this.scaleBreaks = g;
      this.optionsName = d;
      this.isOptionsInArray = !0;
      this.type = b.type ? this.type : g.type;
      this.fillOpacity = u(b.fillOpacity) ? g.fillOpacity : this.fillOpacity;
      this.lineThickness = u(b.lineThickness) ? g.lineThickness : this.lineThickness;
      this.color = b.color ? this.color : g.color;
      this.lineColor = b.lineColor ? this.lineColor : g.lineColor;
      this.lineDashType = b.lineDashType ? this.lineDashType : g.lineDashType;
      !u(this.startValue) &&
        this.startValue.getTime &&
        (this.startValue = this.startValue.getTime());
      !u(this.endValue) && this.endValue.getTime && (this.endValue = this.endValue.getTime());
      "number" === typeof this.startValue &&
        "number" === typeof this.endValue && this.endValue < this.startValue &&
        ((a = this.startValue), (this.startValue = this.endValue), (this.endValue = a));
      this.spacing = "undefined" === typeof b.spacing ? g.spacing : b.spacing;
      "string" === typeof this.options.spacing
        ? ((this.spacing = parseFloat(this.spacing)),
          (this.spacing = isNaN(this.spacing) ? 0 : (10 < this.spacing ? 10 : this.spacing) + "%"))
        : "number" !== typeof this.options.spacing && (this.spacing = g.spacing);
      this.size = g.parent.logarithmic ? 1 : 0;
    }
    function ba(a, d, b, c, e) {
      ba.base.constructor.call(this, "StripLine", "stripLines", d, b, e);
      this.id = c;
      this.chart = a;
      this.ctx = this.chart.ctx;
      this.label = this.label;
      this.axis = e;
      this.optionsName = "stripLines";
      this.isOptionsInArray = !0;
      this._thicknessType = "pixel";
      null !== this.startValue &&
        null !== this.endValue &&
        ((this.value = e.logarithmic
          ? Math.sqrt(
              (this.startValue.getTime ? this.startValue.getTime() : this.startValue) *
                (this.endValue.getTime ? this.endValue.getTime() : this.endValue)
            )
          : ((this.startValue.getTime ? this.startValue.getTime() : this.startValue) +
              (this.endValue.getTime ? this.endValue.getTime() : this.endValue)) /
            2),
        (this._thicknessType = null));
    }
    function ga(a, d, b) {
      ga.base.constructor.call(this, "Crosshair", "crosshair", d, null, b);
      this.chart = a;
      this.ctx = this.chart.ctx;
      this.axis = b;
      this.optionsName = "crosshair";
      this._thicknessType = "pixel";
    }
    function aa(a, d) {
      aa.base.constructor.call(this, "ToolTip", "toolTip", d, null, a);
      this.chart = a;
      this.canvas = a.canvas;
      this.ctx = this.chart.ctx;
      this.currentDataPointIndex = this.currentSeriesIndex = -1;
      this._prevY = this._prevX = NaN;
      this.containerTransitionDuration = 0.1;
      this.mozContainerTransition = this.getContainerTransition(this.containerTransitionDuration);
      this.optionsName = "toolTip";
      this._initialize();
    }
    function ha(a) {
      this.chart = a;
      this.lastObjectId = 0;
      this.objectMap = [];
      this.rectangularRegionEventSubscriptions = [];
      this.previousDataPointEventObject = null;
      this.ghostCanvas = ua(this.chart.width, this.chart.height);
      this.ghostCtx = this.ghostCanvas.getContext("2d");
      this.mouseoveredObjectMaps = [];
    }
    function W(a) {
      this.chart = a;
      this.ctx = this.chart.plotArea.ctx;
      this.animations = [];
      this.animationRequestId = null;
    }
    pa(p, U);
    p.prototype.destroy = function() {
      var a = this.allDOMEventHandlers;
      this._animator && this._animator.cancelAllAnimations();
      this._panTimerId && clearTimeout(this._panTimerId);
      for (var d = 0; d < a.length; d++) {
        var b = a[d][0],
          c = a[d][1],
          e = a[d][2],
          g = a[d][3],
          g = g || !1;
        b.removeEventListener
          ? b.removeEventListener(c, e, g)
          : b.detachEvent && b.detachEvent("on" + c, e);
      }
      this.allDOMEventHandlers = [];
      for (
        this.removeAllEventListeners();
        this._canvasJSContainer && this._canvasJSContainer.hasChildNodes();

      )
        this._canvasJSContainer.removeChild(this._canvasJSContainer.lastChild);
      for (; this.container && this.container.hasChildNodes(); )
        this.container.removeChild(this.container.lastChild);
      for (; this._dropdownMenu && this._dropdownMenu.hasChildNodes(); )
        this._dropdownMenu.removeChild(this._dropdownMenu.lastChild);
      this.overlaidCanvas = this.canvas = this.container = this._canvasJSContainer = null;
      this._toolBar = this._dropdownMenu = this._menuButton = this._resetButton = this._zoomButton = this._breaksCanvas = this._preRenderCanvas = this.toolTip.container = null;
    };
    p.prototype._updateOptions = function() {
      var a = this;
      this.updateOption("width");
      this.updateOption("height");
      this.updateOption("dataPointWidth");
      this.updateOption("dataPointMinWidth");
      this.updateOption("dataPointMaxWidth");
      this.updateOption("interactivityEnabled");
      this.updateOption("theme");
      this.updateOption("colorSet") &&
        (this._selectedColorSet =
          "undefined" !== typeof Aa[this.colorSet] ? Aa[this.colorSet] : Aa.colorSet1);
      this.updateOption("backgroundColor");
      this.backgroundColor || (this.backgroundColor = "rgba(0,0,0,0)");
      this.updateOption("culture");
      this._cultureInfo = new La(this.options.culture);
      this.updateOption("animationEnabled");
      this.animationEnabled = this.animationEnabled && s;
      this.updateOption("animationDuration");
      this.updateOption("rangeChanging");
      this.updateOption("rangeChanged");
      this.updateOption("exportEnabled");
      this.updateOption("exportFileName");
      this.updateOption("zoomType");
      if (this.options.zoomEnabled) {
        if (!this._zoomButton) {
          var d = !1;
          wa((this._zoomButton = document.createElement("button")));
          va(this, this._zoomButton, "pan");
          this._toolBar.appendChild(this._zoomButton);
          this._zoomButton.style.borderRight =
            this.toolbar.borderThickness + "px solid " + this.toolbar.borderColor;
          N(
            this._zoomButton,
            "touchstart",
            function(a) {
              d = !0;
            },
            this.allDOMEventHandlers
          );
          N(
            this._zoomButton,
            "click",
            function() {
              a.zoomEnabled
                ? ((a.zoomEnabled = !1), (a.panEnabled = !0), va(a, a._zoomButton, "zoom"))
                : ((a.zoomEnabled = !0), (a.panEnabled = !1), va(a, a._zoomButton, "pan"));
              a.render();
            },
            this.allDOMEventHandlers
          );
          N(
            this._zoomButton,
            "mouseover",
            function() {
              d
                ? (d = !1)
                : (ra(a, a._zoomButton, {
                    backgroundColor: a.toolbar.backgroundColorOnHover,
                    color: a.toolbar.fontColorOnHover,
                    transition: "0.4s",
                    WebkitTransition: "0.4s"
                  }),
                  0 >= navigator.userAgent.search("MSIE") &&
                    ra(a, a._zoomButton.childNodes[0], {
                      WebkitFilter: "invert(100%)",
                      filter: "invert(100%)"
                    }));
            },
            this.allDOMEventHandlers
          );
          N(
            this._zoomButton,
            "mouseout",
            function() {
              d ||
                (ra(a, a._zoomButton, {
                  backgroundColor: a.toolbar.backgroundColor,
                  color: a.toolbar.fontColor,
                  transition: "0.4s",
                  WebkitTransition: "0.4s"
                }),
                0 >= navigator.userAgent.search("MSIE") &&
                  ra(a, a._zoomButton.childNodes[0], {
                    WebkitFilter: "invert(0%)",
                    filter: "invert(0%)"
                  }));
            },
            this.allDOMEventHandlers
          );
        }
        this._resetButton ||
          ((d = !1),
          wa((this._resetButton = document.createElement("button"))),
          va(this, this._resetButton, "reset"),
          (this._resetButton.style.borderRight =
            (this.exportEnabled ? this.toolbar.borderThickness : 0) +
            "px solid " +
            this.toolbar.borderColor),
          this._toolBar.appendChild(this._resetButton),
          N(
            this._resetButton,
            "touchstart",
            function(a) {
              d = !0;
            },
            this.allDOMEventHandlers
          ),
          N(
            this._resetButton,
            "click",
            function() {
              a.toolTip.hide();
              a.zoomEnabled || a.panEnabled
                ? ((a.zoomEnabled = !0),
                  (a.panEnabled = !1),
                  va(a, a._zoomButton, "pan"),
                  (a._defaultCursor = "default"),
                  (a.overlaidCanvas.style.cursor = a._defaultCursor))
                : ((a.zoomEnabled = !1), (a.panEnabled = !1));
              if (a.sessionVariables.axisX)
                for (var c = 0; c < a.sessionVariables.axisX.length; c++)
                  (a.sessionVariables.axisX[c].newViewportMinimum = null),
                    (a.sessionVariables.axisX[c].newViewportMaximum = null);
              if (a.sessionVariables.axisX2)
                for (c = 0; c < a.sessionVariables.axisX2.length; c++)
                  (a.sessionVariables.axisX2[c].newViewportMinimum = null),
                    (a.sessionVariables.axisX2[c].newViewportMaximum = null);
              if (a.sessionVariables.axisY)
                for (c = 0; c < a.sessionVariables.axisY.length; c++)
                  (a.sessionVariables.axisY[c].newViewportMinimum = null),
                    (a.sessionVariables.axisY[c].newViewportMaximum = null);
              if (a.sessionVariables.axisY2)
                for (c = 0; c < a.sessionVariables.axisY2.length; c++)
                  (a.sessionVariables.axisY2[c].newViewportMinimum = null),
                    (a.sessionVariables.axisY2[c].newViewportMaximum = null);
              a.resetOverlayedCanvas();
              wa(a._zoomButton, a._resetButton);
              a._dispatchRangeEvent("rangeChanging", "reset");
              a.render();
              a._dispatchRangeEvent("rangeChanged", "reset");
              a.syncCharts && a.syncCharts(null, null);
            },
            this.allDOMEventHandlers
          ),
          N(
            this._resetButton,
            "mouseover",
            function() {
              d ||
                (ra(a, a._resetButton, {
                  backgroundColor: a.toolbar.backgroundColorOnHover,
                  color: a.toolbar.hoverFfontColorOnHoverontColor,
                  transition: "0.4s",
                  WebkitTransition: "0.4s"
                }),
                0 >= navigator.userAgent.search("MSIE") &&
                  ra(a, a._resetButton.childNodes[0], {
                    WebkitFilter: "invert(100%)",
                    filter: "invert(100%)"
                  }));
            },
            this.allDOMEventHandlers
          ),
          N(
            this._resetButton,
            "mouseout",
            function() {
              d ||
                (ra(a, a._resetButton, {
                  backgroundColor: a.toolbar.backgroundColor,
                  color: a.toolbar.fontColor,
                  transition: "0.4s",
                  WebkitTransition: "0.4s"
                }),
                0 >= navigator.userAgent.search("MSIE") &&
                  ra(a, a._resetButton.childNodes[0], {
                    WebkitFilter: "invert(0%)",
                    filter: "invert(0%)"
                  }));
            },
            this.allDOMEventHandlers
          ),
          (this.overlaidCanvas.style.cursor = a._defaultCursor));
        this.zoomEnabled ||
          this.panEnabled ||
          (this._zoomButton
            ? (a._zoomButton.getAttribute("state") === a._cultureInfo.zoomText
                ? ((this.panEnabled = !0), (this.zoomEnabled = !1))
                : ((this.zoomEnabled = !0), (this.panEnabled = !1)),
              Qa(a._zoomButton, a._resetButton))
            : ((this.zoomEnabled = !0), (this.panEnabled = !1)));
      } else this.panEnabled = this.zoomEnabled = !1;
      this._menuButton
        ? this.exportEnabled
          ? Qa(this._menuButton)
          : wa(this._menuButton)
        : this.exportEnabled &&
          s &&
          ((d = !1),
          (this._menuButton = document.createElement("button")),
          va(this, this._menuButton, "menu"),
          this._toolBar.appendChild(this._menuButton),
          N(
            this._menuButton,
            "touchstart",
            function(a) {
              d = !0;
            },
            this.allDOMEventHandlers
          ),
          N(
            this._menuButton,
            "click",
            function() {
              "none" !== a._dropdownMenu.style.display ||
                (a._dropDownCloseTime &&
                  500 >= new Date().getTime() - a._dropDownCloseTime.getTime()) ||
                ((a._dropdownMenu.style.display = "block"),
                a._menuButton.blur(),
                a._dropdownMenu.focus());
            },
            this.allDOMEventHandlers,
            !0
          ),
          N(
            this._menuButton,
            "mouseover",
            function() {
              d ||
                (ra(a, a._menuButton, {
                  backgroundColor: a.toolbar.backgroundColorOnHover,
                  color: a.toolbar.fontColorOnHover
                }),
                0 >= navigator.userAgent.search("MSIE") &&
                  ra(a, a._menuButton.childNodes[0], {
                    WebkitFilter: "invert(100%)",
                    filter: "invert(100%)"
                  }));
            },
            this.allDOMEventHandlers,
            !0
          ),
          N(
            this._menuButton,
            "mouseout",
            function() {
              d ||
                (ra(a, a._menuButton, {
                  backgroundColor: a.toolbar.backgroundColor,
                  color: a.toolbar.fontColor
                }),
                0 >= navigator.userAgent.search("MSIE") &&
                  ra(a, a._menuButton.childNodes[0], {
                    WebkitFilter: "invert(0%)",
                    filter: "invert(0%)"
                  }));
            },
            this.allDOMEventHandlers,
            !0
          ));
      if (!this._dropdownMenu && this.exportEnabled && s) {
        d = !1;
        this._dropdownMenu = document.createElement("div");
        this._dropdownMenu.setAttribute("tabindex", -1);
        var b = -1 !== this.theme.indexOf("dark") ? "black" : "#888888";
        this._dropdownMenu.style.cssText =
          "position: absolute; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; cursor: pointer;right: 0px;top: 25px;min-width: 120px;outline: 0;font-size: 14px; font-family: Arial, Helvetica, sans-serif;padding: 5px 0px 5px 0px;text-align: left;line-height: 10px;background-color:" +
          this.toolbar.backgroundColor +
          ";box-shadow: 2px 2px 10px " +
          b;
        a._dropdownMenu.style.display = "none";
        this._toolBar.appendChild(this._dropdownMenu);
        N(
          this._dropdownMenu,
          "blur",
          function() {
            wa(a._dropdownMenu);
            a._dropDownCloseTime = new Date();
          },
          this.allDOMEventHandlers,
          !0
        );
        b = document.createElement("div");
        b.style.cssText = "padding: 12px 8px 12px 8px";
        b.innerHTML = this._cultureInfo.printText;
        b.style.backgroundColor = this.toolbar.backgroundColor;
        b.style.color = this.toolbar.fontColor;
        this._dropdownMenu.appendChild(b);
        N(
          b,
          "touchstart",
          function(a) {
            d = !0;
          },
          this.allDOMEventHandlers
        );
        N(
          b,
          "mouseover",
          function() {
            d ||
              ((this.style.backgroundColor = a.toolbar.backgroundColorOnHover),
              (this.style.color = a.toolbar.fontColorOnHover));
          },
          this.allDOMEventHandlers,
          !0
        );
        N(
          b,
          "mouseout",
          function() {
            d ||
              ((this.style.backgroundColor = a.toolbar.backgroundColor),
              (this.style.color = a.toolbar.fontColor));
          },
          this.allDOMEventHandlers,
          !0
        );
        N(
          b,
          "click",
          function() {
            a.print();
            wa(a._dropdownMenu);
          },
          this.allDOMEventHandlers,
          !0
        );
        b = document.createElement("div");
        b.style.cssText = "padding: 12px 8px 12px 8px";
        b.innerHTML = this._cultureInfo.saveJPGText;
        b.style.backgroundColor = this.toolbar.backgroundColor;
        b.style.color = this.toolbar.fontColor;
        this._dropdownMenu.appendChild(b);
        N(
          b,
          "touchstart",
          function(a) {
            d = !0;
          },
          this.allDOMEventHandlers
        );
        N(
          b,
          "mouseover",
          function() {
            d ||
              ((this.style.backgroundColor = a.toolbar.backgroundColorOnHover),
              (this.style.color = a.toolbar.fontColorOnHover));
          },
          this.allDOMEventHandlers,
          !0
        );
        N(
          b,
          "mouseout",
          function() {
            d ||
              ((this.style.backgroundColor = a.toolbar.backgroundColor),
              (this.style.color = a.toolbar.fontColor));
          },
          this.allDOMEventHandlers,
          !0
        );
        N(
          b,
          "click",
          function() {
            Ta(a.canvas, "jpeg", a.exportFileName);
            wa(a._dropdownMenu);
          },
          this.allDOMEventHandlers,
          !0
        );
        b = document.createElement("div");
        b.style.cssText = "padding: 12px 8px 12px 8px";
        b.innerHTML = this._cultureInfo.savePNGText;
        b.style.backgroundColor = this.toolbar.backgroundColor;
        b.style.color = this.toolbar.fontColor;
        this._dropdownMenu.appendChild(b);
        N(
          b,
          "touchstart",
          function(a) {
            d = !0;
          },
          this.allDOMEventHandlers
        );
        N(
          b,
          "mouseover",
          function() {
            d ||
              ((this.style.backgroundColor = a.toolbar.backgroundColorOnHover),
              (this.style.color = a.toolbar.fontColorOnHover));
          },
          this.allDOMEventHandlers,
          !0
        );
        N(
          b,
          "mouseout",
          function() {
            d ||
              ((this.style.backgroundColor = a.toolbar.backgroundColor),
              (this.style.color = a.toolbar.fontColor));
          },
          this.allDOMEventHandlers,
          !0
        );
        N(
          b,
          "click",
          function() {
            Ta(a.canvas, "png", a.exportFileName);
            wa(a._dropdownMenu);
          },
          this.allDOMEventHandlers,
          !0
        );
      }
      "none" !== this._toolBar.style.display &&
        this._zoomButton &&
        (this.panEnabled ? va(a, a._zoomButton, "zoom") : va(a, a._zoomButton, "pan"),
        a._resetButton.getAttribute("state") !== a._cultureInfo.resetText &&
          va(a, a._resetButton, "reset"));
      this.options.toolTip &&
        this.toolTip.options !== this.options.toolTip &&
        (this.toolTip.options = this.options.toolTip);
      for (var c in this.toolTip.options)
        this.toolTip.options.hasOwnProperty(c) && this.toolTip.updateOption(c);
    };
    p.prototype._updateSize = function() {
      var a;
      a = [this.canvas, this.overlaidCanvas, this._eventManager.ghostCanvas];
      var d = 0,
        b = 0;
      this.options.width
        ? (d = this.width)
        : (this.width = d =
            0 < this.container.clientWidth ? this.container.clientWidth : this.width);
      this.options.height
        ? (b = this.height)
        : (this.height = b =
            0 < this.container.clientHeight ? this.container.clientHeight : this.height);
      if (this.canvas.width !== d * ka || this.canvas.height !== b * ka) {
        for (var c = 0; c < a.length; c++) Oa(a[c], d, b);
        a = !0;
      } else a = !1;
      return a;
    };
    p.prototype._initialize = function() {
      this.isNavigator =
        u(this.parent) || u(this.parent._defaultsKey) || "Navigator" !== this.parent._defaultsKey
          ? !1
          : !0;
      this.toolbar = new Va(this, this.options.toolbar);
      this._animator ? this._animator.cancelAllAnimations() : (this._animator = new W(this));
      this.removeAllEventListeners();
      this.disableToolTip = !1;
      this._axes = [];
      this.funnelPyramidClickHandler = this.pieDoughnutClickHandler = null;
      this._updateOptions();
      this.animatedRender = s && this.animationEnabled && 0 === this.renderCount;
      this._updateSize();
      this.clearCanvas();
      this.ctx.beginPath();
      this.axisX = [];
      this.axisX2 = [];
      this.axisY = [];
      this.axisY2 = [];
      this._indexLabels = [];
      this._dataInRenderedOrder = [];
      this._events = [];
      this._eventManager && this._eventManager.reset();
      this.plotInfo = { axisPlacement: null, plotTypes: [] };
      this.layoutManager = new Fa(0, 0, this.width, this.height, this.isNavigator ? 0 : 2);
      this.plotArea.layoutManager && this.plotArea.layoutManager.reset();
      this.data = [];
      var a = 0,
        d = null;
      if (this.options.data) {
        for (var b = 0; b < this.options.data.length; b++)
          if (
            (a++,
            !this.options.data[b].type ||
              0 <= p._supportedChartTypes.indexOf(this.options.data[b].type))
          ) {
            var c = new C(this, this.options.data[b], a - 1, ++this._eventManager.lastObjectId);
            "error" === c.type &&
              ((c.linkedDataSeriesIndex = u(this.options.data[b].linkedDataSeriesIndex)
                ? b - 1
                : this.options.data[b].linkedDataSeriesIndex),
              0 > c.linkedDataSeriesIndex ||
                c.linkedDataSeriesIndex >= this.options.data.length ||
                "number" !== typeof c.linkedDataSeriesIndex ||
                "error" === this.options.data[c.linkedDataSeriesIndex].type) &&
              (c.linkedDataSeriesIndex = null);
            null === c.name && (c.name = "DataSeries " + a);
            null === c.color
              ? 1 < this.options.data.length
                ? ((c._colorSet = [
                    this._selectedColorSet[c.index % this._selectedColorSet.length]
                  ]),
                  (c.color = this._selectedColorSet[c.index % this._selectedColorSet.length]))
                : (c._colorSet =
                    "line" === c.type ||
                    "stepLine" === c.type ||
                    "spline" === c.type ||
                    "area" === c.type ||
                    "stepArea" === c.type ||
                    "splineArea" === c.type ||
                    "stackedArea" === c.type ||
                    "stackedArea100" === c.type ||
                    "rangeArea" === c.type ||
                    "rangeSplineArea" === c.type ||
                    "candlestick" === c.type ||
                    "ohlc" === c.type ||
                    "waterfall" === c.type ||
                    "boxAndWhisker" === c.type
                      ? [this._selectedColorSet[0]]
                      : this._selectedColorSet)
              : (c._colorSet = [c.color]);
            null === c.markerSize &&
              ((("line" === c.type ||
                "stepLine" === c.type ||
                "spline" === c.type ||
                0 <= c.type.toLowerCase().indexOf("area")) &&
                c.dataPoints &&
                c.dataPoints.length < this.width / 16) ||
                "scatter" === c.type) &&
              (c.markerSize = 8);
            ("bubble" !== c.type && "scatter" !== c.type) ||
              !c.dataPoints ||
              (c.dataPoints.some
                ? c.dataPoints.some(function(a) {
                    return a.x;
                  }) && c.dataPoints.sort(l)
                : c.dataPoints.sort(l));
            this.data.push(c);
            var e = c.axisPlacement,
              d = d || e,
              g;
            "normal" === e
              ? "xySwapped" === this.plotInfo.axisPlacement
                ? (g = 'You cannot combine "' + c.type + '" with bar chart')
                : "none" === this.plotInfo.axisPlacement
                ? (g = 'You cannot combine "' + c.type + '" with pie chart')
                : null === this.plotInfo.axisPlacement && (this.plotInfo.axisPlacement = "normal")
              : "xySwapped" === e
              ? "normal" === this.plotInfo.axisPlacement
                ? (g = 'You cannot combine "' + c.type + '" with line, area, column or pie chart')
                : "none" === this.plotInfo.axisPlacement
                ? (g = 'You cannot combine "' + c.type + '" with pie chart')
                : null === this.plotInfo.axisPlacement &&
                  (this.plotInfo.axisPlacement = "xySwapped")
              : "none" === e
              ? "normal" === this.plotInfo.axisPlacement
                ? (g = 'You cannot combine "' + c.type + '" with line, area, column or bar chart')
                : "xySwapped" === this.plotInfo.axisPlacement
                ? (g = 'You cannot combine "' + c.type + '" with bar chart')
                : null === this.plotInfo.axisPlacement && (this.plotInfo.axisPlacement = "none")
              : null === e &&
                "none" === this.plotInfo.axisPlacement &&
                (g = 'You cannot combine "' + c.type + '" with pie chart');
            if (g && window.console) {
              window.console.log(g);
              return;
            }
          }
        for (b = 0; b < this.data.length; b++) {
          if ("none" == d && "error" === this.data[b].type && window.console) {
            window.console.log('You cannot combine "' + c.type + '" with error chart');
            return;
          }
          "error" === this.data[b].type &&
            ((this.data[b].axisPlacement = this.plotInfo.axisPlacement = d || "normal"),
            (this.data[b]._linkedSeries =
              null === this.data[b].linkedDataSeriesIndex
                ? null
                : this.data[this.data[b].linkedDataSeriesIndex]));
        }
      }
      this._objectsInitialized = !0;
      this._plotAreaElements = [];
    };
    p._supportedChartTypes = Ea(
      "line stepLine spline column area stepArea splineArea bar bubble scatter stackedColumn stackedColumn100 stackedBar stackedBar100 stackedArea stackedArea100 candlestick ohlc boxAndWhisker rangeColumn error rangeBar rangeArea rangeSplineArea pie doughnut funnel pyramid waterfall".split(
        " "
      )
    );
    p.prototype.setLayout = function() {
      for (var a = this._plotAreaElements, d = 0; d < this.data.length; d++)
        if (
          "normal" === this.plotInfo.axisPlacement ||
          "xySwapped" === this.plotInfo.axisPlacement
        ) {
          if (!this.data[d].axisYType || "primary" === this.data[d].axisYType)
            if (this.options.axisY && 0 < this.options.axisY.length) {
              if (!this.axisY.length)
                for (var b = 0; b < this.options.axisY.length; b++)
                  "normal" === this.plotInfo.axisPlacement
                    ? this._axes.push(
                        (this.axisY[b] = new y(
                          this,
                          "axisY",
                          this.options.axisY[b],
                          b,
                          "axisY",
                          "left"
                        ))
                      )
                    : "xySwapped" === this.plotInfo.axisPlacement &&
                      this._axes.push(
                        (this.axisY[b] = new y(
                          this,
                          "axisY",
                          this.options.axisY[b],
                          b,
                          "axisY",
                          "bottom"
                        ))
                      );
              this.data[d].axisY = this.axisY[
                0 <= this.data[d].axisYIndex && this.data[d].axisYIndex < this.axisY.length
                  ? this.data[d].axisYIndex
                  : 0
              ];
              this.axisY[
                0 <= this.data[d].axisYIndex && this.data[d].axisYIndex < this.axisY.length
                  ? this.data[d].axisYIndex
                  : 0
              ].dataSeries.push(this.data[d]);
            } else
              this.axisY.length ||
                ("normal" === this.plotInfo.axisPlacement
                  ? this._axes.push(
                      (this.axisY[0] = new y(this, "axisY", this.options.axisY, 0, "axisY", "left"))
                    )
                  : "xySwapped" === this.plotInfo.axisPlacement &&
                    this._axes.push(
                      (this.axisY[0] = new y(
                        this,
                        "axisY",
                        this.options.axisY,
                        0,
                        "axisY",
                        "bottom"
                      ))
                    )),
                (this.data[d].axisY = this.axisY[0]),
                this.axisY[0].dataSeries.push(this.data[d]);
          if ("secondary" === this.data[d].axisYType)
            if (this.options.axisY2 && 0 < this.options.axisY2.length) {
              if (!this.axisY2.length)
                for (b = 0; b < this.options.axisY2.length; b++)
                  "normal" === this.plotInfo.axisPlacement
                    ? this._axes.push(
                        (this.axisY2[b] = new y(
                          this,
                          "axisY2",
                          this.options.axisY2[b],
                          b,
                          "axisY",
                          "right"
                        ))
                      )
                    : "xySwapped" === this.plotInfo.axisPlacement &&
                      this._axes.push(
                        (this.axisY2[b] = new y(
                          this,
                          "axisY2",
                          this.options.axisY2[b],
                          b,
                          "axisY",
                          "top"
                        ))
                      );
              this.data[d].axisY = this.axisY2[
                0 <= this.data[d].axisYIndex && this.data[d].axisYIndex < this.axisY2.length
                  ? this.data[d].axisYIndex
                  : 0
              ];
              this.axisY2[
                0 <= this.data[d].axisYIndex && this.data[d].axisYIndex < this.axisY2.length
                  ? this.data[d].axisYIndex
                  : 0
              ].dataSeries.push(this.data[d]);
            } else
              this.axisY2.length ||
                ("normal" === this.plotInfo.axisPlacement
                  ? this._axes.push(
                      (this.axisY2[0] = new y(
                        this,
                        "axisY2",
                        this.options.axisY2,
                        0,
                        "axisY",
                        "right"
                      ))
                    )
                  : "xySwapped" === this.plotInfo.axisPlacement &&
                    this._axes.push(
                      (this.axisY2[0] = new y(
                        this,
                        "axisY2",
                        this.options.axisY2,
                        0,
                        "axisY",
                        "top"
                      ))
                    )),
                (this.data[d].axisY = this.axisY2[0]),
                this.axisY2[0].dataSeries.push(this.data[d]);
          if (!this.data[d].axisXType || "primary" === this.data[d].axisXType)
            if (this.options.axisX && 0 < this.options.axisX.length) {
              if (!this.axisX.length)
                for (b = 0; b < this.options.axisX.length; b++)
                  "normal" === this.plotInfo.axisPlacement
                    ? this._axes.push(
                        (this.axisX[b] = new y(
                          this,
                          "axisX",
                          this.options.axisX[b],
                          b,
                          "axisX",
                          "bottom"
                        ))
                      )
                    : "xySwapped" === this.plotInfo.axisPlacement &&
                      this._axes.push(
                        (this.axisX[b] = new y(
                          this,
                          "axisX",
                          this.options.axisX[b],
                          b,
                          "axisX",
                          "left"
                        ))
                      );
              this.data[d].axisX = this.axisX[
                0 <= this.data[d].axisXIndex && this.data[d].axisXIndex < this.axisX.length
                  ? this.data[d].axisXIndex
                  : 0
              ];
              this.axisX[
                0 <= this.data[d].axisXIndex && this.data[d].axisXIndex < this.axisX.length
                  ? this.data[d].axisXIndex
                  : 0
              ].dataSeries.push(this.data[d]);
            } else
              this.axisX.length ||
                ("normal" === this.plotInfo.axisPlacement
                  ? this._axes.push(
                      (this.axisX[0] = new y(
                        this,
                        "axisX",
                        this.options.axisX,
                        0,
                        "axisX",
                        "bottom"
                      ))
                    )
                  : "xySwapped" === this.plotInfo.axisPlacement &&
                    this._axes.push(
                      (this.axisX[0] = new y(this, "axisX", this.options.axisX, 0, "axisX", "left"))
                    )),
                (this.data[d].axisX = this.axisX[0]),
                this.axisX[0].dataSeries.push(this.data[d]);
          if ("secondary" === this.data[d].axisXType)
            if (this.options.axisX2 && 0 < this.options.axisX2.length) {
              if (!this.axisX2.length)
                for (b = 0; b < this.options.axisX2.length; b++)
                  "normal" === this.plotInfo.axisPlacement
                    ? this._axes.push(
                        (this.axisX2[b] = new y(
                          this,
                          "axisX2",
                          this.options.axisX2[b],
                          b,
                          "axisX",
                          "top"
                        ))
                      )
                    : "xySwapped" === this.plotInfo.axisPlacement &&
                      this._axes.push(
                        (this.axisX2[b] = new y(
                          this,
                          "axisX2",
                          this.options.axisX2[b],
                          b,
                          "axisX",
                          "right"
                        ))
                      );
              this.data[d].axisX = this.axisX2[
                0 <= this.data[d].axisXIndex && this.data[d].axisXIndex < this.axisX2.length
                  ? this.data[d].axisXIndex
                  : 0
              ];
              this.axisX2[
                0 <= this.data[d].axisXIndex && this.data[d].axisXIndex < this.axisX2.length
                  ? this.data[d].axisXIndex
                  : 0
              ].dataSeries.push(this.data[d]);
            } else
              this.axisX2.length ||
                ("normal" === this.plotInfo.axisPlacement
                  ? this._axes.push(
                      (this.axisX2[0] = new y(
                        this,
                        "axisX2",
                        this.options.axisX2,
                        0,
                        "axisX",
                        "top"
                      ))
                    )
                  : "xySwapped" === this.plotInfo.axisPlacement &&
                    this._axes.push(
                      (this.axisX2[0] = new y(
                        this,
                        "axisX2",
                        this.options.axisX2,
                        0,
                        "axisX",
                        "right"
                      ))
                    )),
                (this.data[d].axisX = this.axisX2[0]),
                this.axisX2[0].dataSeries.push(this.data[d]);
        }
      if (this.axisY) {
        for (b = 1; b < this.axisY.length; b++)
          "undefined" === typeof this.axisY[b].options.gridThickness &&
            (this.axisY[b].gridThickness = 0);
        for (b = 0; b < this.axisY.length - 1; b++)
          "undefined" === typeof this.axisY[b].options.margin && (this.axisY[b].margin = 10);
      }
      if (this.axisY2) {
        for (b = 1; b < this.axisY2.length; b++)
          "undefined" === typeof this.axisY2[b].options.gridThickness &&
            (this.axisY2[b].gridThickness = 0);
        for (b = 0; b < this.axisY2.length - 1; b++)
          "undefined" === typeof this.axisY2[b].options.margin && (this.axisY2[b].margin = 10);
      }
      this.axisY &&
        0 < this.axisY.length &&
        this.axisY2 && 0 < this.axisY2.length &&
        (0 < this.axisY[0].gridThickness &&
        "undefined" === typeof this.axisY2[0].options.gridThickness
          ? (this.axisY2[0].gridThickness = 0)
          : 0 < this.axisY2[0].gridThickness &&
            "undefined" === typeof this.axisY[0].options.gridThickness &&
            (this.axisY[0].gridThickness = 0));
      if (this.axisX)
        for (b = 0; b < this.axisX.length; b++)
          "undefined" === typeof this.axisX[b].options.gridThickness &&
            (this.axisX[b].gridThickness = 0);
      if (this.axisX2)
        for (b = 0; b < this.axisX2.length; b++)
          "undefined" === typeof this.axisX2[b].options.gridThickness &&
            (this.axisX2[b].gridThickness = 0);
      this.axisX &&
        0 < this.axisX.length &&
        this.axisX2 && 0 < this.axisX2.length &&
        (0 < this.axisX[0].gridThickness &&
        "undefined" === typeof this.axisX2[0].options.gridThickness
          ? (this.axisX2[0].gridThickness = 0)
          : 0 < this.axisX2[0].gridThickness &&
            "undefined" === typeof this.axisX[0].options.gridThickness &&
            (this.axisX[0].gridThickness = 0));
      b = !1;
      if (0 < this._axes.length && (this.zoomEnabled || this.panEnabled))
        for (d = 0; d < this._axes.length; d++)
          if (null !== this._axes[d].viewportMinimum || null !== this._axes[d].viewportMaximum) {
            b = !0;
            break;
          }
      b
        ? (Qa(this._zoomButton, this._resetButton),
          (this._toolBar.style.border =
            this.toolbar.borderThickness + "px solid " + this.toolbar.borderColor),
          (this._zoomButton.style.borderRight =
            this.toolbar.borderThickness + "px solid " + this.toolbar.borderColor),
          (this._resetButton.style.borderRight =
            (this.exportEnabled ? this.toolbar.borderThickness : 0) +
            "px solid " +
            this.toolbar.borderColor))
        : (wa(this._zoomButton, this._resetButton),
          (this._toolBar.style.border = this.toolbar.borderThickness + "px solid transparent"),
          this.options.zoomEnabled && ((this.zoomEnabled = !0), (this.panEnabled = !1)));
      fb(this);
      this._processData();
      this.options.title &&
        ((this.title = new za(this, this.options.title)),
        this.title.dockInsidePlotArea ? a.push(this.title) : this.title.setLayout());
      this.subtitles = [];
      if (this.options.subtitles)
        for (d = 0; d < this.options.subtitles.length; d++)
          (b = new Ka(this, this.options.subtitles[d], d)),
            this.subtitles.push(b),
            b.dockInsidePlotArea ? a.push(b) : b.setLayout();
      this.legend = new E(this, this.options.legend);
      for (d = 0; d < this.data.length; d++)
        (this.data[d].showInLegend ||
          "pie" === this.data[d].type ||
          "doughnut" === this.data[d].type ||
          "funnel" === this.data[d].type ||
          "pyramid" === this.data[d].type) &&
          this.legend.dataSeries.push(this.data[d]);
      this.legend.dockInsidePlotArea ? a.push(this.legend) : this.legend.setLayout();
      for (d = 0; d < this._axes.length; d++)
        if (this._axes[d].scaleBreaks && this._axes[d].scaleBreaks._appliedBreaks.length) {
          s
            ? ((this._breaksCanvas = ua(this.width, this.height, !0)),
              (this._breaksCanvasCtx = this._breaksCanvas.getContext("2d")))
            : ((this._breaksCanvas = this.canvas), (this._breaksCanvasCtx = this.ctx));
          break;
        }
      this._preRenderCanvas = ua(this.width, this.height);
      this._preRenderCtx = this._preRenderCanvas.getContext("2d");
      ("normal" !== this.plotInfo.axisPlacement && "xySwapped" !== this.plotInfo.axisPlacement) ||
        y.setLayout(
          this.axisX,
          this.axisX2,
          this.axisY,
          this.axisY2,
          this.plotInfo.axisPlacement,
          this.layoutManager.getFreeSpace()
        );
    };
    p.prototype.renderElements = function() {
      var a = this._plotAreaElements;
      this.title && !this.title.dockInsidePlotArea && this.title.render();
      for (var d = 0; d < this.subtitles.length; d++)
        this.subtitles[d].dockInsidePlotArea || this.subtitles[d].render();
      this.legend.dockInsidePlotArea || this.legend.render();
      if ("normal" === this.plotInfo.axisPlacement || "xySwapped" === this.plotInfo.axisPlacement)
        y.render(this.axisX, this.axisX2, this.axisY, this.axisY2, this.plotInfo.axisPlacement);
      else if ("none" === this.plotInfo.axisPlacement) this.preparePlotArea();
      else return;
      for (d = 0; d < a.length; d++) a[d].setLayout(), a[d].render();
      var b = [];
      if (this.animatedRender) {
        var c = ua(this.width, this.height);
        c.getContext("2d").drawImage(this.canvas, 0, 0, this.width, this.height);
      }
      gb(this);
      var a = this.ctx.miterLimit,
        e;
      this.ctx.miterLimit = 3;
      s &&
        this._breaksCanvas &&
        (this._preRenderCtx.drawImage(this.canvas, 0, 0, this.width, this.height),
        this._preRenderCtx.drawImage(this._breaksCanvas, 0, 0, this.width, this.height),
        (this._breaksCanvasCtx.globalCompositeOperation = "source-atop"),
        this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
        this._preRenderCtx.clearRect(0, 0, this.width, this.height));
      for (d = 0; d < this.plotInfo.plotTypes.length; d++)
        for (var g = this.plotInfo.plotTypes[d], h = 0; h < g.plotUnits.length; h++) {
          var k = g.plotUnits[h],
            v = null;
          k.targetCanvas = null;
          this.animatedRender &&
            ((k.targetCanvas = ua(this.width, this.height)),
            (k.targetCanvasCtx = k.targetCanvas.getContext("2d")),
            (e = k.targetCanvasCtx.miterLimit),
            (k.targetCanvasCtx.miterLimit = 3));
          "line" === k.type
            ? (v = this.renderLine(k))
            : "stepLine" === k.type
            ? (v = this.renderStepLine(k))
            : "spline" === k.type
            ? (v = this.renderSpline(k))
            : "column" === k.type
            ? (v = this.renderColumn(k))
            : "bar" === k.type
            ? (v = this.renderBar(k))
            : "area" === k.type
            ? (v = this.renderArea(k))
            : "stepArea" === k.type
            ? (v = this.renderStepArea(k))
            : "splineArea" === k.type
            ? (v = this.renderSplineArea(k))
            : "stackedColumn" === k.type
            ? (v = this.renderStackedColumn(k))
            : "stackedColumn100" === k.type
            ? (v = this.renderStackedColumn100(k))
            : "stackedBar" === k.type
            ? (v = this.renderStackedBar(k))
            : "stackedBar100" === k.type
            ? (v = this.renderStackedBar100(k))
            : "stackedArea" === k.type
            ? (v = this.renderStackedArea(k))
            : "stackedArea100" === k.type
            ? (v = this.renderStackedArea100(k))
            : "bubble" === k.type
            ? (v = v = this.renderBubble(k))
            : "scatter" === k.type
            ? (v = this.renderScatter(k))
            : "pie" === k.type
            ? this.renderPie(k)
            : "doughnut" === k.type
            ? this.renderPie(k)
            : "funnel" === k.type
            ? (v = this.renderFunnel(k))
            : "pyramid" === k.type
            ? (v = this.renderFunnel(k))
            : "candlestick" === k.type
            ? (v = this.renderCandlestick(k))
            : "ohlc" === k.type
            ? (v = this.renderCandlestick(k))
            : "rangeColumn" === k.type
            ? (v = this.renderRangeColumn(k))
            : "error" === k.type
            ? (v = this.renderError(k))
            : "rangeBar" === k.type
            ? (v = this.renderRangeBar(k))
            : "rangeArea" === k.type
            ? (v = this.renderRangeArea(k))
            : "rangeSplineArea" === k.type
            ? (v = this.renderRangeSplineArea(k))
            : "waterfall" === k.type
            ? (v = this.renderWaterfall(k))
            : "boxAndWhisker" === k.type && (v = this.renderBoxAndWhisker(k));
          for (var m = 0; m < k.dataSeriesIndexes.length; m++)
            this._dataInRenderedOrder.push(this.data[k.dataSeriesIndexes[m]]);
          this.animatedRender && ((k.targetCanvasCtx.miterLimit = e), v && b.push(v));
        }
      this.ctx.miterLimit = a;
      this.animatedRender &&
        this._breaksCanvasCtx &&
        b.push({
          source: this._breaksCanvasCtx,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0,
          startTimePercent: 0.7
        });
      this.animatedRender &&
        0 < this._indexLabels.length &&
        ((e = ua(this.width, this.height).getContext("2d")), b.push(this.renderIndexLabels(e)));
      var r = this;
      if (0 < b.length)
        (r.disableToolTip = !0),
          r._animator.animate(
            200,
            r.animationDuration,
            function(a) {
              r.ctx.clearRect(0, 0, r.width, r.height);
              r.ctx.drawImage(
                c,
                0,
                0,
                Math.floor(r.width * ka),
                Math.floor(r.height * ka),
                0,
                0,
                r.width,
                r.height
              );
              for (var e = 0; e < b.length; e++)
                (v = b[e]),
                  1 > a && "undefined" !== typeof v.startTimePercent
                    ? a >= v.startTimePercent &&
                      v.animationCallback(
                        v.easingFunction(a - v.startTimePercent, 0, 1, 1 - v.startTimePercent),
                        v
                      )
                    : v.animationCallback(v.easingFunction(a, 0, 1, 1), v);
              r.dispatchEvent("dataAnimationIterationEnd", { chart: r });
            },
            function() {
              b = [];
              for (var a = 0; a < r.plotInfo.plotTypes.length; a++)
                for (var e = r.plotInfo.plotTypes[a], d = 0; d < e.plotUnits.length; d++)
                  e.plotUnits[d].targetCanvas = null;
              c = null;
              r.disableToolTip = !1;
            }
          );
      else {
        if (r._breaksCanvas)
          if (s) r.plotArea.ctx.drawImage(r._breaksCanvas, 0, 0, this.width, this.height);
          else for (m = 0; m < r._axes.length; m++) r._axes[m].createMask();
        0 < r._indexLabels.length && r.renderIndexLabels();
        r.dispatchEvent("dataAnimationIterationEnd", { chart: r });
      }
      this.attachPlotAreaEventHandlers();
      this.zoomEnabled ||
        this.panEnabled || !this._zoomButton || "none" === this._zoomButton.style.display ||
        wa(this._zoomButton, this._resetButton);
      this.toolTip._updateToolTip();
      this.renderCount++;
      Ja &&
        ((r = this),
        setTimeout(function() {
          var a = document.getElementById("ghostCanvasCopy");
          a &&
            (Oa(a, r.width, r.height),
            a.getContext("2d").drawImage(r._eventManager.ghostCanvas, 0, 0));
        }, 2e3));
      this._breaksCanvas && (delete this._breaksCanvas, delete this._breaksCanvasCtx);
      for (m = 0; m < this._axes.length; m++)
        this._axes[m].maskCanvas && (delete this._axes[m].maskCanvas, delete this._axes[m].maskCtx);
    };
    p.prototype.render = function(a) {
      a && (this.options = a);
      this._initialize();
      this.setLayout();
      this.renderElements();
      this._preRenderCanvas = null;
    };
    p.prototype.attachPlotAreaEventHandlers = function() {
      this.attachEvent({
        context: this,
        chart: this,
        mousedown: this._plotAreaMouseDown,
        mouseup: this._plotAreaMouseUp,
        mousemove: this._plotAreaMouseMove,
        cursor: this.panEnabled ? "move" : "default",
        capture: !0,
        bounds: this.plotArea
      });
    };
    p.prototype.categoriseDataSeries = function() {
      for (var a = "", d = 0; d < this.data.length; d++)
        if (
          ((a = this.data[d]),
          a.dataPoints &&
            0 !== a.dataPoints.length && a.visible &&
            0 <= p._supportedChartTypes.indexOf(a.type))
        ) {
          for (
            var b = null, c = !1, e = null, g = !1, h = 0;
            h < this.plotInfo.plotTypes.length;
            h++
          )
            if (this.plotInfo.plotTypes[h].type === a.type) {
              c = !0;
              b = this.plotInfo.plotTypes[h];
              break;
            }
          c ||
            ((b = { type: a.type, totalDataSeries: 0, plotUnits: [] }),
            this.plotInfo.plotTypes.push(b));
          for (h = 0; h < b.plotUnits.length; h++)
            if (
              b.plotUnits[h].axisYType === a.axisYType &&
              b.plotUnits[h].axisXType === a.axisXType &&
              b.plotUnits[h].axisYIndex === a.axisYIndex &&
              b.plotUnits[h].axisXIndex === a.axisXIndex
            ) {
              g = !0;
              e = b.plotUnits[h];
              break;
            }
          g ||
            ((e = {
              type: a.type,
              previousDataSeriesCount: 0,
              index: b.plotUnits.length,
              plotType: b,
              axisXType: a.axisXType,
              axisYType: a.axisYType,
              axisYIndex: a.axisYIndex,
              axisXIndex: a.axisXIndex,
              axisY:
                "primary" === a.axisYType
                  ? this.axisY[
                      0 <= a.axisYIndex && a.axisYIndex < this.axisY.length ? a.axisYIndex : 0
                    ]
                  : this.axisY2[
                      0 <= a.axisYIndex && a.axisYIndex < this.axisY2.length ? a.axisYIndex : 0
                    ],
              axisX:
                "primary" === a.axisXType
                  ? this.axisX[
                      0 <= a.axisXIndex && a.axisXIndex < this.axisX.length ? a.axisXIndex : 0
                    ]
                  : this.axisX2[
                      0 <= a.axisXIndex && a.axisXIndex < this.axisX2.length ? a.axisXIndex : 0
                    ],
              dataSeriesIndexes: [],
              yTotals: []
            }),
            b.plotUnits.push(e));
          b.totalDataSeries++;
          e.dataSeriesIndexes.push(d);
          a.plotUnit = e;
        }
      for (d = 0; d < this.plotInfo.plotTypes.length; d++)
        for (b = this.plotInfo.plotTypes[d], h = a = 0; h < b.plotUnits.length; h++)
          (b.plotUnits[h].previousDataSeriesCount = a),
            (a += b.plotUnits[h].dataSeriesIndexes.length);
    };
    p.prototype.assignIdToDataPoints = function() {
      for (var a = 0; a < this.data.length; a++) {
        var d = this.data[a];
        if (d.dataPoints)
          for (var b = d.dataPoints.length, c = 0; c < b; c++)
            d.dataPointIds[c] = ++this._eventManager.lastObjectId;
      }
    };
    p.prototype._processData = function() {
      this.assignIdToDataPoints();
      this.categoriseDataSeries();
      for (var a = 0; a < this.plotInfo.plotTypes.length; a++)
        for (var d = this.plotInfo.plotTypes[a], b = 0; b < d.plotUnits.length; b++) {
          var c = d.plotUnits[b];
          "line" === c.type ||
          "stepLine" === c.type ||
          "spline" === c.type ||
          "column" === c.type ||
          "area" === c.type ||
          "stepArea" === c.type ||
          "splineArea" === c.type ||
          "bar" === c.type ||
          "bubble" === c.type ||
          "scatter" === c.type
            ? this._processMultiseriesPlotUnit(c)
            : "stackedColumn" === c.type || "stackedBar" === c.type || "stackedArea" === c.type
            ? this._processStackedPlotUnit(c)
            : "stackedColumn100" === c.type ||
              "stackedBar100" === c.type ||
              "stackedArea100" === c.type
            ? this._processStacked100PlotUnit(c)
            : "candlestick" === c.type ||
              "ohlc" === c.type ||
              "rangeColumn" === c.type ||
              "rangeBar" === c.type ||
              "rangeArea" === c.type ||
              "rangeSplineArea" === c.type ||
              "error" === c.type ||
              "boxAndWhisker" === c.type
            ? this._processMultiYPlotUnit(c)
            : "waterfall" === c.type && this._processSpecificPlotUnit(c);
        }
      this.calculateAutoBreaks();
    };
    p.prototype._processMultiseriesPlotUnit = function(a) {
      if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length))
        for (
          var d = a.axisY.dataInfo, b = a.axisX.dataInfo, c, e, g = !1, h = 0;
          h < a.dataSeriesIndexes.length;
          h++
        ) {
          var k = this.data[a.dataSeriesIndexes[h]],
            v = 0,
            m = !1,
            r = !1,
            q;
          if ("normal" === k.axisPlacement || "xySwapped" === k.axisPlacement)
            var n = a.axisX.sessionVariables.newViewportMinimum
                ? a.axisX.sessionVariables.newViewportMinimum
                : this.options.axisX && this.options.axisX.viewportMinimum
                ? this.options.axisX.viewportMinimum
                : this.options.axisX && this.options.axisX.minimum
                ? this.options.axisX.minimum
                : a.axisX.logarithmic
                ? 0
                : -Infinity,
              f = a.axisX.sessionVariables.newViewportMaximum
                ? a.axisX.sessionVariables.newViewportMaximum
                : this.options.axisX && this.options.axisX.viewportMaximum
                ? this.options.axisX.viewportMaximum
                : this.options.axisX && this.options.axisX.maximum
                ? this.options.axisX.maximum
                : Infinity;
          if ((k.dataPoints[v].x && k.dataPoints[v].x.getTime) || "dateTime" === k.xValueType)
            g = !0;
          for (v = 0; v < k.dataPoints.length; v++) {
            "undefined" === typeof k.dataPoints[v].x &&
              (k.dataPoints[v].x = v + (a.axisX.logarithmic ? 1 : 0));
            k.dataPoints[v].x.getTime
              ? ((g = !0), (c = k.dataPoints[v].x.getTime()))
              : (c = k.dataPoints[v].x);
            e = k.dataPoints[v].y;
            c < b.min && (b.min = c);
            c > b.max && (b.max = c);
            e < d.min && "number" === typeof e && (d.min = e);
            e > d.max && "number" === typeof e && (d.max = e);
            if (0 < v) {
              if (a.axisX.logarithmic) {
                var A = c / k.dataPoints[v - 1].x;
                1 > A && (A = 1 / A);
                b.minDiff > A && 1 !== A && (b.minDiff = A);
              } else
                (A = c - k.dataPoints[v - 1].x),
                  0 > A && (A *= -1),
                  b.minDiff > A && 0 !== A && (b.minDiff = A);
              null !== e &&
                null !== k.dataPoints[v - 1].y &&
                (a.axisY.logarithmic
                  ? ((A = e / k.dataPoints[v - 1].y),
                    1 > A && (A = 1 / A),
                    d.minDiff > A && 1 !== A && (d.minDiff = A))
                  : ((A = e - k.dataPoints[v - 1].y),
                    0 > A && (A *= -1),
                    d.minDiff > A && 0 !== A && (d.minDiff = A)));
            }
            if (c < n && !m) null !== e && (q = c);
            else {
              if (!m && ((m = !0), 0 < v)) {
                v -= 2;
                continue;
              }
              if (c > f && !r) r = !0;
              else if (c > f && r) continue;
              k.dataPoints[v].label && (a.axisX.labels[c] = k.dataPoints[v].label);
              c < b.viewPortMin && (b.viewPortMin = c);
              c > b.viewPortMax && (b.viewPortMax = c);
              null === e
                ? b.viewPortMin === c && q < c && (b.viewPortMin = q)
                : (e < d.viewPortMin && "number" === typeof e && (d.viewPortMin = e),
                  e > d.viewPortMax && "number" === typeof e && (d.viewPortMax = e));
            }
          }
          k.axisX.valueType = k.xValueType = g ? "dateTime" : "number";
        }
    };
    p.prototype._processStackedPlotUnit = function(a) {
      if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length)) {
        for (
          var d = a.axisY.dataInfo,
            b = a.axisX.dataInfo,
            c,
            e,
            g = !1,
            h = [],
            k = [],
            v = Infinity,
            m = -Infinity,
            r = 0;
          r < a.dataSeriesIndexes.length;
          r++
        ) {
          var q = this.data[a.dataSeriesIndexes[r]],
            n = 0,
            f = !1,
            A = !1,
            l;
          if ("normal" === q.axisPlacement || "xySwapped" === q.axisPlacement)
            var p = a.axisX.sessionVariables.newViewportMinimum
                ? a.axisX.sessionVariables.newViewportMinimum
                : this.options.axisX && this.options.axisX.viewportMinimum
                ? this.options.axisX.viewportMinimum
                : this.options.axisX && this.options.axisX.minimum
                ? this.options.axisX.minimum
                : -Infinity,
              t = a.axisX.sessionVariables.newViewportMaximum
                ? a.axisX.sessionVariables.newViewportMaximum
                : this.options.axisX && this.options.axisX.viewportMaximum
                ? this.options.axisX.viewportMaximum
                : this.options.axisX && this.options.axisX.maximum
                ? this.options.axisX.maximum
                : Infinity;
          if ((q.dataPoints[n].x && q.dataPoints[n].x.getTime) || "dateTime" === q.xValueType)
            g = !0;
          for (n = 0; n < q.dataPoints.length; n++) {
            "undefined" === typeof q.dataPoints[n].x &&
              (q.dataPoints[n].x = n + (a.axisX.logarithmic ? 1 : 0));
            q.dataPoints[n].x.getTime
              ? ((g = !0), (c = q.dataPoints[n].x.getTime()))
              : (c = q.dataPoints[n].x);
            e = u(q.dataPoints[n].y) ? 0 : q.dataPoints[n].y;
            c < b.min && (b.min = c);
            c > b.max && (b.max = c);
            if (0 < n) {
              if (a.axisX.logarithmic) {
                var s = c / q.dataPoints[n - 1].x;
                1 > s && (s = 1 / s);
                b.minDiff > s && 1 !== s && (b.minDiff = s);
              } else
                (s = c - q.dataPoints[n - 1].x),
                  0 > s && (s *= -1),
                  b.minDiff > s && 0 !== s && (b.minDiff = s);
              null !== e &&
                null !== q.dataPoints[n - 1].y &&
                (a.axisY.logarithmic
                  ? 0 < e &&
                    ((s = e / q.dataPoints[n - 1].y),
                    1 > s && (s = 1 / s),
                    d.minDiff > s && 1 !== s && (d.minDiff = s))
                  : ((s = e - q.dataPoints[n - 1].y),
                    0 > s && (s *= -1),
                    d.minDiff > s && 0 !== s && (d.minDiff = s)));
            }
            if (c < p && !f) null !== q.dataPoints[n].y && (l = c);
            else {
              if (!f && ((f = !0), 0 < n)) {
                n -= 2;
                continue;
              }
              if (c > t && !A) A = !0;
              else if (c > t && A) continue;
              q.dataPoints[n].label && (a.axisX.labels[c] = q.dataPoints[n].label);
              c < b.viewPortMin && (b.viewPortMin = c);
              c > b.viewPortMax && (b.viewPortMax = c);
              null === q.dataPoints[n].y
                ? b.viewPortMin === c && l < c && (b.viewPortMin = l)
                : ((a.yTotals[c] = (a.yTotals[c] ? a.yTotals[c] : 0) + e),
                  0 <= e
                    ? h[c]
                      ? (h[c] += e)
                      : ((h[c] = e), (v = Math.min(e, v)))
                    : k[c]
                    ? (k[c] += e)
                    : ((k[c] = e), (m = Math.max(e, m))));
            }
          }
          a.axisY.scaleBreaks &&
            a.axisY.scaleBreaks.autoCalculate && 1 <= a.axisY.scaleBreaks.maxNumberOfAutoBreaks &&
            (d.dataPointYPositiveSums
              ? (d.dataPointYPositiveSums.push.apply(d.dataPointYPositiveSums, h),
                d.dataPointYNegativeSums.push.apply(d.dataPointYPositiveSums, k))
              : ((d.dataPointYPositiveSums = h), (d.dataPointYNegativeSums = k)));
          q.axisX.valueType = q.xValueType = g ? "dateTime" : "number";
        }
        for (n in h)
          h.hasOwnProperty(n) &&
            !isNaN(n) &&
            ((a = h[n]),
            a < d.min && (d.min = Math.min(a, v)),
            a > d.max && (d.max = a),
            n < b.viewPortMin ||
              n > b.viewPortMax ||
              (a < d.viewPortMin && (d.viewPortMin = Math.min(a, v)),
              a > d.viewPortMax && (d.viewPortMax = a)));
        for (n in k)
          k.hasOwnProperty(n) &&
            !isNaN(n) &&
            ((a = k[n]),
            a < d.min && (d.min = a),
            a > d.max && (d.max = Math.max(a, m)),
            n < b.viewPortMin ||
              n > b.viewPortMax ||
              (a < d.viewPortMin && (d.viewPortMin = a),
              a > d.viewPortMax && (d.viewPortMax = Math.max(a, m))));
      }
    };
    p.prototype._processStacked100PlotUnit = function(a) {
      if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length)) {
        for (
          var d = a.axisY.dataInfo,
            b = a.axisX.dataInfo,
            c,
            e,
            g = !1,
            h = !1,
            k = !1,
            v = [],
            m = 0;
          m < a.dataSeriesIndexes.length;
          m++
        ) {
          var r = this.data[a.dataSeriesIndexes[m]],
            q = 0,
            n = !1,
            f = !1,
            A;
          if ("normal" === r.axisPlacement || "xySwapped" === r.axisPlacement)
            var l = a.axisX.sessionVariables.newViewportMinimum
                ? a.axisX.sessionVariables.newViewportMinimum
                : this.options.axisX && this.options.axisX.viewportMinimum
                ? this.options.axisX.viewportMinimum
                : this.options.axisX && this.options.axisX.minimum
                ? this.options.axisX.minimum
                : -Infinity,
              s = a.axisX.sessionVariables.newViewportMaximum
                ? a.axisX.sessionVariables.newViewportMaximum
                : this.options.axisX && this.options.axisX.viewportMaximum
                ? this.options.axisX.viewportMaximum
                : this.options.axisX && this.options.axisX.maximum
                ? this.options.axisX.maximum
                : Infinity;
          if ((r.dataPoints[q].x && r.dataPoints[q].x.getTime) || "dateTime" === r.xValueType)
            g = !0;
          for (q = 0; q < r.dataPoints.length; q++) {
            "undefined" === typeof r.dataPoints[q].x &&
              (r.dataPoints[q].x = q + (a.axisX.logarithmic ? 1 : 0));
            r.dataPoints[q].x.getTime
              ? ((g = !0), (c = r.dataPoints[q].x.getTime()))
              : (c = r.dataPoints[q].x);
            e = u(r.dataPoints[q].y) ? null : r.dataPoints[q].y;
            c < b.min && (b.min = c);
            c > b.max && (b.max = c);
            if (0 < q) {
              if (a.axisX.logarithmic) {
                var t = c / r.dataPoints[q - 1].x;
                1 > t && (t = 1 / t);
                b.minDiff > t && 1 !== t && (b.minDiff = t);
              } else
                (t = c - r.dataPoints[q - 1].x),
                  0 > t && (t *= -1),
                  b.minDiff > t && 0 !== t && (b.minDiff = t);
              u(e) ||
                null === r.dataPoints[q - 1].y ||
                (a.axisY.logarithmic
                  ? 0 < e &&
                    ((t = e / r.dataPoints[q - 1].y),
                    1 > t && (t = 1 / t),
                    d.minDiff > t && 1 !== t && (d.minDiff = t))
                  : ((t = e - r.dataPoints[q - 1].y),
                    0 > t && (t *= -1),
                    d.minDiff > t && 0 !== t && (d.minDiff = t)));
            }
            if (c < l && !n) null !== e && (A = c);
            else {
              if (!n && ((n = !0), 0 < q)) {
                q -= 2;
                continue;
              }
              if (c > s && !f) f = !0;
              else if (c > s && f) continue;
              r.dataPoints[q].label && (a.axisX.labels[c] = r.dataPoints[q].label);
              c < b.viewPortMin && (b.viewPortMin = c);
              c > b.viewPortMax && (b.viewPortMax = c);
              null === e
                ? b.viewPortMin === c && A < c && (b.viewPortMin = A)
                : ((a.yTotals[c] = (a.yTotals[c] ? a.yTotals[c] : 0) + e),
                  0 <= e ? (h = !0) : 0 > e && (k = !0),
                  (v[c] = v[c] ? v[c] + Math.abs(e) : Math.abs(e)));
            }
          }
          r.axisX.valueType = r.xValueType = g ? "dateTime" : "number";
        }
        a.axisY.logarithmic
          ? ((d.max = u(d.viewPortMax)
              ? 99 * Math.pow(a.axisY.logarithmBase, -0.05)
              : Math.max(d.viewPortMax, 99 * Math.pow(a.axisY.logarithmBase, -0.05))),
            (d.min = u(d.viewPortMin) ? 1 : Math.min(d.viewPortMin, 1)))
          : h && !k
          ? ((d.max = u(d.viewPortMax) ? 99 : Math.max(d.viewPortMax, 99)),
            (d.min = u(d.viewPortMin) ? 1 : Math.min(d.viewPortMin, 1)))
          : h && k
          ? ((d.max = u(d.viewPortMax) ? 99 : Math.max(d.viewPortMax, 99)),
            (d.min = u(d.viewPortMin) ? -99 : Math.min(d.viewPortMin, -99)))
          : !h &&
            k &&
            ((d.max = u(d.viewPortMax) ? -1 : Math.max(d.viewPortMax, -1)),
            (d.min = u(d.viewPortMin) ? -99 : Math.min(d.viewPortMin, -99)));
        d.viewPortMin = d.min;
        d.viewPortMax = d.max;
        a.dataPointYSums = v;
      }
    };
    p.prototype._processMultiYPlotUnit = function(a) {
      if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length))
        for (
          var d = a.axisY.dataInfo, b = a.axisX.dataInfo, c, e, g, h, k = !1, v = 0;
          v < a.dataSeriesIndexes.length;
          v++
        ) {
          var m = this.data[a.dataSeriesIndexes[v]],
            r = 0,
            q = !1,
            n = !1,
            f,
            A,
            l;
          if ("normal" === m.axisPlacement || "xySwapped" === m.axisPlacement)
            var s = a.axisX.sessionVariables.newViewportMinimum
                ? a.axisX.sessionVariables.newViewportMinimum
                : this.options.axisX && this.options.axisX.viewportMinimum
                ? this.options.axisX.viewportMinimum
                : this.options.axisX && this.options.axisX.minimum
                ? this.options.axisX.minimum
                : a.axisX.logarithmic
                ? 0
                : -Infinity,
              t = a.axisX.sessionVariables.newViewportMaximum
                ? a.axisX.sessionVariables.newViewportMaximum
                : this.options.axisX && this.options.axisX.viewportMaximum
                ? this.options.axisX.viewportMaximum
                : this.options.axisX && this.options.axisX.maximum
                ? this.options.axisX.maximum
                : Infinity;
          if ((m.dataPoints[r].x && m.dataPoints[r].x.getTime) || "dateTime" === m.xValueType)
            k = !0;
          for (r = 0; r < m.dataPoints.length; r++) {
            "undefined" === typeof m.dataPoints[r].x &&
              (m.dataPoints[r].x = r + (a.axisX.logarithmic ? 1 : 0));
            m.dataPoints[r].x.getTime
              ? ((k = !0), (c = m.dataPoints[r].x.getTime()))
              : (c = m.dataPoints[r].x);
            if ((e = m.dataPoints[r].y) && e.length) {
              g = Math.min.apply(null, e);
              h = Math.max.apply(null, e);
              A = !0;
              for (var p = 0; p < e.length; p++) null === e.k && (A = !1);
              A && (q || (l = f), (f = c));
            }
            c < b.min && (b.min = c);
            c > b.max && (b.max = c);
            g < d.min && (d.min = g);
            h > d.max && (d.max = h);
            0 < r &&
              (a.axisX.logarithmic
                ? ((A = c / m.dataPoints[r - 1].x),
                  1 > A && (A = 1 / A),
                  b.minDiff > A && 1 !== A && (b.minDiff = A))
                : ((A = c - m.dataPoints[r - 1].x),
                  0 > A && (A *= -1),
                  b.minDiff > A && 0 !== A && (b.minDiff = A)),
              e &&
                null !== e[0] && m.dataPoints[r - 1].y && null !== m.dataPoints[r - 1].y[0] &&
                (a.axisY.logarithmic
                  ? ((A = e[0] / m.dataPoints[r - 1].y[0]),
                    1 > A && (A = 1 / A),
                    d.minDiff > A && 1 !== A && (d.minDiff = A))
                  : ((A = e[0] - m.dataPoints[r - 1].y[0]),
                    0 > A && (A *= -1),
                    d.minDiff > A && 0 !== A && (d.minDiff = A))));
            if (!(c < s) || q) {
              if (!q && ((q = !0), 0 < r)) {
                r -= 2;
                f = l;
                continue;
              }
              if (c > t && !n) n = !0;
              else if (c > t && n) continue;
              m.dataPoints[r].label && (a.axisX.labels[c] = m.dataPoints[r].label);
              c < b.viewPortMin && (b.viewPortMin = c);
              c > b.viewPortMax && (b.viewPortMax = c);
              if (b.viewPortMin === c && e)
                for (p = 0; p < e.length; p++)
                  if (null === e[p] && f < c) {
                    b.viewPortMin = f;
                    break;
                  }
              null === e
                ? b.viewPortMin === c && f < c && (b.viewPortMin = f)
                : (g < d.viewPortMin && (d.viewPortMin = g),
                  h > d.viewPortMax && (d.viewPortMax = h));
            }
          }
          m.axisX.valueType = m.xValueType = k ? "dateTime" : "number";
        }
    };
    p.prototype._processSpecificPlotUnit = function(a) {
      if ("waterfall" === a.type && a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length))
        for (
          var d = a.axisY.dataInfo, b = a.axisX.dataInfo, c, e, g = !1, h = 0;
          h < a.dataSeriesIndexes.length;
          h++
        ) {
          var k = this.data[a.dataSeriesIndexes[h]],
            v = 0,
            m = !1,
            r = !1,
            q = (c = 0);
          if ("normal" === k.axisPlacement || "xySwapped" === k.axisPlacement)
            var n = a.axisX.sessionVariables.newViewportMinimum
                ? a.axisX.sessionVariables.newViewportMinimum
                : this.options.axisX && this.options.axisX.viewportMinimum
                ? this.options.axisX.viewportMinimum
                : this.options.axisX && this.options.axisX.minimum
                ? this.options.axisX.minimum
                : a.axisX.logarithmic
                ? 0
                : -Infinity,
              f = a.axisX.sessionVariables.newViewportMaximum
                ? a.axisX.sessionVariables.newViewportMaximum
                : this.options.axisX && this.options.axisX.viewportMaximum
                ? this.options.axisX.viewportMaximum
                : this.options.axisX && this.options.axisX.maximum
                ? this.options.axisX.maximum
                : Infinity;
          if ((k.dataPoints[v].x && k.dataPoints[v].x.getTime) || "dateTime" === k.xValueType)
            g = !0;
          for (v = 0; v < k.dataPoints.length; v++)
            "undefined" !== typeof k.dataPoints[v].isCumulativeSum &&
            !0 === k.dataPoints[v].isCumulativeSum
              ? ((k.dataPointEOs[v].cumulativeSumYStartValue = 0),
                (k.dataPointEOs[v].cumulativeSum =
                  0 === v ? 0 : k.dataPointEOs[v - 1].cumulativeSum),
                (k.dataPoints[v].y = 0 === v ? 0 : k.dataPointEOs[v - 1].cumulativeSum))
              : "undefined" !== typeof k.dataPoints[v].isIntermediateSum &&
                !0 === k.dataPoints[v].isIntermediateSum
              ? ((k.dataPointEOs[v].cumulativeSumYStartValue = q),
                (k.dataPointEOs[v].cumulativeSum =
                  0 === v ? 0 : k.dataPointEOs[v - 1].cumulativeSum),
                (k.dataPoints[v].y = 0 === v ? 0 : c),
                (q = 0 === v ? 0 : k.dataPointEOs[v - 1].cumulativeSum),
                (c = 0))
              : ((e = "number" !== typeof k.dataPoints[v].y ? 0 : k.dataPoints[v].y),
                (k.dataPointEOs[v].cumulativeSumYStartValue =
                  0 === v ? 0 : k.dataPointEOs[v - 1].cumulativeSum),
                (k.dataPointEOs[v].cumulativeSum =
                  0 === v ? e : k.dataPointEOs[v - 1].cumulativeSum + e),
                (c += e));
          for (v = 0; v < k.dataPoints.length; v++)
            if (
              ("undefined" === typeof k.dataPoints[v].x &&
                (k.dataPoints[v].x = v + (a.axisX.logarithmic ? 1 : 0)),
              k.dataPoints[v].x.getTime
                ? ((g = !0), (c = k.dataPoints[v].x.getTime()))
                : (c = k.dataPoints[v].x),
              (e = k.dataPoints[v].y),
              c < b.min && (b.min = c),
              c > b.max && (b.max = c),
              k.dataPointEOs[v].cumulativeSum < d.min && (d.min = k.dataPointEOs[v].cumulativeSum),
              k.dataPointEOs[v].cumulativeSum > d.max && (d.max = k.dataPointEOs[v].cumulativeSum),
              0 < v &&
                (a.axisX.logarithmic
                  ? ((q = c / k.dataPoints[v - 1].x),
                    1 > q && (q = 1 / q),
                    b.minDiff > q && 1 !== q && (b.minDiff = q))
                  : ((q = c - k.dataPoints[v - 1].x),
                    0 > q && (q *= -1),
                    b.minDiff > q && 0 !== q && (b.minDiff = q)),
                null !== e &&
                  null !== k.dataPoints[v - 1].y &&
                  (a.axisY.logarithmic
                    ? ((e = k.dataPointEOs[v].cumulativeSum / k.dataPointEOs[v - 1].cumulativeSum),
                      1 > e && (e = 1 / e),
                      d.minDiff > e && 1 !== e && (d.minDiff = e))
                    : ((e = k.dataPointEOs[v].cumulativeSum - k.dataPointEOs[v - 1].cumulativeSum),
                      0 > e && (e *= -1),
                      d.minDiff > e && 0 !== e && (d.minDiff = e)))),
              !(c < n) || m)
            ) {
              if (!m && ((m = !0), 0 < v)) {
                v -= 2;
                continue;
              }
              if (c > f && !r) r = !0;
              else if (c > f && r) continue;
              k.dataPoints[v].label && (a.axisX.labels[c] = k.dataPoints[v].label);
              c < b.viewPortMin && (b.viewPortMin = c);
              c > b.viewPortMax && (b.viewPortMax = c);
              0 < v &&
                (k.dataPointEOs[v - 1].cumulativeSum < d.viewPortMin &&
                  (d.viewPortMin = k.dataPointEOs[v - 1].cumulativeSum),
                k.dataPointEOs[v - 1].cumulativeSum > d.viewPortMax &&
                  (d.viewPortMax = k.dataPointEOs[v - 1].cumulativeSum));
              k.dataPointEOs[v].cumulativeSum < d.viewPortMin &&
                (d.viewPortMin = k.dataPointEOs[v].cumulativeSum);
              k.dataPointEOs[v].cumulativeSum > d.viewPortMax &&
                (d.viewPortMax = k.dataPointEOs[v].cumulativeSum);
            }
          k.axisX.valueType = k.xValueType = g ? "dateTime" : "number";
        }
    };
    p.prototype.calculateAutoBreaks = function() {
      function a(a, c, b, e) {
        if (e)
          return (
            (b = Math.pow(Math.min((b * a) / c, c / a), 0.2)),
            1 >= b && (b = Math.pow(1 > a ? 1 / a : Math.min(c / a, a), 0.25)),
            { startValue: a * b, endValue: c / b }
          );
        b = 0.2 * Math.min(b - c + a, c - a);
        0 >= b && (b = 0.25 * Math.min(c - a, Math.abs(a)));
        return { startValue: a + b, endValue: c - b };
      }
      function d(a) {
        if (a.dataSeriesIndexes && !(1 > a.dataSeriesIndexes.length)) {
          var c =
              a.axisX.scaleBreaks &&
              a.axisX.scaleBreaks.autoCalculate &&
              1 <= a.axisX.scaleBreaks.maxNumberOfAutoBreaks,
            b =
              a.axisY.scaleBreaks &&
              a.axisY.scaleBreaks.autoCalculate &&
              1 <= a.axisY.scaleBreaks.maxNumberOfAutoBreaks;
          if (c || b)
            for (
              var d = a.axisY.dataInfo,
                f = a.axisX.dataInfo,
                g,
                m = f.min,
                k = f.max,
                h = d.min,
                n = d.max,
                f = f._dataRanges,
                d = d._dataRanges,
                q,
                v = 0,
                r = 0;
              r < a.dataSeriesIndexes.length;
              r++
            ) {
              var l = e.data[a.dataSeriesIndexes[r]];
              if (!(4 > l.dataPoints.length))
                for (v = 0; v < l.dataPoints.length; v++)
                  if (
                    (c &&
                      ((q =
                        ((k + 1 - m) *
                          Math.max(
                            parseFloat(a.axisX.scaleBreaks.collapsibleThreshold) || 10,
                            10
                          )) /
                        100),
                      (g = l.dataPoints[v].x.getTime
                        ? l.dataPoints[v].x.getTime()
                        : l.dataPoints[v].x),
                      (q = Math.floor((g - m) / q)),
                      g < f[q].min && (f[q].min = g),
                      g > f[q].max && (f[q].max = g)),
                    b)
                  ) {
                    var s =
                      ((n + 1 - h) *
                        Math.max(parseFloat(a.axisY.scaleBreaks.collapsibleThreshold) || 10, 10)) /
                      100;
                    if (
                      (g =
                        "waterfall" === a.type
                          ? l.dataPointEOs[v].cumulativeSum
                          : l.dataPoints[v].y) &&
                      g.length
                    )
                      for (var p = 0; p < g.length; p++)
                        (q = Math.floor((g[p] - h) / s)),
                          g[p] < d[q].min && (d[q].min = g[p]),
                          g[p] > d[q].max && (d[q].max = g[p]);
                    else
                      u(g) ||
                        ((q = Math.floor((g - h) / s)),
                        g < d[q].min && (d[q].min = g),
                        g > d[q].max && (d[q].max = g));
                  }
            }
        }
      }
      function b(a) {
        if (
          a.dataSeriesIndexes &&
          !(1 > a.dataSeriesIndexes.length) &&
          a.axisX.scaleBreaks &&
          a.axisX.scaleBreaks.autoCalculate &&
          1 <= a.axisX.scaleBreaks.maxNumberOfAutoBreaks
        )
          for (
            var c = a.axisX.dataInfo, b = c.min, d = c.max, f = c._dataRanges, g, m = 0, k = 0;
            k < a.dataSeriesIndexes.length;
            k++
          ) {
            var h = e.data[a.dataSeriesIndexes[k]];
            if (!(4 > h.dataPoints.length))
              for (m = 0; m < h.dataPoints.length; m++)
                (g =
                  ((d + 1 - b) *
                    Math.max(parseFloat(a.axisX.scaleBreaks.collapsibleThreshold) || 10, 10)) /
                  100),
                  (c = h.dataPoints[m].x.getTime ? h.dataPoints[m].x.getTime() : h.dataPoints[m].x),
                  (g = Math.floor((c - b) / g)),
                  c < f[g].min && (f[g].min = c),
                  c > f[g].max && (f[g].max = c);
          }
      }
      for (var c, e = this, g = !1, h = 0; h < this._axes.length; h++)
        if (
          this._axes[h].scaleBreaks &&
          this._axes[h].scaleBreaks.autoCalculate &&
          1 <= this._axes[h].scaleBreaks.maxNumberOfAutoBreaks
        ) {
          g = !0;
          this._axes[h].dataInfo._dataRanges = [];
          for (
            var k = 0;
            k <
            100 / Math.max(parseFloat(this._axes[h].scaleBreaks.collapsibleThreshold) || 10, 10);
            k++
          )
            this._axes[h].dataInfo._dataRanges.push({ min: Infinity, max: -Infinity });
        }
      if (g) {
        for (h = 0; h < this.plotInfo.plotTypes.length; h++)
          for (g = this.plotInfo.plotTypes[h], k = 0; k < g.plotUnits.length; k++)
            (c = g.plotUnits[k]),
              "line" === c.type ||
              "stepLine" === c.type ||
              "spline" === c.type ||
              "column" === c.type ||
              "area" === c.type ||
              "stepArea" === c.type ||
              "splineArea" === c.type ||
              "bar" === c.type ||
              "bubble" === c.type ||
              "scatter" === c.type ||
              "candlestick" === c.type ||
              "ohlc" === c.type ||
              "rangeColumn" === c.type ||
              "rangeBar" === c.type ||
              "rangeArea" === c.type ||
              "rangeSplineArea" === c.type ||
              "waterfall" === c.type ||
              "error" === c.type ||
              "boxAndWhisker" === c.type
                ? d(c)
                : 0 <= c.type.indexOf("stacked") && b(c);
        for (h = 0; h < this._axes.length; h++)
          if (this._axes[h].dataInfo._dataRanges) {
            var v = this._axes[h].dataInfo.min;
            c =
              ((this._axes[h].dataInfo.max + 1 - v) *
                Math.max(parseFloat(this._axes[h].scaleBreaks.collapsibleThreshold) || 10, 10)) /
              100;
            var m = this._axes[h].dataInfo._dataRanges,
              r,
              q,
              g = [];
            if (this._axes[h].dataInfo.dataPointYPositiveSums) {
              var n = this._axes[h].dataInfo.dataPointYPositiveSums;
              r = m;
              for (k in n)
                if (n.hasOwnProperty(k) && !isNaN(k) && ((q = n[k]), !u(q))) {
                  var f = Math.floor((q - v) / c);
                  q < r[f].min && (r[f].min = q);
                  q > r[f].max && (r[f].max = q);
                }
              delete this._axes[h].dataInfo.dataPointYPositiveSums;
            }
            if (this._axes[h].dataInfo.dataPointYNegativeSums) {
              n = this._axes[h].dataInfo.dataPointYNegativeSums;
              r = m;
              for (k in n)
                n.hasOwnProperty(k) &&
                  !isNaN(k) &&
                  ((q = -1 * n[k]),
                  u(q) ||
                    ((f = Math.floor((q - v) / c)),
                    q < r[f].min && (r[f].min = q),
                    q > r[f].max && (r[f].max = q)));
              delete this._axes[h].dataInfo.dataPointYNegativeSums;
            }
            for (k = 0; k < m.length - 1; k++)
              if (((r = m[k].max), isFinite(r)))
                for (; k < m.length - 1; )
                  if (((v = m[k + 1].min), isFinite(v))) {
                    q = v - r;
                    q > c && g.push({ diff: q, start: r, end: v });
                    break;
                  } else k++;
            if (this._axes[h].scaleBreaks.customBreaks)
              for (k = 0; k < this._axes[h].scaleBreaks.customBreaks.length; k++)
                for (c = 0; c < g.length; c++)
                  if (
                    (this._axes[h].scaleBreaks.customBreaks[k].startValue <= g[c].start &&
                      g[c].start <= this._axes[h].scaleBreaks.customBreaks[k].endValue) ||
                    (this._axes[h].scaleBreaks.customBreaks[k].startValue <= g[c].start &&
                      g[c].start <= this._axes[h].scaleBreaks.customBreaks[k].endValue) ||
                    (g[c].start <= this._axes[h].scaleBreaks.customBreaks[k].startValue &&
                      this._axes[h].scaleBreaks.customBreaks[k].startValue <= g[c].end) ||
                    (g[c].start <= this._axes[h].scaleBreaks.customBreaks[k].endValue &&
                      this._axes[h].scaleBreaks.customBreaks[k].endValue <= g[c].end)
                  )
                    g.splice(c, 1), c--;
            g.sort(function(a, c) {
              return c.diff - a.diff;
            });
            for (
              k = 0;
              k < Math.min(g.length, this._axes[h].scaleBreaks.maxNumberOfAutoBreaks);
              k++
            )
              (c = a(
                g[k].start,
                g[k].end,
                this._axes[h].logarithmic
                  ? this._axes[h].dataInfo.max / this._axes[h].dataInfo.min
                  : this._axes[h].dataInfo.max - this._axes[h].dataInfo.min,
                this._axes[h].logarithmic
              )),
                this._axes[h].scaleBreaks.autoBreaks.push(
                  new L(
                    this,
                    "autoBreaks",
                    c,
                    k,
                    ++this._eventManager.lastObjectId,
                    this._axes[h].scaleBreaks
                  )
                ),
                this._axes[h].scaleBreaks._appliedBreaks.push(
                  this._axes[h].scaleBreaks.autoBreaks[
                    this._axes[h].scaleBreaks.autoBreaks.length - 1
                  ]
                );
            this._axes[h].scaleBreaks._appliedBreaks.sort(function(a, c) {
              return a.startValue - c.startValue;
            });
          }
      }
    };
    p.prototype.getDataPointAtXY = function(a, d, b) {
      b = b || !1;
      for (var c = [], e = this._dataInRenderedOrder.length - 1; 0 <= e; e--) {
        var g = null;
        (g = this._dataInRenderedOrder[e].getDataPointAtXY(a, d, b)) && c.push(g);
      }
      a = null;
      d = !1;
      for (b = 0; b < c.length; b++)
        if (
          "line" === c[b].dataSeries.type ||
          "stepLine" === c[b].dataSeries.type ||
          "area" === c[b].dataSeries.type ||
          "stepArea" === c[b].dataSeries.type
        )
          if (
            ((e = ma("markerSize", c[b].dataPoint, c[b].dataSeries) || 8), c[b].distance <= e / 2)
          ) {
            d = !0;
            break;
          }
      for (b = 0; b < c.length; b++)
        (d &&
          "line" !== c[b].dataSeries.type &&
          "stepLine" !== c[b].dataSeries.type &&
          "area" !== c[b].dataSeries.type &&
          "stepArea" !== c[b].dataSeries.type) ||
          (a ? c[b].distance <= a.distance && (a = c[b]) : (a = c[b]));
      return a;
    };
    p.prototype.getObjectAtXY = function(a, d, b) {
      var c = null;
      if ((b = this.getDataPointAtXY(a, d, b || !1)))
        c = b.dataSeries.dataPointIds[b.dataPointIndex];
      else if (s) c = ab(a, d, this._eventManager.ghostCtx);
      else
        for (b = 0; b < this.legend.items.length; b++) {
          var e = this.legend.items[b];
          a >= e.x1 && a <= e.x2 && d >= e.y1 && d <= e.y2 && (c = e.id);
        }
      return c;
    };
    p.prototype.getAutoFontSize = kb;
    p.prototype.resetOverlayedCanvas = function() {
      this.overlaidCanvasCtx.clearRect(0, 0, this.width, this.height);
    };
    p.prototype.clearCanvas = jb;
    p.prototype.attachEvent = function(a) {
      this._events.push(a);
    };
    p.prototype._touchEventHandler = function(a) {
      if (a.changedTouches && this.interactivityEnabled) {
        var d = [],
          b = a.changedTouches,
          c = b ? b[0] : a,
          e = null;
        switch (a.type) {
          case "touchstart":
          case "MSPointerDown":
            d = ["mousemove", "mousedown"];
            this._lastTouchData = Ra(c);
            this._lastTouchData.time = new Date();
            break;
          case "touchmove":
          case "MSPointerMove":
            d = ["mousemove"];
            break;
          case "touchend":
          case "MSPointerUp":
            var g =
                this._lastTouchData && this._lastTouchData.time
                  ? new Date() - this._lastTouchData.time
                  : 0,
              d =
                "touchstart" === this._lastTouchEventType ||
                "MSPointerDown" === this._lastTouchEventType ||
                300 > g
                  ? ["mouseup", "click"]
                  : ["mouseup"];
            break;
          default:
            return;
        }
        if (!(b && 1 < b.length)) {
          e = Ra(c);
          e.time = new Date();
          try {
            var h = e.y - this._lastTouchData.y,
              g = e.time - this._lastTouchData.time;
            if ((1 < Math.abs(h) && this._lastTouchData.scroll) || (5 < Math.abs(h) && 250 > g))
              this._lastTouchData.scroll = !0;
          } catch (k) {}
          this._lastTouchEventType = a.type;
          if (this._lastTouchData.scroll && this.zoomEnabled)
            this.isDrag && this.resetOverlayedCanvas(), (this.isDrag = !1);
          else
            for (b = 0; b < d.length; b++)
              if (
                ((e = d[b]),
                (h = document.createEvent("MouseEvent")),
                h.initMouseEvent(
                  e,
                  !0,
                  !0,
                  window,
                  1,
                  c.screenX,
                  c.screenY,
                  c.clientX,
                  c.clientY,
                  !1,
                  !1,
                  !1,
                  !1,
                  0,
                  null
                ),
                c.target.dispatchEvent(h),
                (!u(this._lastTouchData.scroll) && !this._lastTouchData.scroll) ||
                  (!this._lastTouchData.scroll && 250 < g) ||
                  "click" === e)
              )
                a.preventManipulation && a.preventManipulation(),
                  a.preventDefault && a.preventDefault();
        }
      }
    };
    p.prototype._dispatchRangeEvent = function(a, d) {
      var b = { chart: this };
      b.type = a;
      b.trigger = d;
      var c = [];
      this.axisX && 0 < this.axisX.length && c.push("axisX");
      this.axisX2 && 0 < this.axisX2.length && c.push("axisX2");
      this.axisY && 0 < this.axisY.length && c.push("axisY");
      this.axisY2 && 0 < this.axisY2.length && c.push("axisY2");
      for (var e = 0; e < c.length; e++)
        if ((u(b[c[e]]) && (b[c[e]] = []), "axisY" === c[e]))
          for (var g = 0; g < this.axisY.length; g++)
            b[c[e]].push({
              viewportMinimum: this[c[e]][g].sessionVariables.newViewportMinimum,
              viewportMaximum: this[c[e]][g].sessionVariables.newViewportMaximum
            });
        else if ("axisY2" === c[e])
          for (g = 0; g < this.axisY2.length; g++)
            b[c[e]].push({
              viewportMinimum: this[c[e]][g].sessionVariables.newViewportMinimum,
              viewportMaximum: this[c[e]][g].sessionVariables.newViewportMaximum
            });
        else if ("axisX" === c[e])
          for (g = 0; g < this.axisX.length; g++)
            b[c[e]].push({
              viewportMinimum: this[c[e]][g].sessionVariables.newViewportMinimum,
              viewportMaximum: this[c[e]][g].sessionVariables.newViewportMaximum
            });
        else if ("axisX2" === c[e])
          for (g = 0; g < this.axisX2.length; g++)
            b[c[e]].push({
              viewportMinimum: this[c[e]][g].sessionVariables.newViewportMinimum,
              viewportMaximum: this[c[e]][g].sessionVariables.newViewportMaximum
            });
      this.dispatchEvent(a, b, this);
    };
    p.prototype._mouseEventHandler = function(a) {
      "undefined" === typeof a.target && a.srcElement && (a.target = a.srcElement);
      var d = Ra(a),
        b = a.type,
        c,
        e;
      a.which ? (e = 3 == a.which) : a.button && (e = 2 == a.button);
      p.capturedEventParam &&
        ((c = p.capturedEventParam),
        "mouseup" === b &&
          ((p.capturedEventParam = null),
          c.chart.overlaidCanvas.releaseCapture
            ? c.chart.overlaidCanvas.releaseCapture()
            : document.documentElement.removeEventListener(
                "mouseup",
                c.chart._mouseEventHandler,
                !1
              )),
        c.hasOwnProperty(b) &&
          ("mouseup" !== b || c.chart.overlaidCanvas.releaseCapture
            ? (a.target !== c.chart.overlaidCanvas && s) || c[b].call(c.context, d.x, d.y)
            : a.target !== c.chart.overlaidCanvas && (c.chart.isDrag = !1)));
      if (this.interactivityEnabled)
        if (this._ignoreNextEvent) this._ignoreNextEvent = !1;
        else if (
          (a.preventManipulation && a.preventManipulation(),
          a.preventDefault && a.preventDefault(),
          Ja &&
            window.console &&
            (window.console.log(b + " --\x3e x: " + d.x + "; y:" + d.y),
            e && window.console.log(a.which),
            "mouseup" === b && window.console.log("mouseup")),
          !e)
        ) {
          if (!p.capturedEventParam && this._events) {
            for (var g = 0; g < this._events.length; g++)
              if (this._events[g].hasOwnProperty(b))
                if (
                  ((c = this._events[g]),
                  (e = c.bounds),
                  d.x >= e.x1 && d.x <= e.x2 && d.y >= e.y1 && d.y <= e.y2)
                ) {
                  c[b].call(c.context, d.x, d.y);
                  "mousedown" === b && !0 === c.capture
                    ? ((p.capturedEventParam = c),
                      this.overlaidCanvas.setCapture
                        ? this.overlaidCanvas.setCapture()
                        : document.documentElement.addEventListener(
                            "mouseup",
                            this._mouseEventHandler,
                            !1
                          ))
                    : "mouseup" === b &&
                      (c.chart.overlaidCanvas.releaseCapture
                        ? c.chart.overlaidCanvas.releaseCapture()
                        : document.documentElement.removeEventListener(
                            "mouseup",
                            this._mouseEventHandler,
                            !1
                          ));
                  break;
                } else c = null;
            a.target.style.cursor = c && c.cursor ? c.cursor : this._defaultCursor;
          }
          b = this.plotArea;
          if (d.x < b.x1 || d.x > b.x2 || d.y < b.y1 || d.y > b.y2)
            this.toolTip && this.toolTip.enabled
              ? this.toolTip.hide()
              : this.resetOverlayedCanvas();
          (this.isDrag && this.zoomEnabled) ||
            !this._eventManager ||
            this._eventManager.mouseEventHandler(a);
        }
    };
    p.prototype._plotAreaMouseDown = function(a, d) {
      this.isDrag = !0;
      this.dragStartPoint = { x: a, y: d };
    };
    p.prototype._plotAreaMouseUp = function(a, d) {
      if (
        ("normal" === this.plotInfo.axisPlacement || "xySwapped" === this.plotInfo.axisPlacement) &&
        this.isDrag
      ) {
        var b = d - this.dragStartPoint.y,
          c = a - this.dragStartPoint.x,
          e = 0 <= this.zoomType.indexOf("x"),
          g = 0 <= this.zoomType.indexOf("y"),
          h = !1;
        this.resetOverlayedCanvas();
        if ("xySwapped" === this.plotInfo.axisPlacement)
          var k = g,
            g = e,
            e = k;
        if (this.panEnabled || this.zoomEnabled) {
          if (this.panEnabled)
            for (e = g = 0; e < this._axes.length; e++)
              (b = this._axes[e]),
                b.logarithmic
                  ? b.viewportMinimum < b.minimum
                    ? ((g = b.minimum / b.viewportMinimum),
                      (b.sessionVariables.newViewportMinimum = b.viewportMinimum * g),
                      (b.sessionVariables.newViewportMaximum = b.viewportMaximum * g),
                      (h = !0))
                    : b.viewportMaximum > b.maximum &&
                      ((g = b.viewportMaximum / b.maximum),
                      (b.sessionVariables.newViewportMinimum = b.viewportMinimum / g),
                      (b.sessionVariables.newViewportMaximum = b.viewportMaximum / g),
                      (h = !0))
                  : b.viewportMinimum < b.minimum
                  ? ((g = b.minimum - b.viewportMinimum),
                    (b.sessionVariables.newViewportMinimum = b.viewportMinimum + g),
                    (b.sessionVariables.newViewportMaximum = b.viewportMaximum + g),
                    (h = !0))
                  : b.viewportMaximum > b.maximum &&
                    ((g = b.viewportMaximum - b.maximum),
                    (b.sessionVariables.newViewportMinimum = b.viewportMinimum - g),
                    (b.sessionVariables.newViewportMaximum = b.viewportMaximum - g),
                    (h = !0));
          else if ((!e || 2 < Math.abs(c)) && (!g || 2 < Math.abs(b)) && this.zoomEnabled) {
            if (!this.dragStartPoint) return;
            b = e ? this.dragStartPoint.x : this.plotArea.x1;
            c = g ? this.dragStartPoint.y : this.plotArea.y1;
            e = e ? a : this.plotArea.x2;
            g = g ? d : this.plotArea.y2;
            2 < Math.abs(b - e) &&
              2 < Math.abs(c - g) &&
              this._zoomPanToSelectedRegion(b, c, e, g) &&
              (h = !0);
          }
          h &&
            ((this._ignoreNextEvent = !0),
            this._dispatchRangeEvent("rangeChanging", "zoom"),
            this.render(),
            this._dispatchRangeEvent("rangeChanged", "zoom"),
            h &&
              this.zoomEnabled && "none" === this._zoomButton.style.display &&
              (Qa(this._zoomButton, this._resetButton),
              va(this, this._zoomButton, "pan"),
              va(this, this._resetButton, "reset")));
        }
      }
      this.isDrag = !1;
      if ("none" !== this.plotInfo.axisPlacement) {
        this.resetOverlayedCanvas();
        if (this.axisX && 0 < this.axisX.length)
          for (h = 0; h < this.axisX.length; h++)
            this.axisX[h].crosshair &&
              this.axisX[h].crosshair.enabled &&
              this.axisX[h].renderCrosshair(a, d);
        if (this.axisX2 && 0 < this.axisX2.length)
          for (h = 0; h < this.axisX2.length; h++)
            this.axisX2[h].crosshair &&
              this.axisX2[h].crosshair.enabled &&
              this.axisX2[h].renderCrosshair(a, d);
        if (this.axisY && 0 < this.axisY.length)
          for (h = 0; h < this.axisY.length; h++)
            this.axisY[h].crosshair &&
              this.axisY[h].crosshair.enabled &&
              this.axisY[h].renderCrosshair(a, d);
        if (this.axisY2 && 0 < this.axisY2.length)
          for (h = 0; h < this.axisY2.length; h++)
            this.axisY2[h].crosshair &&
              this.axisY2[h].crosshair.enabled &&
              this.axisY2[h].renderCrosshair(a, d);
      }
    };
    p.prototype._plotAreaMouseMove = function(a, d) {
      if (this.isDrag && "none" !== this.plotInfo.axisPlacement) {
        var b = 0,
          c = 0,
          e = (b = null),
          e = 0 <= this.zoomType.indexOf("x"),
          g = 0 <= this.zoomType.indexOf("y"),
          h = this;
        "xySwapped" === this.plotInfo.axisPlacement && ((b = g), (g = e), (e = b));
        b = this.dragStartPoint.x - a;
        c = this.dragStartPoint.y - d;
        2 < Math.abs(b) && 8 > Math.abs(b) && (this.panEnabled || this.zoomEnabled)
          ? this.toolTip.hide()
          : this.panEnabled || this.zoomEnabled || this.toolTip.mouseMoveHandler(a, d);
        if (
          (!e || 2 < Math.abs(b) || !g || 2 < Math.abs(c)) &&
          (this.panEnabled || this.zoomEnabled)
        )
          if (this.panEnabled)
            (e = {
              x1: e ? this.plotArea.x1 + b : this.plotArea.x1,
              y1: g ? this.plotArea.y1 + c : this.plotArea.y1,
              x2: e ? this.plotArea.x2 + b : this.plotArea.x2,
              y2: g ? this.plotArea.y2 + c : this.plotArea.y2
            }),
              clearTimeout(h._panTimerId),
              (h._panTimerId = setTimeout(
                (function(c, b, e, f) {
                  return function() {
                    h._zoomPanToSelectedRegion(c, b, e, f, !0) &&
                      (h._dispatchRangeEvent("rangeChanging", "pan"),
                      h.render(),
                      h._dispatchRangeEvent("rangeChanged", "pan"),
                      (h.dragStartPoint.x = a),
                      (h.dragStartPoint.y = d));
                  };
                })(e.x1, e.y1, e.x2, e.y2),
                0
              ));
          else if (this.zoomEnabled) {
            this.resetOverlayedCanvas();
            b = this.overlaidCanvasCtx.globalAlpha;
            this.overlaidCanvasCtx.fillStyle = "#A89896";
            var c = e ? this.dragStartPoint.x : this.plotArea.x1,
              k = g ? this.dragStartPoint.y : this.plotArea.y1,
              v = e ? a - this.dragStartPoint.x : this.plotArea.x2 - this.plotArea.x1,
              m = g ? d - this.dragStartPoint.y : this.plotArea.y2 - this.plotArea.y1;
            this.validateRegion(
              c,
              k,
              e ? a : this.plotArea.x2 - this.plotArea.x1,
              g ? d : this.plotArea.y2 - this.plotArea.y1,
              "xy" !== this.zoomType
            ).isValid &&
              (this.resetOverlayedCanvas(), (this.overlaidCanvasCtx.fillStyle = "#99B2B5"));
            this.overlaidCanvasCtx.globalAlpha = 0.7;
            this.overlaidCanvasCtx.fillRect(c, k, v, m);
            this.overlaidCanvasCtx.globalAlpha = b;
          }
      } else if ((this.toolTip.mouseMoveHandler(a, d), "none" !== this.plotInfo.axisPlacement)) {
        if (this.axisX && 0 < this.axisX.length)
          for (e = 0; e < this.axisX.length; e++)
            this.axisX[e].crosshair &&
              this.axisX[e].crosshair.enabled &&
              this.axisX[e].renderCrosshair(a, d);
        if (this.axisX2 && 0 < this.axisX2.length)
          for (e = 0; e < this.axisX2.length; e++)
            this.axisX2[e].crosshair &&
              this.axisX2[e].crosshair.enabled &&
              this.axisX2[e].renderCrosshair(a, d);
        if (this.axisY && 0 < this.axisY.length)
          for (e = 0; e < this.axisY.length; e++)
            this.axisY[e].crosshair &&
              this.axisY[e].crosshair.enabled &&
              this.axisY[e].renderCrosshair(a, d);
        if (this.axisY2 && 0 < this.axisY2.length)
          for (e = 0; e < this.axisY2.length; e++)
            this.axisY2[e].crosshair &&
              this.axisY2[e].crosshair.enabled &&
              this.axisY2[e].renderCrosshair(a, d);
      }
    };
    p.prototype._zoomPanToSelectedRegion = function(a, d, b, c, e) {
      a = this.validateRegion(a, d, b, c, e);
      d = a.axesWithValidRange;
      b = a.axesRanges;
      if (a.isValid)
        for (c = 0; c < d.length; c++)
          (e = b[c]),
            d[c].setViewPortRange(e.val1, e.val2),
            this.syncCharts && this.syncCharts(e.val1, e.val2);
      return a.isValid;
    };
    p.prototype.validateRegion = function(a, d, b, c, e) {
      e = e || !1;
      for (
        var g = 0 <= this.zoomType.indexOf("x"),
          h = 0 <= this.zoomType.indexOf("y"),
          k = !1,
          v = [],
          m = [],
          r = [],
          q = 0;
        q < this._axes.length;
        q++
      )
        (("axisX" === this._axes[q].type && g) || ("axisY" === this._axes[q].type && h)) &&
          m.push(this._axes[q]);
      for (h = 0; h < m.length; h++) {
        var q = m[h],
          g = !1,
          n = q.convertPixelToValue({ x: a, y: d }),
          f = q.convertPixelToValue({ x: b, y: c });
        if (n > f)
          var A = f,
            f = n,
            n = A;
        if (q.scaleBreaks)
          for (A = 0; !g && A < q.scaleBreaks._appliedBreaks.length; A++)
            g =
              q.scaleBreaks._appliedBreaks[A].startValue <= n &&
              q.scaleBreaks._appliedBreaks[A].endValue >= f;
        if (isFinite(q.dataInfo.minDiff))
          if (
            ((A = q.getApparentDifference(n, f, null, !0)),
            !(
              g ||
              (!(this.panEnabled && q.scaleBreaks && q.scaleBreaks._appliedBreaks.length) &&
                ((q.logarithmic && A < Math.pow(q.dataInfo.minDiff, 3)) ||
                  (!q.logarithmic && A < 3 * Math.abs(q.dataInfo.minDiff)))) ||
              n < q.minimum ||
              f > q.maximum
            ))
          )
            v.push(q), r.push({ val1: n, val2: f }), (k = !0);
          else if (!e) {
            k = !1;
            break;
          }
      }
      return { isValid: k, axesWithValidRange: v, axesRanges: r };
    };
    p.prototype.preparePlotArea = function() {
      var a = this.plotArea;
      !s && (0 < a.x1 || 0 < a.y1) && a.ctx.translate(a.x1, a.y1);
      if ((this.axisX[0] || this.axisX2[0]) && (this.axisY[0] || this.axisY2[0])) {
        var d = this.axisX[0] ? this.axisX[0].lineCoordinates : this.axisX2[0].lineCoordinates;
        if (this.axisY && 0 < this.axisY.length && this.axisY[0]) {
          var b = this.axisY[0];
          a.x1 = d.x1 < d.x2 ? d.x1 : b.lineCoordinates.x1;
          a.y1 = d.y1 < b.lineCoordinates.y1 ? d.y1 : b.lineCoordinates.y1;
          a.x2 = d.x2 > b.lineCoordinates.x2 ? d.x2 : b.lineCoordinates.x2;
          a.y2 = d.y2 > d.y1 ? d.y2 : b.lineCoordinates.y2;
          a.width = a.x2 - a.x1;
          a.height = a.y2 - a.y1;
        }
        this.axisY2 &&
          0 < this.axisY2.length &&
          this.axisY2[0] &&
          ((b = this.axisY2[0]),
          (a.x1 = d.x1 < d.x2 ? d.x1 : b.lineCoordinates.x1),
          (a.y1 = d.y1 < b.lineCoordinates.y1 ? d.y1 : b.lineCoordinates.y1),
          (a.x2 = d.x2 > b.lineCoordinates.x2 ? d.x2 : b.lineCoordinates.x2),
          (a.y2 = d.y2 > d.y1 ? d.y2 : b.lineCoordinates.y2),
          (a.width = a.x2 - a.x1),
          (a.height = a.y2 - a.y1));
      } else
        (d = this.layoutManager.getFreeSpace()),
          (a.x1 = d.x1),
          (a.x2 = d.x2),
          (a.y1 = d.y1),
          (a.y2 = d.y2),
          (a.width = d.width),
          (a.height = d.height);
      s ||
        ((a.canvas.width = a.width),
        (a.canvas.height = a.height),
        (a.canvas.style.left = a.x1 + "px"),
        (a.canvas.style.top = a.y1 + "px"),
        (0 < a.x1 || 0 < a.y1) && a.ctx.translate(-a.x1, -a.y1));
      a.layoutManager = new Fa(a.x1, a.y1, a.x2, a.y2, 2);
    };
    p.prototype.renderIndexLabels = function(a) {
      var d = a || this.plotArea.ctx,
        b = this.plotArea,
        c = 0,
        e = 0,
        g = 0,
        h = 0,
        k = (c = h = e = g = 0),
        v = 0;
      for (a = 0; a < this._indexLabels.length; a++) {
        var m = this._indexLabels[a],
          r = m.chartType.toLowerCase(),
          q,
          n,
          k = ma("indexLabelFontColor", m.dataPoint, m.dataSeries),
          v = ma("indexLabelFontSize", m.dataPoint, m.dataSeries);
        q = ma("indexLabelFontFamily", m.dataPoint, m.dataSeries);
        n = ma("indexLabelFontStyle", m.dataPoint, m.dataSeries);
        var h = ma("indexLabelFontWeight", m.dataPoint, m.dataSeries),
          f = ma("indexLabelBackgroundColor", m.dataPoint, m.dataSeries),
          e = ma("indexLabelMaxWidth", m.dataPoint, m.dataSeries),
          g = ma("indexLabelWrap", m.dataPoint, m.dataSeries),
          A = ma("indexLabelLineDashType", m.dataPoint, m.dataSeries),
          l = ma("indexLabelLineColor", m.dataPoint, m.dataSeries),
          p = u(m.dataPoint.indexLabelLineThickness)
            ? u(m.dataSeries.options.indexLabelLineThickness)
              ? 0
              : m.dataSeries.options.indexLabelLineThickness
            : m.dataPoint.indexLabelLineThickness,
          c =
            0 < p
              ? Math.min(
                  10,
                  ("normal" === this.plotInfo.axisPlacement
                    ? this.plotArea.height
                    : this.plotArea.width) << 0
                )
              : 0,
          t = { percent: null, total: null },
          D = null;
        if (
          0 <= m.dataSeries.type.indexOf("stacked") ||
          "pie" === m.dataSeries.type ||
          "doughnut" === m.dataSeries.type
        )
          t = this.getPercentAndTotal(m.dataSeries, m.dataPoint);
        if (m.dataSeries.indexLabelFormatter || m.dataPoint.indexLabelFormatter)
          D = {
            chart: this,
            dataSeries: m.dataSeries,
            dataPoint: m.dataPoint,
            index: m.indexKeyword,
            total: t.total,
            percent: t.percent
          };
        var x = m.dataPoint.indexLabelFormatter
          ? m.dataPoint.indexLabelFormatter(D)
          : m.dataPoint.indexLabel
          ? this.replaceKeywordsWithValue(
              m.dataPoint.indexLabel,
              m.dataPoint,
              m.dataSeries,
              null,
              m.indexKeyword
            )
          : m.dataSeries.indexLabelFormatter
          ? m.dataSeries.indexLabelFormatter(D)
          : m.dataSeries.indexLabel
          ? this.replaceKeywordsWithValue(
              m.dataSeries.indexLabel,
              m.dataPoint,
              m.dataSeries,
              null,
              m.indexKeyword
            )
          : null;
        if (null !== x && "" !== x) {
          var t = ma("indexLabelPlacement", m.dataPoint, m.dataSeries),
            D = ma("indexLabelOrientation", m.dataPoint, m.dataSeries),
            la = m.direction,
            z = m.dataSeries.axisX,
            B = m.dataSeries.axisY,
            w = !1,
            f = new ja(d, {
              x: 0,
              y: 0,
              maxWidth: e ? e : 0.5 * this.width,
              maxHeight: g ? 5 * v : 1.5 * v,
              angle: "horizontal" === D ? 0 : -90,
              text: x,
              padding: 0,
              backgroundColor: f,
              horizontalAlign: "left",
              fontSize: v,
              fontFamily: q,
              fontWeight: h,
              fontColor: k,
              fontStyle: n,
              textBaseline: "top"
            });
          f.measureText();
          m.dataSeries.indexLabelMaxWidth = f.maxWidth;
          if ("stackedarea100" === r) {
            if (
              m.point.x < b.x1 ||
              m.point.x > b.x2 ||
              m.point.y < b.y1 - 1 ||
              m.point.y > b.y2 + 1
            )
              continue;
          } else if ("rangearea" === r || "rangesplinearea" === r) {
            if (
              m.dataPoint.x < z.viewportMinimum ||
              m.dataPoint.x > z.viewportMaximum ||
              Math.max.apply(null, m.dataPoint.y) < B.viewportMinimum ||
              Math.min.apply(null, m.dataPoint.y) > B.viewportMaximum
            )
              continue;
          } else if (
            0 <= r.indexOf("line") ||
            0 <= r.indexOf("area") ||
            0 <= r.indexOf("bubble") ||
            0 <= r.indexOf("scatter")
          ) {
            if (
              m.dataPoint.x < z.viewportMinimum ||
              m.dataPoint.x > z.viewportMaximum ||
              m.dataPoint.y < B.viewportMinimum ||
              m.dataPoint.y > B.viewportMaximum
            )
              continue;
          } else if (
            0 <= r.indexOf("column") ||
            "waterfall" === r ||
            ("error" === r && !m.axisSwapped)
          ) {
            if (
              m.dataPoint.x < z.viewportMinimum ||
              m.dataPoint.x > z.viewportMaximum ||
              m.bounds.y1 > b.y2 ||
              m.bounds.y2 < b.y1
            )
              continue;
          } else if (0 <= r.indexOf("bar") || "error" === r) {
            if (
              m.dataPoint.x < z.viewportMinimum ||
              m.dataPoint.x > z.viewportMaximum ||
              m.bounds.x1 > b.x2 ||
              m.bounds.x2 < b.x1
            )
              continue;
          } else if ("candlestick" === r || "ohlc" === r) {
            if (
              m.dataPoint.x < z.viewportMinimum ||
              m.dataPoint.x > z.viewportMaximum ||
              Math.max.apply(null, m.dataPoint.y) < B.viewportMinimum ||
              Math.min.apply(null, m.dataPoint.y) > B.viewportMaximum
            )
              continue;
          } else if (m.dataPoint.x < z.viewportMinimum || m.dataPoint.x > z.viewportMaximum)
            continue;
          e = h = 2;
          "horizontal" === D ? ((k = f.width), (v = f.height)) : ((v = f.width), (k = f.height));
          if ("normal" === this.plotInfo.axisPlacement) {
            if (0 <= r.indexOf("line") || 0 <= r.indexOf("area")) (t = "auto"), (h = 4);
            else if (0 <= r.indexOf("stacked")) "auto" === t && (t = "inside");
            else if ("bubble" === r || "scatter" === r) t = "inside";
            q = m.point.x - k / 2;
            "inside" !== t
              ? ((e = b.y1),
                (g = b.y2),
                0 < la
                  ? ((n = m.point.y - v - h - c),
                    n < e &&
                      ((n = "auto" === t ? Math.max(m.point.y, e) + h + c : e + h + c),
                      (w = n + v > m.point.y)))
                  : ((n = m.point.y + h + c),
                    n > g - v - h - c &&
                      ((n = "auto" === t ? Math.min(m.point.y, g) - v - h - c : g - v - h - c),
                      (w = n < m.point.y))))
              : ((e = Math.max(m.bounds.y1, b.y1)),
                (g = Math.min(m.bounds.y2, b.y2)),
                (c =
                  0 <= r.indexOf("range") || "error" === r
                    ? 0 < la
                      ? Math.max(m.bounds.y1, b.y1) + v / 2 + h
                      : Math.min(m.bounds.y2, b.y2) - v / 2 - h
                    : (Math.max(m.bounds.y1, b.y1) + Math.min(m.bounds.y2, b.y2)) / 2),
                0 < la
                  ? ((n = Math.max(m.point.y, c) - v / 2),
                    n < e &&
                      ("bubble" === r || "scatter" === r) &&
                      (n = Math.max(m.point.y - v - h, b.y1 + h)))
                  : ((n = Math.min(m.point.y, c) - v / 2),
                    n > g - v - h &&
                      ("bubble" === r || "scatter" === r) &&
                      (n = Math.min(m.point.y + h, b.y2 - v - h))),
                (n = Math.min(n, g - v)));
          } else
            0 <= r.indexOf("line") || 0 <= r.indexOf("area") || 0 <= r.indexOf("scatter")
              ? ((t = "auto"), (e = 4))
              : 0 <= r.indexOf("stacked")
              ? "auto" === t && (t = "inside")
              : "bubble" === r && (t = "inside"),
              (n = m.point.y - v / 2),
              "inside" !== t
                ? ((h = b.x1),
                  (g = b.x2),
                  0 > la
                    ? ((q = m.point.x - k - e - c),
                      q < h &&
                        ((q = "auto" === t ? Math.max(m.point.x, h) + e + c : h + e + c),
                        (w = q + k > m.point.x)))
                    : ((q = m.point.x + e + c),
                      q > g - k - e - c &&
                        ((q = "auto" === t ? Math.min(m.point.x, g) - k - e - c : g - k - e - c),
                        (w = q < m.point.x))))
                : ((h = Math.max(m.bounds.x1, b.x1)),
                  Math.min(m.bounds.x2, b.x2),
                  (c =
                    0 <= r.indexOf("range") || "error" === r
                      ? 0 > la
                        ? Math.max(m.bounds.x1, b.x1) + k / 2 + e
                        : Math.min(m.bounds.x2, b.x2) - k / 2 - e
                      : (Math.max(m.bounds.x1, b.x1) + Math.min(m.bounds.x2, b.x2)) / 2),
                  (q = 0 > la ? Math.max(m.point.x, c) - k / 2 : Math.min(m.point.x, c) - k / 2),
                  (q = Math.max(q, h)));
          "vertical" === D && (n += v);
          f.x = q;
          f.y = n;
          f.render(!0);
          p &&
            "inside" !== t &&
              ((0 > r.indexOf("bar") &&
                ("error" !== r || !m.axisSwapped) &&
                m.point.x > b.x1 &&
                m.point.x < b.x2) ||
                !w) &&
              ((0 > r.indexOf("column") &&
                ("error" !== r || m.axisSwapped) &&
                m.point.y > b.y1 &&
                m.point.y < b.y2) ||
                !w) &&
            ((d.lineWidth = p),
            (d.strokeStyle = l ? l : "gray"),
            d.setLineDash && d.setLineDash(Q(A, p)),
            d.beginPath(),
            d.moveTo(m.point.x, m.point.y),
            0 <= r.indexOf("bar") || ("error" === r && m.axisSwapped)
              ? d.lineTo(q + (0 < m.direction ? 0 : k), n + ("horizontal" === D ? v : -v) / 2)
              : 0 <= r.indexOf("column") || ("error" === r && !m.axisSwapped)
              ? d.lineTo(
                  q + k / 2,
                  n + ((0 < m.direction ? v : -v) + ("horizontal" === D ? v : -v)) / 2
                )
              : d.lineTo(
                  q + k / 2,
                  n + ((n < m.point.y ? v : -v) + ("horizontal" === D ? v : -v)) / 2
                ),
            d.stroke());
        }
      }
      d = {
        source: d,
        dest: this.plotArea.ctx,
        animationCallback: M.fadeInAnimation,
        easingFunction: M.easing.easeInQuad,
        animationBase: 0,
        startTimePercent: 0.7
      };
      for (a = 0; a < this._indexLabels.length; a++)
        (m = this._indexLabels[a]),
          (f = ma("indexLabelBackgroundColor", m.dataPoint, m.dataSeries)),
          (m.dataSeries.indexLabelBackgroundColor = u(f) ? (s ? "transparent" : null) : f);
      return d;
    };
    p.prototype.renderLine = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = this._eventManager.ghostCtx;
        b.save();
        var e = this.plotArea;
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        for (var g = [], h, k = 0; k < a.dataSeriesIndexes.length; k++) {
          var v = a.dataSeriesIndexes[k],
            m = this.data[v];
          b.lineWidth = m.lineThickness;
          var r = m.dataPoints,
            q = "solid";
          if (b.setLineDash) {
            var n = Q(m.nullDataLineDashType, m.lineThickness),
              q = m.lineDashType,
              f = Q(q, m.lineThickness);
            b.setLineDash(f);
          }
          var A = m.id;
          this._eventManager.objectMap[A] = { objectType: "dataSeries", dataSeriesIndex: v };
          A = P(A);
          c.strokeStyle = A;
          c.lineWidth = 0 < m.lineThickness ? Math.max(m.lineThickness, 4) : 0;
          var A = m._colorSet,
            l = (A = m.lineColor = m.options.lineColor ? m.options.lineColor : A[0]);
          b.strokeStyle = A;
          var p = !0,
            t = 0,
            D,
            x;
          b.beginPath();
          if (0 < r.length) {
            for (var u = !1, t = 0; t < r.length; t++)
              if (
                ((D = r[t].x.getTime ? r[t].x.getTime() : r[t].x),
                !(
                  D < a.axisX.dataInfo.viewPortMin ||
                  (D > a.axisX.dataInfo.viewPortMax && (!m.connectNullData || !u))
                ))
              )
                if ("number" !== typeof r[t].y)
                  0 < t && !(m.connectNullData || u || p) && (b.stroke(), s && c.stroke()),
                    (u = !0);
                else {
                  D = a.axisX.convertValueToPixel(D);
                  x = a.axisY.convertValueToPixel(r[t].y);
                  var z = m.dataPointIds[t];
                  this._eventManager.objectMap[z] = {
                    id: z,
                    objectType: "dataPoint",
                    dataSeriesIndex: v,
                    dataPointIndex: t,
                    x1: D,
                    y1: x
                  };
                  p || u
                    ? (!p && m.connectNullData
                        ? (b.setLineDash &&
                            (m.options.nullDataLineDashType ||
                              (q === m.lineDashType &&
                                m.lineDashType !== m.nullDataLineDashType)) &&
                            (b.stroke(),
                            b.beginPath(),
                            b.moveTo(h.x, h.y),
                            (q = m.nullDataLineDashType),
                            b.setLineDash(n)),
                          b.lineTo(D, x),
                          s && c.lineTo(D, x))
                        : (b.beginPath(), b.moveTo(D, x), s && (c.beginPath(), c.moveTo(D, x))),
                      (u = p = !1))
                    : (b.lineTo(D, x),
                      s && c.lineTo(D, x),
                      0 == t % 500 &&
                        (b.stroke(),
                        b.beginPath(),
                        b.moveTo(D, x),
                        s && (c.stroke(), c.beginPath(), c.moveTo(D, x))));
                  h = { x: D, y: x };
                  t < r.length - 1 &&
                    (l !== (r[t].lineColor || A) || q !== (r[t].lineDashType || m.lineDashType)) &&
                    (b.stroke(),
                    b.beginPath(),
                    b.moveTo(D, x),
                    (l = r[t].lineColor || A),
                    (b.strokeStyle = l),
                    b.setLineDash &&
                      (r[t].lineDashType
                        ? ((q = r[t].lineDashType), b.setLineDash(Q(q, m.lineThickness)))
                        : ((q = m.lineDashType), b.setLineDash(f))));
                  if (0 < r[t].markerSize || 0 < m.markerSize) {
                    var B = m.getMarkerProperties(t, D, x, b);
                    g.push(B);
                    z = P(z);
                    s &&
                      g.push({
                        x: D,
                        y: x,
                        ctx: c,
                        type: B.type,
                        size: B.size,
                        color: z,
                        borderColor: z,
                        borderThickness: B.borderThickness
                      });
                  }
                  (r[t].indexLabel ||
                    m.indexLabel ||
                    r[t].indexLabelFormatter ||
                    m.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "line",
                      dataPoint: r[t],
                      dataSeries: m,
                      point: { x: D, y: x },
                      direction: 0 > r[t].y === a.axisY.reversed ? 1 : -1,
                      color: A
                    });
                }
            b.stroke();
            s && c.stroke();
          }
        }
        Y.drawMarkers(g);
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          c.beginPath());
        b.restore();
        b.beginPath();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderStepLine = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = this._eventManager.ghostCtx;
        b.save();
        var e = this.plotArea;
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        for (var g = [], h, k = 0; k < a.dataSeriesIndexes.length; k++) {
          var v = a.dataSeriesIndexes[k],
            m = this.data[v];
          b.lineWidth = m.lineThickness;
          var r = m.dataPoints,
            q = "solid";
          if (b.setLineDash) {
            var n = Q(m.nullDataLineDashType, m.lineThickness),
              q = m.lineDashType,
              f = Q(q, m.lineThickness);
            b.setLineDash(f);
          }
          var A = m.id;
          this._eventManager.objectMap[A] = { objectType: "dataSeries", dataSeriesIndex: v };
          A = P(A);
          c.strokeStyle = A;
          c.lineWidth = 0 < m.lineThickness ? Math.max(m.lineThickness, 4) : 0;
          var A = m._colorSet,
            l = (A = m.lineColor = m.options.lineColor ? m.options.lineColor : A[0]);
          b.strokeStyle = A;
          var p = !0,
            t = 0,
            D,
            x;
          b.beginPath();
          if (0 < r.length) {
            for (var u = !1, t = 0; t < r.length; t++)
              if (
                ((D = r[t].getTime ? r[t].x.getTime() : r[t].x),
                !(
                  D < a.axisX.dataInfo.viewPortMin ||
                  (D > a.axisX.dataInfo.viewPortMax && (!m.connectNullData || !u))
                ))
              )
                if ("number" !== typeof r[t].y)
                  0 < t && !(m.connectNullData || u || p) && (b.stroke(), s && c.stroke()),
                    (u = !0);
                else {
                  var z = x;
                  D = a.axisX.convertValueToPixel(D);
                  x = a.axisY.convertValueToPixel(r[t].y);
                  var B = m.dataPointIds[t];
                  this._eventManager.objectMap[B] = {
                    id: B,
                    objectType: "dataPoint",
                    dataSeriesIndex: v,
                    dataPointIndex: t,
                    x1: D,
                    y1: x
                  };
                  p || u
                    ? (!p && m.connectNullData
                        ? (b.setLineDash &&
                            (m.options.nullDataLineDashType ||
                              (q === m.lineDashType &&
                                m.lineDashType !== m.nullDataLineDashType)) &&
                            (b.stroke(),
                            b.beginPath(),
                            b.moveTo(h.x, h.y),
                            (q = m.nullDataLineDashType),
                            b.setLineDash(n)),
                          b.lineTo(D, z),
                          b.lineTo(D, x),
                          s && (c.lineTo(D, z), c.lineTo(D, x)))
                        : (b.beginPath(), b.moveTo(D, x), s && (c.beginPath(), c.moveTo(D, x))),
                      (u = p = !1))
                    : (b.lineTo(D, z),
                      s && c.lineTo(D, z),
                      b.lineTo(D, x),
                      s && c.lineTo(D, x),
                      0 == t % 500 &&
                        (b.stroke(),
                        b.beginPath(),
                        b.moveTo(D, x),
                        s && (c.stroke(), c.beginPath(), c.moveTo(D, x))));
                  h = { x: D, y: x };
                  t < r.length - 1 &&
                    (l !== (r[t].lineColor || A) || q !== (r[t].lineDashType || m.lineDashType)) &&
                    (b.stroke(),
                    b.beginPath(),
                    b.moveTo(D, x),
                    (l = r[t].lineColor || A),
                    (b.strokeStyle = l),
                    b.setLineDash &&
                      (r[t].lineDashType
                        ? ((q = r[t].lineDashType), b.setLineDash(Q(q, m.lineThickness)))
                        : ((q = m.lineDashType), b.setLineDash(f))));
                  if (0 < r[t].markerSize || 0 < m.markerSize)
                    (z = m.getMarkerProperties(t, D, x, b)),
                      g.push(z),
                      (B = P(B)),
                      s &&
                        g.push({
                          x: D,
                          y: x,
                          ctx: c,
                          type: z.type,
                          size: z.size,
                          color: B,
                          borderColor: B,
                          borderThickness: z.borderThickness
                        });
                  (r[t].indexLabel ||
                    m.indexLabel ||
                    r[t].indexLabelFormatter ||
                    m.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "stepLine",
                      dataPoint: r[t],
                      dataSeries: m,
                      point: { x: D, y: x },
                      direction: 0 > r[t].y === a.axisY.reversed ? 1 : -1,
                      color: A
                    });
                }
            b.stroke();
            s && c.stroke();
          }
        }
        Y.drawMarkers(g);
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          c.beginPath());
        b.restore();
        b.beginPath();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderSpline = function(a) {
      function d(a) {
        a = w(a, 2);
        if (0 < a.length) {
          c.beginPath();
          s && e.beginPath();
          c.moveTo(a[0].x, a[0].y);
          a[0].newStrokeStyle && (c.strokeStyle = a[0].newStrokeStyle);
          a[0].newLineDashArray && c.setLineDash(a[0].newLineDashArray);
          s && e.moveTo(a[0].x, a[0].y);
          for (var b = 0; b < a.length - 3; b += 3)
            if (
              (c.bezierCurveTo(
                a[b + 1].x,
                a[b + 1].y,
                a[b + 2].x,
                a[b + 2].y,
                a[b + 3].x,
                a[b + 3].y
              ),
              s &&
                e.bezierCurveTo(
                  a[b + 1].x,
                  a[b + 1].y,
                  a[b + 2].x,
                  a[b + 2].y,
                  a[b + 3].x,
                  a[b + 3].y
                ),
              (0 < b && 0 === b % 3e3) || a[b + 3].newStrokeStyle || a[b + 3].newLineDashArray)
            )
              c.stroke(),
                c.beginPath(),
                c.moveTo(a[b + 3].x, a[b + 3].y),
                a[b + 3].newStrokeStyle && (c.strokeStyle = a[b + 3].newStrokeStyle),
                a[b + 3].newLineDashArray && c.setLineDash(a[b + 3].newLineDashArray),
                s && (e.stroke(), e.beginPath(), e.moveTo(a[b + 3].x, a[b + 3].y));
          c.stroke();
          s && e.stroke();
        }
      }
      var b = a.targetCanvasCtx || this.plotArea.ctx,
        c = s ? this._preRenderCtx : b;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = this._eventManager.ghostCtx;
        c.save();
        var g = this.plotArea;
        c.beginPath();
        c.rect(g.x1, g.y1, g.width, g.height);
        c.clip();
        for (var h = [], k = 0; k < a.dataSeriesIndexes.length; k++) {
          var v = a.dataSeriesIndexes[k],
            m = this.data[v];
          c.lineWidth = m.lineThickness;
          var r = m.dataPoints,
            q = "solid";
          if (c.setLineDash) {
            var n = Q(m.nullDataLineDashType, m.lineThickness),
              q = m.lineDashType,
              f = Q(q, m.lineThickness);
            c.setLineDash(f);
          }
          var A = m.id;
          this._eventManager.objectMap[A] = { objectType: "dataSeries", dataSeriesIndex: v };
          A = P(A);
          e.strokeStyle = A;
          e.lineWidth = 0 < m.lineThickness ? Math.max(m.lineThickness, 4) : 0;
          var A = m._colorSet,
            l = (A = m.lineColor = m.options.lineColor ? m.options.lineColor : A[0]);
          c.strokeStyle = A;
          var p = 0,
            t,
            u,
            x = [];
          c.beginPath();
          if (0 < r.length)
            for (u = !1, p = 0; p < r.length; p++)
              if (
                ((t = r[p].getTime ? r[p].x.getTime() : r[p].x),
                !(
                  t < a.axisX.dataInfo.viewPortMin ||
                  (t > a.axisX.dataInfo.viewPortMax && (!m.connectNullData || !u))
                ))
              )
                if ("number" !== typeof r[p].y)
                  0 < p &&
                    !u &&
                    (m.connectNullData
                      ? c.setLineDash &&
                        0 < x.length &&
                          (m.options.nullDataLineDashType || !r[p - 1].lineDashType) &&
                        ((x[x.length - 1].newLineDashArray = n), (q = m.nullDataLineDashType))
                      : (d(x), (x = []))),
                    (u = !0);
                else {
                  t = a.axisX.convertValueToPixel(t);
                  u = a.axisY.convertValueToPixel(r[p].y);
                  var la = m.dataPointIds[p];
                  this._eventManager.objectMap[la] = {
                    id: la,
                    objectType: "dataPoint",
                    dataSeriesIndex: v,
                    dataPointIndex: p,
                    x1: t,
                    y1: u
                  };
                  x[x.length] = { x: t, y: u };
                  p < r.length - 1 &&
                    (l !== (r[p].lineColor || A) || q !== (r[p].lineDashType || m.lineDashType)) &&
                    ((l = r[p].lineColor || A),
                    (x[x.length - 1].newStrokeStyle = l),
                    c.setLineDash &&
                      (r[p].lineDashType
                        ? ((q = r[p].lineDashType),
                          (x[x.length - 1].newLineDashArray = Q(q, m.lineThickness)))
                        : ((q = m.lineDashType), (x[x.length - 1].newLineDashArray = f))));
                  if (0 < r[p].markerSize || 0 < m.markerSize) {
                    var z = m.getMarkerProperties(p, t, u, c);
                    h.push(z);
                    la = P(la);
                    s &&
                      h.push({
                        x: t,
                        y: u,
                        ctx: e,
                        type: z.type,
                        size: z.size,
                        color: la,
                        borderColor: la,
                        borderThickness: z.borderThickness
                      });
                  }
                  (r[p].indexLabel ||
                    m.indexLabel ||
                    r[p].indexLabelFormatter ||
                    m.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "spline",
                      dataPoint: r[p],
                      dataSeries: m,
                      point: { x: t, y: u },
                      direction: 0 > r[p].y === a.axisY.reversed ? 1 : -1,
                      color: A
                    });
                  u = !1;
                }
          d(x);
        }
        Y.drawMarkers(h);
        s &&
          (b.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (c.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          c.clearRect(g.x1, g.y1, g.width, g.height),
          e.beginPath());
        c.restore();
        c.beginPath();
        return {
          source: b,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderColumn = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = 0,
          h,
          k,
          v,
          m = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          g = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1,
          r = this.options.dataPointMaxWidth
            ? this.dataPointMaxWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : Math.min(
                0.15 * this.width,
                0.9 * (this.plotArea.width / a.plotType.totalDataSeries)
              ) << 0,
          q = a.axisX.dataInfo.minDiff;
        isFinite(q) || (q = 0.3 * Math.abs(a.axisX.range));
        q = this.dataPointWidth = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.width *
                (a.axisX.logarithmic
                  ? Math.log(q) / Math.log(a.axisX.range)
                  : Math.abs(q) / Math.abs(a.axisX.range))) /
                a.plotType.totalDataSeries)) <<
            0;
        this.dataPointMaxWidth &&
          g > r &&
          (g = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, r));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && r < g &&
          (r = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, g));
        q < g && (q = g);
        q > r && (q = r);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (r = 0; r < a.dataSeriesIndexes.length; r++) {
          var n = a.dataSeriesIndexes[r],
            f = this.data[n],
            A = f.dataPoints;
          if (0 < A.length)
            for (var l = 5 < q && f.bevelEnabled ? !0 : !1, g = 0; g < A.length; g++)
              if (
                (A[g].getTime ? (v = A[g].x.getTime()) : (v = A[g].x),
                !(v < a.axisX.dataInfo.viewPortMin || v > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof A[g].y)
              ) {
                h = a.axisX.convertValueToPixel(v);
                k = a.axisY.convertValueToPixel(A[g].y);
                h = a.axisX.reversed
                  ? (h +
                      (a.plotType.totalDataSeries * q) / 2 -
                      (a.previousDataSeriesCount + r) * q) <<
                    0
                  : (h -
                      (a.plotType.totalDataSeries * q) / 2 +
                      (a.previousDataSeriesCount + r) * q) <<
                    0;
                var p = a.axisX.reversed ? (h - q) << 0 : (h + q) << 0,
                  t;
                0 <= A[g].y ? (t = m) : ((t = k), (k = m));
                k > t && ((c = k), (k = t), (t = c));
                c = A[g].color ? A[g].color : f._colorSet[g % f._colorSet.length];
                V(
                  b,
                  h,
                  k,
                  p,
                  t,
                  c,
                  0,
                  null,
                  l && 0 <= A[g].y,
                  0 > A[g].y && l,
                  !1,
                  !1,
                  f.fillOpacity
                );
                c = f.dataPointIds[g];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: n,
                  dataPointIndex: g,
                  x1: h,
                  y1: k,
                  x2: p,
                  y2: t
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, h, k, p, t, c, 0, null, !1, !1, !1, !1);
                (A[g].indexLabel ||
                  f.indexLabel ||
                  A[g].indexLabelFormatter ||
                  f.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "column",
                    dataPoint: A[g],
                    dataSeries: f,
                    point: { x: h + (p - h) / 2, y: 0 > A[g].y === a.axisY.reversed ? k : t },
                    direction: 0 > A[g].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: h, y1: Math.min(k, t), x2: p, y2: Math.max(k, t) },
                    color: c
                  });
              }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.yScaleAnimation,
          easingFunction: M.easing.easeOutQuart,
          animationBase:
            m < a.axisY.bounds.y1
              ? a.axisY.bounds.y1
              : m > a.axisY.bounds.y2
              ? a.axisY.bounds.y2
              : m
        };
      }
    };
    p.prototype.renderStackedColumn = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = [],
          h = [],
          k = [],
          v = [],
          m = 0,
          r,
          q,
          n = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          m = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        r = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.15 * this.width) << 0;
        var f = a.axisX.dataInfo.minDiff;
        isFinite(f) || (f = 0.3 * Math.abs(a.axisX.range));
        f = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.width *
                (a.axisX.logarithmic
                  ? Math.log(f) / Math.log(a.axisX.range)
                  : Math.abs(f) / Math.abs(a.axisX.range))) /
                a.plotType.plotUnits.length)) <<
            0;
        this.dataPointMaxWidth &&
          m > r &&
          (m = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, r));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && r < m &&
          (r = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, m));
        f < m && (f = m);
        f > r && (f = r);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (var A = 0; A < a.dataSeriesIndexes.length; A++) {
          var p = a.dataSeriesIndexes[A],
            l = this.data[p],
            t = l.dataPoints;
          if (0 < t.length) {
            var u = 5 < f && l.bevelEnabled ? !0 : !1;
            b.strokeStyle = "#4572A7 ";
            for (m = 0; m < t.length; m++)
              if (
                ((c = t[m].x.getTime ? t[m].x.getTime() : t[m].x),
                !(c < a.axisX.dataInfo.viewPortMin || c > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof t[m].y)
              ) {
                r = a.axisX.convertValueToPixel(c);
                var x = (r - (a.plotType.plotUnits.length * f) / 2 + a.index * f) << 0,
                  w = (x + f) << 0,
                  z;
                if (
                  a.axisY.logarithmic ||
                  (a.axisY.scaleBreaks &&
                    0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                    0 < t[m].y)
                )
                  (k[c] = t[m].y + (k[c] ? k[c] : 0)),
                    0 < k[c] &&
                      ((q = a.axisY.convertValueToPixel(k[c])),
                      (z = "undefined" !== typeof g[c] ? g[c] : n),
                      (g[c] = q));
                else if (
                  a.axisY.scaleBreaks &&
                  0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                  0 >= t[m].y
                )
                  (v[c] = t[m].y + (v[c] ? v[c] : 0)),
                    (z = a.axisY.convertValueToPixel(v[c])),
                    (q = "undefined" !== typeof h[c] ? h[c] : n),
                    (h[c] = z);
                else if (((q = a.axisY.convertValueToPixel(t[m].y)), 0 <= t[m].y)) {
                  var B = "undefined" !== typeof g[c] ? g[c] : 0;
                  q -= B;
                  z = n - B;
                  g[c] = B + (z - q);
                } else (B = h[c] ? h[c] : 0), (z = q + B), (q = n + B), (h[c] = B + (z - q));
                c = t[m].color ? t[m].color : l._colorSet[m % l._colorSet.length];
                V(
                  b,
                  x,
                  q,
                  w,
                  z,
                  c,
                  0,
                  null,
                  u && 0 <= t[m].y,
                  0 > t[m].y && u,
                  !1,
                  !1,
                  l.fillOpacity
                );
                c = l.dataPointIds[m];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: p,
                  dataPointIndex: m,
                  x1: x,
                  y1: q,
                  x2: w,
                  y2: z
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, x, q, w, z, c, 0, null, !1, !1, !1, !1);
                (t[m].indexLabel ||
                  l.indexLabel ||
                  t[m].indexLabelFormatter ||
                  l.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "stackedColumn",
                    dataPoint: t[m],
                    dataSeries: l,
                    point: { x: r, y: 0 <= t[m].y ? q : z },
                    direction: 0 > t[m].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: x, y1: Math.min(q, z), x2: w, y2: Math.max(q, z) },
                    color: c
                  });
              }
          }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.yScaleAnimation,
          easingFunction: M.easing.easeOutQuart,
          animationBase:
            n < a.axisY.bounds.y1
              ? a.axisY.bounds.y1
              : n > a.axisY.bounds.y2
              ? a.axisY.bounds.y2
              : n
        };
      }
    };
    p.prototype.renderStackedColumn100 = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = [],
          h = [],
          k = [],
          v = [],
          m = 0,
          r,
          q,
          n = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          m = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        r = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.15 * this.width) << 0;
        var f = a.axisX.dataInfo.minDiff;
        isFinite(f) || (f = 0.3 * Math.abs(a.axisX.range));
        f = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.width *
                (a.axisX.logarithmic
                  ? Math.log(f) / Math.log(a.axisX.range)
                  : Math.abs(f) / Math.abs(a.axisX.range))) /
                a.plotType.plotUnits.length)) <<
            0;
        this.dataPointMaxWidth &&
          m > r &&
          (m = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, r));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && r < m &&
          (r = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, m));
        f < m && (f = m);
        f > r && (f = r);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (var A = 0; A < a.dataSeriesIndexes.length; A++) {
          var l = a.dataSeriesIndexes[A],
            p = this.data[l],
            t = p.dataPoints;
          if (0 < t.length)
            for (var u = 5 < f && p.bevelEnabled ? !0 : !1, m = 0; m < t.length; m++)
              if (
                ((c = t[m].x.getTime ? t[m].x.getTime() : t[m].x),
                !(c < a.axisX.dataInfo.viewPortMin || c > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof t[m].y)
              ) {
                r = a.axisX.convertValueToPixel(c);
                q = 0 !== a.dataPointYSums[c] ? 100 * (t[m].y / a.dataPointYSums[c]) : 0;
                var x = (r - (a.plotType.plotUnits.length * f) / 2 + a.index * f) << 0,
                  w = (x + f) << 0,
                  z;
                if (
                  a.axisY.logarithmic ||
                  (a.axisY.scaleBreaks &&
                    0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                    0 < t[m].y)
                ) {
                  k[c] = q + ("undefined" !== typeof k[c] ? k[c] : 0);
                  if (0 >= k[c]) continue;
                  q = a.axisY.convertValueToPixel(k[c]);
                  z = g[c] ? g[c] : n;
                  g[c] = q;
                } else if (
                  a.axisY.scaleBreaks &&
                  0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                  0 >= t[m].y
                )
                  (v[c] = q + ("undefined" !== typeof v[c] ? v[c] : 0)),
                    (z = a.axisY.convertValueToPixel(v[c])),
                    (q = h[c] ? h[c] : n),
                    (h[c] = z);
                else if (((q = a.axisY.convertValueToPixel(q)), 0 <= t[m].y)) {
                  var B = "undefined" !== typeof g[c] ? g[c] : 0;
                  q -= B;
                  z = n - B;
                  a.dataSeriesIndexes.length - 1 === A && 1 >= Math.abs(e.y1 - q) && (q = e.y1);
                  g[c] = B + (z - q);
                } else
                  (B = "undefined" !== typeof h[c] ? h[c] : 0),
                    (z = q + B),
                    (q = n + B),
                    a.dataSeriesIndexes.length - 1 === A && 1 >= Math.abs(e.y2 - z) && (z = e.y2),
                    (h[c] = B + (z - q));
                c = t[m].color ? t[m].color : p._colorSet[m % p._colorSet.length];
                V(
                  b,
                  x,
                  q,
                  w,
                  z,
                  c,
                  0,
                  null,
                  u && 0 <= t[m].y,
                  0 > t[m].y && u,
                  !1,
                  !1,
                  p.fillOpacity
                );
                c = p.dataPointIds[m];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: l,
                  dataPointIndex: m,
                  x1: x,
                  y1: q,
                  x2: w,
                  y2: z
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, x, q, w, z, c, 0, null, !1, !1, !1, !1);
                (t[m].indexLabel ||
                  p.indexLabel ||
                  t[m].indexLabelFormatter ||
                  p.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "stackedColumn100",
                    dataPoint: t[m],
                    dataSeries: p,
                    point: { x: r, y: 0 <= t[m].y ? q : z },
                    direction: 0 > t[m].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: x, y1: Math.min(q, z), x2: w, y2: Math.max(q, z) },
                    color: c
                  });
              }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.yScaleAnimation,
          easingFunction: M.easing.easeOutQuart,
          animationBase:
            n < a.axisY.bounds.y1
              ? a.axisY.bounds.y1
              : n > a.axisY.bounds.y2
              ? a.axisY.bounds.y2
              : n
        };
      }
    };
    p.prototype.renderBar = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = 0,
          h,
          k,
          v,
          m = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          g = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1,
          r = this.options.dataPointMaxWidth
            ? this.dataPointMaxWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : Math.min(
                0.15 * this.height,
                0.9 * (this.plotArea.height / a.plotType.totalDataSeries)
              ) << 0,
          q = a.axisX.dataInfo.minDiff;
        isFinite(q) || (q = 0.3 * Math.abs(a.axisX.range));
        q = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.height *
                (a.axisX.logarithmic
                  ? Math.log(q) / Math.log(a.axisX.range)
                  : Math.abs(q) / Math.abs(a.axisX.range))) /
                a.plotType.totalDataSeries)) <<
            0;
        this.dataPointMaxWidth &&
          g > r &&
          (g = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, r));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && r < g &&
          (r = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, g));
        q < g && (q = g);
        q > r && (q = r);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (r = 0; r < a.dataSeriesIndexes.length; r++) {
          var n = a.dataSeriesIndexes[r],
            f = this.data[n],
            A = f.dataPoints;
          if (0 < A.length) {
            var p = 5 < q && f.bevelEnabled ? !0 : !1;
            b.strokeStyle = "#4572A7 ";
            for (g = 0; g < A.length; g++)
              if (
                (A[g].getTime ? (v = A[g].x.getTime()) : (v = A[g].x),
                !(v < a.axisX.dataInfo.viewPortMin || v > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof A[g].y)
              ) {
                k = a.axisX.convertValueToPixel(v);
                h = a.axisY.convertValueToPixel(A[g].y);
                k = a.axisX.reversed
                  ? (k +
                      (a.plotType.totalDataSeries * q) / 2 -
                      (a.previousDataSeriesCount + r) * q) <<
                    0
                  : (k -
                      (a.plotType.totalDataSeries * q) / 2 +
                      (a.previousDataSeriesCount + r) * q) <<
                    0;
                var l = a.axisX.reversed ? (k - q) << 0 : (k + q) << 0,
                  t;
                0 <= A[g].y ? (t = m) : ((t = h), (h = m));
                c = A[g].color ? A[g].color : f._colorSet[g % f._colorSet.length];
                V(b, t, k, h, l, c, 0, null, p, !1, !1, !1, f.fillOpacity);
                c = f.dataPointIds[g];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: n,
                  dataPointIndex: g,
                  x1: t,
                  y1: k,
                  x2: h,
                  y2: l
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, t, k, h, l, c, 0, null, !1, !1, !1, !1);
                (A[g].indexLabel ||
                  f.indexLabel ||
                  A[g].indexLabelFormatter ||
                  f.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "bar",
                    dataPoint: A[g],
                    dataSeries: f,
                    point: { x: 0 <= A[g].y ? h : t, y: k + (l - k) / 2 },
                    direction: 0 > A[g].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: Math.min(t, h), y1: k, x2: Math.max(t, h), y2: l },
                    color: c
                  });
              }
          }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.xScaleAnimation,
          easingFunction: M.easing.easeOutQuart,
          animationBase:
            m < a.axisY.bounds.x1
              ? a.axisY.bounds.x1
              : m > a.axisY.bounds.x2
              ? a.axisY.bounds.x2
              : m
        };
      }
    };
    p.prototype.renderStackedBar = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = [],
          h = [],
          k = [],
          v = [],
          m = 0,
          r,
          q,
          n = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          m = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        q = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.15 * this.height) << 0;
        var f = a.axisX.dataInfo.minDiff;
        isFinite(f) || (f = 0.3 * Math.abs(a.axisX.range));
        f = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.height *
                (a.axisX.logarithmic
                  ? Math.log(f) / Math.log(a.axisX.range)
                  : Math.abs(f) / Math.abs(a.axisX.range))) /
                a.plotType.plotUnits.length)) <<
            0;
        this.dataPointMaxWidth &&
          m > q &&
          (m = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, q));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && q < m &&
          (q = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, m));
        f < m && (f = m);
        f > q && (f = q);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (var A = 0; A < a.dataSeriesIndexes.length; A++) {
          var l = a.dataSeriesIndexes[A],
            p = this.data[l],
            t = p.dataPoints;
          if (0 < t.length) {
            var u = 5 < f && p.bevelEnabled ? !0 : !1;
            b.strokeStyle = "#4572A7 ";
            for (m = 0; m < t.length; m++)
              if (
                ((c = t[m].x.getTime ? t[m].x.getTime() : t[m].x),
                !(c < a.axisX.dataInfo.viewPortMin || c > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof t[m].y)
              ) {
                q = a.axisX.convertValueToPixel(c);
                var x = (q - (a.plotType.plotUnits.length * f) / 2 + a.index * f) << 0,
                  w = (x + f) << 0,
                  z;
                if (
                  a.axisY.logarithmic ||
                  (a.axisY.scaleBreaks &&
                    0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                    0 < t[m].y)
                )
                  (k[c] = t[m].y + (k[c] ? k[c] : 0)),
                    0 < k[c] &&
                      ((z = g[c] ? g[c] : n), (g[c] = r = a.axisY.convertValueToPixel(k[c])));
                else if (
                  a.axisY.scaleBreaks &&
                  0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                  0 >= t[m].y
                )
                  (v[c] = t[m].y + (v[c] ? v[c] : 0)),
                    (r = h[c] ? h[c] : n),
                    (h[c] = z = a.axisY.convertValueToPixel(v[c]));
                else if (((r = a.axisY.convertValueToPixel(t[m].y)), 0 <= t[m].y)) {
                  var B = g[c] ? g[c] : 0;
                  z = n + B;
                  r += B;
                  g[c] = B + (r - z);
                } else (B = h[c] ? h[c] : 0), (z = r - B), (r = n - B), (h[c] = B + (r - z));
                c = t[m].color ? t[m].color : p._colorSet[m % p._colorSet.length];
                V(b, z, x, r, w, c, 0, null, u, !1, !1, !1, p.fillOpacity);
                c = p.dataPointIds[m];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: l,
                  dataPointIndex: m,
                  x1: z,
                  y1: x,
                  x2: r,
                  y2: w
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, z, x, r, w, c, 0, null, !1, !1, !1, !1);
                (t[m].indexLabel ||
                  p.indexLabel ||
                  t[m].indexLabelFormatter ||
                  p.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "stackedBar",
                    dataPoint: t[m],
                    dataSeries: p,
                    point: { x: 0 <= t[m].y ? r : z, y: q },
                    direction: 0 > t[m].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: Math.min(z, r), y1: x, x2: Math.max(z, r), y2: w },
                    color: c
                  });
              }
          }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.xScaleAnimation,
          easingFunction: M.easing.easeOutQuart,
          animationBase:
            n < a.axisY.bounds.x1
              ? a.axisY.bounds.x1
              : n > a.axisY.bounds.x2
              ? a.axisY.bounds.x2
              : n
        };
      }
    };
    p.prototype.renderStackedBar100 = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = [],
          h = [],
          k = [],
          v = [],
          m = 0,
          r,
          q,
          n = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          m = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        q = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.15 * this.height) << 0;
        var f = a.axisX.dataInfo.minDiff;
        isFinite(f) || (f = 0.3 * Math.abs(a.axisX.range));
        f = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.height *
                (a.axisX.logarithmic
                  ? Math.log(f) / Math.log(a.axisX.range)
                  : Math.abs(f) / Math.abs(a.axisX.range))) /
                a.plotType.plotUnits.length)) <<
            0;
        this.dataPointMaxWidth &&
          m > q &&
          (m = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, q));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && q < m &&
          (q = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, m));
        f < m && (f = m);
        f > q && (f = q);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (var A = 0; A < a.dataSeriesIndexes.length; A++) {
          var p = a.dataSeriesIndexes[A],
            l = this.data[p],
            t = l.dataPoints;
          if (0 < t.length) {
            var u = 5 < f && l.bevelEnabled ? !0 : !1;
            b.strokeStyle = "#4572A7 ";
            for (m = 0; m < t.length; m++)
              if (
                ((c = t[m].x.getTime ? t[m].x.getTime() : t[m].x),
                !(c < a.axisX.dataInfo.viewPortMin || c > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof t[m].y)
              ) {
                q = a.axisX.convertValueToPixel(c);
                var x;
                x = 0 !== a.dataPointYSums[c] ? 100 * (t[m].y / a.dataPointYSums[c]) : 0;
                var w = (q - (a.plotType.plotUnits.length * f) / 2 + a.index * f) << 0,
                  z = (w + f) << 0;
                if (
                  a.axisY.logarithmic ||
                  (a.axisY.scaleBreaks &&
                    0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                    0 < t[m].y)
                ) {
                  k[c] = x + (k[c] ? k[c] : 0);
                  if (0 >= k[c]) continue;
                  x = g[c] ? g[c] : n;
                  g[c] = r = a.axisY.convertValueToPixel(k[c]);
                } else if (
                  a.axisY.scaleBreaks &&
                  0 < a.axisY.scaleBreaks._appliedBreaks.length &&
                  0 >= t[m].y
                )
                  (v[c] = x + (v[c] ? v[c] : 0)),
                    (r = h[c] ? h[c] : n),
                    (h[c] = x = a.axisY.convertValueToPixel(v[c]));
                else if (((r = a.axisY.convertValueToPixel(x)), 0 <= t[m].y)) {
                  var B = g[c] ? g[c] : 0;
                  x = n + B;
                  r += B;
                  a.dataSeriesIndexes.length - 1 === A && 1 >= Math.abs(e.x2 - r) && (r = e.x2);
                  g[c] = B + (r - x);
                } else
                  (B = h[c] ? h[c] : 0),
                    (x = r - B),
                    (r = n - B),
                    a.dataSeriesIndexes.length - 1 === A && 1 >= Math.abs(e.x1 - x) && (x = e.x1),
                    (h[c] = B + (r - x));
                c = t[m].color ? t[m].color : l._colorSet[m % l._colorSet.length];
                V(b, x, w, r, z, c, 0, null, u, !1, !1, !1, l.fillOpacity);
                c = l.dataPointIds[m];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: p,
                  dataPointIndex: m,
                  x1: x,
                  y1: w,
                  x2: r,
                  y2: z
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, x, w, r, z, c, 0, null, !1, !1, !1, !1);
                (t[m].indexLabel ||
                  l.indexLabel ||
                  t[m].indexLabelFormatter ||
                  l.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "stackedBar100",
                    dataPoint: t[m],
                    dataSeries: l,
                    point: { x: 0 <= t[m].y ? r : x, y: q },
                    direction: 0 > t[m].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: Math.min(x, r), y1: w, x2: Math.max(x, r), y2: z },
                    color: c
                  });
              }
          }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.xScaleAnimation,
          easingFunction: M.easing.easeOutQuart,
          animationBase:
            n < a.axisY.bounds.x1
              ? a.axisY.bounds.x1
              : n > a.axisY.bounds.x2
              ? a.axisY.bounds.x2
              : n
        };
      }
    };
    p.prototype.renderArea = function(a) {
      var d, b;
      function c() {
        B &&
          (0 < A.lineThickness && g.stroke(),
          a.axisY.logarithmic || (0 >= a.axisY.viewportMinimum && 0 <= a.axisY.viewportMaximum)
            ? (z = w)
            : 0 > a.axisY.viewportMaximum
            ? (z = v.y1)
            : 0 < a.axisY.viewportMinimum && (z = k.y2),
          g.lineTo(t, z),
          g.lineTo(B.x, z),
          g.closePath(),
          (g.globalAlpha = A.fillOpacity),
          g.fill(),
          (g.globalAlpha = 1),
          s && (h.lineTo(t, z), h.lineTo(B.x, z), h.closePath(), h.fill()),
          g.beginPath(),
          g.moveTo(t, u),
          h.beginPath(),
          h.moveTo(t, u),
          (B = { x: t, y: u }));
      }
      var e = a.targetCanvasCtx || this.plotArea.ctx,
        g = s ? this._preRenderCtx : e;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var h = this._eventManager.ghostCtx,
          k = a.axisX.lineCoordinates,
          v = a.axisY.lineCoordinates,
          m = [],
          r = this.plotArea,
          q;
        g.save();
        s && h.save();
        g.beginPath();
        g.rect(r.x1, r.y1, r.width, r.height);
        g.clip();
        s && (h.beginPath(), h.rect(r.x1, r.y1, r.width, r.height), h.clip());
        for (var n = 0; n < a.dataSeriesIndexes.length; n++) {
          var f = a.dataSeriesIndexes[n],
            A = this.data[f],
            l = A.dataPoints,
            m = A.id;
          this._eventManager.objectMap[m] = { objectType: "dataSeries", dataSeriesIndex: f };
          m = P(m);
          h.fillStyle = m;
          m = [];
          d = !0;
          var p = 0,
            t,
            u,
            x,
            w = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
            z,
            B = null;
          if (0 < l.length) {
            var y = A._colorSet[p % A._colorSet.length],
              X = (A.lineColor = A.options.lineColor || y),
              T = X;
            g.fillStyle = y;
            g.strokeStyle = X;
            g.lineWidth = A.lineThickness;
            b = "solid";
            if (g.setLineDash) {
              var Z = Q(A.nullDataLineDashType, A.lineThickness);
              b = A.lineDashType;
              var ca = Q(b, A.lineThickness);
              g.setLineDash(ca);
            }
            for (var ea = !0; p < l.length; p++)
              if (
                ((x = l[p].x.getTime ? l[p].x.getTime() : l[p].x),
                !(
                  x < a.axisX.dataInfo.viewPortMin ||
                  (x > a.axisX.dataInfo.viewPortMax && (!A.connectNullData || !ea))
                ))
              )
                if ("number" !== typeof l[p].y) A.connectNullData || ea || d || c(), (ea = !0);
                else {
                  t = a.axisX.convertValueToPixel(x);
                  u = a.axisY.convertValueToPixel(l[p].y);
                  d || ea
                    ? (!d && A.connectNullData
                        ? (g.setLineDash &&
                            (A.options.nullDataLineDashType ||
                              (b === A.lineDashType &&
                                A.lineDashType !== A.nullDataLineDashType)) &&
                            ((d = t),
                            (b = u),
                            (t = q.x),
                            (u = q.y),
                            c(),
                            g.moveTo(q.x, q.y),
                            (t = d),
                            (u = b),
                            (B = q),
                            (b = A.nullDataLineDashType),
                            g.setLineDash(Z)),
                          g.lineTo(t, u),
                          s && h.lineTo(t, u))
                        : (g.beginPath(),
                          g.moveTo(t, u),
                          s && (h.beginPath(), h.moveTo(t, u)),
                          (B = { x: t, y: u })),
                      (ea = d = !1))
                    : (g.lineTo(t, u), s && h.lineTo(t, u), 0 == p % 250 && c());
                  q = { x: t, y: u };
                  p < l.length - 1 &&
                    (T !== (l[p].lineColor || X) || b !== (l[p].lineDashType || A.lineDashType)) &&
                    (c(),
                    (T = l[p].lineColor || X),
                    (g.strokeStyle = T),
                    g.setLineDash &&
                      (l[p].lineDashType
                        ? ((b = l[p].lineDashType), g.setLineDash(Q(b, A.lineThickness)))
                        : ((b = A.lineDashType), g.setLineDash(ca))));
                  var $ = A.dataPointIds[p];
                  this._eventManager.objectMap[$] = {
                    id: $,
                    objectType: "dataPoint",
                    dataSeriesIndex: f,
                    dataPointIndex: p,
                    x1: t,
                    y1: u
                  };
                  0 !== l[p].markerSize &&
                    (0 < l[p].markerSize || 0 < A.markerSize) &&
                    ((x = A.getMarkerProperties(p, t, u, g)),
                    m.push(x),
                    ($ = P($)),
                    s &&
                      m.push({
                        x: t,
                        y: u,
                        ctx: h,
                        type: x.type,
                        size: x.size,
                        color: $,
                        borderColor: $,
                        borderThickness: x.borderThickness
                      }));
                  (l[p].indexLabel ||
                    A.indexLabel ||
                    l[p].indexLabelFormatter ||
                    A.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "area",
                      dataPoint: l[p],
                      dataSeries: A,
                      point: { x: t, y: u },
                      direction: 0 > l[p].y === a.axisY.reversed ? 1 : -1,
                      color: y
                    });
                }
            c();
            Y.drawMarkers(m);
          }
        }
        s &&
          (e.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (g.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && g.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && g.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          g.clearRect(r.x1, r.y1, r.width, r.height),
          this._eventManager.ghostCtx.restore());
        g.restore();
        return {
          source: e,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderSplineArea = function(a) {
      function d() {
        var b = w(x, 2);
        if (0 < b.length) {
          if (0 < q.lineThickness) {
            c.beginPath();
            c.moveTo(b[0].x, b[0].y);
            b[0].newStrokeStyle && (c.strokeStyle = b[0].newStrokeStyle);
            b[0].newLineDashArray && c.setLineDash(b[0].newLineDashArray);
            for (var d = 0; d < b.length - 3; d += 3)
              if (
                (c.bezierCurveTo(
                  b[d + 1].x,
                  b[d + 1].y,
                  b[d + 2].x,
                  b[d + 2].y,
                  b[d + 3].x,
                  b[d + 3].y
                ),
                s &&
                  e.bezierCurveTo(
                    b[d + 1].x,
                    b[d + 1].y,
                    b[d + 2].x,
                    b[d + 2].y,
                    b[d + 3].x,
                    b[d + 3].y
                  ),
                b[d + 3].newStrokeStyle || b[d + 3].newLineDashArray)
              )
                c.stroke(),
                  c.beginPath(),
                  c.moveTo(b[d + 3].x, b[d + 3].y),
                  b[d + 3].newStrokeStyle && (c.strokeStyle = b[d + 3].newStrokeStyle),
                  b[d + 3].newLineDashArray && c.setLineDash(b[d + 3].newLineDashArray);
            c.stroke();
          }
          c.beginPath();
          c.moveTo(b[0].x, b[0].y);
          s && (e.beginPath(), e.moveTo(b[0].x, b[0].y));
          for (d = 0; d < b.length - 3; d += 3)
            c.bezierCurveTo(b[d + 1].x, b[d + 1].y, b[d + 2].x, b[d + 2].y, b[d + 3].x, b[d + 3].y),
              s &&
                e.bezierCurveTo(
                  b[d + 1].x,
                  b[d + 1].y,
                  b[d + 2].x,
                  b[d + 2].y,
                  b[d + 3].x,
                  b[d + 3].y
                );
          a.axisY.logarithmic || (0 >= a.axisY.viewportMinimum && 0 <= a.axisY.viewportMaximum)
            ? (t = l)
            : 0 > a.axisY.viewportMaximum
            ? (t = h.y1)
            : 0 < a.axisY.viewportMinimum && (t = g.y2);
          u = { x: b[0].x, y: b[0].y };
          c.lineTo(b[b.length - 1].x, t);
          c.lineTo(u.x, t);
          c.closePath();
          c.globalAlpha = q.fillOpacity;
          c.fill();
          c.globalAlpha = 1;
          s && (e.lineTo(b[b.length - 1].x, t), e.lineTo(u.x, t), e.closePath(), e.fill());
        }
      }
      var b = a.targetCanvasCtx || this.plotArea.ctx,
        c = s ? this._preRenderCtx : b;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = this._eventManager.ghostCtx,
          g = a.axisX.lineCoordinates,
          h = a.axisY.lineCoordinates,
          k = [],
          v = this.plotArea;
        c.save();
        s && e.save();
        c.beginPath();
        c.rect(v.x1, v.y1, v.width, v.height);
        c.clip();
        s && (e.beginPath(), e.rect(v.x1, v.y1, v.width, v.height), e.clip());
        for (var m = 0; m < a.dataSeriesIndexes.length; m++) {
          var r = a.dataSeriesIndexes[m],
            q = this.data[r],
            n = q.dataPoints,
            k = q.id;
          this._eventManager.objectMap[k] = { objectType: "dataSeries", dataSeriesIndex: r };
          k = P(k);
          e.fillStyle = k;
          var k = [],
            f = 0,
            A,
            p,
            l = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
            t,
            u = null,
            x = [];
          if (0 < n.length) {
            var la = q._colorSet[f % q._colorSet.length],
              z = (q.lineColor = q.options.lineColor || la),
              B = z;
            c.fillStyle = la;
            c.strokeStyle = z;
            c.lineWidth = q.lineThickness;
            var y = "solid";
            if (c.setLineDash) {
              var X = Q(q.nullDataLineDashType, q.lineThickness),
                y = q.lineDashType,
                T = Q(y, q.lineThickness);
              c.setLineDash(T);
            }
            for (p = !1; f < n.length; f++)
              if (
                ((A = n[f].x.getTime ? n[f].x.getTime() : n[f].x),
                !(
                  A < a.axisX.dataInfo.viewPortMin ||
                  (A > a.axisX.dataInfo.viewPortMax && (!q.connectNullData || !p))
                ))
              )
                if ("number" !== typeof n[f].y)
                  0 < f &&
                    !p &&
                    (q.connectNullData
                      ? c.setLineDash &&
                        0 < x.length &&
                          (q.options.nullDataLineDashType || !n[f - 1].lineDashType) &&
                        ((x[x.length - 1].newLineDashArray = X), (y = q.nullDataLineDashType))
                      : (d(), (x = []))),
                    (p = !0);
                else {
                  A = a.axisX.convertValueToPixel(A);
                  p = a.axisY.convertValueToPixel(n[f].y);
                  var Z = q.dataPointIds[f];
                  this._eventManager.objectMap[Z] = {
                    id: Z,
                    objectType: "dataPoint",
                    dataSeriesIndex: r,
                    dataPointIndex: f,
                    x1: A,
                    y1: p
                  };
                  x[x.length] = { x: A, y: p };
                  f < n.length - 1 &&
                    (B !== (n[f].lineColor || z) || y !== (n[f].lineDashType || q.lineDashType)) &&
                    ((B = n[f].lineColor || z),
                    (x[x.length - 1].newStrokeStyle = B),
                    c.setLineDash &&
                      (n[f].lineDashType
                        ? ((y = n[f].lineDashType),
                          (x[x.length - 1].newLineDashArray = Q(y, q.lineThickness)))
                        : ((y = q.lineDashType), (x[x.length - 1].newLineDashArray = T))));
                  if (0 !== n[f].markerSize && (0 < n[f].markerSize || 0 < q.markerSize)) {
                    var ca = q.getMarkerProperties(f, A, p, c);
                    k.push(ca);
                    Z = P(Z);
                    s &&
                      k.push({
                        x: A,
                        y: p,
                        ctx: e,
                        type: ca.type,
                        size: ca.size,
                        color: Z,
                        borderColor: Z,
                        borderThickness: ca.borderThickness
                      });
                  }
                  (n[f].indexLabel ||
                    q.indexLabel ||
                    n[f].indexLabelFormatter ||
                    q.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "splineArea",
                      dataPoint: n[f],
                      dataSeries: q,
                      point: { x: A, y: p },
                      direction: 0 > n[f].y === a.axisY.reversed ? 1 : -1,
                      color: la
                    });
                  p = !1;
                }
            d();
            Y.drawMarkers(k);
          }
        }
        s &&
          (b.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (c.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          c.clearRect(v.x1, v.y1, v.width, v.height),
          this._eventManager.ghostCtx.restore());
        c.restore();
        return {
          source: b,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderStepArea = function(a) {
      var d, b;
      function c() {
        B &&
          (0 < A.lineThickness && g.stroke(),
          a.axisY.logarithmic || (0 >= a.axisY.viewportMinimum && 0 <= a.axisY.viewportMaximum)
            ? (z = w)
            : 0 > a.axisY.viewportMaximum
            ? (z = v.y1)
            : 0 < a.axisY.viewportMinimum && (z = k.y2),
          g.lineTo(t, z),
          g.lineTo(B.x, z),
          g.closePath(),
          (g.globalAlpha = A.fillOpacity),
          g.fill(),
          (g.globalAlpha = 1),
          s && (h.lineTo(t, z), h.lineTo(B.x, z), h.closePath(), h.fill()),
          g.beginPath(),
          g.moveTo(t, u),
          h.beginPath(),
          h.moveTo(t, u),
          (B = { x: t, y: u }));
      }
      var e = a.targetCanvasCtx || this.plotArea.ctx,
        g = s ? this._preRenderCtx : e;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var h = this._eventManager.ghostCtx,
          k = a.axisX.lineCoordinates,
          v = a.axisY.lineCoordinates,
          m = [],
          r = this.plotArea,
          q;
        g.save();
        s && h.save();
        g.beginPath();
        g.rect(r.x1, r.y1, r.width, r.height);
        g.clip();
        s && (h.beginPath(), h.rect(r.x1, r.y1, r.width, r.height), h.clip());
        for (var n = 0; n < a.dataSeriesIndexes.length; n++) {
          var f = a.dataSeriesIndexes[n],
            A = this.data[f],
            p = A.dataPoints,
            m = A.id;
          this._eventManager.objectMap[m] = { objectType: "dataSeries", dataSeriesIndex: f };
          m = P(m);
          h.fillStyle = m;
          m = [];
          d = !0;
          var l = 0,
            t,
            u,
            x,
            w = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
            z,
            B = null;
          b = !1;
          if (0 < p.length) {
            var y = A._colorSet[l % A._colorSet.length],
              X = (A.lineColor = A.options.lineColor || y),
              T = X;
            g.fillStyle = y;
            g.strokeStyle = X;
            g.lineWidth = A.lineThickness;
            var Z = "solid";
            if (g.setLineDash) {
              var ca = Q(A.nullDataLineDashType, A.lineThickness),
                Z = A.lineDashType,
                ea = Q(Z, A.lineThickness);
              g.setLineDash(ea);
            }
            for (; l < p.length; l++)
              if (
                ((x = p[l].x.getTime ? p[l].x.getTime() : p[l].x),
                !(
                  x < a.axisX.dataInfo.viewPortMin ||
                  (x > a.axisX.dataInfo.viewPortMax && (!A.connectNullData || !b))
                ))
              ) {
                var $ = u;
                "number" !== typeof p[l].y
                  ? (A.connectNullData || b || d || c(), (b = !0))
                  : ((t = a.axisX.convertValueToPixel(x)),
                    (u = a.axisY.convertValueToPixel(p[l].y)),
                    d || b
                      ? (!d && A.connectNullData
                          ? (g.setLineDash &&
                              (A.options.nullDataLineDashType ||
                                (Z === A.lineDashType &&
                                  A.lineDashType !== A.nullDataLineDashType)) &&
                              ((d = t),
                              (b = u),
                              (t = q.x),
                              (u = q.y),
                              c(),
                              g.moveTo(q.x, q.y),
                              (t = d),
                              (u = b),
                              (B = q),
                              (Z = A.nullDataLineDashType),
                              g.setLineDash(ca)),
                            g.lineTo(t, $),
                            g.lineTo(t, u),
                            s && (h.lineTo(t, $), h.lineTo(t, u)))
                          : (g.beginPath(),
                            g.moveTo(t, u),
                            s && (h.beginPath(), h.moveTo(t, u)),
                            (B = { x: t, y: u })),
                        (b = d = !1))
                      : (g.lineTo(t, $),
                        s && h.lineTo(t, $),
                        g.lineTo(t, u),
                        s && h.lineTo(t, u),
                        0 == l % 250 && c()),
                    (q = { x: t, y: u }),
                    l < p.length - 1 &&
                      (T !== (p[l].lineColor || X) ||
                        Z !== (p[l].lineDashType || A.lineDashType)) &&
                      (c(),
                      (T = p[l].lineColor || X),
                      (g.strokeStyle = T),
                      g.setLineDash &&
                        (p[l].lineDashType
                          ? ((Z = p[l].lineDashType), g.setLineDash(Q(Z, A.lineThickness)))
                          : ((Z = A.lineDashType), g.setLineDash(ea)))),
                    (x = A.dataPointIds[l]),
                    (this._eventManager.objectMap[x] = {
                      id: x,
                      objectType: "dataPoint",
                      dataSeriesIndex: f,
                      dataPointIndex: l,
                      x1: t,
                      y1: u
                    }),
                    0 !== p[l].markerSize &&
                      (0 < p[l].markerSize || 0 < A.markerSize) &&
                      (($ = A.getMarkerProperties(l, t, u, g)),
                      m.push($),
                      (x = P(x)),
                      s &&
                        m.push({
                          x: t,
                          y: u,
                          ctx: h,
                          type: $.type,
                          size: $.size,
                          color: x,
                          borderColor: x,
                          borderThickness: $.borderThickness
                        })),
                    (p[l].indexLabel ||
                      A.indexLabel ||
                      p[l].indexLabelFormatter ||
                      A.indexLabelFormatter) &&
                      this._indexLabels.push({
                        chartType: "stepArea",
                        dataPoint: p[l],
                        dataSeries: A,
                        point: { x: t, y: u },
                        direction: 0 > p[l].y === a.axisY.reversed ? 1 : -1,
                        color: y
                      }));
              }
            c();
            Y.drawMarkers(m);
          }
        }
        s &&
          (e.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (g.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && g.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && g.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          g.clearRect(r.x1, r.y1, r.width, r.height),
          this._eventManager.ghostCtx.restore());
        g.restore();
        return {
          source: e,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderStackedArea = function(a) {
      function d() {
        if (!(1 > m.length)) {
          for (0 < B.lineThickness && c.stroke(); 0 < m.length; ) {
            var a = m.pop();
            c.lineTo(a.x, a.y);
            s && l.lineTo(a.x, a.y);
          }
          c.closePath();
          c.globalAlpha = B.fillOpacity;
          c.fill();
          c.globalAlpha = 1;
          c.beginPath();
          s && (l.closePath(), l.fill(), l.beginPath());
          m = [];
        }
      }
      var b = a.targetCanvasCtx || this.plotArea.ctx,
        c = s ? this._preRenderCtx : b;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = null,
          g = null,
          h = [],
          k = this.plotArea,
          v = [],
          m = [],
          r = [],
          q = [],
          n = 0,
          f,
          A,
          p = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          l = this._eventManager.ghostCtx,
          t,
          u,
          x;
        s && l.beginPath();
        c.save();
        s && l.save();
        c.beginPath();
        c.rect(k.x1, k.y1, k.width, k.height);
        c.clip();
        s && (l.beginPath(), l.rect(k.x1, k.y1, k.width, k.height), l.clip());
        for (var e = [], w = 0; w < a.dataSeriesIndexes.length; w++) {
          var z = a.dataSeriesIndexes[w],
            B = this.data[z],
            y = B.dataPoints;
          B.dataPointIndexes = [];
          for (n = 0; n < y.length; n++)
            (z = y[n].x.getTime ? y[n].x.getTime() : y[n].x),
              (B.dataPointIndexes[z] = n),
              e[z] || (r.push(z), (e[z] = !0));
          r.sort(Sa);
        }
        for (w = 0; w < a.dataSeriesIndexes.length; w++) {
          z = a.dataSeriesIndexes[w];
          B = this.data[z];
          y = B.dataPoints;
          u = !0;
          m = [];
          n = B.id;
          this._eventManager.objectMap[n] = { objectType: "dataSeries", dataSeriesIndex: z };
          n = P(n);
          l.fillStyle = n;
          if (0 < r.length) {
            var e = B._colorSet[0],
              X = (B.lineColor = B.options.lineColor || e),
              T = X;
            c.fillStyle = e;
            c.strokeStyle = X;
            c.lineWidth = B.lineThickness;
            x = "solid";
            if (c.setLineDash) {
              var Z = Q(B.nullDataLineDashType, B.lineThickness);
              x = B.lineDashType;
              var ca = Q(x, B.lineThickness);
              c.setLineDash(ca);
            }
            for (var ea = !0, n = 0; n < r.length; n++) {
              var g = r[n],
                $ = null,
                $ = 0 <= B.dataPointIndexes[g] ? y[B.dataPointIndexes[g]] : { x: g, y: null };
              if (
                !(
                  g < a.axisX.dataInfo.viewPortMin ||
                  (g > a.axisX.dataInfo.viewPortMax && (!B.connectNullData || !ea))
                )
              )
                if ("number" !== typeof $.y) B.connectNullData || ea || u || d(), (ea = !0);
                else {
                  f = a.axisX.convertValueToPixel(g);
                  var na = v[g] ? v[g] : 0;
                  if (
                    a.axisY.logarithmic ||
                    (a.axisY.scaleBreaks && 0 < a.axisY.scaleBreaks._appliedBreaks.length)
                  ) {
                    q[g] = $.y + (q[g] ? q[g] : 0);
                    if (0 >= q[g] && a.axisY.logarithmic) continue;
                    A = a.axisY.convertValueToPixel(q[g]);
                  } else (A = a.axisY.convertValueToPixel($.y)), (A -= na);
                  m.push({ x: f, y: p - na });
                  v[g] = p - A;
                  u || ea
                    ? (!u && B.connectNullData
                        ? (c.setLineDash &&
                            (B.options.nullDataLineDashType ||
                              (x === B.lineDashType &&
                                B.lineDashType !== B.nullDataLineDashType)) &&
                            ((u = m.pop()),
                            (x = m[m.length - 1]),
                            d(),
                            c.moveTo(t.x, t.y),
                            m.push(x),
                            m.push(u),
                            (x = B.nullDataLineDashType),
                            c.setLineDash(Z)),
                          c.lineTo(f, A),
                          s && l.lineTo(f, A))
                        : (c.beginPath(), c.moveTo(f, A), s && (l.beginPath(), l.moveTo(f, A))),
                      (ea = u = !1))
                    : (c.lineTo(f, A),
                      s && l.lineTo(f, A),
                      0 == n % 250 &&
                        (d(), c.moveTo(f, A), s && l.moveTo(f, A), m.push({ x: f, y: p - na })));
                  t = { x: f, y: A };
                  n < y.length - 1 &&
                    (T !== (y[n].lineColor || X) || x !== (y[n].lineDashType || B.lineDashType)) &&
                    (d(),
                    c.beginPath(),
                    c.moveTo(f, A),
                    m.push({ x: f, y: p - na }),
                    (T = y[n].lineColor || X),
                    (c.strokeStyle = T),
                    c.setLineDash &&
                      (y[n].lineDashType
                        ? ((x = y[n].lineDashType), c.setLineDash(Q(x, B.lineThickness)))
                        : ((x = B.lineDashType), c.setLineDash(ca))));
                  if (0 <= B.dataPointIndexes[g]) {
                    var da = B.dataPointIds[B.dataPointIndexes[g]];
                    this._eventManager.objectMap[da] = {
                      id: da,
                      objectType: "dataPoint",
                      dataSeriesIndex: z,
                      dataPointIndex: B.dataPointIndexes[g],
                      x1: f,
                      y1: A
                    };
                  }
                  0 <= B.dataPointIndexes[g] &&
                    0 !== $.markerSize &&
                    (0 < $.markerSize || 0 < B.markerSize) &&
                    ((na = B.getMarkerProperties(B.dataPointIndexes[g], f, A, c)),
                    h.push(na),
                    (g = P(da)),
                    s &&
                      h.push({
                        x: f,
                        y: A,
                        ctx: l,
                        type: na.type,
                        size: na.size,
                        color: g,
                        borderColor: g,
                        borderThickness: na.borderThickness
                      }));
                  ($.indexLabel ||
                    B.indexLabel ||
                    $.indexLabelFormatter ||
                    B.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "stackedArea",
                      dataPoint: $,
                      dataSeries: B,
                      point: { x: f, y: A },
                      direction: 0 > y[n].y === a.axisY.reversed ? 1 : -1,
                      color: e
                    });
                }
            }
            d();
            c.moveTo(f, A);
            s && l.moveTo(f, A);
          }
          delete B.dataPointIndexes;
        }
        Y.drawMarkers(h);
        s &&
          (b.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (c.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          c.clearRect(k.x1, k.y1, k.width, k.height),
          l.restore());
        c.restore();
        return {
          source: b,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderStackedArea100 = function(a) {
      function d() {
        for (0 < B.lineThickness && c.stroke(); 0 < m.length; ) {
          var a = m.pop();
          c.lineTo(a.x, a.y);
          s && x.lineTo(a.x, a.y);
        }
        c.closePath();
        c.globalAlpha = B.fillOpacity;
        c.fill();
        c.globalAlpha = 1;
        c.beginPath();
        s && (x.closePath(), x.fill(), x.beginPath());
        m = [];
      }
      var b = a.targetCanvasCtx || this.plotArea.ctx,
        c = s ? this._preRenderCtx : b;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = null,
          g = null,
          h = this.plotArea,
          k = [],
          v = [],
          m = [],
          r = [],
          q = [],
          n = 0,
          f,
          l,
          p,
          u,
          t,
          D = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          x = this._eventManager.ghostCtx;
        c.save();
        s && x.save();
        c.beginPath();
        c.rect(h.x1, h.y1, h.width, h.height);
        c.clip();
        s && (x.beginPath(), x.rect(h.x1, h.y1, h.width, h.height), x.clip());
        for (var e = [], w = 0; w < a.dataSeriesIndexes.length; w++) {
          var z = a.dataSeriesIndexes[w],
            B = this.data[z],
            y = B.dataPoints;
          B.dataPointIndexes = [];
          for (n = 0; n < y.length; n++)
            (z = y[n].x.getTime ? y[n].x.getTime() : y[n].x),
              (B.dataPointIndexes[z] = n),
              e[z] || (r.push(z), (e[z] = !0));
          r.sort(Sa);
        }
        for (w = 0; w < a.dataSeriesIndexes.length; w++) {
          z = a.dataSeriesIndexes[w];
          B = this.data[z];
          y = B.dataPoints;
          u = !0;
          e = B.id;
          this._eventManager.objectMap[e] = { objectType: "dataSeries", dataSeriesIndex: z };
          e = P(e);
          x.fillStyle = e;
          m = [];
          if (0 < r.length) {
            var e = B._colorSet[n % B._colorSet.length],
              X = (B.lineColor = B.options.lineColor || e),
              T = X;
            c.fillStyle = e;
            c.strokeStyle = X;
            c.lineWidth = B.lineThickness;
            t = "solid";
            if (c.setLineDash) {
              var Z = Q(B.nullDataLineDashType, B.lineThickness);
              t = B.lineDashType;
              var ca = Q(t, B.lineThickness);
              c.setLineDash(ca);
            }
            for (var ea = !0, n = 0; n < r.length; n++) {
              var g = r[n],
                $ = null,
                $ = 0 <= B.dataPointIndexes[g] ? y[B.dataPointIndexes[g]] : { x: g, y: null };
              if (
                !(
                  g < a.axisX.dataInfo.viewPortMin ||
                  (g > a.axisX.dataInfo.viewPortMax && (!B.connectNullData || !ea))
                )
              )
                if ("number" !== typeof $.y) B.connectNullData || ea || u || d(), (ea = !0);
                else {
                  var na;
                  na = 0 !== a.dataPointYSums[g] ? 100 * ($.y / a.dataPointYSums[g]) : 0;
                  f = a.axisX.convertValueToPixel(g);
                  var da = v[g] ? v[g] : 0;
                  if (
                    a.axisY.logarithmic ||
                    (a.axisY.scaleBreaks && 0 < a.axisY.scaleBreaks._appliedBreaks.length)
                  ) {
                    q[g] = na + (q[g] ? q[g] : 0);
                    if (0 >= q[g] && a.axisY.logarithmic) continue;
                    l = a.axisY.convertValueToPixel(q[g]);
                  } else (l = a.axisY.convertValueToPixel(na)), (l -= da);
                  m.push({ x: f, y: D - da });
                  v[g] = D - l;
                  u || ea
                    ? (!u && B.connectNullData
                        ? (c.setLineDash &&
                            (B.options.nullDataLineDashType ||
                              (t === B.lineDashType &&
                                B.lineDashType !== B.nullDataLineDashType)) &&
                            ((u = m.pop()),
                            (t = m[m.length - 1]),
                            d(),
                            c.moveTo(p.x, p.y),
                            m.push(t),
                            m.push(u),
                            (t = B.nullDataLineDashType),
                            c.setLineDash(Z)),
                          c.lineTo(f, l),
                          s && x.lineTo(f, l))
                        : (c.beginPath(), c.moveTo(f, l), s && (x.beginPath(), x.moveTo(f, l))),
                      (ea = u = !1))
                    : (c.lineTo(f, l),
                      s && x.lineTo(f, l),
                      0 == n % 250 &&
                        (d(), c.moveTo(f, l), s && x.moveTo(f, l), m.push({ x: f, y: D - da })));
                  p = { x: f, y: l };
                  n < y.length - 1 &&
                    (T !== (y[n].lineColor || X) || t !== (y[n].lineDashType || B.lineDashType)) &&
                    (d(),
                    c.beginPath(),
                    c.moveTo(f, l),
                    m.push({ x: f, y: D - da }),
                    (T = y[n].lineColor || X),
                    (c.strokeStyle = T),
                    c.setLineDash &&
                      (y[n].lineDashType
                        ? ((t = y[n].lineDashType), c.setLineDash(Q(t, B.lineThickness)))
                        : ((t = B.lineDashType), c.setLineDash(ca))));
                  if (0 <= B.dataPointIndexes[g]) {
                    var F = B.dataPointIds[B.dataPointIndexes[g]];
                    this._eventManager.objectMap[F] = {
                      id: F,
                      objectType: "dataPoint",
                      dataSeriesIndex: z,
                      dataPointIndex: B.dataPointIndexes[g],
                      x1: f,
                      y1: l
                    };
                  }
                  0 <= B.dataPointIndexes[g] &&
                    0 !== $.markerSize &&
                    (0 < $.markerSize || 0 < B.markerSize) &&
                    ((da = B.getMarkerProperties(n, f, l, c)),
                    k.push(da),
                    (g = P(F)),
                    s &&
                      k.push({
                        x: f,
                        y: l,
                        ctx: x,
                        type: da.type,
                        size: da.size,
                        color: g,
                        borderColor: g,
                        borderThickness: da.borderThickness
                      }));
                  ($.indexLabel ||
                    B.indexLabel ||
                    $.indexLabelFormatter ||
                    B.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "stackedArea100",
                      dataPoint: $,
                      dataSeries: B,
                      point: { x: f, y: l },
                      direction: 0 > y[n].y === a.axisY.reversed ? 1 : -1,
                      color: e
                    });
                }
            }
            d();
            c.moveTo(f, l);
            s && x.moveTo(f, l);
          }
          delete B.dataPointIndexes;
        }
        Y.drawMarkers(k);
        s &&
          (b.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (c.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          c.clearRect(h.x1, h.y1, h.width, h.height),
          x.restore());
        c.restore();
        return {
          source: b,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderBubble = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = this.plotArea,
          e = 0,
          g,
          h;
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(c.x1, c.y1, c.width, c.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(c.x1, c.y1, c.width, c.height),
          this._eventManager.ghostCtx.clip());
        for (var k = -Infinity, v = Infinity, m = 0; m < a.dataSeriesIndexes.length; m++)
          for (
            var r = a.dataSeriesIndexes[m], q = this.data[r], n = q.dataPoints, f = 0, e = 0;
            e < n.length;
            e++
          )
            (g = n[e].getTime ? (g = n[e].x.getTime()) : (g = n[e].x)),
              g < a.axisX.dataInfo.viewPortMin ||
                g > a.axisX.dataInfo.viewPortMax ||
                "undefined" === typeof n[e].z ||
                ((f = n[e].z), f > k && (k = f), f < v && (v = f));
        for (
          var l = 25 * Math.PI,
            p = Math.max(Math.pow((0.25 * Math.min(c.height, c.width)) / 2, 2) * Math.PI, l),
            m = 0;
          m < a.dataSeriesIndexes.length;
          m++
        )
          if (((r = a.dataSeriesIndexes[m]), (q = this.data[r]), (n = q.dataPoints), 0 < n.length))
            for (b.strokeStyle = "#4572A7 ", e = 0; e < n.length; e++)
              if (
                ((g = n[e].getTime ? (g = n[e].x.getTime()) : (g = n[e].x)),
                !(g < a.axisX.dataInfo.viewPortMin || g > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof n[e].y)
              ) {
                g = a.axisX.convertValueToPixel(g);
                h = a.axisY.convertValueToPixel(n[e].y);
                var f = n[e].z,
                  u =
                    2 *
                    Math.max(
                      Math.sqrt((k === v ? p / 2 : l + ((p - l) / (k - v)) * (f - v)) / Math.PI) <<
                        0,
                      1
                    ),
                  f = q.getMarkerProperties(e, b);
                f.size = u;
                b.globalAlpha = q.fillOpacity;
                Y.drawMarker(g, h, b, f.type, f.size, f.color, f.borderColor, f.borderThickness);
                b.globalAlpha = 1;
                var t = q.dataPointIds[e];
                this._eventManager.objectMap[t] = {
                  id: t,
                  objectType: "dataPoint",
                  dataSeriesIndex: r,
                  dataPointIndex: e,
                  x1: g,
                  y1: h,
                  size: u
                };
                u = P(t);
                s &&
                  Y.drawMarker(
                    g,
                    h,
                    this._eventManager.ghostCtx,
                    f.type,
                    f.size,
                    u,
                    u,
                    f.borderThickness
                  );
                (n[e].indexLabel ||
                  q.indexLabel ||
                  n[e].indexLabelFormatter ||
                  q.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "bubble",
                    dataPoint: n[e],
                    dataSeries: q,
                    point: { x: g, y: h },
                    direction: 1,
                    bounds: {
                      x1: g - f.size / 2,
                      y1: h - f.size / 2,
                      x2: g + f.size / 2,
                      y2: h + f.size / 2
                    },
                    color: null
                  });
              }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(c.x1, c.y1, c.width, c.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderScatter = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = this.plotArea,
          e = 0,
          g,
          h;
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(c.x1, c.y1, c.width, c.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(c.x1, c.y1, c.width, c.height),
          this._eventManager.ghostCtx.clip());
        for (var k = 0; k < a.dataSeriesIndexes.length; k++) {
          var v = a.dataSeriesIndexes[k],
            m = this.data[v],
            r = m.dataPoints;
          if (0 < r.length) {
            b.strokeStyle = "#4572A7 ";
            Math.pow((0.3 * Math.min(c.height, c.width)) / 2, 2);
            for (var q = 0, n = 0, e = 0; e < r.length; e++)
              if (
                ((g = r[e].getTime ? (g = r[e].x.getTime()) : (g = r[e].x)),
                !(g < a.axisX.dataInfo.viewPortMin || g > a.axisX.dataInfo.viewPortMax) &&
                  "number" === typeof r[e].y)
              ) {
                g = a.axisX.convertValueToPixel(g);
                h = a.axisY.convertValueToPixel(r[e].y);
                var f = m.getMarkerProperties(e, g, h, b);
                b.globalAlpha = m.fillOpacity;
                Y.drawMarker(
                  f.x,
                  f.y,
                  f.ctx,
                  f.type,
                  f.size,
                  f.color,
                  f.borderColor,
                  f.borderThickness
                );
                b.globalAlpha = 1;
                (Math.sqrt((q - g) * (q - g) + (n - h) * (n - h)) < Math.min(f.size, 5) &&
                  r.length > Math.min(this.plotArea.width, this.plotArea.height)) ||
                  ((q = m.dataPointIds[e]),
                  (this._eventManager.objectMap[q] = {
                    id: q,
                    objectType: "dataPoint",
                    dataSeriesIndex: v,
                    dataPointIndex: e,
                    x1: g,
                    y1: h
                  }),
                  (q = P(q)),
                  s &&
                    Y.drawMarker(
                      f.x,
                      f.y,
                      this._eventManager.ghostCtx,
                      f.type,
                      f.size,
                      q,
                      q,
                      f.borderThickness
                    ),
                  (r[e].indexLabel ||
                    m.indexLabel ||
                    r[e].indexLabelFormatter ||
                    m.indexLabelFormatter) &&
                    this._indexLabels.push({
                      chartType: "scatter",
                      dataPoint: r[e],
                      dataSeries: m,
                      point: { x: g, y: h },
                      direction: 1,
                      bounds: {
                        x1: g - f.size / 2,
                        y1: h - f.size / 2,
                        x2: g + f.size / 2,
                        y2: h + f.size / 2
                      },
                      color: null
                    }),
                  (q = g),
                  (n = h));
              }
          }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(c.x1, c.y1, c.width, c.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderCandlestick = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d,
        c = this._eventManager.ghostCtx;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = null,
          g = null,
          h = this.plotArea,
          k = 0,
          v,
          m,
          r,
          q,
          n,
          f,
          e = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1,
          g = this.options.dataPointMaxWidth
            ? this.dataPointMaxWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 0.015 * this.width,
          l = a.axisX.dataInfo.minDiff;
        isFinite(l) || (l = 0.3 * Math.abs(a.axisX.range));
        l = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.7 *
              h.width *
              (a.axisX.logarithmic
                ? Math.log(l) / Math.log(a.axisX.range)
                : Math.abs(l) / Math.abs(a.axisX.range))) <<
            0;
        this.dataPointMaxWidth &&
          e > g &&
          (e = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, g));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && g < e &&
          (g = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, e));
        l < e && (l = e);
        l > g && (l = g);
        b.save();
        s && c.save();
        b.beginPath();
        b.rect(h.x1, h.y1, h.width, h.height);
        b.clip();
        s && (c.beginPath(), c.rect(h.x1, h.y1, h.width, h.height), c.clip());
        for (var p = 0; p < a.dataSeriesIndexes.length; p++) {
          var w = a.dataSeriesIndexes[p],
            t = this.data[w],
            D = t.dataPoints;
          if (0 < D.length)
            for (var x = 5 < l && t.bevelEnabled ? !0 : !1, k = 0; k < D.length; k++)
              if (
                (D[k].getTime ? (f = D[k].x.getTime()) : (f = D[k].x),
                !(f < a.axisX.dataInfo.viewPortMin || f > a.axisX.dataInfo.viewPortMax) &&
                  !u(D[k].y) &&
                  D[k].y.length &&
                  "number" === typeof D[k].y[0] &&
                  "number" === typeof D[k].y[1] &&
                  "number" === typeof D[k].y[2] &&
                  "number" === typeof D[k].y[3])
              ) {
                v = a.axisX.convertValueToPixel(f);
                m = a.axisY.convertValueToPixel(D[k].y[0]);
                r = a.axisY.convertValueToPixel(D[k].y[1]);
                q = a.axisY.convertValueToPixel(D[k].y[2]);
                n = a.axisY.convertValueToPixel(D[k].y[3]);
                var y = (v - l / 2) << 0,
                  z = (y + l) << 0,
                  g = t.options.fallingColor ? t.fallingColor : t._colorSet[0],
                  e = D[k].color ? D[k].color : t._colorSet[0],
                  B = Math.round(Math.max(1, 0.15 * l)),
                  C = 0 === B % 2 ? 0 : 0.5,
                  X = t.dataPointIds[k];
                this._eventManager.objectMap[X] = {
                  id: X,
                  objectType: "dataPoint",
                  dataSeriesIndex: w,
                  dataPointIndex: k,
                  x1: y,
                  y1: m,
                  x2: z,
                  y2: r,
                  x3: v,
                  y3: q,
                  x4: v,
                  y4: n,
                  borderThickness: B,
                  color: e
                };
                b.strokeStyle = e;
                b.beginPath();
                b.lineWidth = B;
                c.lineWidth = Math.max(B, 4);
                "candlestick" === t.type
                  ? (b.moveTo(v - C, r),
                    b.lineTo(v - C, Math.min(m, n)),
                    b.stroke(),
                    b.moveTo(v - C, Math.max(m, n)),
                    b.lineTo(v - C, q),
                    b.stroke(),
                    V(
                      b,
                      y,
                      Math.min(m, n),
                      z,
                      Math.max(m, n),
                      D[k].y[0] <= D[k].y[3] ? t.risingColor : g,
                      B,
                      e,
                      x,
                      x,
                      !1,
                      !1,
                      t.fillOpacity
                    ),
                    s &&
                      ((e = P(X)),
                      (c.strokeStyle = e),
                      c.moveTo(v - C, r),
                      c.lineTo(v - C, Math.min(m, n)),
                      c.stroke(),
                      c.moveTo(v - C, Math.max(m, n)),
                      c.lineTo(v - C, q),
                      c.stroke(),
                      V(c, y, Math.min(m, n), z, Math.max(m, n), e, 0, null, !1, !1, !1, !1)))
                  : "ohlc" === t.type &&
                    (b.moveTo(v - C, r),
                    b.lineTo(v - C, q),
                    b.stroke(),
                    b.beginPath(),
                    b.moveTo(v, m),
                    b.lineTo(y, m),
                    b.stroke(),
                    b.beginPath(),
                    b.moveTo(v, n),
                    b.lineTo(z, n),
                    b.stroke(),
                    s &&
                      ((e = P(X)),
                      (c.strokeStyle = e),
                      c.moveTo(v - C, r),
                      c.lineTo(v - C, q),
                      c.stroke(),
                      c.beginPath(),
                      c.moveTo(v, m),
                      c.lineTo(y, m),
                      c.stroke(),
                      c.beginPath(),
                      c.moveTo(v, n),
                      c.lineTo(z, n),
                      c.stroke()));
                (D[k].indexLabel ||
                  t.indexLabel ||
                  D[k].indexLabelFormatter ||
                  t.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: t.type,
                    dataPoint: D[k],
                    dataSeries: t,
                    point: { x: y + (z - y) / 2, y: a.axisY.reversed ? q : r },
                    direction: 1,
                    bounds: { x1: y, y1: Math.min(r, q), x2: z, y2: Math.max(r, q) },
                    color: e
                  });
              }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(h.x1, h.y1, h.width, h.height),
          c.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderBoxAndWhisker = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d,
        c = this._eventManager.ghostCtx;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = null,
          g = this.plotArea,
          h = 0,
          k,
          v,
          m,
          r,
          q,
          n,
          f,
          e = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1,
          h = this.options.dataPointMaxWidth
            ? this.dataPointMaxWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 0.015 * this.width,
          l = a.axisX.dataInfo.minDiff;
        isFinite(l) || (l = 0.3 * Math.abs(a.axisX.range));
        l = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.7 *
              g.width *
              (a.axisX.logarithmic
                ? Math.log(l) / Math.log(a.axisX.range)
                : Math.abs(l) / Math.abs(a.axisX.range))) <<
            0;
        this.dataPointMaxWidth &&
          e > h &&
          (e = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, h));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && h < e &&
          (h = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, e));
        l < e && (l = e);
        l > h && (l = h);
        b.save();
        s && c.save();
        b.beginPath();
        b.rect(g.x1, g.y1, g.width, g.height);
        b.clip();
        s && (c.beginPath(), c.rect(g.x1, g.y1, g.width, g.height), c.clip());
        for (var p = !1, p = !!a.axisY.reversed, w = 0; w < a.dataSeriesIndexes.length; w++) {
          var t = a.dataSeriesIndexes[w],
            D = this.data[t],
            x = D.dataPoints;
          if (0 < x.length)
            for (var y = 5 < l && D.bevelEnabled ? !0 : !1, h = 0; h < x.length; h++)
              if (
                (x[h].getTime ? (f = x[h].x.getTime()) : (f = x[h].x),
                !(f < a.axisX.dataInfo.viewPortMin || f > a.axisX.dataInfo.viewPortMax) &&
                  !u(x[h].y) &&
                  x[h].y.length &&
                  "number" === typeof x[h].y[0] &&
                  "number" === typeof x[h].y[1] &&
                  "number" === typeof x[h].y[2] &&
                  "number" === typeof x[h].y[3] &&
                  "number" === typeof x[h].y[4] &&
                  5 === x[h].y.length)
              ) {
                k = a.axisX.convertValueToPixel(f);
                v = a.axisY.convertValueToPixel(x[h].y[0]);
                m = a.axisY.convertValueToPixel(x[h].y[1]);
                r = a.axisY.convertValueToPixel(x[h].y[2]);
                q = a.axisY.convertValueToPixel(x[h].y[3]);
                n = a.axisY.convertValueToPixel(x[h].y[4]);
                var z = (k - l / 2) << 0,
                  B = (k + l / 2) << 0,
                  e = x[h].color ? x[h].color : D._colorSet[0],
                  C = Math.round(Math.max(1, 0.15 * l)),
                  X = 0 === C % 2 ? 0 : 0.5,
                  T = x[h].whiskerColor
                    ? x[h].whiskerColor
                    : x[h].color
                    ? D.whiskerColor
                      ? D.whiskerColor
                      : x[h].color
                    : D.whiskerColor
                    ? D.whiskerColor
                    : e,
                  Z =
                    "number" === typeof x[h].whiskerThickness
                      ? x[h].whiskerThickness
                      : "number" === typeof D.options.whiskerThickness
                      ? D.whiskerThickness
                      : C,
                  ca = x[h].whiskerDashType ? x[h].whiskerDashType : D.whiskerDashType,
                  ea = u(x[h].whiskerLength)
                    ? u(D.options.whiskerLength)
                      ? l
                      : D.whiskerLength
                    : x[h].whiskerLength,
                  ea =
                    "number" === typeof ea
                      ? 0 >= ea
                        ? 0
                        : ea >= l
                        ? l
                        : ea
                      : "string" === typeof ea
                      ? (parseInt(ea) * l) / 100 > l
                        ? l
                        : (parseInt(ea) * l) / 100
                      : l,
                  $ = 1 === Math.round(Z) % 2 ? 0.5 : 0,
                  na = x[h].stemColor
                    ? x[h].stemColor
                    : x[h].color
                    ? D.stemColor
                      ? D.stemColor
                      : x[h].color
                    : D.stemColor
                    ? D.stemColor
                    : e,
                  da =
                    "number" === typeof x[h].stemThickness
                      ? x[h].stemThickness
                      : "number" === typeof D.options.stemThickness
                      ? D.stemThickness
                      : C,
                  F = 1 === Math.round(da) % 2 ? 0.5 : 0,
                  H = x[h].stemDashType ? x[h].stemDashType : D.stemDashType,
                  I = x[h].lineColor
                    ? x[h].lineColor
                    : x[h].color
                    ? D.lineColor
                      ? D.lineColor
                      : x[h].color
                    : D.lineColor
                    ? D.lineColor
                    : e,
                  E =
                    "number" === typeof x[h].lineThickness
                      ? x[h].lineThickness
                      : "number" === typeof D.options.lineThickness
                      ? D.lineThickness
                      : C,
                  G = x[h].lineDashType ? x[h].lineDashType : D.lineDashType,
                  K = 1 === Math.round(E) % 2 ? 0.5 : 0,
                  L = D.upperBoxColor,
                  N = D.lowerBoxColor,
                  ta = u(D.options.fillOpacity) ? 1 : D.fillOpacity,
                  O = D.dataPointIds[h];
                this._eventManager.objectMap[O] = {
                  id: O,
                  objectType: "dataPoint",
                  dataSeriesIndex: t,
                  dataPointIndex: h,
                  x1: z,
                  y1: v,
                  x2: B,
                  y2: m,
                  x3: k,
                  y3: r,
                  x4: k,
                  y4: q,
                  y5: n,
                  borderThickness: C,
                  color: e,
                  stemThickness: da,
                  stemColor: na,
                  whiskerThickness: Z,
                  whiskerLength: ea,
                  whiskerColor: T,
                  lineThickness: E,
                  lineColor: I
                };
                b.save();
                0 < da &&
                  (b.beginPath(),
                  (b.strokeStyle = na),
                  (b.lineWidth = da),
                  b.setLineDash && b.setLineDash(Q(H, da)),
                  b.moveTo(k - F, m),
                  b.lineTo(k - F, v),
                  b.stroke(),
                  b.moveTo(k - F, q),
                  b.lineTo(k - F, r),
                  b.stroke());
                b.restore();
                c.lineWidth = Math.max(C, 4);
                b.beginPath();
                V(
                  b,
                  z,
                  Math.min(n, m),
                  B,
                  Math.max(m, n),
                  N,
                  0,
                  e,
                  p ? y : !1,
                  p ? !1 : y,
                  !1,
                  !1,
                  ta
                );
                b.beginPath();
                V(
                  b,
                  z,
                  Math.min(r, n),
                  B,
                  Math.max(n, r),
                  L,
                  0,
                  e,
                  p ? !1 : y,
                  p ? y : !1,
                  !1,
                  !1,
                  ta
                );
                b.beginPath();
                b.lineWidth = C;
                b.strokeStyle = e;
                b.rect(
                  z - X,
                  Math.min(m, r) - X,
                  B - z + 2 * X,
                  Math.max(m, r) - Math.min(m, r) + 2 * X
                );
                b.stroke();
                b.save();
                0 < E &&
                  (b.beginPath(),
                  (b.globalAlpha = 1),
                  b.setLineDash && b.setLineDash(Q(G, E)),
                  (b.strokeStyle = I),
                  (b.lineWidth = E),
                  b.moveTo(z, n - K),
                  b.lineTo(B, n - K),
                  b.stroke());
                b.restore();
                b.save();
                0 < Z &&
                  (b.beginPath(),
                  b.setLineDash && b.setLineDash(Q(ca, Z)),
                  (b.strokeStyle = T),
                  (b.lineWidth = Z),
                  b.moveTo((k - ea / 2) << 0, q - $),
                  b.lineTo((k + ea / 2) << 0, q - $),
                  b.stroke(),
                  b.moveTo((k - ea / 2) << 0, v + $),
                  b.lineTo((k + ea / 2) << 0, v + $),
                  b.stroke());
                b.restore();
                s &&
                  ((e = P(O)),
                  (c.strokeStyle = e),
                  (c.lineWidth = da),
                  0 < da &&
                    (c.moveTo(k - X - F, m),
                    c.lineTo(k - X - F, Math.max(v, q)),
                    c.stroke(),
                    c.moveTo(k - X - F, Math.min(v, q)),
                    c.lineTo(k - X - F, r),
                    c.stroke()),
                  V(c, z, Math.max(m, r), B, Math.min(m, r), e, 0, null, !1, !1, !1, !1),
                  0 < Z &&
                    (c.beginPath(),
                    (c.lineWidth = Z),
                    c.moveTo(k + ea / 2, q - $),
                    c.lineTo(k - ea / 2, q - $),
                    c.stroke(),
                    c.moveTo(k + ea / 2, v + $),
                    c.lineTo(k - ea / 2, v + $),
                    c.stroke()));
                (x[h].indexLabel ||
                  D.indexLabel ||
                  x[h].indexLabelFormatter ||
                  D.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: D.type,
                    dataPoint: x[h],
                    dataSeries: D,
                    point: { x: z + (B - z) / 2, y: a.axisY.reversed ? v : q },
                    direction: 1,
                    bounds: { x1: z, y1: Math.min(v, q), x2: B, y2: Math.max(v, q) },
                    color: e
                  });
              }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(g.x1, g.y1, g.width, g.height),
          c.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderRangeColumn = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = 0,
          h,
          k,
          v,
          g = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        h = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : 0.03 * this.width;
        var m = a.axisX.dataInfo.minDiff;
        isFinite(m) || (m = 0.3 * Math.abs(a.axisX.range));
        m = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.width *
                (a.axisX.logarithmic
                  ? Math.log(m) / Math.log(a.axisX.range)
                  : Math.abs(m) / Math.abs(a.axisX.range))) /
                a.plotType.totalDataSeries)) <<
            0;
        this.dataPointMaxWidth &&
          g > h &&
          (g = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, h));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && h < g &&
          (h = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, g));
        m < g && (m = g);
        m > h && (m = h);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (var r = 0; r < a.dataSeriesIndexes.length; r++) {
          var q = a.dataSeriesIndexes[r],
            n = this.data[q],
            f = n.dataPoints;
          if (0 < f.length)
            for (var l = 5 < m && n.bevelEnabled ? !0 : !1, g = 0; g < f.length; g++)
              if (
                (f[g].getTime ? (v = f[g].x.getTime()) : (v = f[g].x),
                !(v < a.axisX.dataInfo.viewPortMin || v > a.axisX.dataInfo.viewPortMax) &&
                  !u(f[g].y) &&
                  f[g].y.length &&
                  "number" === typeof f[g].y[0] &&
                  "number" === typeof f[g].y[1])
              ) {
                c = a.axisX.convertValueToPixel(v);
                h = a.axisY.convertValueToPixel(f[g].y[0]);
                k = a.axisY.convertValueToPixel(f[g].y[1]);
                var p = a.axisX.reversed
                    ? (c +
                        (a.plotType.totalDataSeries * m) / 2 -
                        (a.previousDataSeriesCount + r) * m) <<
                      0
                    : (c -
                        (a.plotType.totalDataSeries * m) / 2 +
                        (a.previousDataSeriesCount + r) * m) <<
                      0,
                  w = a.axisX.reversed ? (p - m) << 0 : (p + m) << 0,
                  c = f[g].color ? f[g].color : n._colorSet[g % n._colorSet.length];
                if (h > k) {
                  var t = h;
                  h = k;
                  k = t;
                }
                t = n.dataPointIds[g];
                this._eventManager.objectMap[t] = {
                  id: t,
                  objectType: "dataPoint",
                  dataSeriesIndex: q,
                  dataPointIndex: g,
                  x1: p,
                  y1: h,
                  x2: w,
                  y2: k
                };
                V(b, p, h, w, k, c, 0, c, l, l, !1, !1, n.fillOpacity);
                c = P(t);
                s && V(this._eventManager.ghostCtx, p, h, w, k, c, 0, null, !1, !1, !1, !1);
                if (
                  f[g].indexLabel ||
                  n.indexLabel ||
                  f[g].indexLabelFormatter ||
                  n.indexLabelFormatter
                )
                  this._indexLabels.push({
                    chartType: "rangeColumn",
                    dataPoint: f[g],
                    dataSeries: n,
                    indexKeyword: 0,
                    point: { x: p + (w - p) / 2, y: f[g].y[1] >= f[g].y[0] ? k : h },
                    direction: f[g].y[1] >= f[g].y[0] ? -1 : 1,
                    bounds: { x1: p, y1: Math.min(h, k), x2: w, y2: Math.max(h, k) },
                    color: c
                  }),
                    this._indexLabels.push({
                      chartType: "rangeColumn",
                      dataPoint: f[g],
                      dataSeries: n,
                      indexKeyword: 1,
                      point: { x: p + (w - p) / 2, y: f[g].y[1] >= f[g].y[0] ? h : k },
                      direction: f[g].y[1] >= f[g].y[0] ? 1 : -1,
                      bounds: { x1: p, y1: Math.min(h, k), x2: w, y2: Math.max(h, k) },
                      color: c
                    });
              }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderError = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d,
        c = a.axisY._position
          ? "left" === a.axisY._position || "right" === a.axisY._position
            ? !1
            : !0
          : !1;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = null,
          g = !1,
          h = this.plotArea,
          k = 0,
          v,
          m,
          r,
          q,
          n,
          f,
          l,
          p = a.axisX.dataInfo.minDiff;
        isFinite(p) || (p = 0.3 * Math.abs(a.axisX.range));
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(h.x1, h.y1, h.width, h.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(h.x1, h.y1, h.width, h.height),
          this._eventManager.ghostCtx.clip());
        for (var w = 0, t = 0; t < this.data.length; t++)
          !this.data[t].type.match(/(bar|column)/gi) ||
            !this.data[t].visible ||
            (this.data[t].type.match(/(stacked)/gi) && w) ||
            w++;
        for (var D = 0; D < a.dataSeriesIndexes.length; D++) {
          var x = a.dataSeriesIndexes[D],
            y = this.data[x],
            z = y.dataPoints,
            B = u(y._linkedSeries)
              ? !1
              : y._linkedSeries.type.match(/(bar|column)/gi) && y._linkedSeries.visible
              ? !0
              : !1,
            C = 0;
          if (B)
            for (e = y._linkedSeries.id, t = 0; t < e; t++)
              !this.data[t].type.match(/(bar|column)/gi) ||
                !this.data[t].visible ||
                (this.data[t].type.match(/(stacked)/gi) && C) ||
                (this.data[t].type.match(/(range)/gi) && (g = !0), C++);
          e = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
          k = this.options.dataPointMaxWidth
            ? this.dataPointMaxWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : c
            ? Math.min(0.15 * this.height, 0.9 * (this.plotArea.height / (B ? w : 1))) << 0
            : 0.3 * this.width;
          g &&
            (k = this.options.dataPointMaxWidth
              ? this.dataPointMaxWidth
              : this.options.dataPointWidth
              ? this.dataPointWidth
              : c
              ? Math.min(0.15 * this.height, 0.9 * (this.plotArea.height / (B ? w : 1))) << 0
              : 0.03 * this.width);
          t = this.options.dataPointWidth
            ? this.dataPointWidth
            : (0.9 *
                (((c ? h.height : h.width) *
                  (a.axisX.logarithmic
                    ? Math.log(p) / Math.log(a.axisX.range)
                    : Math.abs(p) / Math.abs(a.axisX.range))) /
                  (B ? w : 1))) <<
              0;
          this.dataPointMaxWidth &&
            e > k &&
            (e = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, k));
          !this.dataPointMaxWidth &&
            this.dataPointMinWidth && k < e &&
            (k = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, e));
          t < e && (t = e);
          t > k && (t = k);
          if (0 < z.length)
            for (var X = y._colorSet, k = 0; k < z.length; k++) {
              var e = (y.lineColor = y.options.color ? y.options.color : X[0]),
                T = {
                  color: z[k].whiskerColor
                    ? z[k].whiskerColor
                    : z[k].color
                    ? y.whiskerColor
                      ? y.whiskerColor
                      : z[k].color
                    : y.whiskerColor
                    ? y.whiskerColor
                    : e,
                  thickness: u(z[k].whiskerThickness) ? y.whiskerThickness : z[k].whiskerThickness,
                  dashType: z[k].whiskerDashType ? z[k].whiskerDashType : y.whiskerDashType,
                  length: u(z[k].whiskerLength)
                    ? u(y.options.whiskerLength)
                      ? t
                      : y.options.whiskerLength
                    : z[k].whiskerLength,
                  trimLength: u(z[k].whiskerLength) ? (u(y.options.whiskerLength) ? 50 : 0) : 0
                };
              T.length =
                "number" === typeof T.length
                  ? 0 >= T.length
                    ? 0
                    : T.length >= t
                    ? t
                    : T.length
                  : "string" === typeof T.length
                  ? (parseInt(T.length) * t) / 100 > t
                    ? t
                    : (parseInt(T.length) * t) / 100 > t
                  : t;
              T.thickness =
                "number" === typeof T.thickness
                  ? 0 > T.thickness
                    ? 0
                    : Math.round(T.thickness)
                  : 2;
              var Z = {
                color: z[k].stemColor
                  ? z[k].stemColor
                  : z[k].color
                  ? y.stemColor
                    ? y.stemColor
                    : z[k].color
                  : y.stemColor
                  ? y.stemColor
                  : e,
                thickness: z[k].stemThickness ? z[k].stemThickness : y.stemThickness,
                dashType: z[k].stemDashType ? z[k].stemDashType : y.stemDashType
              };
              Z.thickness =
                "number" === typeof Z.thickness
                  ? 0 > Z.thickness
                    ? 0
                    : Math.round(Z.thickness)
                  : 2;
              z[k].getTime ? (l = z[k].x.getTime()) : (l = z[k].x);
              if (
                !(l < a.axisX.dataInfo.viewPortMin || l > a.axisX.dataInfo.viewPortMax) &&
                !u(z[k].y) &&
                z[k].y.length &&
                "number" === typeof z[k].y[0] &&
                "number" === typeof z[k].y[1]
              ) {
                var ca = a.axisX.convertValueToPixel(l);
                c ? (m = ca) : (v = ca);
                ca = a.axisY.convertValueToPixel(z[k].y[0]);
                c ? (r = ca) : (n = ca);
                ca = a.axisY.convertValueToPixel(z[k].y[1]);
                c ? (q = ca) : (f = ca);
                c
                  ? ((n = a.axisX.reversed
                      ? (m + ((B ? w : 1) * t) / 2 - (B ? C - 1 : 0) * t) << 0
                      : (m - ((B ? w : 1) * t) / 2 + (B ? C - 1 : 0) * t) << 0),
                    (f = a.axisX.reversed ? (n - t) << 0 : (n + t) << 0))
                  : ((r = a.axisX.reversed
                      ? (v + ((B ? w : 1) * t) / 2 - (B ? C - 1 : 0) * t) << 0
                      : (v - ((B ? w : 1) * t) / 2 + (B ? C - 1 : 0) * t) << 0),
                    (q = a.axisX.reversed ? (r - t) << 0 : (r + t) << 0));
                !c && n > f && ((ca = n), (n = f), (f = ca));
                c && r > q && ((ca = r), (r = q), (q = ca));
                ca = y.dataPointIds[k];
                this._eventManager.objectMap[ca] = {
                  id: ca,
                  objectType: "dataPoint",
                  dataSeriesIndex: x,
                  dataPointIndex: k,
                  x1: Math.min(r, q),
                  y1: Math.min(n, f),
                  x2: Math.max(q, r),
                  y2: Math.max(f, n),
                  isXYSwapped: c,
                  stemProperties: Z,
                  whiskerProperties: T
                };
                H(b, Math.min(r, q), Math.min(n, f), Math.max(q, r), Math.max(f, n), e, T, Z, c);
                s && H(this._eventManager.ghostCtx, r, n, q, f, e, T, Z, c);
                if (
                  z[k].indexLabel ||
                  y.indexLabel ||
                  z[k].indexLabelFormatter ||
                  y.indexLabelFormatter
                )
                  this._indexLabels.push({
                    chartType: "error",
                    dataPoint: z[k],
                    dataSeries: y,
                    indexKeyword: 0,
                    point: {
                      x: c ? (z[k].y[1] >= z[k].y[0] ? r : q) : r + (q - r) / 2,
                      y: c ? n + (f - n) / 2 : z[k].y[1] >= z[k].y[0] ? f : n
                    },
                    direction: z[k].y[1] >= z[k].y[0] ? -1 : 1,
                    bounds: {
                      x1: c ? Math.min(r, q) : r,
                      y1: c ? n : Math.min(n, f),
                      x2: c ? Math.max(r, q) : q,
                      y2: c ? f : Math.max(n, f)
                    },
                    color: e,
                    axisSwapped: c
                  }),
                    this._indexLabels.push({
                      chartType: "error",
                      dataPoint: z[k],
                      dataSeries: y,
                      indexKeyword: 1,
                      point: {
                        x: c ? (z[k].y[1] >= z[k].y[0] ? q : r) : r + (q - r) / 2,
                        y: c ? n + (f - n) / 2 : z[k].y[1] >= z[k].y[0] ? n : f
                      },
                      direction: z[k].y[1] >= z[k].y[0] ? 1 : -1,
                      bounds: {
                        x1: c ? Math.min(r, q) : r,
                        y1: c ? n : Math.min(n, f),
                        x2: c ? Math.max(r, q) : q,
                        y2: c ? f : Math.max(n, f)
                      },
                      color: e,
                      axisSwapped: c
                    });
              }
            }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(h.x1, h.y1, h.width, h.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderRangeBar = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = null,
          e = this.plotArea,
          g = 0,
          h,
          k,
          v,
          m,
          g = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        h = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : Math.min(
              0.15 * this.height,
              0.9 * (this.plotArea.height / a.plotType.totalDataSeries)
            ) << 0;
        var r = a.axisX.dataInfo.minDiff;
        isFinite(r) || (r = 0.3 * Math.abs(a.axisX.range));
        r = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.9 *
              ((e.height *
                (a.axisX.logarithmic
                  ? Math.log(r) / Math.log(a.axisX.range)
                  : Math.abs(r) / Math.abs(a.axisX.range))) /
                a.plotType.totalDataSeries)) <<
            0;
        this.dataPointMaxWidth &&
          g > h &&
          (g = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, h));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && h < g &&
          (h = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, g));
        r < g && (r = g);
        r > h && (r = h);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(e.x1, e.y1, e.width, e.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.clip());
        for (var q = 0; q < a.dataSeriesIndexes.length; q++) {
          var n = a.dataSeriesIndexes[q],
            f = this.data[n],
            l = f.dataPoints;
          if (0 < l.length) {
            var p = 5 < r && f.bevelEnabled ? !0 : !1;
            b.strokeStyle = "#4572A7 ";
            for (g = 0; g < l.length; g++)
              if (
                (l[g].getTime ? (m = l[g].x.getTime()) : (m = l[g].x),
                !(m < a.axisX.dataInfo.viewPortMin || m > a.axisX.dataInfo.viewPortMax) &&
                  !u(l[g].y) &&
                  l[g].y.length &&
                  "number" === typeof l[g].y[0] &&
                  "number" === typeof l[g].y[1])
              ) {
                h = a.axisY.convertValueToPixel(l[g].y[0]);
                k = a.axisY.convertValueToPixel(l[g].y[1]);
                v = a.axisX.convertValueToPixel(m);
                v = a.axisX.reversed
                  ? (v +
                      (a.plotType.totalDataSeries * r) / 2 -
                      (a.previousDataSeriesCount + q) * r) <<
                    0
                  : (v -
                      (a.plotType.totalDataSeries * r) / 2 +
                      (a.previousDataSeriesCount + q) * r) <<
                    0;
                var w = a.axisX.reversed ? (v - r) << 0 : (v + r) << 0;
                h > k && ((c = h), (h = k), (k = c));
                c = l[g].color ? l[g].color : f._colorSet[g % f._colorSet.length];
                V(b, h, v, k, w, c, 0, null, p, !1, !1, !1, f.fillOpacity);
                c = f.dataPointIds[g];
                this._eventManager.objectMap[c] = {
                  id: c,
                  objectType: "dataPoint",
                  dataSeriesIndex: n,
                  dataPointIndex: g,
                  x1: h,
                  y1: v,
                  x2: k,
                  y2: w
                };
                c = P(c);
                s && V(this._eventManager.ghostCtx, h, v, k, w, c, 0, null, !1, !1, !1, !1);
                if (
                  l[g].indexLabel ||
                  f.indexLabel ||
                  l[g].indexLabelFormatter ||
                  f.indexLabelFormatter
                )
                  this._indexLabels.push({
                    chartType: "rangeBar",
                    dataPoint: l[g],
                    dataSeries: f,
                    indexKeyword: 0,
                    point: { x: l[g].y[1] >= l[g].y[0] ? h : k, y: v + (w - v) / 2 },
                    direction: l[g].y[1] >= l[g].y[0] ? -1 : 1,
                    bounds: { x1: Math.min(h, k), y1: v, x2: Math.max(h, k), y2: w },
                    color: c
                  }),
                    this._indexLabels.push({
                      chartType: "rangeBar",
                      dataPoint: l[g],
                      dataSeries: f,
                      indexKeyword: 1,
                      point: { x: l[g].y[1] >= l[g].y[0] ? k : h, y: v + (w - v) / 2 },
                      direction: l[g].y[1] >= l[g].y[0] ? 1 : -1,
                      bounds: { x1: Math.min(h, k), y1: v, x2: Math.max(h, k), y2: w },
                      color: c
                    });
              }
          }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(e.x1, e.y1, e.width, e.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    p.prototype.renderRangeArea = function(a) {
      function d() {
        if (D) {
          var a = null;
          0 < r.lineThickness && c.stroke();
          for (var b = v.length - 1; 0 <= b; b--)
            (a = v[b]), c.lineTo(a.x, a.y), e.lineTo(a.x, a.y);
          c.closePath();
          c.globalAlpha = r.fillOpacity;
          c.fill();
          c.globalAlpha = 1;
          e.fill();
          if (0 < r.lineThickness) {
            c.beginPath();
            c.moveTo(a.x, a.y);
            for (b = 0; b < v.length; b++) (a = v[b]), c.lineTo(a.x, a.y);
            c.stroke();
          }
          c.beginPath();
          c.moveTo(l, p);
          e.beginPath();
          e.moveTo(l, p);
          D = { x: l, y: p };
          v = [];
          v.push({ x: l, y: u });
        }
      }
      var b = a.targetCanvasCtx || this.plotArea.ctx,
        c = s ? this._preRenderCtx : b;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = this._eventManager.ghostCtx,
          g = [],
          h = this.plotArea;
        c.save();
        s && e.save();
        c.beginPath();
        c.rect(h.x1, h.y1, h.width, h.height);
        c.clip();
        s && (e.beginPath(), e.rect(h.x1, h.y1, h.width, h.height), e.clip());
        for (var k = 0; k < a.dataSeriesIndexes.length; k++) {
          var v = [],
            m = a.dataSeriesIndexes[k],
            r = this.data[m],
            q = r.dataPoints,
            g = r.id;
          this._eventManager.objectMap[g] = { objectType: "dataSeries", dataSeriesIndex: m };
          g = P(g);
          e.fillStyle = g;
          var g = [],
            n = !0,
            f = 0,
            l,
            p,
            u,
            t,
            D = null;
          if (0 < q.length) {
            var x = r._colorSet[f % r._colorSet.length],
              w = (r.lineColor = r.options.lineColor || x),
              z = w;
            c.fillStyle = x;
            c.strokeStyle = w;
            c.lineWidth = r.lineThickness;
            var B = "solid";
            if (c.setLineDash) {
              var y = Q(r.nullDataLineDashType, r.lineThickness),
                B = r.lineDashType,
                C = Q(B, r.lineThickness);
              c.setLineDash(C);
            }
            for (var T = !0; f < q.length; f++)
              if (
                ((t = q[f].x.getTime ? q[f].x.getTime() : q[f].x),
                !(
                  t < a.axisX.dataInfo.viewPortMin ||
                  (t > a.axisX.dataInfo.viewPortMax && (!r.connectNullData || !T))
                ))
              )
                if (
                  null !== q[f].y &&
                  q[f].y.length &&
                  "number" === typeof q[f].y[0] &&
                  "number" === typeof q[f].y[1]
                ) {
                  l = a.axisX.convertValueToPixel(t);
                  p = a.axisY.convertValueToPixel(q[f].y[0]);
                  u = a.axisY.convertValueToPixel(q[f].y[1]);
                  n || T
                    ? (r.connectNullData && !n
                        ? (c.setLineDash &&
                            (r.options.nullDataLineDashType ||
                              (B === r.lineDashType &&
                                r.lineDashType !== r.nullDataLineDashType)) &&
                            ((v[v.length - 1].newLineDashArray = C),
                            (B = r.nullDataLineDashType),
                            c.setLineDash(y)),
                          c.lineTo(l, p),
                          s && e.lineTo(l, p),
                          v.push({ x: l, y: u }))
                        : (c.beginPath(),
                          c.moveTo(l, p),
                          (D = { x: l, y: p }),
                          (v = []),
                          v.push({ x: l, y: u }),
                          s && (e.beginPath(), e.moveTo(l, p))),
                      (T = n = !1))
                    : (c.lineTo(l, p),
                      v.push({ x: l, y: u }),
                      s && e.lineTo(l, p),
                      0 == f % 250 && d());
                  t = r.dataPointIds[f];
                  this._eventManager.objectMap[t] = {
                    id: t,
                    objectType: "dataPoint",
                    dataSeriesIndex: m,
                    dataPointIndex: f,
                    x1: l,
                    y1: p,
                    y2: u
                  };
                  f < q.length - 1 &&
                    (z !== (q[f].lineColor || w) || B !== (q[f].lineDashType || r.lineDashType)) &&
                    (d(),
                    (z = q[f].lineColor || w),
                    (v[v.length - 1].newStrokeStyle = z),
                    (c.strokeStyle = z),
                    c.setLineDash &&
                      (q[f].lineDashType
                        ? ((B = q[f].lineDashType),
                          (v[v.length - 1].newLineDashArray = Q(B, r.lineThickness)),
                          c.setLineDash(v[v.length - 1].newLineDashArray))
                        : ((B = r.lineDashType),
                          (v[v.length - 1].newLineDashArray = C),
                          c.setLineDash(C))));
                  if (0 !== q[f].markerSize && (0 < q[f].markerSize || 0 < r.markerSize)) {
                    var Z = r.getMarkerProperties(f, l, u, c);
                    g.push(Z);
                    var ca = P(t);
                    s &&
                      g.push({
                        x: l,
                        y: u,
                        ctx: e,
                        type: Z.type,
                        size: Z.size,
                        color: ca,
                        borderColor: ca,
                        borderThickness: Z.borderThickness
                      });
                    Z = r.getMarkerProperties(f, l, p, c);
                    g.push(Z);
                    ca = P(t);
                    s &&
                      g.push({
                        x: l,
                        y: p,
                        ctx: e,
                        type: Z.type,
                        size: Z.size,
                        color: ca,
                        borderColor: ca,
                        borderThickness: Z.borderThickness
                      });
                  }
                  if (
                    q[f].indexLabel ||
                    r.indexLabel ||
                    q[f].indexLabelFormatter ||
                    r.indexLabelFormatter
                  )
                    this._indexLabels.push({
                      chartType: "rangeArea",
                      dataPoint: q[f],
                      dataSeries: r,
                      indexKeyword: 0,
                      point: { x: l, y: p },
                      direction: q[f].y[0] > q[f].y[1] === a.axisY.reversed ? -1 : 1,
                      color: x
                    }),
                      this._indexLabels.push({
                        chartType: "rangeArea",
                        dataPoint: q[f],
                        dataSeries: r,
                        indexKeyword: 1,
                        point: { x: l, y: u },
                        direction: q[f].y[0] > q[f].y[1] === a.axisY.reversed ? 1 : -1,
                        color: x
                      });
                } else T || n || d(), (T = !0);
            d();
            Y.drawMarkers(g);
          }
        }
        s &&
          (b.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (c.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          c.clearRect(h.x1, h.y1, h.width, h.height),
          this._eventManager.ghostCtx.restore());
        c.restore();
        return {
          source: b,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderRangeSplineArea = function(a) {
      function d(a, b) {
        var d = w(p, 2);
        if (0 < d.length) {
          if (0 < m.lineThickness) {
            c.strokeStyle = b;
            c.setLineDash && c.setLineDash(a);
            c.beginPath();
            c.moveTo(d[0].x, d[0].y);
            for (var f = 0; f < d.length - 3; f += 3) {
              if (d[f].newStrokeStyle || d[f].newLineDashArray)
                c.stroke(),
                  c.beginPath(),
                  c.moveTo(d[f].x, d[f].y),
                  d[f].newStrokeStyle && (c.strokeStyle = d[f].newStrokeStyle),
                  d[f].newLineDashArray && c.setLineDash(d[f].newLineDashArray);
              c.bezierCurveTo(
                d[f + 1].x,
                d[f + 1].y,
                d[f + 2].x,
                d[f + 2].y,
                d[f + 3].x,
                d[f + 3].y
              );
            }
            c.stroke();
          }
          c.beginPath();
          c.moveTo(d[0].x, d[0].y);
          s && (e.beginPath(), e.moveTo(d[0].x, d[0].y));
          for (f = 0; f < d.length - 3; f += 3)
            c.bezierCurveTo(d[f + 1].x, d[f + 1].y, d[f + 2].x, d[f + 2].y, d[f + 3].x, d[f + 3].y),
              s &&
                e.bezierCurveTo(
                  d[f + 1].x,
                  d[f + 1].y,
                  d[f + 2].x,
                  d[f + 2].y,
                  d[f + 3].x,
                  d[f + 3].y
                );
          d = w(u, 2);
          c.lineTo(u[u.length - 1].x, u[u.length - 1].y);
          for (f = d.length - 1; 2 < f; f -= 3)
            c.bezierCurveTo(d[f - 1].x, d[f - 1].y, d[f - 2].x, d[f - 2].y, d[f - 3].x, d[f - 3].y),
              s &&
                e.bezierCurveTo(
                  d[f - 1].x,
                  d[f - 1].y,
                  d[f - 2].x,
                  d[f - 2].y,
                  d[f - 3].x,
                  d[f - 3].y
                );
          c.closePath();
          c.globalAlpha = m.fillOpacity;
          c.fill();
          s && (e.closePath(), e.fill());
          c.globalAlpha = 1;
          if (0 < m.lineThickness) {
            c.strokeStyle = b;
            c.setLineDash && c.setLineDash(a);
            c.beginPath();
            c.moveTo(d[0].x, d[0].y);
            for (var g = (f = 0); f < d.length - 3; f += 3, g++) {
              if (p[g].newStrokeStyle || p[g].newLineDashArray)
                c.stroke(),
                  c.beginPath(),
                  c.moveTo(d[f].x, d[f].y),
                  p[g].newStrokeStyle && (c.strokeStyle = p[g].newStrokeStyle),
                  p[g].newLineDashArray && c.setLineDash(p[g].newLineDashArray);
              c.bezierCurveTo(
                d[f + 1].x,
                d[f + 1].y,
                d[f + 2].x,
                d[f + 2].y,
                d[f + 3].x,
                d[f + 3].y
              );
            }
            c.stroke();
          }
          c.beginPath();
        }
      }
      var b = a.targetCanvasCtx || this.plotArea.ctx,
        c = s ? this._preRenderCtx : b;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var e = this._eventManager.ghostCtx,
          g = [],
          h = this.plotArea;
        c.save();
        s && e.save();
        c.beginPath();
        c.rect(h.x1, h.y1, h.width, h.height);
        c.clip();
        s && (e.beginPath(), e.rect(h.x1, h.y1, h.width, h.height), e.clip());
        for (var k = 0; k < a.dataSeriesIndexes.length; k++) {
          var v = a.dataSeriesIndexes[k],
            m = this.data[v],
            r = m.dataPoints,
            g = m.id;
          this._eventManager.objectMap[g] = { objectType: "dataSeries", dataSeriesIndex: v };
          g = P(g);
          e.fillStyle = g;
          var g = [],
            q = 0,
            n,
            f,
            l,
            p = [],
            u = [];
          if (0 < r.length) {
            var t = m._colorSet[q % m._colorSet.length],
              D = (m.lineColor = m.options.lineColor || t),
              x = D;
            c.fillStyle = t;
            c.lineWidth = m.lineThickness;
            var y = "solid",
              z;
            if (c.setLineDash) {
              var B = Q(m.nullDataLineDashType, m.lineThickness),
                y = m.lineDashType;
              z = Q(y, m.lineThickness);
            }
            for (f = !1; q < r.length; q++)
              if (
                ((n = r[q].x.getTime ? r[q].x.getTime() : r[q].x),
                !(
                  n < a.axisX.dataInfo.viewPortMin ||
                  (n > a.axisX.dataInfo.viewPortMax && (!m.connectNullData || !f))
                ))
              )
                if (
                  null !== r[q].y &&
                  r[q].y.length &&
                  "number" === typeof r[q].y[0] &&
                  "number" === typeof r[q].y[1]
                ) {
                  n = a.axisX.convertValueToPixel(n);
                  f = a.axisY.convertValueToPixel(r[q].y[0]);
                  l = a.axisY.convertValueToPixel(r[q].y[1]);
                  var C = m.dataPointIds[q];
                  this._eventManager.objectMap[C] = {
                    id: C,
                    objectType: "dataPoint",
                    dataSeriesIndex: v,
                    dataPointIndex: q,
                    x1: n,
                    y1: f,
                    y2: l
                  };
                  p[p.length] = { x: n, y: f };
                  u[u.length] = { x: n, y: l };
                  q < r.length - 1 &&
                    (x !== (r[q].lineColor || D) || y !== (r[q].lineDashType || m.lineDashType)) &&
                    ((x = r[q].lineColor || D),
                    (p[p.length - 1].newStrokeStyle = x),
                    c.setLineDash &&
                      (r[q].lineDashType
                        ? ((y = r[q].lineDashType),
                          (p[p.length - 1].newLineDashArray = Q(y, m.lineThickness)))
                        : ((y = m.lineDashType), (p[p.length - 1].newLineDashArray = z))));
                  if (0 !== r[q].markerSize && (0 < r[q].markerSize || 0 < m.markerSize)) {
                    var X = m.getMarkerProperties(q, n, f, c);
                    g.push(X);
                    var T = P(C);
                    s &&
                      g.push({
                        x: n,
                        y: f,
                        ctx: e,
                        type: X.type,
                        size: X.size,
                        color: T,
                        borderColor: T,
                        borderThickness: X.borderThickness
                      });
                    X = m.getMarkerProperties(q, n, l, c);
                    g.push(X);
                    T = P(C);
                    s &&
                      g.push({
                        x: n,
                        y: l,
                        ctx: e,
                        type: X.type,
                        size: X.size,
                        color: T,
                        borderColor: T,
                        borderThickness: X.borderThickness
                      });
                  }
                  if (
                    r[q].indexLabel ||
                    m.indexLabel ||
                    r[q].indexLabelFormatter ||
                    m.indexLabelFormatter
                  )
                    this._indexLabels.push({
                      chartType: "rangeSplineArea",
                      dataPoint: r[q],
                      dataSeries: m,
                      indexKeyword: 0,
                      point: { x: n, y: f },
                      direction: r[q].y[0] <= r[q].y[1] ? -1 : 1,
                      color: t
                    }),
                      this._indexLabels.push({
                        chartType: "rangeSplineArea",
                        dataPoint: r[q],
                        dataSeries: m,
                        indexKeyword: 1,
                        point: { x: n, y: l },
                        direction: r[q].y[0] <= r[q].y[1] ? 1 : -1,
                        color: t
                      });
                  f = !1;
                } else
                  0 < q &&
                    !f &&
                    (m.connectNullData
                      ? c.setLineDash &&
                        0 < p.length &&
                          (m.options.nullDataLineDashType || !r[q - 1].lineDashType) &&
                        ((p[p.length - 1].newLineDashArray = B), (y = m.nullDataLineDashType))
                      : (d(z, D), (p = []), (u = []))),
                    (f = !0);
            d(z, D);
            Y.drawMarkers(g);
          }
        }
        s &&
          (b.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (c.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && c.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && c.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          c.clearRect(h.x1, h.y1, h.width, h.height),
          this._eventManager.ghostCtx.restore());
        c.restore();
        return {
          source: b,
          dest: this.plotArea.ctx,
          animationCallback: M.xClipAnimation,
          easingFunction: M.easing.linear,
          animationBase: 0
        };
      }
    };
    p.prototype.renderWaterfall = function(a) {
      var d = a.targetCanvasCtx || this.plotArea.ctx,
        b = s ? this._preRenderCtx : d;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var c = this._eventManager.ghostCtx,
          e = null,
          g = this.plotArea,
          h = 0,
          k,
          v,
          m,
          r,
          q = a.axisY.convertValueToPixel(a.axisY.logarithmic ? a.axisY.viewportMinimum : 0),
          h = this.options.dataPointMinWidth
            ? this.dataPointMinWidth
            : this.options.dataPointWidth
            ? this.dataPointWidth
            : 1;
        v = this.options.dataPointMaxWidth
          ? this.dataPointMaxWidth
          : this.options.dataPointWidth
          ? this.dataPointWidth
          : Math.min(0.15 * this.width, 0.9 * (this.plotArea.width / a.plotType.totalDataSeries)) <<
            0;
        var n = a.axisX.dataInfo.minDiff;
        isFinite(n) || (n = 0.3 * Math.abs(a.axisX.range));
        n = this.options.dataPointWidth
          ? this.dataPointWidth
          : (0.6 *
              ((g.width *
                (a.axisX.logarithmic
                  ? Math.log(n) / Math.log(a.axisX.range)
                  : Math.abs(n) / Math.abs(a.axisX.range))) /
                a.plotType.totalDataSeries)) <<
            0;
        this.dataPointMaxWidth &&
          h > v &&
          (h = Math.min(this.options.dataPointWidth ? this.dataPointWidth : Infinity, v));
        !this.dataPointMaxWidth &&
          this.dataPointMinWidth && v < h &&
          (v = Math.max(this.options.dataPointWidth ? this.dataPointWidth : -Infinity, h));
        n < h && (n = h);
        n > v && (n = v);
        b.save();
        s && this._eventManager.ghostCtx.save();
        b.beginPath();
        b.rect(g.x1, g.y1, g.width, g.height);
        b.clip();
        s &&
          (this._eventManager.ghostCtx.beginPath(),
          this._eventManager.ghostCtx.rect(g.x1, g.y1, g.width, g.height),
          this._eventManager.ghostCtx.clip());
        for (var f = 0; f < a.dataSeriesIndexes.length; f++) {
          var l = a.dataSeriesIndexes[f],
            p = this.data[l],
            u = p.dataPoints,
            e = p._colorSet[0];
          p.risingColor = p.options.risingColor ? p.options.risingColor : e;
          p.fallingColor = p.options.fallingColor ? p.options.fallingColor : "#e40a0a";
          var t = "number" === typeof p.options.lineThickness ? Math.round(p.lineThickness) : 1,
            w = 1 === Math.round(t) % 2 ? -0.5 : 0;
          if (0 < u.length)
            for (
              var x = 5 < n && p.bevelEnabled ? !0 : !1, y = !1, z = null, B = null, h = 0;
              h < u.length;
              h++
            )
              if (
                (u[h].getTime ? (r = u[h].x.getTime()) : (r = u[h].x), "number" !== typeof u[h].y)
              ) {
                if (0 < h && !y && p.connectNullData)
                  var C =
                    p.options.nullDataLineDashType || !u[h - 1].lineDashType
                      ? p.nullDataLineDashType
                      : u[h - 1].lineDashType;
                y = !0;
              } else {
                k = a.axisX.convertValueToPixel(r);
                v =
                  0 === p.dataPointEOs[h].cumulativeSum
                    ? q
                    : a.axisY.convertValueToPixel(p.dataPointEOs[h].cumulativeSum);
                m =
                  0 === p.dataPointEOs[h].cumulativeSumYStartValue
                    ? q
                    : a.axisY.convertValueToPixel(p.dataPointEOs[h].cumulativeSumYStartValue);
                k = a.axisX.reversed
                  ? (k +
                      (a.plotType.totalDataSeries * n) / 2 -
                      (a.previousDataSeriesCount + f) * n) <<
                    0
                  : (k -
                      (a.plotType.totalDataSeries * n) / 2 +
                      (a.previousDataSeriesCount + f) * n) <<
                    0;
                var X = a.axisX.reversed ? (k - n) << 0 : (k + n) << 0;
                v > m && ((e = v), (v = m), (m = e));
                a.axisY.reversed && ((e = v), (v = m), (m = e));
                e = p.dataPointIds[h];
                this._eventManager.objectMap[e] = {
                  id: e,
                  objectType: "dataPoint",
                  dataSeriesIndex: l,
                  dataPointIndex: h,
                  x1: k,
                  y1: v,
                  x2: X,
                  y2: m
                };
                var T = u[h].color ? u[h].color : 0 < u[h].y ? p.risingColor : p.fallingColor;
                V(b, k, v, X, m, T, 0, T, x, x, !1, !1, p.fillOpacity);
                e = P(e);
                s && V(this._eventManager.ghostCtx, k, v, X, m, e, 0, null, !1, !1, !1, !1);
                var Z,
                  T = k;
                Z =
                  ("undefined" !== typeof u[h].isIntermediateSum &&
                    !0 === u[h].isIntermediateSum) ||
                  ("undefined" !== typeof u[h].isCumulativeSum && !0 === u[h].isCumulativeSum)
                    ? 0 < u[h].y
                      ? v
                      : m
                    : 0 < u[h].y
                    ? m
                    : v;
                0 < h &&
                  z &&
                  (!y || p.connectNullData) &&
                  (y && b.setLineDash && b.setLineDash(Q(C, t)),
                  b.beginPath(),
                  b.moveTo(z, B - w),
                  b.lineTo(T, Z - w),
                  0 < t && b.stroke(),
                  s &&
                    (c.beginPath(), c.moveTo(z, B - w), c.lineTo(T, Z - w), 0 < t && c.stroke()));
                y = !1;
                z = X;
                B = 0 < u[h].y ? v : m;
                T = u[h].lineDashType
                  ? u[h].lineDashType
                  : p.options.lineDashType
                  ? p.options.lineDashType
                  : "shortDash";
                b.strokeStyle = u[h].lineColor
                  ? u[h].lineColor
                  : p.options.lineColor
                  ? p.options.lineColor
                  : "#9e9e9e";
                b.lineWidth = t;
                b.setLineDash && ((T = Q(T, t)), b.setLineDash(T));
                (u[h].indexLabel ||
                  p.indexLabel ||
                  u[h].indexLabelFormatter ||
                  p.indexLabelFormatter) &&
                  this._indexLabels.push({
                    chartType: "waterfall",
                    dataPoint: u[h],
                    dataSeries: p,
                    point: { x: k + (X - k) / 2, y: 0 <= u[h].y ? v : m },
                    direction: 0 > u[h].y === a.axisY.reversed ? 1 : -1,
                    bounds: { x1: k, y1: Math.min(v, m), x2: X, y2: Math.max(v, m) },
                    color: e
                  });
              }
        }
        s &&
          (d.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          (b.globalCompositeOperation = "source-atop"),
          a.axisX.maskCanvas && b.drawImage(a.axisX.maskCanvas, 0, 0, this.width, this.height),
          a.axisY.maskCanvas && b.drawImage(a.axisY.maskCanvas, 0, 0, this.width, this.height),
          this._breaksCanvasCtx &&
            this._breaksCanvasCtx.drawImage(this._preRenderCanvas, 0, 0, this.width, this.height),
          b.clearRect(g.x1, g.y1, g.width, g.height),
          this._eventManager.ghostCtx.restore());
        b.restore();
        return {
          source: d,
          dest: this.plotArea.ctx,
          animationCallback: M.fadeInAnimation,
          easingFunction: M.easing.easeInQuad,
          animationBase: 0
        };
      }
    };
    var ia = function(a, d, b, c, e, g, h, k, v) {
      if (!(0 > b)) {
        "undefined" === typeof k && (k = 1);
        if (!s) {
          var m = Number((h % (2 * Math.PI)).toFixed(8));
          Number((g % (2 * Math.PI)).toFixed(8)) === m && (h -= 1e-4);
        }
        a.save();
        a.globalAlpha = k;
        "pie" === e
          ? (a.beginPath(),
            a.moveTo(d.x, d.y),
            a.arc(d.x, d.y, b, g, h, !1),
            (a.fillStyle = c),
            (a.strokeStyle = "white"),
            (a.lineWidth = 2),
            a.closePath(),
            a.fill())
          : "doughnut" === e &&
            (a.beginPath(),
            a.arc(d.x, d.y, b, g, h, !1),
            0 <= v && a.arc(d.x, d.y, v * b, h, g, !0),
            a.closePath(),
            (a.fillStyle = c),
            (a.strokeStyle = "white"),
            (a.lineWidth = 2),
            a.fill());
        a.globalAlpha = 1;
        a.restore();
      }
    };
    p.prototype.renderPie = function(a) {
      function d() {
        if (m && r) {
          for (var a = 0, b = 0, c = 0, e = 0, d = 0; d < r.length; d++) {
            var g = r[d],
              k = m.dataPointIds[d];
            f[d].id = k;
            f[d].objectType = "dataPoint";
            f[d].dataPointIndex = d;
            f[d].dataSeriesIndex = 0;
            var h = f[d],
              q = { percent: null, total: null },
              l = null,
              q = v.getPercentAndTotal(m, g);
            if (m.indexLabelFormatter || g.indexLabelFormatter)
              l = {
                chart: v.options,
                dataSeries: m,
                dataPoint: g,
                total: q.total,
                percent: q.percent
              };
            q = g.indexLabelFormatter
              ? g.indexLabelFormatter(l)
              : g.indexLabel
              ? v.replaceKeywordsWithValue(g.indexLabel, g, m, d)
              : m.indexLabelFormatter
              ? m.indexLabelFormatter(l)
              : m.indexLabel
              ? v.replaceKeywordsWithValue(m.indexLabel, g, m, d)
              : g.label
              ? g.label
              : "";
            v._eventManager.objectMap[k] = h;
            h.center = { x: x.x, y: x.y };
            h.y = g.y;
            h.radius = B;
            h.percentInnerRadius = X;
            h.indexLabelText = q;
            h.indexLabelPlacement = m.indexLabelPlacement;
            h.indexLabelLineColor = g.indexLabelLineColor
              ? g.indexLabelLineColor
              : m.options.indexLabelLineColor
              ? m.options.indexLabelLineColor
              : g.color
              ? g.color
              : m._colorSet[d % m._colorSet.length];
            h.indexLabelLineThickness = u(g.indexLabelLineThickness)
              ? m.indexLabelLineThickness
              : g.indexLabelLineThickness;
            h.indexLabelLineDashType = g.indexLabelLineDashType
              ? g.indexLabelLineDashType
              : m.indexLabelLineDashType;
            h.indexLabelFontColor = g.indexLabelFontColor
              ? g.indexLabelFontColor
              : m.indexLabelFontColor;
            h.indexLabelFontStyle = g.indexLabelFontStyle
              ? g.indexLabelFontStyle
              : m.indexLabelFontStyle;
            h.indexLabelFontWeight = g.indexLabelFontWeight
              ? g.indexLabelFontWeight
              : m.indexLabelFontWeight;
            h.indexLabelFontSize = u(g.indexLabelFontSize)
              ? m.indexLabelFontSize
              : g.indexLabelFontSize;
            h.indexLabelFontFamily = g.indexLabelFontFamily
              ? g.indexLabelFontFamily
              : m.indexLabelFontFamily;
            h.indexLabelBackgroundColor = g.indexLabelBackgroundColor
              ? g.indexLabelBackgroundColor
              : m.options.indexLabelBackgroundColor
              ? m.options.indexLabelBackgroundColor
              : m.indexLabelBackgroundColor;
            h.indexLabelMaxWidth = g.indexLabelMaxWidth
              ? g.indexLabelMaxWidth
              : m.indexLabelMaxWidth
              ? m.indexLabelMaxWidth
              : 0.33 * n.width;
            h.indexLabelWrap =
              "undefined" !== typeof g.indexLabelWrap ? g.indexLabelWrap : m.indexLabelWrap;
            h.startAngle =
              0 === d ? (m.startAngle ? (m.startAngle / 180) * Math.PI : 0) : f[d - 1].endAngle;
            h.startAngle = (h.startAngle + 2 * Math.PI) % (2 * Math.PI);
            h.endAngle = h.startAngle + ((2 * Math.PI) / y) * Math.abs(g.y);
            g = (h.endAngle + h.startAngle) / 2;
            g = (g + 2 * Math.PI) % (2 * Math.PI);
            h.midAngle = g;
            if (h.midAngle > Math.PI / 2 - t && h.midAngle < Math.PI / 2 + t) {
              if (0 === a || f[c].midAngle > h.midAngle) c = d;
              a++;
            } else if (h.midAngle > (3 * Math.PI) / 2 - t && h.midAngle < (3 * Math.PI) / 2 + t) {
              if (0 === b || f[e].midAngle > h.midAngle) e = d;
              b++;
            }
            h.hemisphere = g > Math.PI / 2 && g <= (3 * Math.PI) / 2 ? "left" : "right";
            h.indexLabelTextBlock = new ja(v.plotArea.ctx, {
              fontSize: h.indexLabelFontSize,
              fontFamily: h.indexLabelFontFamily,
              fontColor: h.indexLabelFontColor,
              fontStyle: h.indexLabelFontStyle,
              fontWeight: h.indexLabelFontWeight,
              horizontalAlign: "left",
              backgroundColor: h.indexLabelBackgroundColor,
              maxWidth: h.indexLabelMaxWidth,
              maxHeight: h.indexLabelWrap ? 5 * h.indexLabelFontSize : 1.5 * h.indexLabelFontSize,
              text: h.indexLabelText,
              padding: 0,
              textBaseline: "top"
            });
            h.indexLabelTextBlock.measureText();
          }
          k = g = 0;
          q = !1;
          for (d = 0; d < r.length; d++)
            (h = f[(c + d) % r.length]),
              1 < a &&
                h.midAngle > Math.PI / 2 - t && h.midAngle < Math.PI / 2 + t &&
                (g <= a / 2 && !q
                  ? ((h.hemisphere = "right"), g++)
                  : ((h.hemisphere = "left"), (q = !0)));
          q = !1;
          for (d = 0; d < r.length; d++)
            (h = f[(e + d) % r.length]),
              1 < b &&
                h.midAngle > (3 * Math.PI) / 2 - t && h.midAngle < (3 * Math.PI) / 2 + t &&
                (k <= b / 2 && !q
                  ? ((h.hemisphere = "left"), k++)
                  : ((h.hemisphere = "right"), (q = !0)));
        }
      }
      function b(a) {
        var b = v.plotArea.ctx;
        b.clearRect(n.x1, n.y1, n.width, n.height);
        b.fillStyle = v.backgroundColor;
        b.fillRect(n.x1, n.y1, n.width, n.height);
        for (b = 0; b < r.length; b++) {
          var c = f[b].startAngle,
            e = f[b].endAngle;
          if (e > c) {
            var d = 0.07 * B * Math.cos(f[b].midAngle),
              g = 0.07 * B * Math.sin(f[b].midAngle),
              h = !1;
            if (r[b].exploded) {
              if (
                1e-9 < Math.abs(f[b].center.x - (x.x + d)) ||
                1e-9 < Math.abs(f[b].center.y - (x.y + g))
              )
                (f[b].center.x = x.x + d * a), (f[b].center.y = x.y + g * a), (h = !0);
            } else if (0 < Math.abs(f[b].center.x - x.x) || 0 < Math.abs(f[b].center.y - x.y))
              (f[b].center.x = x.x + d * (1 - a)), (f[b].center.y = x.y + g * (1 - a)), (h = !0);
            h &&
              ((d = {}),
              (d.dataSeries = m),
              (d.dataPoint = m.dataPoints[b]),
              (d.index = b),
              v.toolTip.highlightObjects([d]));
            ia(
              v.plotArea.ctx,
              f[b].center,
              f[b].radius,
              r[b].color ? r[b].color : m._colorSet[b % m._colorSet.length],
              m.type,
              c,
              e,
              m.fillOpacity,
              f[b].percentInnerRadius
            );
          }
        }
        a = v.plotArea.ctx;
        a.save();
        a.fillStyle = "black";
        a.strokeStyle = "grey";
        a.textBaseline = "middle";
        a.lineJoin = "round";
        for (b = b = 0; b < r.length; b++)
          (c = f[b]),
            c.indexLabelText &&
              ((c.indexLabelTextBlock.y -= c.indexLabelTextBlock.height / 2),
              (e = 0),
              (e =
                "left" === c.hemisphere
                  ? "inside" !== m.indexLabelPlacement
                    ? -(c.indexLabelTextBlock.width + q)
                    : -c.indexLabelTextBlock.width / 2
                  : "inside" !== m.indexLabelPlacement
                  ? q
                  : -c.indexLabelTextBlock.width / 2),
              (c.indexLabelTextBlock.x += e),
              c.indexLabelTextBlock.render(!0),
              (c.indexLabelTextBlock.x -= e),
              (c.indexLabelTextBlock.y += c.indexLabelTextBlock.height / 2),
              "inside" !== c.indexLabelPlacement &&
                0 < c.indexLabelLineThickness &&
                ((e = c.center.x + B * Math.cos(c.midAngle)),
                (d = c.center.y + B * Math.sin(c.midAngle)),
                (a.strokeStyle = c.indexLabelLineColor),
                (a.lineWidth = c.indexLabelLineThickness),
                a.setLineDash &&
                  a.setLineDash(Q(c.indexLabelLineDashType, c.indexLabelLineThickness)),
                a.beginPath(),
                a.moveTo(e, d),
                a.lineTo(c.indexLabelTextBlock.x, c.indexLabelTextBlock.y),
                a.lineTo(
                  c.indexLabelTextBlock.x + ("left" === c.hemisphere ? -q : q),
                  c.indexLabelTextBlock.y
                ),
                a.stroke()),
              (a.lineJoin = "miter"));
        a.save();
      }
      function c(a, b) {
        var c = 0,
          c = a.indexLabelTextBlock.y - a.indexLabelTextBlock.height / 2,
          e = a.indexLabelTextBlock.y + a.indexLabelTextBlock.height / 2,
          d = b.indexLabelTextBlock.y - b.indexLabelTextBlock.height / 2,
          f = b.indexLabelTextBlock.y + b.indexLabelTextBlock.height / 2;
        return (c = b.indexLabelTextBlock.y > a.indexLabelTextBlock.y ? d - e : c - f);
      }
      function e(a) {
        for (var b = null, e = 1; e < r.length; e++)
          if (((b = (a + e + f.length) % f.length), f[b].hemisphere !== f[a].hemisphere)) {
            b = null;
            break;
          } else if (
            f[b].indexLabelText &&
            b !== a &&
            (0 > c(f[b], f[a]) ||
              ("right" === f[a].hemisphere
                ? f[b].indexLabelTextBlock.y >= f[a].indexLabelTextBlock.y
                : f[b].indexLabelTextBlock.y <= f[a].indexLabelTextBlock.y))
          )
            break;
          else b = null;
        return b;
      }
      function g(a, b, d) {
        d = (d || 0) + 1;
        if (1e3 < d) return 0;
        b = b || 0;
        var h = 0,
          m = x.y - 1 * p,
          k = x.y + 1 * p;
        if (0 <= a && a < r.length) {
          var n = f[a];
          if ((0 > b && n.indexLabelTextBlock.y < m) || (0 < b && n.indexLabelTextBlock.y > k))
            return 0;
          var q = 0,
            v = 0,
            v = (q = q = 0);
          0 > b
            ? n.indexLabelTextBlock.y - n.indexLabelTextBlock.height / 2 > m &&
              n.indexLabelTextBlock.y - n.indexLabelTextBlock.height / 2 + b < m &&
              (b = -(m - (n.indexLabelTextBlock.y - n.indexLabelTextBlock.height / 2 + b)))
            : n.indexLabelTextBlock.y + n.indexLabelTextBlock.height / 2 < m &&
              n.indexLabelTextBlock.y + n.indexLabelTextBlock.height / 2 + b > k &&
              (b = n.indexLabelTextBlock.y + n.indexLabelTextBlock.height / 2 + b - k);
          b = n.indexLabelTextBlock.y + b;
          m = 0;
          m =
            "right" === n.hemisphere
              ? x.x + Math.sqrt(Math.pow(p, 2) - Math.pow(b - x.y, 2))
              : x.x - Math.sqrt(Math.pow(p, 2) - Math.pow(b - x.y, 2));
          v = x.x + B * Math.cos(n.midAngle);
          q = x.y + B * Math.sin(n.midAngle);
          q = Math.sqrt(Math.pow(m - v, 2) + Math.pow(b - q, 2));
          v = Math.acos(B / p);
          q = Math.acos((p * p + B * B - q * q) / (2 * B * p));
          b = q < v ? b - n.indexLabelTextBlock.y : 0;
          m = null;
          for (k = 1; k < r.length; k++)
            if (((m = (a - k + f.length) % f.length), f[m].hemisphere !== f[a].hemisphere)) {
              m = null;
              break;
            } else if (
              f[m].indexLabelText &&
              f[m].hemisphere === f[a].hemisphere &&
              m !== a &&
              (0 > c(f[m], f[a]) ||
                ("right" === f[a].hemisphere
                  ? f[m].indexLabelTextBlock.y <= f[a].indexLabelTextBlock.y
                  : f[m].indexLabelTextBlock.y >= f[a].indexLabelTextBlock.y))
            )
              break;
            else m = null;
          v = m;
          q = e(a);
          k = m = 0;
          0 > b
            ? ((k = "right" === n.hemisphere ? v : q),
              (h = b),
              null !== k &&
                ((v = -b),
                (b =
                  n.indexLabelTextBlock.y -
                  n.indexLabelTextBlock.height / 2 -
                  (f[k].indexLabelTextBlock.y + f[k].indexLabelTextBlock.height / 2)),
                b - v < l &&
                  ((m = -v),
                  (k = g(k, m, d + 1)),
                  +k.toFixed(w) > +m.toFixed(w) && (h = b > l ? -(b - l) : -(v - (k - m))))))
            : 0 < b &&
              ((k = "right" === n.hemisphere ? q : v),
              (h = b),
              null !== k &&
                ((v = b),
                (b =
                  f[k].indexLabelTextBlock.y -
                  f[k].indexLabelTextBlock.height / 2 -
                  (n.indexLabelTextBlock.y + n.indexLabelTextBlock.height / 2)),
                b - v < l &&
                  ((m = v),
                  (k = g(k, m, d + 1)),
                  +k.toFixed(w) < +m.toFixed(w) && (h = b > l ? b - l : v - (m - k)))));
          h &&
            ((d = n.indexLabelTextBlock.y + h),
            (b = 0),
            (b =
              "right" === n.hemisphere
                ? x.x + Math.sqrt(Math.pow(p, 2) - Math.pow(d - x.y, 2))
                : x.x - Math.sqrt(Math.pow(p, 2) - Math.pow(d - x.y, 2))),
            n.midAngle > Math.PI / 2 - t && n.midAngle < Math.PI / 2 + t
              ? ((m = (a - 1 + f.length) % f.length),
                (m = f[m]),
                (a = f[(a + 1 + f.length) % f.length]),
                "left" === n.hemisphere && "right" === m.hemisphere && b > m.indexLabelTextBlock.x
                  ? (b = m.indexLabelTextBlock.x - 15)
                  : "right" === n.hemisphere &&
                    "left" === a.hemisphere && b < a.indexLabelTextBlock.x &&
                    (b = a.indexLabelTextBlock.x + 15))
              : n.midAngle > (3 * Math.PI) / 2 - t &&
                n.midAngle < (3 * Math.PI) / 2 + t &&
                ((m = (a - 1 + f.length) % f.length),
                (m = f[m]),
                (a = f[(a + 1 + f.length) % f.length]),
                "right" === n.hemisphere && "left" === m.hemisphere && b < m.indexLabelTextBlock.x
                  ? (b = m.indexLabelTextBlock.x + 15)
                  : "left" === n.hemisphere &&
                    "right" === a.hemisphere && b > a.indexLabelTextBlock.x &&
                    (b = a.indexLabelTextBlock.x - 15)),
            (n.indexLabelTextBlock.y = d),
            (n.indexLabelTextBlock.x = b),
            (n.indexLabelAngle = Math.atan2(
              n.indexLabelTextBlock.y - x.y,
              n.indexLabelTextBlock.x - x.x
            )));
        }
        return h;
      }
      function h() {
        var a = v.plotArea.ctx;
        a.fillStyle = "grey";
        a.strokeStyle = "grey";
        a.font = "16px Arial";
        a.textBaseline = "middle";
        for (var b = (a = 0), d = 0, h = !0, b = 0; 10 > b && (1 > b || 0 < d); b++) {
          if (
            m.radius ||
            (!m.radius &&
              "undefined" !== typeof m.innerRadius &&
              null !== m.innerRadius &&
              B - d <= C)
          )
            h = !1;
          h && (B -= d);
          d = 0;
          if ("inside" !== m.indexLabelPlacement) {
            p = B * s;
            for (a = 0; a < r.length; a++) {
              var k = f[a];
              k.indexLabelTextBlock.x = x.x + p * Math.cos(k.midAngle);
              k.indexLabelTextBlock.y = x.y + p * Math.sin(k.midAngle);
              k.indexLabelAngle = k.midAngle;
              k.radius = B;
              k.percentInnerRadius = X;
            }
            for (var t, u, a = 0; a < r.length; a++) {
              var k = f[a],
                z = e(a);
              if (null !== z) {
                t = f[a];
                u = f[z];
                var y = 0,
                  y = c(t, u) - l;
                if (0 > y) {
                  for (var H = (u = 0), E = 0; E < r.length; E++)
                    E !== a &&
                      f[E].hemisphere === k.hemisphere &&
                      (f[E].indexLabelTextBlock.y < k.indexLabelTextBlock.y ? u++ : H++);
                  u = (y / (u + H || 1)) * H;
                  var H = -1 * (y - u),
                    I = (E = 0);
                  "right" === k.hemisphere
                    ? ((E = g(a, u)),
                      (H = -1 * (y - E)),
                      (I = g(z, H)),
                      +I.toFixed(w) < +H.toFixed(w) &&
                        +E.toFixed(w) <= +u.toFixed(w) &&
                        g(a, -(H - I)))
                    : ((E = g(z, u)),
                      (H = -1 * (y - E)),
                      (I = g(a, H)),
                      +I.toFixed(w) < +H.toFixed(w) &&
                        +E.toFixed(w) <= +u.toFixed(w) &&
                        g(z, -(H - I)));
                }
              }
            }
          } else
            for (a = 0; a < r.length; a++)
              (k = f[a]),
                (p = "pie" === m.type ? 0.7 * B : 0.8 * B),
                (z = x.x + p * Math.cos(k.midAngle)),
                (u = x.y + p * Math.sin(k.midAngle)),
                (k.indexLabelTextBlock.x = z),
                (k.indexLabelTextBlock.y = u);
          for (a = 0; a < r.length; a++)
            if (
              ((k = f[a]),
              (z = k.indexLabelTextBlock.measureText()),
              0 !== z.height && 0 !== z.width)
            )
              (z = z = 0),
                "right" === k.hemisphere
                  ? ((z = n.x2 - (k.indexLabelTextBlock.x + k.indexLabelTextBlock.width + q)),
                    (z *= -1))
                  : (z = n.x1 - (k.indexLabelTextBlock.x - k.indexLabelTextBlock.width - q)),
                0 < z &&
                  (!h &&
                    k.indexLabelText &&
                    ((u =
                      "right" === k.hemisphere
                        ? n.x2 - k.indexLabelTextBlock.x
                        : k.indexLabelTextBlock.x - n.x1),
                    0.3 * k.indexLabelTextBlock.maxWidth > u
                      ? (k.indexLabelText = "")
                      : (k.indexLabelTextBlock.maxWidth = 0.85 * u),
                    0.3 * k.indexLabelTextBlock.maxWidth < u &&
                      (k.indexLabelTextBlock.x -= "right" === k.hemisphere ? 2 : -2)),
                  Math.abs(k.indexLabelTextBlock.y - k.indexLabelTextBlock.height / 2 - x.y) < B ||
                    Math.abs(k.indexLabelTextBlock.y + k.indexLabelTextBlock.height / 2 - x.y) <
                      B) &&
                  ((z /= Math.abs(Math.cos(k.indexLabelAngle))),
                  9 < z && (z *= 0.3),
                  z > d && (d = z)),
                (z = z = 0),
                0 < k.indexLabelAngle && k.indexLabelAngle < Math.PI
                  ? ((z = n.y2 - (k.indexLabelTextBlock.y + k.indexLabelTextBlock.height / 2 + 5)),
                    (z *= -1))
                  : (z = n.y1 - (k.indexLabelTextBlock.y - k.indexLabelTextBlock.height / 2 - 5)),
                0 < z &&
                  (!h &&
                    k.indexLabelText &&
                    ((u = 0 < k.indexLabelAngle && k.indexLabelAngle < Math.PI ? -1 : 1),
                    0 === g(a, z * u) && g(a, 2 * u)),
                  Math.abs(k.indexLabelTextBlock.x - x.x) < B &&
                    ((z /= Math.abs(Math.sin(k.indexLabelAngle))),
                    9 < z && (z *= 0.3),
                    z > d && (d = z)));
          var G = function(a, b, c) {
            for (var e = [], d = 0; e.push(f[b]), b !== c; b = (b + 1 + r.length) % r.length);
            e.sort(function(a, b) {
              return a.y - b.y;
            });
            for (b = 0; b < e.length; b++)
              if (((c = e[b]), d < 0.7 * a))
                (d += c.indexLabelTextBlock.height),
                  (c.indexLabelTextBlock.text = ""),
                  (c.indexLabelText = ""),
                  c.indexLabelTextBlock.measureText();
              else break;
          };
          (function() {
            for (var a = -1, b = -1, d = 0, g = !1, k = 0; k < r.length; k++)
              if (((g = !1), (t = f[k]), t.indexLabelText)) {
                var h = e(k);
                if (null !== h) {
                  var m = f[h];
                  y = 0;
                  y = c(t, m);
                  var n;
                  if ((n = 0 > y)) {
                    n = t.indexLabelTextBlock.x;
                    var v = t.indexLabelTextBlock.y - t.indexLabelTextBlock.height / 2,
                      l = t.indexLabelTextBlock.y + t.indexLabelTextBlock.height / 2,
                      p = m.indexLabelTextBlock.y - m.indexLabelTextBlock.height / 2,
                      u = m.indexLabelTextBlock.x + m.indexLabelTextBlock.width,
                      s = m.indexLabelTextBlock.y + m.indexLabelTextBlock.height / 2;
                    n =
                      t.indexLabelTextBlock.x + t.indexLabelTextBlock.width <
                        m.indexLabelTextBlock.x - q ||
                      n > u + q ||
                      v > s + q ||
                      l < p - q
                        ? !1
                        : !0;
                  }
                  n
                    ? (0 > a && (a = k),
                      h !== a && ((b = h), (d += -y)),
                      0 === k % Math.max(r.length / 10, 3) && (g = !0))
                    : (g = !0);
                  g && 0 < d && 0 <= a && 0 <= b && (G(d, a, b), (b = a = -1), (d = 0));
                }
              }
            0 < d && G(d, a, b);
          })();
        }
      }
      function k() {
        v.plotArea.layoutManager.reset();
        v.title &&
          (v.title.dockInsidePlotArea ||
            ("center" === v.title.horizontalAlign && "center" === v.title.verticalAlign)) &&
          v.title.render();
        if (v.subtitles)
          for (var a = 0; a < v.subtitles.length; a++) {
            var b = v.subtitles[a];
            (b.dockInsidePlotArea ||
              ("center" === b.horizontalAlign && "center" === b.verticalAlign)) &&
              b.render();
          }
        v.legend &&
          (v.legend.dockInsidePlotArea ||
            ("center" === v.legend.horizontalAlign && "center" === v.legend.verticalAlign)) &&
          (v.legend.setLayout(), v.legend.render());
      }
      var v = this;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        var m = this.data[a.dataSeriesIndexes[0]],
          r = m.dataPoints,
          q = 10,
          n = this.plotArea,
          f = m.dataPointEOs,
          l = 2,
          p,
          s = 1.3,
          t = (20 / 180) * Math.PI,
          w = 6,
          x = { x: (n.x2 + n.x1) / 2, y: (n.y2 + n.y1) / 2 },
          y = 0;
        a = !1;
        for (var z = 0; z < r.length; z++)
          (y += Math.abs(r[z].y)),
            !a &&
              "undefined" !== typeof r[z].indexLabel &&
                null !== r[z].indexLabel &&
                0 < r[z].indexLabel.toString().length &&
              (a = !0),
            !a &&
              "undefined" !== typeof r[z].label &&
                null !== r[z].label &&
                0 < r[z].label.toString().length &&
              (a = !0);
        if (0 !== y) {
          a =
            a ||
            ("undefined" !== typeof m.indexLabel &&
              null !== m.indexLabel &&
              0 < m.indexLabel.toString().length);
          var B =
            "inside" !== m.indexLabelPlacement && a
              ? (0.75 * Math.min(n.width, n.height)) / 2
              : (0.92 * Math.min(n.width, n.height)) / 2;
          m.radius && (B = G(m.radius, B));
          var C =
            "undefined" !== typeof m.innerRadius && null !== m.innerRadius
              ? G(m.innerRadius, B)
              : 0.7 * B;
          m.radius = B;
          "doughnut" === m.type && (m.innerRadius = C);
          var X = Math.min(C / B, (B - 1) / B);
          this.pieDoughnutClickHandler = function(a) {
            v.isAnimating ||
              (!u(a.dataSeries.explodeOnClick) && !a.dataSeries.explodeOnClick) ||
              ((a = a.dataPoint),
              (a.exploded = a.exploded ? !1 : !0),
              1 < this.dataPoints.length &&
                v._animator.animate(0, 500, function(a) {
                  b(a);
                  k();
                }));
          };
          d();
          h();
          h();
          h();
          h();
          this.disableToolTip = !0;
          this._animator.animate(
            0,
            this.animatedRender ? this.animationDuration : 0,
            function(a) {
              var b = v.plotArea.ctx;
              b.clearRect(n.x1, n.y1, n.width, n.height);
              b.fillStyle = v.backgroundColor;
              b.fillRect(n.x1, n.y1, n.width, n.height);
              a = f[0].startAngle + 2 * Math.PI * a;
              for (b = 0; b < r.length; b++) {
                var c = 0 === b ? f[b].startAngle : e,
                  e = c + (f[b].endAngle - f[b].startAngle),
                  d = !1;
                e > a && ((e = a), (d = !0));
                var g = r[b].color ? r[b].color : m._colorSet[b % m._colorSet.length];
                e > c &&
                  ia(
                    v.plotArea.ctx,
                    f[b].center,
                    f[b].radius,
                    g,
                    m.type,
                    c,
                    e,
                    m.fillOpacity,
                    f[b].percentInnerRadius
                  );
                if (d) break;
              }
              k();
            },
            function() {
              v.disableToolTip = !1;
              v._animator.animate(0, v.animatedRender ? 500 : 0, function(a) {
                b(a);
                k();
              });
            }
          );
        }
      }
    };
    var sa = function(a, d, b, c) {
      "undefined" === typeof b && (b = 1);
      0 >= Math.round(d.y4 - d.y1) ||
        (a.save(),
        (a.globalAlpha = b),
        a.beginPath(),
        a.moveTo(Math.round(d.x1), Math.round(d.y1)),
        a.lineTo(Math.round(d.x2), Math.round(d.y2)),
        a.lineTo(Math.round(d.x3), Math.round(d.y3)),
        a.lineTo(Math.round(d.x4), Math.round(d.y4)),
        "undefined" !== d.x5 &&
          (a.lineTo(Math.round(d.x5), Math.round(d.y5)),
          a.lineTo(Math.round(d.x6), Math.round(d.y6))),
        a.closePath(),
        (a.fillStyle = c ? c : d.color),
        a.fill(),
        (a.globalAplha = 1),
        a.restore());
    };
    p.prototype.renderFunnel = function(a) {
      function d() {
        for (var a = 0, b = [], c = 0; c < D.length; c++) {
          if ("undefined" === typeof D[c].y) return -1;
          D[c].y = "number" === typeof D[c].y ? D[c].y : 0;
          a += Math.abs(D[c].y);
        }
        if (0 === a) return -1;
        for (c = b[0] = 0; c < D.length; c++) b.push((Math.abs(D[c].y) * H) / a);
        return b;
      }
      function b() {
        var a = R,
          b = U,
          c = K,
          e = aa,
          d,
          f;
        d = N;
        f = $ - da;
        e = Math.abs(((f - d) * (b - a + (e - c))) / 2);
        c = aa - K;
        d = f - d;
        f = c * (f - $);
        f = Math.abs(f);
        f = e + f;
        for (var e = [], g = 0, k = 0; k < D.length; k++) {
          if ("undefined" === typeof D[k].y) return -1;
          D[k].y = "number" === typeof D[k].y ? D[k].y : 0;
          g += Math.abs(D[k].y);
        }
        if (0 === g) return -1;
        for (var m = (e[0] = 0), h = 0, n, q, b = b - a, m = !1, k = 0; k < D.length; k++)
          (a = (Math.abs(D[k].y) * f) / g),
            m
              ? (n = 0 == Number(c.toFixed(3)) ? 0 : a / c)
              : ((q = Y * Y * b * b - 4 * Math.abs(Y) * a),
                0 > q
                  ? ((q = c),
                    (m = ((b + q) * (d - h)) / 2),
                    (a -= m),
                    (n = d - h),
                    (h += d - h),
                    (n += 0 == q ? 0 : a / q),
                    (h += a / q),
                    (m = !0))
                  : ((n = (Math.abs(Y) * b - Math.sqrt(q)) / 2),
                    (q = b - (2 * n) / Math.abs(Y)),
                    (h += n),
                    h > d &&
                      ((h -= n),
                      (q = c),
                      (m = ((b + q) * (d - h)) / 2),
                      (a -= m),
                      (n = d - h),
                      (h += d - h),
                      (n += a / q),
                      (h += a / q),
                      (m = !0)),
                    (b = q))),
            e.push(n);
        return e;
      }
      function c() {
        if (t && D) {
          for (
            var a,
              b,
              c,
              e,
              d,
              g,
              k,
              h,
              m,
              n,
              q,
              v,
              l,
              r,
              p = [],
              A = [],
              x = { percent: null, total: null },
              z = null,
              w = 0;
            w < D.length;
            w++
          )
            (r = O[w]),
              (r = "undefined" !== typeof r.x5 ? (r.y2 + r.y4) / 2 : (r.y2 + r.y3) / 2),
              (r = f(r).x2 + 1),
              (p[w] = L - r - S);
          r = 0.5 * S;
          for (var w = 0, B = D.length - 1; w < D.length || 0 <= B; w++, B--) {
            b = t.reversed ? D[B] : D[w];
            a = b.color
              ? b.color
              : t.reversed
              ? t._colorSet[(D.length - 1 - w) % t._colorSet.length]
              : t._colorSet[w % t._colorSet.length];
            c = b.indexLabelPlacement || t.indexLabelPlacement || "outside";
            e =
              b.indexLabelBackgroundColor ||
              t.indexLabelBackgroundColor ||
              (s ? "transparent" : null);
            d = b.indexLabelFontColor || t.indexLabelFontColor || "#979797";
            g = u(b.indexLabelFontSize) ? t.indexLabelFontSize : b.indexLabelFontSize;
            k = b.indexLabelFontStyle || t.indexLabelFontStyle || "normal";
            h = b.indexLabelFontFamily || t.indexLabelFontFamily || "arial";
            m = b.indexLabelFontWeight || t.indexLabelFontWeight || "normal";
            a = b.indexLabelLineColor || t.options.indexLabelLineColor || a;
            n =
              "number" === typeof b.indexLabelLineThickness
                ? b.indexLabelLineThickness
                : "number" === typeof t.indexLabelLineThickness
                ? t.indexLabelLineThickness
                : 2;
            q = b.indexLabelLineDashType || t.indexLabelLineDashType || "solid";
            v =
              "undefined" !== typeof b.indexLabelWrap
                ? b.indexLabelWrap
                : "undefined" !== typeof t.indexLabelWrap
                ? t.indexLabelWrap
                : !0;
            l = t.dataPointIds[w];
            y._eventManager.objectMap[l] = {
              id: l,
              objectType: "dataPoint",
              dataPointIndex: w,
              dataSeriesIndex: 0,
              funnelSection: O[t.reversed ? D.length - 1 - w : w]
            };
            "inside" === t.indexLabelPlacement &&
              ((p[w] =
                w !== ba
                  ? t.reversed
                    ? O[w].x2 - O[w].x1
                    : O[w].x3 - O[w].x4
                  : O[w].x3 - O[w].x6),
              20 > p[w] &&
                ((p[w] =
                  w !== ba
                    ? t.reversed
                      ? O[w].x3 - O[w].x4
                      : O[w].x2 - O[w].x1
                    : O[w].x2 - O[w].x1),
                (p[w] /= 2)));
            l = b.indexLabelMaxWidth
              ? b.indexLabelMaxWidth
              : t.options.indexLabelMaxWidth
              ? t.indexLabelMaxWidth
              : p[w];
            if (l > p[w] || 0 > l) l = p[w];
            A[w] = "inside" === t.indexLabelPlacement ? O[w].height : !1;
            x = y.getPercentAndTotal(t, b);
            if (t.indexLabelFormatter || b.indexLabelFormatter)
              z = {
                chart: y.options,
                dataSeries: t,
                dataPoint: b,
                total: x.total,
                percent: x.percent
              };
            b = b.indexLabelFormatter
              ? b.indexLabelFormatter(z)
              : b.indexLabel
              ? y.replaceKeywordsWithValue(b.indexLabel, b, t, w)
              : t.indexLabelFormatter
              ? t.indexLabelFormatter(z)
              : t.indexLabel
              ? y.replaceKeywordsWithValue(t.indexLabel, b, t, w)
              : b.label
              ? b.label
              : "";
            0 >= n && (n = 0);
            1e3 > l && 1e3 - l < r && (l += 1e3 - l);
            P.roundRect || Da(P);
            c = new ja(P, {
              fontSize: g,
              fontFamily: h,
              fontColor: d,
              fontStyle: k,
              fontWeight: m,
              horizontalAlign: c,
              backgroundColor: e,
              maxWidth: l,
              maxHeight: !1 === A[w] ? (v ? 4.28571429 * g : 1.5 * g) : A[w],
              text: b,
              padding: fa
            });
            c.measureText();
            J.push({
              textBlock: c,
              id: t.reversed ? B : w,
              isDirty: !1,
              lineColor: a,
              lineThickness: n,
              lineDashType: q,
              height: c.height < c.maxHeight ? c.height : c.maxHeight,
              width: c.width < c.maxWidth ? c.width : c.maxWidth
            });
          }
        }
      }
      function e() {
        var a,
          b,
          c,
          e,
          d,
          f = [];
        d = !1;
        c = 0;
        for (
          var g,
            k = L - U - S / 2,
            k = t.options.indexLabelMaxWidth
              ? t.indexLabelMaxWidth > k
                ? k
                : t.indexLabelMaxWidth
              : k,
            h = J.length - 1;
          0 <= h;
          h--
        ) {
          g = D[J[h].id];
          c = J[h];
          e = c.textBlock;
          b = (a = n(h) < O.length ? J[n(h)] : null) ? a.textBlock : null;
          c = c.height;
          a && e.y + c + fa > b.y && (d = !0);
          c = g.indexLabelMaxWidth || k;
          if (c > k || 0 > c) c = k;
          f.push(c);
        }
        if (d)
          for (h = J.length - 1; 0 <= h; h--)
            (a = O[h]),
              (J[h].textBlock.maxWidth = f[f.length - (h + 1)]),
              J[h].textBlock.measureText(),
              (J[h].textBlock.x = L - k),
              (c =
                J[h].textBlock.height < J[h].textBlock.maxHeight
                  ? J[h].textBlock.height
                  : J[h].textBlock.maxHeight),
              (d =
                J[h].textBlock.width < J[h].textBlock.maxWidth
                  ? J[h].textBlock.width
                  : J[h].textBlock.maxWidth),
              (J[h].height = c),
              (J[h].width = d),
              (c = "undefined" !== typeof a.x5 ? (a.y2 + a.y4) / 2 : (a.y2 + a.y3) / 2),
              (J[h].textBlock.y = c - J[h].height / 2),
              t.reversed
                ? (J[h].textBlock.y + J[h].height > oa + C &&
                    (J[h].textBlock.y = oa + C - J[h].height),
                  J[h].textBlock.y < ta - C && (J[h].textBlock.y = ta - C))
                : (J[h].textBlock.y < oa - C && (J[h].textBlock.y = oa - C),
                  J[h].textBlock.y + J[h].height > ta + C &&
                    (J[h].textBlock.y = ta + C - J[h].height));
      }
      function g() {
        var a, b, c, e;
        if ("inside" !== t.indexLabelPlacement)
          for (var d = 0; d < O.length; d++)
            0 == J[d].textBlock.text.length
              ? (J[d].isDirty = !0)
              : ((a = O[d]),
                (c = "undefined" !== typeof a.x5 ? (a.y2 + a.y4) / 2 : (a.y2 + a.y3) / 2),
                (b = t.reversed
                  ? "undefined" !== typeof a.x5
                    ? c > Ca
                      ? f(c).x2 + 1
                      : (a.x2 + a.x3) / 2 + 1
                    : (a.x2 + a.x3) / 2 + 1
                  : "undefined" !== typeof a.x5
                  ? c < Ca
                    ? f(c).x2 + 1
                    : (a.x4 + a.x3) / 2 + 1
                  : (a.x2 + a.x3) / 2 + 1),
                (J[d].textBlock.x = b + S),
                (J[d].textBlock.y = c - J[d].height / 2),
                t.reversed
                  ? (J[d].textBlock.y + J[d].height > oa + C &&
                      (J[d].textBlock.y = oa + C - J[d].height),
                    J[d].textBlock.y < ta - C && (J[d].textBlock.y = ta - C))
                  : (J[d].textBlock.y < oa - C && (J[d].textBlock.y = oa - C),
                    J[d].textBlock.y + J[d].height > ta + C &&
                      (J[d].textBlock.y = ta + C - J[d].height)));
        else
          for (d = 0; d < O.length; d++)
            0 == J[d].textBlock.text.length
              ? (J[d].isDirty = !0)
              : ((a = O[d]),
                (b = a.height),
                (c = J[d].height),
                (e = J[d].width),
                b >= c
                  ? ((b = d != ba ? (a.x4 + a.x3) / 2 - e / 2 : (a.x5 + a.x4) / 2 - e / 2),
                    (c = d != ba ? (a.y1 + a.y3) / 2 - c / 2 : (a.y1 + a.y4) / 2 - c / 2),
                    (J[d].textBlock.x = b),
                    (J[d].textBlock.y = c))
                  : (J[d].isDirty = !0));
      }
      function h() {
        function a(b, c) {
          var d;
          if (0 > b || b >= J.length) return 0;
          var e,
            f = J[b].textBlock;
          if (0 > c) {
            c *= -1;
            e = q(b);
            d = k(e, b);
            if (d >= c) return (f.y -= c), c;
            if (0 == b) return 0 < d && (f.y -= d), d;
            d += a(e, -(c - d));
            0 < d && (f.y -= d);
            return d;
          }
          e = n(b);
          d = k(b, e);
          if (d >= c) return (f.y += c), c;
          if (b == O.length - 1) return 0 < d && (f.y += d), d;
          d += a(e, c - d);
          0 < d && (f.y += d);
          return d;
        }
        function b() {
          var a,
            d,
            e,
            f,
            g = 0,
            h;
          f = ($ - N + 2 * C) / v;
          h = v;
          for (var k, m = 1; m < h; m++) {
            e = m * f;
            for (var l = J.length - 1; 0 <= l; l--)
              !J[l].isDirty &&
                J[l].textBlock.y < e && J[l].textBlock.y + J[l].height > e &&
                ((k = n(l)),
                !(k >= J.length - 1) &&
                  J[l].textBlock.y + J[l].height + fa > J[k].textBlock.y &&
                  (J[l].textBlock.y =
                    J[l].textBlock.y + J[l].height - e > e - J[l].textBlock.y
                      ? e + 1
                      : e - J[l].height - 1));
          }
          for (k = O.length - 1; 0 < k; k--)
            if (!J[k].isDirty) {
              e = q(k);
              if (0 > e && ((e = 0), J[e].isDirty)) break;
              if (J[k].textBlock.y < J[e].textBlock.y + J[e].height) {
                d = d || k;
                f = k;
                for (h = 0; J[f].textBlock.y < J[e].textBlock.y + J[e].height + fa; ) {
                  a = a || J[f].textBlock.y + J[f].height;
                  h += J[f].height;
                  h += fa;
                  f = e;
                  if (0 >= f) {
                    f = 0;
                    h += J[f].height;
                    break;
                  }
                  e = q(f);
                  if (0 > e) {
                    f = 0;
                    h += J[f].height;
                    break;
                  }
                }
                if (f != k) {
                  g = J[f].textBlock.y;
                  a -= g;
                  a = h - a;
                  g = c(a, d, f);
                  break;
                }
              }
            }
          return g;
        }
        function c(a, b, d) {
          var e = [],
            f = 0,
            g = 0;
          for (a = Math.abs(a); d <= b; d++) e.push(O[d]);
          e.sort(function(a, b) {
            return a.height - b.height;
          });
          for (d = 0; d < e.length; d++)
            if (((b = e[d]), f < a))
              g++,
                (f += J[b.id].height + fa),
                (J[b.id].textBlock.text = ""),
                (J[b.id].indexLabelText = ""),
                (J[b.id].isDirty = !0),
                J[b.id].textBlock.measureText();
            else break;
          return g;
        }
        for (var d, e, f, g, h, m, v = 1, l = 0; l < 2 * v; l++) {
          for (
            var r = J.length - 1;
            0 <= r &&
            !(0 <= q(r) && q(r),
            (f = J[r]),
            (g = f.textBlock),
            (m = (h = n(r) < O.length ? J[n(r)] : null) ? h.textBlock : null),
            (d = +f.height.toFixed(6)),
            (e = +g.y.toFixed(6)),
            !f.isDirty &&
              h && e + d + fa > +m.y.toFixed(6) &&
              ((d = g.y + d + fa - m.y),
              (e = a(r, -d)),
              e < d && (0 < e && (d -= e), (e = a(n(r), d)), e != d)));
            r--
          );
          b();
        }
      }
      function k(a, b) {
        return (
          (b < O.length ? J[b].textBlock.y : t.reversed ? oa + C : ta + C) -
          (0 > a ? (t.reversed ? ta - C : oa - C) : J[a].textBlock.y + J[a].height + fa)
        );
      }
      function v(a, b, c) {
        var d,
          e,
          f,
          h = [],
          k = C,
          n = [];
        -1 !== b &&
          (0 <= W.indexOf(b)
            ? ((e = W.indexOf(b)), W.splice(e, 1))
            : (W.push(b),
              (W = W.sort(function(a, b) {
                return a - b;
              }))));
        if (0 === W.length) h = ia;
        else {
          e = (C * (1 != W.length || (0 != W[0] && W[0] != O.length - 1) ? 2 : 1)) / m();
          for (var q = 0; q < O.length; q++) {
            if (1 == W.length && 0 == W[0]) {
              if (0 === q) {
                h.push(ia[q]);
                d = k;
                continue;
              }
            } else 0 === q && (d = -1 * k);
            h.push(ia[q] + d);
            if (0 <= W.indexOf(q) || (q < O.length && 0 <= W.indexOf(q + 1))) d += e;
          }
        }
        f = (function() {
          for (var a = [], b = 0; b < O.length; b++) a.push(h[b] - O[b].y1);
          return a;
        })();
        var v = {
          startTime: new Date().getTime(),
          duration: c || 500,
          easingFunction: function(a, b, c, d) {
            return M.easing.easeOutQuart(a, b, c, d);
          },
          changeSection: function(a) {
            for (var b, c, d = 0; d < O.length; d++)
              (b = f[d]),
                (c = O[d]),
                (b *= a),
                "undefined" === typeof n[d] && (n[d] = 0),
                0 > n && (n *= -1),
                (c.y1 += b - n[d]),
                (c.y2 += b - n[d]),
                (c.y3 += b - n[d]),
                (c.y4 += b - n[d]),
                c.y5 && ((c.y5 += b - n[d]), (c.y6 += b - n[d])),
                (n[d] = b);
          }
        };
        a._animator.animate(
          0,
          c,
          function(c) {
            var d = a.plotArea.ctx || a.ctx;
            ha = !0;
            d.clearRect(x.x1, x.y1, x.x2 - x.x1, x.y2 - x.y1);
            d.fillStyle = a.backgroundColor;
            d.fillRect(x.x1, x.y1, x.width, x.height);
            v.changeSection(c, b);
            var e = {};
            e.dataSeries = t;
            e.dataPoint = t.reversed ? t.dataPoints[D.length - 1 - b] : t.dataPoints[b];
            e.index = t.reversed ? D.length - 1 - b : b;
            a.toolTip.highlightObjects([e]);
            for (e = 0; e < O.length; e++) sa(d, O[e], t.fillOpacity);
            w(d);
            I && ("inside" !== t.indexLabelPlacement ? l(d) : g(), p(d));
            1 <= c && (ha = !1);
          },
          null,
          M.easing.easeOutQuart
        );
      }
      function m() {
        for (var a = 0, b = 0; b < O.length - 1; b++)
          (0 <= W.indexOf(b) || 0 <= W.indexOf(b + 1)) && a++;
        return a;
      }
      function l(a) {
        for (var b, c, d, e, g = 0; g < O.length; g++)
          (e = 1 === J[g].lineThickness % 2 ? 0.5 : 0),
            (c = (((O[g].y2 + O[g].y4) / 2) << 0) + e),
            (b = f(c).x2 - 1),
            (d = J[g].textBlock.x),
            (e = ((J[g].textBlock.y + J[g].height / 2) << 0) + e),
            J[g].isDirty ||
              0 == J[g].lineThickness ||
              ((a.strokeStyle = J[g].lineColor),
              (a.lineWidth = J[g].lineThickness),
              a.setLineDash && a.setLineDash(Q(J[g].lineDashType, J[g].lineThickness)),
              a.beginPath(),
              a.moveTo(b, c),
              a.lineTo(d, e),
              a.stroke());
      }
      function q(a) {
        for (a -= 1; -1 <= a && -1 != a && J[a].isDirty; a--);
        return a;
      }
      function n(a) {
        for (a += 1; a <= O.length && a != O.length && J[a].isDirty; a++);
        return a;
      }
      function f(a) {
        for (var b, c = 0; c < D.length; c++)
          if (O[c].y1 < a && O[c].y4 > a) {
            b = O[c];
            break;
          }
        return b
          ? ((a = b.y6
              ? a > b.y6
                ? b.x3 + ((b.x4 - b.x3) / (b.y4 - b.y3)) * (a - b.y3)
                : b.x2 + ((b.x3 - b.x2) / (b.y3 - b.y2)) * (a - b.y2)
              : b.x2 + ((b.x3 - b.x2) / (b.y3 - b.y2)) * (a - b.y2)),
            { x1: a, x2: a })
          : -1;
      }
      function p(a) {
        for (var b = 0; b < O.length; b++)
          J[b].isDirty || (a && (J[b].textBlock.ctx = a), J[b].textBlock.render(!0));
      }
      function w(a) {
        y.plotArea.layoutManager.reset();
        a.roundRect || Da(a);
        y.title &&
          (y.title.dockInsidePlotArea ||
            ("center" === y.title.horizontalAlign && "center" === y.title.verticalAlign)) &&
          ((y.title.ctx = a), y.title.render());
        if (y.subtitles)
          for (var b = 0; b < y.subtitles.length; b++) {
            var c = y.subtitles[b];
            if (
              c.dockInsidePlotArea ||
              ("center" === c.horizontalAlign && "center" === c.verticalAlign)
            )
              (y.subtitles.ctx = a), c.render();
          }
        y.legend &&
          (y.legend.dockInsidePlotArea ||
            ("center" === y.legend.horizontalAlign && "center" === y.legend.verticalAlign)) &&
          ((y.legend.ctx = a), y.legend.setLayout(), y.legend.render());
        Ia.fNg && Ia.fNg(y);
      }
      var y = this;
      if (!(0 >= a.dataSeriesIndexes.length)) {
        for (
          var t = this.data[a.dataSeriesIndexes[0]],
            D = t.dataPoints,
            x = this.plotArea,
            C = 0.025 * x.width,
            z = 0.01 * x.width,
            B = 0,
            H = x.height - 2 * C,
            E = Math.min(x.width - 2 * z, 2.8 * x.height),
            I = !1,
            G = 0;
          G < D.length;
          G++
        )
          if (
            (!I &&
              "undefined" !== typeof D[G].indexLabel &&
                null !== D[G].indexLabel &&
                0 < D[G].indexLabel.toString().length &&
              (I = !0),
            !I &&
              "undefined" !== typeof D[G].label &&
                null !== D[G].label &&
                0 < D[G].label.toString().length &&
              (I = !0),
            (!I && "function" === typeof t.indexLabelFormatter) ||
              "function" === typeof D[G].indexLabelFormatter)
          )
            I = !0;
        I =
          I ||
          ("undefined" !== typeof t.indexLabel &&
            null !== t.indexLabel &&
            0 < t.indexLabel.toString().length);
        ("inside" !== t.indexLabelPlacement && I) || (z = (x.width - 0.75 * E) / 2);
        var G = x.x1 + z,
          L = x.x2 - z,
          N = x.y1 + C,
          $ = x.y2 - C,
          P = a.targetCanvasCtx || this.plotArea.ctx || this.ctx;
        if (0 != t.length && t.dataPoints && t.visible && 0 !== D.length) {
          var da, F;
          a = (75 * E) / 100;
          var S = (30 * (L - a)) / 100;
          "funnel" === t.type
            ? ((da = u(t.options.neckHeight) ? 0.35 * H : t.neckHeight),
              (F = u(t.options.neckWidth) ? 0.25 * a : t.neckWidth),
              "string" === typeof da && da.match(/%$/)
                ? ((da = parseInt(da)), (da = (da * H) / 100))
                : (da = parseInt(da)),
              "string" === typeof F && F.match(/%$/)
                ? ((F = parseInt(F)), (F = (F * a) / 100))
                : (F = parseInt(F)),
              da > H ? (da = H) : 0 >= da && (da = 0),
              F > a ? (F = a - 0.5) : 0 >= F && (F = 0))
            : "pyramid" === t.type && ((F = da = 0), (t.reversed = t.reversed ? !1 : !0));
          var z = G + a / 2,
            R = G,
            U = G + a,
            oa = t.reversed ? $ : N,
            K = z - F / 2,
            aa = z + F / 2,
            Ca = t.reversed ? N + da : $ - da,
            ta = t.reversed ? N : $;
          a = [];
          var z = [],
            O = [],
            E = [],
            V = N,
            ba,
            Y = (Ca - oa) / (K - R),
            ga = -Y,
            G = "area" === (t.valueRepresents ? t.valueRepresents : "height") ? b() : d();
          if (-1 !== G) {
            if (t.reversed) for (E.push(V), F = G.length - 1; 0 < F; F--) (V += G[F]), E.push(V);
            else for (F = 0; F < G.length; F++) (V += G[F]), E.push(V);
            if (t.reversed)
              for (F = 0; F < G.length; F++)
                E[F] < Ca
                  ? (a.push(K), z.push(aa), (ba = F))
                  : (a.push((E[F] - oa + Y * R) / Y), z.push((E[F] - oa + ga * U) / ga));
            else
              for (F = 0; F < G.length; F++)
                E[F] < Ca
                  ? (a.push((E[F] - oa + Y * R) / Y), z.push((E[F] - oa + ga * U) / ga), (ba = F))
                  : (a.push(K), z.push(aa));
            for (F = 0; F < G.length - 1; F++)
              (V = t.reversed
                ? D[D.length - 1 - F].color
                  ? D[D.length - 1 - F].color
                  : t._colorSet[(D.length - 1 - F) % t._colorSet.length]
                : D[F].color
                ? D[F].color
                : t._colorSet[F % t._colorSet.length]),
                F === ba
                  ? O.push({
                      x1: a[F],
                      y1: E[F],
                      x2: z[F],
                      y2: E[F],
                      x3: aa,
                      y3: Ca,
                      x4: z[F + 1],
                      y4: E[F + 1],
                      x5: a[F + 1],
                      y5: E[F + 1],
                      x6: K,
                      y6: Ca,
                      id: F,
                      height: E[F + 1] - E[F],
                      color: V
                    })
                  : O.push({
                      x1: a[F],
                      y1: E[F],
                      x2: z[F],
                      y2: E[F],
                      x3: z[F + 1],
                      y3: E[F + 1],
                      x4: a[F + 1],
                      y4: E[F + 1],
                      id: F,
                      height: E[F + 1] - E[F],
                      color: V
                    });
            var fa = 2,
              J = [],
              ha = !1,
              W = [],
              ia = [],
              G = !1;
            a = a = 0;
            Ea(W);
            for (F = 0; F < D.length; F++)
              D[F].exploded && ((G = !0), t.reversed ? W.push(D.length - 1 - F) : W.push(F));
            P.clearRect(x.x1, x.y1, x.width, x.height);
            P.fillStyle = y.backgroundColor;
            P.fillRect(x.x1, x.y1, x.width, x.height);
            if (I && t.visible && (c(), g(), e(), "inside" !== t.indexLabelPlacement)) {
              h();
              for (F = 0; F < D.length; F++)
                J[F].isDirty ||
                  ((a = J[F].textBlock.x + J[F].width),
                  (a = (L - a) / 2),
                  0 == F && (B = a),
                  B > a && (B = a));
              for (F = 0; F < O.length; F++)
                (O[F].x1 += B),
                  (O[F].x2 += B),
                  (O[F].x3 += B),
                  (O[F].x4 += B),
                  O[F].x5 && ((O[F].x5 += B), (O[F].x6 += B)),
                  (J[F].textBlock.x += B);
            }
            for (F = 0; F < O.length; F++) (B = O[F]), sa(P, B, t.fillOpacity), ia.push(B.y1);
            w(P);
            I &&
              t.visible &&
              ("inside" === t.indexLabelPlacement || y.animationEnabled || l(P),
              y.animationEnabled || p());
            if (!I)
              for (F = 0; F < D.length; F++)
                (B = t.dataPointIds[F]),
                  (a = {
                    id: B,
                    objectType: "dataPoint",
                    dataPointIndex: F,
                    dataSeriesIndex: 0,
                    funnelSection: O[t.reversed ? D.length - 1 - F : F]
                  }),
                  (y._eventManager.objectMap[B] = a);
            !y.animationEnabled && G
              ? v(y, -1, 0)
              : y.animationEnabled && !y.animatedRender && v(y, -1, 0);
            this.funnelPyramidClickHandler = function(a) {
              var b = -1;
              if (
                !ha &&
                !y.isAnimating &&
                (u(a.dataSeries.explodeOnClick) || a.dataSeries.explodeOnClick) &&
                ((b = t.reversed ? D.length - 1 - a.dataPointIndex : a.dataPointIndex), 0 <= b)
              ) {
                a = b;
                if ("funnel" === t.type || "pyramid" === t.type)
                  t.reversed
                    ? (D[D.length - 1 - a].exploded = D[D.length - 1 - a].exploded ? !1 : !0)
                    : (D[a].exploded = D[a].exploded ? !1 : !0);
                v(y, b, 500);
              }
            };
            return {
              source: P,
              dest: this.plotArea.ctx,
              animationCallback: function(a, b) {
                M.fadeInAnimation(a, b);
                1 <= a && (v(y, -1, 500), w(y.plotArea.ctx || y.ctx));
              },
              easingFunction: M.easing.easeInQuad,
              animationBase: 0
            };
          }
        }
      }
    };
    p.prototype.requestAnimFrame = (function() {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(a) {
          window.setTimeout(a, 1e3 / 60);
        }
      );
    })();
    p.prototype.cancelRequestAnimFrame =
      window.cancelAnimationFrame ||
      window.webkitCancelRequestAnimationFrame ||
      window.mozCancelRequestAnimationFrame ||
      window.oCancelRequestAnimationFrame ||
      window.msCancelRequestAnimationFrame ||
      clearTimeout;
    p.prototype.set = function(a, d, b) {
      b = "undefined" === typeof b ? !0 : b;
      "options" === a ? ((this.options = d), b && this.render()) : p.base.set.call(this, a, d, b);
    };
    p.prototype.exportChart = function(a) {
      a = "undefined" === typeof a ? {} : a;
      var d = a.format ? a.format : "png",
        b = a.fileName ? a.fileName : this.exportFileName;
      if (a.toDataURL) return this.canvas.toDataURL("image/" + d);
      Ta(this.canvas, d, b);
    };
    p.prototype.print = function() {
      var a = this.exportChart({ toDataURL: !0 }),
        d = document.createElement("iframe");
      d.setAttribute("class", "canvasjs-chart-print-frame");
      d.setAttribute(
        "style",
        "position:absolute; width:100%; border: 0px; margin: 0px 0px 0px 0px; padding 0px 0px 0px 0px;"
      );
      d.style.height = this.height + "px";
      this._canvasJSContainer.appendChild(d);
      var b = this,
        c = d.contentWindow || d.contentDocument.document || d.contentDocument;
      c.document.open();
      c.document.write(
        '<!DOCTYPE HTML>\n<html><body style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;"><img src="' +
          a +
          '"/><body/></html>'
      );
      c.document.close();
      setTimeout(function() {
        c.focus();
        c.print();
        setTimeout(function() {
          b._canvasJSContainer.removeChild(d);
        }, 1e3);
      }, 500);
    };
    p.prototype.getPercentAndTotal = function(a, d) {
      var b = null,
        c = null,
        e = null;
      if (0 <= a.type.indexOf("stacked"))
        (c = 0),
          (b = d.x.getTime ? d.x.getTime() : d.x),
          b in a.plotUnit.yTotals &&
            ((c = a.plotUnit.yTotals[b]), (e = isNaN(d.y) ? 0 : 100 * (d.y / c)));
      else if (
        "pie" === a.type ||
        "doughnut" === a.type ||
        "funnel" === a.type ||
        "pyramid" === a.type
      ) {
        for (b = c = 0; b < a.dataPoints.length; b++)
          isNaN(a.dataPoints[b].y) || (c += a.dataPoints[b].y);
        e = isNaN(d.y) ? 0 : 100 * (d.y / c);
      }
      return { percent: e, total: c };
    };
    p.prototype.replaceKeywordsWithValue = function(a, d, b, c, e) {
      var g = this;
      e = "undefined" === typeof e ? 0 : e;
      if (
        (0 <= b.type.indexOf("stacked") ||
          "pie" === b.type ||
          "doughnut" === b.type ||
          "funnel" === b.type ||
          "pyramid" === b.type) &&
        (0 <= a.indexOf("#percent") || 0 <= a.indexOf("#total"))
      ) {
        var h = "#percent",
          k = "#total",
          v = this.getPercentAndTotal(b, d),
          k = isNaN(v.total) ? k : v.total,
          h = isNaN(v.percent) ? h : v.percent;
        do {
          v = "";
          if (b.percentFormatString) v = b.percentFormatString;
          else {
            var v = "#,##0.",
              m = Math.max(Math.ceil(Math.log(1 / Math.abs(h)) / Math.LN10), 2);
            if (isNaN(m) || !isFinite(m)) m = 2;
            for (var l = 0; l < m; l++) v += "#";
            b.percentFormatString = v;
          }
          a = a.replace("#percent", fa(h, v, g._cultureInfo));
          a = a.replace(
            "#total",
            fa(k, b.yValueFormatString ? b.yValueFormatString : "#,##0.########", g._cultureInfo)
          );
        } while (0 <= a.indexOf("#percent") || 0 <= a.indexOf("#total"));
      }
      return a.replace(/\{.*?\}|"[^"]*"|'[^']*'/g, function(a) {
        if (('"' === a[0] && '"' === a[a.length - 1]) || ("'" === a[0] && "'" === a[a.length - 1]))
          return a.slice(1, a.length - 1);
        a = Ha(a.slice(1, a.length - 1));
        a = a.replace("#index", e);
        var h = null;
        try {
          var f = a.match(/(.*?)\s*\[\s*(.*?)\s*\]/);
          f && 0 < f.length && ((h = Ha(f[2])), (a = Ha(f[1])));
        } catch (k) {}
        f = null;
        if ("color" === a)
          return "waterfall" === b.type
            ? d.color
              ? d.color
              : 0 < d.y
              ? b.risingColor
              : b.fallingColor
            : "error" === b.type
            ? b.color
              ? b.color
              : b._colorSet[h % b._colorSet.length]
            : d.color
            ? d.color
            : b.color
            ? b.color
            : b._colorSet[c % b._colorSet.length];
        if (d.hasOwnProperty(a)) f = d;
        else if (b.hasOwnProperty(a)) f = b;
        else return "";
        f = f[a];
        null !== h && (f = f[h]);
        if ("x" === a)
          if (
            "dateTime" === b.axisX.valueType ||
            "dateTime" === b.xValueType ||
            (d.x && d.x.getTime)
          ) {
            if (
              g.plotInfo.plotTypes[0].plotUnits[0].axisX &&
              !g.plotInfo.plotTypes[0].plotUnits[0].axisX.logarithmic
            )
              return Ba(
                f,
                d.xValueFormatString
                  ? d.xValueFormatString
                  : b.xValueFormatString
                  ? b.xValueFormatString
                  : (b.xValueFormatString =
                      g.axisX && g.axisX.autoValueFormatString
                        ? g.axisX.autoValueFormatString
                        : "DD MMM YY"),
                g._cultureInfo
              );
          } else
            return fa(
              f,
              d.xValueFormatString
                ? d.xValueFormatString
                : b.xValueFormatString
                ? b.xValueFormatString
                : (b.xValueFormatString = "#,##0.########"),
              g._cultureInfo
            );
        else
          return "y" === a
            ? fa(
                f,
                d.yValueFormatString
                  ? d.yValueFormatString
                  : b.yValueFormatString
                  ? b.yValueFormatString
                  : (b.yValueFormatString = "#,##0.########"),
                g._cultureInfo
              )
            : "z" === a
            ? fa(
                f,
                d.zValueFormatString
                  ? d.zValueFormatString
                  : b.zValueFormatString
                  ? b.zValueFormatString
                  : (b.zValueFormatString = "#,##0.########"),
                g._cultureInfo
              )
            : f;
      });
    };
    pa(E, U);
    E.prototype.setLayout = function() {
      var a = this.dockInsidePlotArea ? this.chart.plotArea : this.chart,
        d = a.layoutManager.getFreeSpace(),
        b = null,
        c = 0,
        e = 0,
        g = 0,
        h = 0,
        k = (this.markerMargin =
          this.chart.options.legend && !u(this.chart.options.legend.markerMargin)
            ? this.chart.options.legend.markerMargin
            : 0.3 * this.fontSize);
      this.height = 0;
      var l = [],
        m = [];
      "top" === this.verticalAlign || "bottom" === this.verticalAlign
        ? ((this.orientation = "horizontal"),
          (b = this.verticalAlign),
          (g = this.maxWidth = null !== this.maxWidth ? this.maxWidth : d.width),
          (h = this.maxHeight = null !== this.maxHeight ? this.maxHeight : 0.5 * d.height))
        : "center" === this.verticalAlign &&
          ((this.orientation = "vertical"),
          (b = this.horizontalAlign),
          (g = this.maxWidth = null !== this.maxWidth ? this.maxWidth : 0.5 * d.width),
          (h = this.maxHeight = null !== this.maxHeight ? this.maxHeight : d.height));
      this.errorMarkerColor = [];
      for (var r = 0; r < this.dataSeries.length; r++) {
        var q = this.dataSeries[r];
        if (q.dataPoints && q.dataPoints.length)
          if (
            "pie" !== q.type &&
            "doughnut" !== q.type &&
            "funnel" !== q.type &&
            "pyramid" !== q.type
          ) {
            var n = (q.legendMarkerType = q.legendMarkerType
                ? q.legendMarkerType
                : ("line" !== q.type &&
                    "stepLine" !== q.type &&
                    "spline" !== q.type &&
                    "scatter" !== q.type &&
                    "bubble" !== q.type) ||
                  !q.markerType
                ? "error" === q.type && q._linkedSeries
                  ? q._linkedSeries.legendMarkerType
                    ? q._linkedSeries.legendMarkerType
                    : C.getDefaultLegendMarker(q._linkedSeries.type)
                  : C.getDefaultLegendMarker(q.type)
                : q.markerType),
              f = q.legendText
                ? q.legendText
                : this.itemTextFormatter
                ? this.itemTextFormatter({
                    chart: this.chart,
                    legend: this.options,
                    dataSeries: q,
                    dataPoint: null
                  })
                : q.name,
              p = (q.legendMarkerColor = q.legendMarkerColor
                ? q.legendMarkerColor
                : q.markerColor
                ? q.markerColor
                : "error" === q.type
                ? u(q.whiskerColor)
                  ? q._colorSet[0]
                  : q.whiskerColor
                : q._colorSet[0]),
              s =
                q.markerSize || ("line" !== q.type && "stepLine" !== q.type && "spline" !== q.type)
                  ? 0.75 * this.lineHeight
                  : 0,
              w = q.legendMarkerBorderColor ? q.legendMarkerBorderColor : q.markerBorderColor,
              t = q.legendMarkerBorderThickness
                ? q.legendMarkerBorderThickness
                : q.markerBorderThickness
                ? Math.max(1, Math.round(0.2 * s))
                : 0;
            "error" === q.type && this.errorMarkerColor.push(p);
            f = this.chart.replaceKeywordsWithValue(f, q.dataPoints[0], q, r);
            n = {
              markerType: n,
              markerColor: p,
              text: f,
              textBlock: null,
              chartType: q.type,
              markerSize: s,
              lineColor: q._colorSet[0],
              dataSeriesIndex: q.index,
              dataPointIndex: null,
              markerBorderColor: w,
              markerBorderThickness: t
            };
            l.push(n);
          } else
            for (var y = 0; y < q.dataPoints.length; y++) {
              var x = q.dataPoints[y],
                n = x.legendMarkerType
                  ? x.legendMarkerType
                  : q.legendMarkerType
                  ? q.legendMarkerType
                  : C.getDefaultLegendMarker(q.type),
                f = x.legendText
                  ? x.legendText
                  : q.legendText
                  ? q.legendText
                  : this.itemTextFormatter
                  ? this.itemTextFormatter({
                      chart: this.chart,
                      legend: this.options,
                      dataSeries: q,
                      dataPoint: x
                    })
                  : x.name
                  ? x.name
                  : "DataPoint: " + (y + 1),
                p = x.legendMarkerColor
                  ? x.legendMarkerColor
                  : q.legendMarkerColor
                  ? q.legendMarkerColor
                  : x.color
                  ? x.color
                  : q.color
                  ? q.color
                  : q._colorSet[y % q._colorSet.length],
                s = 0.75 * this.lineHeight,
                w = x.legendMarkerBorderColor
                  ? x.legendMarkerBorderColor
                  : q.legendMarkerBorderColor
                  ? q.legendMarkerBorderColor
                  : x.markerBorderColor
                  ? x.markerBorderColor
                  : q.markerBorderColor,
                t = x.legendMarkerBorderThickness
                  ? x.legendMarkerBorderThickness
                  : q.legendMarkerBorderThickness
                  ? q.legendMarkerBorderThickness
                  : x.markerBorderThickness || q.markerBorderThickness
                  ? Math.max(1, Math.round(0.2 * s))
                  : 0,
                f = this.chart.replaceKeywordsWithValue(f, x, q, y),
                n = {
                  markerType: n,
                  markerColor: p,
                  text: f,
                  textBlock: null,
                  chartType: q.type,
                  markerSize: s,
                  dataSeriesIndex: r,
                  dataPointIndex: y,
                  markerBorderColor: w,
                  markerBorderThickness: t
                };
              (x.showInLegend || (q.showInLegend && !1 !== x.showInLegend)) && l.push(n);
            }
      }
      !0 === this.reversed && l.reverse();
      if (0 < l.length) {
        q = null;
        p = f = x = y = 0;
        x =
          null !== this.itemWidth
            ? null !== this.itemMaxWidth
              ? Math.min(this.itemWidth, this.itemMaxWidth, g)
              : (this.itemMaxWidth = Math.min(this.itemWidth, g))
            : null !== this.itemMaxWidth
            ? Math.min(this.itemMaxWidth, g)
            : (this.itemMaxWidth = g);
        s = 0 === s ? 0.75 * this.lineHeight : s;
        x -= s + k;
        for (r = 0; r < l.length; r++) {
          n = l[r];
          w = x;
          if ("line" === n.chartType || "spline" === n.chartType || "stepLine" === n.chartType)
            w -= 2 * 0.1 * this.lineHeight;
          if (!(0 >= h || "undefined" === typeof h || 0 >= w || "undefined" === typeof w)) {
            if ("horizontal" === this.orientation) {
              n.textBlock = new ja(this.ctx, {
                x: 0,
                y: 0,
                maxWidth: w,
                maxHeight: this.itemWrap ? h : this.lineHeight,
                angle: 0,
                text: n.text,
                horizontalAlign: "left",
                fontSize: this.fontSize,
                fontFamily: this.fontFamily,
                fontWeight: this.fontWeight,
                fontColor: this.fontColor,
                fontStyle: this.fontStyle,
                textBaseline: "middle"
              });
              n.textBlock.measureText();
              null !== this.itemWidth &&
                (n.textBlock.width =
                  this.itemWidth -
                  (s +
                    k +
                    ("line" === n.chartType ||
                    "spline" === n.chartType ||
                    "stepLine" === n.chartType
                      ? 2 * 0.1 * this.lineHeight
                      : 0)));
              if (
                !q ||
                q.width +
                  Math.round(
                    n.textBlock.width +
                      s +
                      k +
                      (0 === q.width ? 0 : this.horizontalSpacing) +
                      ("line" === n.chartType ||
                      "spline" === n.chartType ||
                      "stepLine" === n.chartType
                        ? 2 * 0.1 * this.lineHeight
                        : 0)
                  ) >
                  g
              )
                (q = { items: [], width: 0 }), m.push(q), (this.height += f), (f = 0);
              f = Math.max(f, n.textBlock.height);
            } else
              (n.textBlock = new ja(this.ctx, {
                x: 0,
                y: 0,
                maxWidth: x,
                maxHeight: !0 === this.itemWrap ? h : 1.5 * this.fontSize,
                angle: 0,
                text: n.text,
                horizontalAlign: "left",
                fontSize: this.fontSize,
                fontFamily: this.fontFamily,
                fontWeight: this.fontWeight,
                fontColor: this.fontColor,
                fontStyle: this.fontStyle,
                textBaseline: "middle"
              })),
                n.textBlock.measureText(),
                null !== this.itemWidth &&
                  (n.textBlock.width =
                    this.itemWidth -
                    (s +
                      k +
                      ("line" === n.chartType ||
                      "spline" === n.chartType ||
                      "stepLine" === n.chartType
                        ? 2 * 0.1 * this.lineHeight
                        : 0))),
                this.height < h - this.lineHeight
                  ? ((q = { items: [], width: 0 }), m.push(q))
                  : ((q = m[y]), (y = (y + 1) % m.length)),
                (this.height += n.textBlock.height);
            n.textBlock.x = q.width;
            n.textBlock.y = 0;
            q.width += Math.round(
              n.textBlock.width +
                s +
                k +
                (0 === q.width ? 0 : this.horizontalSpacing) +
                ("line" === n.chartType || "spline" === n.chartType || "stepLine" === n.chartType
                  ? 2 * 0.1 * this.lineHeight
                  : 0)
            );
            q.items.push(n);
            this.width = Math.max(q.width, this.width);
            p =
              n.textBlock.width +
              (s +
                k +
                ("line" === n.chartType || "spline" === n.chartType || "stepLine" === n.chartType
                  ? 2 * 0.1 * this.lineHeight
                  : 0));
          }
        }
        this.itemWidth = p;
        this.height = !1 === this.itemWrap ? m.length * this.lineHeight : this.height + f;
        this.height = Math.min(h, this.height);
        this.width = Math.min(g, this.width);
      }
      "top" === this.verticalAlign
        ? ((e =
            "left" === this.horizontalAlign
              ? d.x1
              : "right" === this.horizontalAlign
              ? d.x2 - this.width
              : d.x1 + d.width / 2 - this.width / 2),
          (c = d.y1))
        : "center" === this.verticalAlign
        ? ((e =
            "left" === this.horizontalAlign
              ? d.x1
              : "right" === this.horizontalAlign
              ? d.x2 - this.width
              : d.x1 + d.width / 2 - this.width / 2),
          (c = d.y1 + d.height / 2 - this.height / 2))
        : "bottom" === this.verticalAlign &&
          ((e =
            "left" === this.horizontalAlign
              ? d.x1
              : "right" === this.horizontalAlign
              ? d.x2 - this.width
              : d.x1 + d.width / 2 - this.width / 2),
          (c = d.y2 - this.height));
      this.items = l;
      for (r = 0; r < this.items.length; r++)
        (n = l[r]),
          (n.id = ++this.chart._eventManager.lastObjectId),
          (this.chart._eventManager.objectMap[n.id] = {
            id: n.id,
            objectType: "legendItem",
            legendItemIndex: r,
            dataSeriesIndex: n.dataSeriesIndex,
            dataPointIndex: n.dataPointIndex
          });
      this.markerSize = s;
      this.rows = m;
      0 < l.length &&
        a.layoutManager.registerSpace(b, {
          width: this.width + 2 + 2,
          height: this.height + 5 + 5
        });
      this.bounds = { x1: e, y1: c, x2: e + this.width, y2: c + this.height };
    };
    E.prototype.render = function() {
      var a = this.bounds.x1,
        d = this.bounds.y1,
        b = this.markerMargin,
        c = this.maxWidth,
        e = this.maxHeight,
        g = this.markerSize,
        h = this.rows;
      ((0 < this.borderThickness && this.borderColor) || this.backgroundColor) &&
        this.ctx.roundRect(
          a,
          d,
          this.width,
          this.height,
          this.cornerRadius,
          this.borderThickness,
          this.backgroundColor,
          this.borderColor
        );
      for (var k = 0, l = 0; l < h.length; l++) {
        for (var m = h[l], r = 0, q = 0; q < m.items.length; q++) {
          var n = m.items[q],
            f = n.textBlock.x + a + (0 === q ? 0.2 * g : this.horizontalSpacing),
            p = d + k,
            s = f;
          this.chart.data[n.dataSeriesIndex].visible || (this.ctx.globalAlpha = 0.5);
          this.ctx.save();
          this.ctx.beginPath();
          this.ctx.rect(a, d, c, Math.max(e - (e % this.lineHeight), 0));
          this.ctx.clip();
          if ("line" === n.chartType || "stepLine" === n.chartType || "spline" === n.chartType)
            (this.ctx.strokeStyle = n.lineColor),
              (this.ctx.lineWidth = Math.ceil(this.lineHeight / 8)),
              this.ctx.beginPath(),
              this.ctx.moveTo(f - 0.1 * this.lineHeight, p + this.lineHeight / 2),
              this.ctx.lineTo(f + 0.85 * this.lineHeight, p + this.lineHeight / 2),
              this.ctx.stroke(),
              (s -= 0.1 * this.lineHeight);
          if ("error" === n.chartType) {
            this.ctx.strokeStyle = this.errorMarkerColor[0];
            this.ctx.lineWidth = g / 8;
            this.ctx.beginPath();
            var u = f - 0.08 * this.lineHeight + 0.1 * this.lineHeight,
              t = p + 0.15 * this.lineHeight,
              w = 0.7 * this.lineHeight,
              x = w + 0.02 * this.lineHeight;
            this.ctx.moveTo(u, t);
            this.ctx.lineTo(u + w, t);
            this.ctx.stroke();
            this.ctx.beginPath();
            this.ctx.moveTo(u + w / 2, t);
            this.ctx.lineTo(u + w / 2, t + x);
            this.ctx.stroke();
            this.ctx.beginPath();
            this.ctx.moveTo(u, t + x);
            this.ctx.lineTo(u + w, t + x);
            this.ctx.stroke();
            this.errorMarkerColor.shift();
          }
          Y.drawMarker(
            f + g / 2,
            p + this.lineHeight / 2,
            this.ctx,
            n.markerType,
            "error" === n.chartType || "line" === n.chartType || "spline" === n.chartType
              ? n.markerSize / 2
              : n.markerSize,
            n.markerColor,
            n.markerBorderColor,
            n.markerBorderThickness
          );
          n.textBlock.x = f + b + g;
          if ("line" === n.chartType || "stepLine" === n.chartType || "spline" === n.chartType)
            n.textBlock.x += 0.1 * this.lineHeight;
          n.textBlock.y = Math.round(p + this.lineHeight / 2);
          n.textBlock.render(!0);
          this.ctx.restore();
          r = 0 < q ? Math.max(r, n.textBlock.height) : n.textBlock.height;
          this.chart.data[n.dataSeriesIndex].visible || (this.ctx.globalAlpha = 1);
          f = P(n.id);
          this.ghostCtx.fillStyle = f;
          this.ghostCtx.beginPath();
          this.ghostCtx.fillRect(
            s,
            n.textBlock.y - this.lineHeight / 2,
            n.textBlock.x + n.textBlock.width - s,
            n.textBlock.height
          );
          n.x1 = this.chart._eventManager.objectMap[n.id].x1 = s;
          n.y1 = this.chart._eventManager.objectMap[n.id].y1 = n.textBlock.y - this.lineHeight / 2;
          n.x2 = this.chart._eventManager.objectMap[n.id].x2 = n.textBlock.x + n.textBlock.width;
          n.y2 = this.chart._eventManager.objectMap[n.id].y2 =
            n.textBlock.y + n.textBlock.height - this.lineHeight / 2;
        }
        k += r;
      }
    };
    pa(C, U);
    C.prototype.getDefaultAxisPlacement = function() {
      var a = this.type;
      if (
        "column" === a ||
        "line" === a ||
        "stepLine" === a ||
        "spline" === a ||
        "area" === a ||
        "stepArea" === a ||
        "splineArea" === a ||
        "stackedColumn" === a ||
        "stackedLine" === a ||
        "bubble" === a ||
        "scatter" === a ||
        "stackedArea" === a ||
        "stackedColumn100" === a ||
        "stackedLine100" === a ||
        "stackedArea100" === a ||
        "candlestick" === a ||
        "ohlc" === a ||
        "rangeColumn" === a ||
        "rangeArea" === a ||
        "rangeSplineArea" === a ||
        "boxAndWhisker" === a ||
        "waterfall" === a
      )
        return "normal";
      if ("bar" === a || "stackedBar" === a || "stackedBar100" === a || "rangeBar" === a)
        return "xySwapped";
      if ("pie" === a || "doughnut" === a || "funnel" === a || "pyramid" === a) return "none";
      "error" !== a && window.console.log("Unknown Chart Type: " + a);
      return null;
    };
    C.getDefaultLegendMarker = function(a) {
      if (
        "column" === a ||
        "stackedColumn" === a ||
        "stackedLine" === a ||
        "bar" === a ||
        "stackedBar" === a ||
        "stackedBar100" === a ||
        "bubble" === a ||
        "scatter" === a ||
        "stackedColumn100" === a ||
        "stackedLine100" === a ||
        "stepArea" === a ||
        "candlestick" === a ||
        "ohlc" === a ||
        "rangeColumn" === a ||
        "rangeBar" === a ||
        "rangeArea" === a ||
        "rangeSplineArea" === a ||
        "boxAndWhisker" === a ||
        "waterfall" === a
      )
        return "square";
      if ("line" === a || "stepLine" === a || "spline" === a || "pie" === a || "doughnut" === a)
        return "circle";
      if (
        "area" === a ||
        "splineArea" === a ||
        "stackedArea" === a ||
        "stackedArea100" === a ||
        "funnel" === a ||
        "pyramid" === a
      )
        return "triangle";
      if ("error" === a) return "none";
      window.console.log("Unknown Chart Type: " + a);
      return null;
    };
    C.prototype.getDataPointAtX = function(a, d) {
      if (!this.dataPoints || 0 === this.dataPoints.length) return null;
      var b = { dataPoint: null, distance: Infinity, index: NaN },
        c = null,
        e = 0,
        g = 0,
        h = 1,
        k = Infinity,
        l = 0,
        m = 0,
        r = 0;
      "none" !== this.chart.plotInfo.axisPlacement &&
        (this.axisX.logarithmic
          ? ((r = Math.log(this.dataPoints[this.dataPoints.length - 1].x / this.dataPoints[0].x)),
            (r =
              1 < r
                ? Math.min(
                    Math.max(
                      (((this.dataPoints.length - 1) / r) * Math.log(a / this.dataPoints[0].x)) >>
                        0,
                      0
                    ),
                    this.dataPoints.length
                  )
                : 0))
          : ((r = this.dataPoints[this.dataPoints.length - 1].x - this.dataPoints[0].x),
            (r =
              0 < r
                ? Math.min(
                    Math.max(
                      (((this.dataPoints.length - 1) / r) * (a - this.dataPoints[0].x)) >> 0,
                      0
                    ),
                    this.dataPoints.length
                  )
                : 0)));
      for (;;) {
        g = 0 < h ? r + e : r - e;
        if (0 <= g && g < this.dataPoints.length) {
          var c = this.dataPoints[g],
            q = this.axisX.logarithmic ? (c.x > a ? c.x / a : a / c.x) : Math.abs(c.x - a);
          q < b.distance && ((b.dataPoint = c), (b.distance = q), (b.index = g));
          c = q;
          c <= k ? (k = c) : 0 < h ? l++ : m++;
          if (1e3 < l && 1e3 < m) break;
        } else if (0 > r - e && r + e >= this.dataPoints.length) break;
        -1 === h ? (e++, (h = 1)) : (h = -1);
      }
      return d || b.dataPoint.x !== a ? (d && null !== b.dataPoint ? b : null) : b;
    };
    C.prototype.getDataPointAtXY = function(a, d, b) {
      if (
        !this.dataPoints ||
        0 === this.dataPoints.length ||
        a < this.chart.plotArea.x1 ||
        a > this.chart.plotArea.x2 ||
        d < this.chart.plotArea.y1 ||
        d > this.chart.plotArea.y2
      )
        return null;
      b = b || !1;
      var c = [],
        e = 0,
        g = 0,
        h = 1,
        k = !1,
        l = Infinity,
        m = 0,
        r = 0,
        q = 0;
      if ("none" !== this.chart.plotInfo.axisPlacement)
        if (
          ((q = (this.chart.axisX[0] ? this.chart.axisX[0] : this.chart.axisX2[0]).getXValueAt({
            x: a,
            y: d
          })),
          this.axisX.logarithmic)
        )
          var n = Math.log(this.dataPoints[this.dataPoints.length - 1].x / this.dataPoints[0].x),
            q =
              1 < n
                ? Math.min(
                    Math.max(
                      (((this.dataPoints.length - 1) / n) * Math.log(q / this.dataPoints[0].x)) >>
                        0,
                      0
                    ),
                    this.dataPoints.length
                  )
                : 0;
        else
          (n = this.dataPoints[this.dataPoints.length - 1].x - this.dataPoints[0].x),
            (q =
              0 < n
                ? Math.min(
                    Math.max(
                      (((this.dataPoints.length - 1) / n) * (q - this.dataPoints[0].x)) >> 0,
                      0
                    ),
                    this.dataPoints.length
                  )
                : 0);
      for (;;) {
        g = 0 < h ? q + e : q - e;
        if (0 <= g && g < this.dataPoints.length) {
          var n = this.chart._eventManager.objectMap[this.dataPointIds[g]],
            f = this.dataPoints[g],
            p = null;
          if (n) {
            switch (this.type) {
              case "column":
              case "stackedColumn":
              case "stackedColumn100":
              case "bar":
              case "stackedBar":
              case "stackedBar100":
              case "rangeColumn":
              case "rangeBar":
              case "waterfall":
              case "error":
                a >= n.x1 &&
                  a <= n.x2 && d >= n.y1 && d <= n.y2 &&
                  (c.push({
                    dataPoint: f,
                    dataPointIndex: g,
                    dataSeries: this,
                    distance: Math.min(
                      Math.abs(n.x1 - a),
                      Math.abs(n.x2 - a),
                      Math.abs(n.y1 - d),
                      Math.abs(n.y2 - d)
                    )
                  }),
                  (k = !0));
                break;
              case "line":
              case "stepLine":
              case "spline":
              case "area":
              case "stepArea":
              case "stackedArea":
              case "stackedArea100":
              case "splineArea":
              case "scatter":
                var s = ma("markerSize", f, this) || 4,
                  u = b ? 20 : s,
                  p = Math.sqrt(Math.pow(n.x1 - a, 2) + Math.pow(n.y1 - d, 2));
                p <= u &&
                  c.push({ dataPoint: f, dataPointIndex: g, dataSeries: this, distance: p });
                n = Math.abs(n.x1 - a);
                n <= l ? (l = n) : 0 < h ? m++ : r++;
                p <= s / 2 && (k = !0);
                break;
              case "rangeArea":
              case "rangeSplineArea":
                s = ma("markerSize", f, this) || 4;
                u = b ? 20 : s;
                p = Math.min(
                  Math.sqrt(Math.pow(n.x1 - a, 2) + Math.pow(n.y1 - d, 2)),
                  Math.sqrt(Math.pow(n.x1 - a, 2) + Math.pow(n.y2 - d, 2))
                );
                p <= u &&
                  c.push({ dataPoint: f, dataPointIndex: g, dataSeries: this, distance: p });
                n = Math.abs(n.x1 - a);
                n <= l ? (l = n) : 0 < h ? m++ : r++;
                p <= s / 2 && (k = !0);
                break;
              case "bubble":
                s = n.size;
                p = Math.sqrt(Math.pow(n.x1 - a, 2) + Math.pow(n.y1 - d, 2));
                p <= s / 2 &&
                  (c.push({ dataPoint: f, dataPointIndex: g, dataSeries: this, distance: p }),
                  (k = !0));
                break;
              case "pie":
              case "doughnut":
                s = n.center;
                u = "doughnut" === this.type ? n.percentInnerRadius * n.radius : 0;
                p = Math.sqrt(Math.pow(s.x - a, 2) + Math.pow(s.y - d, 2));
                p < n.radius &&
                  p > u &&
                  ((p = Math.atan2(d - s.y, a - s.x)),
                  0 > p && (p += 2 * Math.PI),
                  (p = Number(((((180 * (p / Math.PI)) % 360) + 360) % 360).toFixed(12))),
                  (s = Number(
                    ((((180 * (n.startAngle / Math.PI)) % 360) + 360) % 360).toFixed(12)
                  )),
                  (u = Number(((((180 * (n.endAngle / Math.PI)) % 360) + 360) % 360).toFixed(12))),
                  0 === u && 1 < n.endAngle && (u = 360),
                  s >= u && 0 !== f.y && ((u += 360), p < s && (p += 360)),
                  p > s &&
                    p < u &&
                    (c.push({ dataPoint: f, dataPointIndex: g, dataSeries: this, distance: 0 }),
                    (k = !0)));
                break;
              case "funnel":
              case "pyramid":
                p = n.funnelSection;
                d > p.y1 &&
                  d < p.y4 &&
                  (p.y6
                    ? d > p.y6
                      ? ((g = p.x6 + ((p.x5 - p.x6) / (p.y5 - p.y6)) * (d - p.y6)),
                        (p = p.x3 + ((p.x4 - p.x3) / (p.y4 - p.y3)) * (d - p.y3)))
                      : ((g = p.x1 + ((p.x6 - p.x1) / (p.y6 - p.y1)) * (d - p.y1)),
                        (p = p.x2 + ((p.x3 - p.x2) / (p.y3 - p.y2)) * (d - p.y2)))
                    : ((g = p.x1 + ((p.x4 - p.x1) / (p.y4 - p.y1)) * (d - p.y1)),
                      (p = p.x2 + ((p.x3 - p.x2) / (p.y3 - p.y2)) * (d - p.y2))),
                  a > g &&
                    a < p &&
                    (c.push({
                      dataPoint: f,
                      dataPointIndex: n.dataPointIndex,
                      dataSeries: this,
                      distance: 0
                    }),
                    (k = !0)));
                break;
              case "boxAndWhisker":
                if (
                  (a >= n.x1 - n.borderThickness / 2 &&
                    a <= n.x2 + n.borderThickness / 2 &&
                    d >= n.y4 - n.borderThickness / 2 &&
                    d <= n.y1 + n.borderThickness / 2) ||
                  (Math.abs(n.x2 - a + n.x1 - a) < n.borderThickness && d >= n.y1 && d <= n.y4)
                )
                  c.push({
                    dataPoint: f,
                    dataPointIndex: g,
                    dataSeries: this,
                    distance: Math.min(
                      Math.abs(n.x1 - a),
                      Math.abs(n.x2 - a),
                      Math.abs(n.y2 - d),
                      Math.abs(n.y3 - d)
                    )
                  }),
                    (k = !0);
                break;
              case "candlestick":
                if (
                  (a >= n.x1 - n.borderThickness / 2 &&
                    a <= n.x2 + n.borderThickness / 2 &&
                    d >= n.y2 - n.borderThickness / 2 &&
                    d <= n.y3 + n.borderThickness / 2) ||
                  (Math.abs(n.x2 - a + n.x1 - a) < n.borderThickness && d >= n.y1 && d <= n.y4)
                )
                  c.push({
                    dataPoint: f,
                    dataPointIndex: g,
                    dataSeries: this,
                    distance: Math.min(
                      Math.abs(n.x1 - a),
                      Math.abs(n.x2 - a),
                      Math.abs(n.y2 - d),
                      Math.abs(n.y3 - d)
                    )
                  }),
                    (k = !0);
                break;
              case "ohlc":
                if (
                  (Math.abs(n.x2 - a + n.x1 - a) < n.borderThickness && d >= n.y2 && d <= n.y3) ||
                  (a >= n.x1 &&
                    a <= (n.x2 + n.x1) / 2 &&
                    d >= n.y1 - n.borderThickness / 2 &&
                    d <= n.y1 + n.borderThickness / 2) ||
                  (a >= (n.x1 + n.x2) / 2 &&
                    a <= n.x2 &&
                    d >= n.y4 - n.borderThickness / 2 &&
                    d <= n.y4 + n.borderThickness / 2)
                )
                  c.push({
                    dataPoint: f,
                    dataPointIndex: g,
                    dataSeries: this,
                    distance: Math.min(
                      Math.abs(n.x1 - a),
                      Math.abs(n.x2 - a),
                      Math.abs(n.y2 - d),
                      Math.abs(n.y3 - d)
                    )
                  }),
                    (k = !0);
            }
            if (k || (1e3 < m && 1e3 < r)) break;
          }
        } else if (0 > q - e && q + e >= this.dataPoints.length) break;
        -1 === h ? (e++, (h = 1)) : (h = -1);
      }
      a = null;
      for (d = 0; d < c.length; d++) a ? c[d].distance <= a.distance && (a = c[d]) : (a = c[d]);
      return a;
    };
    C.prototype.getMarkerProperties = function(a, d, b, c) {
      var e = this.dataPoints;
      return {
        x: d,
        y: b,
        ctx: c,
        type: e[a].markerType ? e[a].markerType : this.markerType,
        size: e[a].markerSize ? e[a].markerSize : this.markerSize,
        color: e[a].markerColor
          ? e[a].markerColor
          : this.markerColor
          ? this.markerColor
          : e[a].color
          ? e[a].color
          : this.color
          ? this.color
          : this._colorSet[a % this._colorSet.length],
        borderColor: e[a].markerBorderColor
          ? e[a].markerBorderColor
          : this.markerBorderColor
          ? this.markerBorderColor
          : null,
        borderThickness: e[a].markerBorderThickness
          ? e[a].markerBorderThickness
          : this.markerBorderThickness
          ? this.markerBorderThickness
          : null
      };
    };
    pa(y, U);
    y.prototype.createExtraLabelsForLog = function(a) {
      a = (a || 0) + 1;
      if (!(5 < a)) {
        var d = this.logLabelValues[0] || this.intervalStartPosition;
        if (Math.log(this.range) / Math.log(d / this.viewportMinimum) < this.noTicks - 1) {
          for (
            var b = y.getNiceNumber(
                (d - this.viewportMinimum) /
                  Math.min(Math.max(2, this.noTicks - this.logLabelValues.length), 3),
                !0
              ),
              c = Math.ceil(this.viewportMinimum / b) * b;
            c < d;
            c += b
          )
            c < this.viewportMinimum || this.logLabelValues.push(c);
          this.logLabelValues.sort(Sa);
          this.createExtraLabelsForLog(a);
        }
      }
    };
    y.prototype.createLabels = function() {
      var a,
        d,
        b = 0,
        c = 0,
        e,
        g = 0,
        h = 0,
        c = 0,
        c = this.interval,
        k = 0,
        p,
        m = 0.6 * this.chart.height,
        l;
      a = !1;
      var q = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [],
        n = q.length
          ? u(this.scaleBreaks.firstBreakIndex)
            ? 0
            : this.scaleBreaks.firstBreakIndex
          : 0;
      if ("axisX" !== this.type || "dateTime" !== this.valueType || this.logarithmic) {
        e = this.viewportMaximum;
        if (this.labels) {
          a = Math.ceil(c);
          for (
            var c = Math.ceil(this.intervalStartPosition), f = !1, b = c;
            b < this.viewportMaximum;
            b += a
          )
            if (this.labels[b]) f = !0;
            else {
              f = !1;
              break;
            }
          f && ((this.interval = a), (this.intervalStartPosition = c));
        }
        if (this.logarithmic && !this.equidistantInterval)
          for (
            this.logLabelValues || ((this.logLabelValues = []), this.createExtraLabelsForLog()),
              c = 0,
              f = n;
            c < this.logLabelValues.length;
            c++
          )
            if (((b = this.logLabelValues[c]), b < this.viewportMinimum)) c++;
            else {
              for (; f < q.length && b > q[f].endValue; f++);
              a = f < q.length && b >= q[f].startValue && b <= q[f].endValue;
              l = b;
              a ||
                ((a = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.options,
                      value: l,
                      label: this.labels[l] ? this.labels[l] : null
                    })
                  : "axisX" === this.type && this.labels[l]
                  ? this.labels[l]
                  : fa(l, this.valueFormatString, this.chart._cultureInfo)),
                (a = new ja(this.ctx, {
                  x: 0,
                  y: 0,
                  maxWidth: g,
                  maxHeight: h,
                  angle: this.labelAngle,
                  text: this.prefix + a + this.suffix,
                  backgroundColor: this.labelBackgroundColor,
                  borderColor: this.labelBorderColor,
                  borderThickness: this.labelBorderThickness,
                  cornerRadius: this.labelCornerRadius,
                  horizontalAlign: "left",
                  fontSize: this.labelFontSize,
                  fontFamily: this.labelFontFamily,
                  fontWeight: this.labelFontWeight,
                  fontColor: this.labelFontColor,
                  fontStyle: this.labelFontStyle,
                  textBaseline: "middle",
                  borderThickness: 0
                })),
                this._labels.push({ position: l, textBlock: a, effectiveHeight: null }));
            }
        f = n;
        for (
          b = this.intervalStartPosition;
          b <= e;
          b = parseFloat(
            1e-12 > this.interval
              ? this.logarithmic && this.equidistantInterval
                ? b * Math.pow(this.logarithmBase, this.interval)
                : b + this.interval
              : (this.logarithmic && this.equidistantInterval
                  ? b * Math.pow(this.logarithmBase, this.interval)
                  : b + this.interval
                ).toFixed(12)
          )
        ) {
          for (; f < q.length && b > q[f].endValue; f++);
          a = f < q.length && b >= q[f].startValue && b <= q[f].endValue;
          l = b;
          a ||
            ((a = this.labelFormatter
              ? this.labelFormatter({
                  chart: this.chart,
                  axis: this.options,
                  value: l,
                  label: this.labels[l] ? this.labels[l] : null
                })
              : "axisX" === this.type && this.labels[l]
              ? this.labels[l]
              : fa(l, this.valueFormatString, this.chart._cultureInfo)),
            (a = new ja(this.ctx, {
              x: 0,
              y: 0,
              maxWidth: g,
              maxHeight: h,
              angle: this.labelAngle,
              text: this.prefix + a + this.suffix,
              horizontalAlign: "left",
              backgroundColor: this.labelBackgroundColor,
              borderColor: this.labelBorderColor,
              borderThickness: this.labelBorderThickness,
              cornerRadius: this.labelCornerRadius,
              fontSize: this.labelFontSize,
              fontFamily: this.labelFontFamily,
              fontWeight: this.labelFontWeight,
              fontColor: this.labelFontColor,
              fontStyle: this.labelFontStyle,
              textBaseline: "middle"
            })),
            this._labels.push({ position: l, textBlock: a, effectiveHeight: null }));
        }
      } else
        for (
          this.intervalStartPosition = this.getLabelStartPoint(
            new Date(this.viewportMinimum),
            this.intervalType,
            this.interval
          ),
            e = Ya(new Date(this.viewportMaximum), this.interval, this.intervalType),
            f = n,
            b = this.intervalStartPosition;
          b < e;
          Ya(b, c, this.intervalType)
        ) {
          for (a = b.getTime(); f < q.length && a > q[f].endValue; f++);
          l = a;
          a = f < q.length && a >= q[f].startValue && a <= q[f].endValue;
          a ||
            ((a = this.labelFormatter
              ? this.labelFormatter({
                  chart: this.chart,
                  axis: this.options,
                  value: new Date(l),
                  label: this.labels[l] ? this.labels[l] : null
                })
              : "axisX" === this.type && this.labels[l]
              ? this.labels[l]
              : Ba(l, this.valueFormatString, this.chart._cultureInfo)),
            (a = new ja(this.ctx, {
              x: 0,
              y: 0,
              maxWidth: g,
              backgroundColor: this.labelBackgroundColor,
              borderColor: this.labelBorderColor,
              borderThickness: this.labelBorderThickness,
              cornerRadius: this.labelCornerRadius,
              maxHeight: h,
              angle: this.labelAngle,
              text: this.prefix + a + this.suffix,
              horizontalAlign: "left",
              fontSize: this.labelFontSize,
              fontFamily: this.labelFontFamily,
              fontWeight: this.labelFontWeight,
              fontColor: this.labelFontColor,
              fontStyle: this.labelFontStyle,
              textBaseline: "middle"
            })),
            this._labels.push({
              position: l,
              textBlock: a,
              effectiveHeight: null,
              breaksLabelType: void 0
            }));
        }
      if ("bottom" === this._position || "top" === this._position)
        (k =
          this.logarithmic && !this.equidistantInterval && 2 <= this._labels.length
            ? (this.lineCoordinates.width *
                Math.log(
                  Math.min(
                    this._labels[this._labels.length - 1].position /
                      this._labels[this._labels.length - 2].position,
                    this._labels[1].position / this._labels[0].position
                  )
                )) /
              Math.log(this.range)
            : (this.lineCoordinates.width /
                (this.logarithmic && this.equidistantInterval
                  ? Math.log(this.range) / Math.log(this.logarithmBase)
                  : Math.abs(this.range))) *
              R[this.intervalType + "Duration"] *
              this.interval),
          (g =
            "undefined" === typeof this.options.labelMaxWidth
              ? (0.5 * this.chart.width) >> 0
              : this.options.labelMaxWidth),
          this.chart.panEnabled ||
            (h =
              "undefined" === typeof this.options.labelWrap || this.labelWrap
                ? (0.8 * this.chart.height) >> 0
                : 1.5 * this.labelFontSize);
      else if ("left" === this._position || "right" === this._position)
        (k =
          this.logarithmic && !this.equidistantInterval && 2 <= this._labels.length
            ? (this.lineCoordinates.height *
                Math.log(
                  Math.min(
                    this._labels[this._labels.length - 1].position /
                      this._labels[this._labels.length - 2].position,
                    this._labels[1].position / this._labels[0].position
                  )
                )) /
              Math.log(this.range)
            : (this.lineCoordinates.height /
                (this.logarithmic && this.equidistantInterval
                  ? Math.log(this.range) / Math.log(this.logarithmBase)
                  : Math.abs(this.range))) *
              R[this.intervalType + "Duration"] *
              this.interval),
          this.chart.panEnabled ||
            (g =
              "undefined" === typeof this.options.labelMaxWidth
                ? (0.3 * this.chart.width) >> 0
                : this.options.labelMaxWidth),
          (h =
            "undefined" === typeof this.options.labelWrap || this.labelWrap
              ? (0.3 * this.chart.height) >> 0
              : 1.5 * this.labelFontSize);
      for (c = 0; c < this._labels.length; c++) {
        a = this._labels[c].textBlock;
        a.maxWidth = g;
        a.maxHeight = h;
        var A = a.measureText();
        p = A.height;
      }
      e = [];
      n = q = 0;
      if (this.labelAutoFit || this.options.labelAutoFit)
        if (
          (u(this.labelAngle) ||
            ((this.labelAngle = ((this.labelAngle % 360) + 360) % 360),
            90 < this.labelAngle && 270 > this.labelAngle
              ? (this.labelAngle -= 180)
              : 270 <= this.labelAngle && 360 >= this.labelAngle && (this.labelAngle -= 360)),
          "bottom" === this._position || "top" === this._position)
        )
          if (((g = (0.9 * k) >> 0), (n = 0), !this.chart.panEnabled && 1 <= this._labels.length)) {
            this.sessionVariables.labelFontSize = this.labelFontSize;
            this.sessionVariables.labelMaxWidth = g;
            this.sessionVariables.labelMaxHeight = h;
            this.sessionVariables.labelAngle = this.labelAngle;
            this.sessionVariables.labelWrap = this.labelWrap;
            for (b = 0; b < this._labels.length; b++)
              if (!this._labels[b].breaksLabelType) {
                a = this._labels[b].textBlock;
                for (var w, f = a.text.split(" "), c = 0; c < f.length; c++)
                  (l = f[c]),
                    (this.ctx.font =
                      a.fontStyle + " " + a.fontWeight + " " + a.fontSize + "px " + a.fontFamily),
                    (l = this.ctx.measureText(l)),
                    l.width > n && ((w = b), (n = l.width));
              }
            b = 0;
            for (
              b = this.intervalStartPosition < this.viewportMinimum ? 1 : 0;
              b < this._labels.length;
              b++
            )
              if (!this._labels[b].breaksLabelType) {
                a = this._labels[b].textBlock;
                A = a.measureText();
                for (f = b + 1; f < this._labels.length; f++)
                  if (!this._labels[f].breaksLabelType) {
                    d = this._labels[f].textBlock;
                    d = d.measureText();
                    break;
                  }
                e.push(a.height);
                this.sessionVariables.labelMaxHeight = Math.max.apply(Math, e);
                Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
                Math.sin((Math.PI / 180) * Math.abs(this.labelAngle));
                c =
                  g * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
                  (h - a.fontSize / 2) * Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
                if (
                  u(this.options.labelAngle) &&
                  isNaN(this.options.labelAngle) &&
                  0 !== this.options.labelAngle
                )
                  if (
                    ((this.sessionVariables.labelMaxHeight =
                      0 === this.labelAngle
                        ? h
                        : Math.min(
                            (c - g * Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))) /
                              Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)),
                            c
                          )),
                    (l =
                      (m - (p + a.fontSize / 2) * Math.cos((Math.PI / 180) * Math.abs(-25))) /
                      Math.sin((Math.PI / 180) * Math.abs(-25))),
                    !u(this.options.labelWrap))
                  )
                    this.labelWrap
                      ? u(this.options.labelMaxWidth)
                        ? ((this.sessionVariables.labelMaxWidth = Math.min(Math.max(g, n), l)),
                          (this.sessionVariables.labelWrap = this.labelWrap),
                          (A.width + d.width) >> 0 > 2 * g &&
                            (this.sessionVariables.labelAngle = -25))
                        : ((this.sessionVariables.labelWrap = this.labelWrap),
                          (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth),
                          (this.sessionVariables.labelAngle =
                            this.sessionVariables.labelMaxWidth > g
                              ? -25
                              : this.sessionVariables.labelAngle))
                      : u(this.options.labelMaxWidth)
                      ? ((this.sessionVariables.labelWrap = this.labelWrap),
                        (this.sessionVariables.labelMaxHeight = h),
                        (this.sessionVariables.labelMaxWidth = g),
                        (A.width + d.width) >> 0 > 2 * g &&
                          ((this.sessionVariables.labelAngle = -25),
                          (this.sessionVariables.labelMaxWidth = l)))
                      : ((this.sessionVariables.labelAngle =
                          this.sessionVariables.labelMaxWidth > g
                            ? -25
                            : this.sessionVariables.labelAngle),
                        (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth),
                        (this.sessionVariables.labelMaxHeight = h),
                        (this.sessionVariables.labelWrap = this.labelWrap));
                  else {
                    if (u(this.options.labelWrap))
                      if (!u(this.options.labelMaxWidth))
                        this.options.labelMaxWidth < g
                          ? ((this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth),
                            (this.sessionVariables.labelMaxHeight = c))
                          : ((this.sessionVariables.labelAngle = -25),
                            (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth),
                            (this.sessionVariables.labelMaxHeight = h));
                      else if (!u(d))
                        if (((c = (A.width + d.width) >> 0), (f = this.labelFontSize), n < g))
                          c - 2 * g > q &&
                            ((q = c - 2 * g),
                            c >= 2 * g && c < 2.2 * g
                              ? ((this.sessionVariables.labelMaxWidth = g),
                                u(this.options.labelFontSize) &&
                                  12 < f &&
                                  ((f = Math.floor((12 / 13) * f)), a.measureText()),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? f
                                  : this.options.labelFontSize),
                                (this.sessionVariables.labelAngle = this.labelAngle))
                              : c >= 2.2 * g && c < 2.8 * g
                              ? ((this.sessionVariables.labelAngle = -25),
                                (this.sessionVariables.labelMaxWidth = l),
                                (this.sessionVariables.labelFontSize = f))
                              : c >= 2.8 * g && c < 3.2 * g
                              ? ((this.sessionVariables.labelMaxWidth = Math.max(g, n)),
                                (this.sessionVariables.labelWrap = !0),
                                u(this.options.labelFontSize) &&
                                  12 < this.labelFontSize &&
                                  ((this.labelFontSize = Math.floor(
                                    (12 / 13) * this.labelFontSize
                                  )),
                                  a.measureText()),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? f
                                  : this.options.labelFontSize),
                                (this.sessionVariables.labelAngle = this.labelAngle))
                              : c >= 3.2 * g && c < 3.6 * g
                              ? ((this.sessionVariables.labelAngle = -25),
                                (this.sessionVariables.labelWrap = !0),
                                (this.sessionVariables.labelMaxWidth = l),
                                (this.sessionVariables.labelFontSize = this.labelFontSize))
                              : c > 3.6 * g && c < 5 * g
                              ? (u(this.options.labelFontSize) &&
                                  12 < f &&
                                  ((f = Math.floor((12 / 13) * f)), a.measureText()),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? f
                                  : this.options.labelFontSize),
                                (this.sessionVariables.labelWrap = !0),
                                (this.sessionVariables.labelAngle = -25),
                                (this.sessionVariables.labelMaxWidth = l))
                              : c > 5 * g &&
                                ((this.sessionVariables.labelWrap = !0),
                                (this.sessionVariables.labelMaxWidth = g),
                                (this.sessionVariables.labelFontSize = f),
                                (this.sessionVariables.labelMaxHeight = h),
                                (this.sessionVariables.labelAngle = this.labelAngle)));
                        else if (
                          w === b &&
                          ((0 === w &&
                            n + this._labels[w + 1].textBlock.measureText().width - 2 * g > q) ||
                            (w === this._labels.length - 1 &&
                              n + this._labels[w - 1].textBlock.measureText().width - 2 * g > q) ||
                            (0 < w &&
                              w < this._labels.length - 1 &&
                              n + this._labels[w + 1].textBlock.measureText().width - 2 * g > q &&
                              n + this._labels[w - 1].textBlock.measureText().width - 2 * g > q))
                        )
                          (q =
                            0 === w
                              ? n + this._labels[w + 1].textBlock.measureText().width - 2 * g
                              : n + this._labels[w - 1].textBlock.measureText().width - 2 * g),
                            (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                              ? f
                              : this.options.labelFontSize),
                            (this.sessionVariables.labelWrap = !0),
                            (this.sessionVariables.labelAngle = -25),
                            (this.sessionVariables.labelMaxWidth = l);
                        else if (0 === q)
                          for (
                            this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                              ? f
                              : this.options.labelFontSize,
                              this.sessionVariables.labelWrap = !0,
                              c = 0;
                            c < this._labels.length;
                            c++
                          )
                            (a = this._labels[c].textBlock),
                              (a.maxWidth = this.sessionVariables.labelMaxWidth = Math.min(
                                Math.max(g, n),
                                l
                              )),
                              (A = a.measureText()),
                              c < this._labels.length - 1 &&
                                ((f = c + 1),
                                (d = this._labels[f].textBlock),
                                (d.maxWidth = this.sessionVariables.labelMaxWidth = Math.min(
                                  Math.max(g, n),
                                  l
                                )),
                                (d = d.measureText()),
                                (A.width + d.width) >> 0 > 2 * g &&
                                  (this.sessionVariables.labelAngle = -25));
                  }
                else
                  ((this.sessionVariables.labelAngle = this.labelAngle),
                  (this.sessionVariables.labelMaxHeight =
                    0 === this.labelAngle
                      ? h
                      : Math.min(
                          (c - g * Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))) /
                            Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)),
                          c
                        )),
                  (l =
                    0 != this.labelAngle
                      ? (m -
                          (p + a.fontSize / 2) *
                            Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))) /
                        Math.sin((Math.PI / 180) * Math.abs(this.labelAngle))
                      : g),
                  (this.sessionVariables.labelMaxHeight = h = this.labelWrap
                    ? (m - l * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle))) /
                      Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))
                    : 1.5 * this.labelFontSize),
                  u(this.options.labelWrap))
                    ? u(this.options.labelWrap) &&
                      (this.labelWrap && !u(this.options.labelMaxWidth)
                        ? ((this.sessionVariables.labelWrap = this.labelWrap),
                          (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                            ? this.options.labelMaxWidth
                            : l),
                          (this.sessionVariables.labelMaxHeight = h))
                        : ((this.sessionVariables.labelAngle = this.labelAngle),
                          (this.sessionVariables.labelMaxWidth = l),
                          (this.sessionVariables.labelMaxHeight = c < 0.9 * k ? 0.9 * k : c),
                          (this.sessionVariables.labelWrap = this.labelWrap)))
                    : (this.options.labelWrap
                        ? ((this.sessionVariables.labelWrap = this.labelWrap),
                          (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                            ? this.options.labelMaxWidth
                            : l))
                        : (u(this.options.labelMaxWidth),
                          (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                            ? this.options.labelMaxWidth
                            : l),
                          (this.sessionVariables.labelWrap = this.labelWrap)),
                      (this.sessionVariables.labelMaxHeight = h));
              }
            for (c = 0; c < this._labels.length; c++)
              (a = this._labels[c].textBlock),
                (a.maxWidth = this.labelMaxWidth = this.sessionVariables.labelMaxWidth),
                (a.fontSize = this.sessionVariables.labelFontSize),
                (a.angle = this.labelAngle = this.sessionVariables.labelAngle),
                (a.wrap = this.labelWrap = this.sessionVariables.labelWrap),
                (a.maxHeight = this.sessionVariables.labelMaxHeight),
                a.measureText();
          } else
            for (b = 0; b < this._labels.length; b++)
              (a = this._labels[b].textBlock),
                (a.maxWidth = this.labelMaxWidth = u(this.options.labelMaxWidth)
                  ? this.sessionVariables.labelMaxWidth
                  : this.options.labelMaxWidth),
                (a.fontSize = this.labelFontSize = u(this.options.labelFontSize)
                  ? this.sessionVariables.labelFontSize
                  : this.options.labelFontSize),
                (a.angle = this.labelAngle = u(this.options.labelAngle)
                  ? this.sessionVariables.labelAngle
                  : this.labelAngle),
                (a.wrap = this.labelWrap = u(this.options.labelWrap)
                  ? this.sessionVariables.labelWrap
                  : this.options.labelWrap),
                (a.maxHeight = this.sessionVariables.labelMaxHeight),
                a.measureText();
        else if ("left" === this._position || "right" === this._position)
          if (
            ((g = u(this.options.labelMaxWidth)
              ? (0.3 * this.chart.width) >> 0
              : this.options.labelMaxWidth),
            (h =
              "undefined" === typeof this.options.labelWrap || this.labelWrap
                ? (0.3 * this.chart.height) >> 0
                : 1.5 * this.labelFontSize),
            !this.chart.panEnabled && 1 <= this._labels.length)
          ) {
            this.sessionVariables.labelFontSize = this.labelFontSize;
            this.sessionVariables.labelMaxWidth = g;
            this.sessionVariables.labelMaxHeight = h;
            this.sessionVariables.labelAngle = u(this.sessionVariables.labelAngle)
              ? 0
              : this.sessionVariables.labelAngle;
            this.sessionVariables.labelWrap = this.labelWrap;
            for (b = 0; b < this._labels.length; b++)
              if (!this._labels[b].breaksLabelType) {
                a = this._labels[b].textBlock;
                A = a.measureText();
                for (f = b + 1; f < this._labels.length; f++)
                  if (!this._labels[f].breaksLabelType) {
                    d = this._labels[f].textBlock;
                    d = d.measureText();
                    break;
                  }
                e.push(a.height);
                this.sessionVariables.labelMaxHeight = Math.max.apply(Math, e);
                c =
                  g * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
                  (h - a.fontSize / 2) * Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
                Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
                Math.sin((Math.PI / 180) * Math.abs(this.labelAngle));
                u(this.options.labelAngle) &&
                isNaN(this.options.labelAngle) &&
                0 !== this.options.labelAngle
                  ? u(this.options.labelWrap)
                    ? u(this.options.labelWrap) &&
                      (u(this.options.labelMaxWidth)
                        ? u(d) ||
                          ((k = (A.height + d.height) >> 0),
                          k - 2 * h > n &&
                            ((n = k - 2 * h),
                            k >= 2 * h && k < 2.4 * h
                              ? (u(this.options.labelFontSize) &&
                                  12 < this.labelFontSize &&
                                  ((this.labelFontSize = Math.floor(
                                    (12 / 13) * this.labelFontSize
                                  )),
                                  a.measureText()),
                                (this.sessionVariables.labelMaxHeight = h),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? this.labelFontSize
                                  : this.options.labelFontSize))
                              : k >= 2.4 * h && k < 2.8 * h
                              ? ((this.sessionVariables.labelMaxHeight = c),
                                (this.sessionVariables.labelFontSize = this.labelFontSize),
                                (this.sessionVariables.labelWrap = !0))
                              : k >= 2.8 * h && k < 3.2 * h
                              ? ((this.sessionVariables.labelMaxHeight = h),
                                (this.sessionVariables.labelWrap = !0),
                                u(this.options.labelFontSize) &&
                                  12 < this.labelFontSize &&
                                  ((this.labelFontSize = Math.floor(
                                    (12 / 13) * this.labelFontSize
                                  )),
                                  a.measureText()),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? this.labelFontSize
                                  : this.options.labelFontSize),
                                (this.sessionVariables.labelAngle = u(
                                  this.sessionVariables.labelAngle
                                )
                                  ? 0
                                  : this.sessionVariables.labelAngle))
                              : k >= 3.2 * h && k < 3.6 * h
                              ? ((this.sessionVariables.labelMaxHeight = c),
                                (this.sessionVariables.labelWrap = !0),
                                (this.sessionVariables.labelFontSize = this.labelFontSize))
                              : k > 3.6 * h && k < 10 * h
                              ? (u(this.options.labelFontSize) &&
                                  12 < this.labelFontSize &&
                                  ((this.labelFontSize = Math.floor(
                                    (12 / 13) * this.labelFontSize
                                  )),
                                  a.measureText()),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? this.labelFontSize
                                  : this.options.labelFontSize),
                                (this.sessionVariables.labelMaxWidth = g),
                                (this.sessionVariables.labelMaxHeight = h),
                                (this.sessionVariables.labelAngle = u(
                                  this.sessionVariables.labelAngle
                                )
                                  ? 0
                                  : this.sessionVariables.labelAngle))
                              : k > 10 * h &&
                                k < 50 * h &&
                                (u(this.options.labelFontSize) &&
                                  12 < this.labelFontSize &&
                                  ((this.labelFontSize = Math.floor(
                                    (12 / 13) * this.labelFontSize
                                  )),
                                  a.measureText()),
                                (this.sessionVariables.labelFontSize = u(this.options.labelFontSize)
                                  ? this.labelFontSize
                                  : this.options.labelFontSize),
                                (this.sessionVariables.labelMaxHeight = h),
                                (this.sessionVariables.labelMaxWidth = g),
                                (this.sessionVariables.labelAngle = u(
                                  this.sessionVariables.labelAngle
                                )
                                  ? 0
                                  : this.sessionVariables.labelAngle))))
                        : ((this.sessionVariables.labelMaxHeight = h),
                          (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                            ? this.options.labelMaxWidth
                            : this.sessionVariables.labelMaxWidth)))
                    : ((this.sessionVariables.labelMaxWidth = this.labelWrap
                        ? this.options.labelMaxWidth
                          ? this.options.labelMaxWidth
                          : this.sessionVariables.labelMaxWidth
                        : this.labelMaxWidth
                        ? this.options.labelMaxWidth
                          ? this.options.labelMaxWidth
                          : this.sessionVariables.labelMaxWidth
                        : g),
                      (this.sessionVariables.labelMaxHeight = h))
                  : ((this.sessionVariables.labelAngle = this.labelAngle),
                    (this.sessionVariables.labelMaxWidth =
                      0 === this.labelAngle
                        ? g
                        : Math.min(
                            (c - h * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle))) /
                              Math.cos((Math.PI / 180) * Math.abs(this.labelAngle)),
                            h
                          )),
                    u(this.options.labelWrap))
                  ? u(this.options.labelWrap) &&
                    (this.labelWrap && !u(this.options.labelMaxWidth)
                      ? ((this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                          ? this.options.labelMaxWidth > this.options.labelMaxWidth
                          : this.sessionVariables.labelMaxWidth),
                        (this.sessionVariables.labelWrap = this.labelWrap),
                        (this.sessionVariables.labelMaxHeight = c))
                      : ((this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                          ? this.options.labelMaxWidth
                          : g),
                        (this.sessionVariables.labelMaxHeight = 0 === this.labelAngle ? h : c),
                        u(this.options.labelMaxWidth) &&
                          (this.sessionVariables.labelAngle = this.labelAngle)))
                  : this.options.labelWrap
                  ? ((this.sessionVariables.labelMaxHeight = 0 === this.labelAngle ? h : c),
                    (this.sessionVariables.labelWrap = this.labelWrap),
                    (this.sessionVariables.labelMaxWidth = g))
                  : ((this.sessionVariables.labelMaxHeight = h),
                    u(this.options.labelMaxWidth),
                    (this.sessionVariables.labelMaxWidth = this.options.labelMaxWidth
                      ? this.options.labelMaxWidth
                      : this.sessionVariables.labelMaxWidth),
                    (this.sessionVariables.labelWrap = this.labelWrap));
              }
            for (c = 0; c < this._labels.length; c++)
              (a = this._labels[c].textBlock),
                (a.maxWidth = this.labelMaxWidth = this.sessionVariables.labelMaxWidth),
                (a.fontSize = this.labelFontSize = this.sessionVariables.labelFontSize),
                (a.angle = this.labelAngle = this.sessionVariables.labelAngle),
                (a.wrap = this.labelWrap = this.sessionVariables.labelWrap),
                (a.maxHeight = this.sessionVariables.labelMaxHeight),
                a.measureText();
          } else
            for (b = 0; b < this._labels.length; b++)
              (a = this._labels[b].textBlock),
                (a.maxWidth = this.labelMaxWidth = u(this.options.labelMaxWidth)
                  ? this.sessionVariables.labelMaxWidth
                  : this.options.labelMaxWidth),
                (a.fontSize = this.labelFontSize = u(this.options.labelFontSize)
                  ? this.sessionVariables.labelFontSize
                  : this.options.labelFontSize),
                (a.angle = this.labelAngle = u(this.options.labelAngle)
                  ? this.sessionVariables.labelAngle
                  : this.labelAngle),
                (a.wrap = this.labelWrap = u(this.options.labelWrap)
                  ? this.sessionVariables.labelWrap
                  : this.options.labelWrap),
                (a.maxHeight = this.sessionVariables.labelMaxHeight),
                a.measureText();
      for (b = 0; b < this.stripLines.length; b++) {
        var g = this.stripLines[b],
          y;
        if ("outside" === g.labelPlacement) {
          h = this.sessionVariables.labelMaxWidth;
          if ("bottom" === this._position || "top" === this._position)
            y = u(g.options.labelWrap)
              ? this.sessionVariables.labelMaxHeight
              : g.labelWrap
              ? (0.8 * this.chart.height) >> 0
              : 1.5 * this.labelFontSize;
          if ("left" === this._position || "right" === this._position)
            y = u(g.options.labelWrap)
              ? this.sessionVariables.labelMaxHeight
              : g.labelWrap
              ? (0.8 * this.chart.width) >> 0
              : 1.5 * this.labelFontSize;
          u(g.labelBackgroundColor) && (g.labelBackgroundColor = "#EEEEEE");
        } else
          (h =
            "bottom" === this._position || "top" === this._position
              ? (0.9 * this.chart.width) >> 0
              : (0.9 * this.chart.height) >> 0),
            (y =
              u(g.options.labelWrap) || g.labelWrap
                ? "bottom" === this._position || "top" === this._position
                  ? (0.8 * this.chart.width) >> 0
                  : (0.8 * this.chart.height) >> 0
                : 1.5 * this.labelFontSize),
            u(g.labelBackgroundColor) &&
              (u(g.startValue) && 0 !== g.startValue
                ? (g.labelBackgroundColor = s ? "transparent" : null)
                : (g.labelBackgroundColor = "#EEEEEE"));
        a = new ja(this.ctx, {
          x: 0,
          y: 0,
          backgroundColor: g.labelBackgroundColor,
          borderColor: g.labelBorderColor,
          borderThickness: g.labelBorderThickness,
          cornerRadius: g.labelCornerRadius,
          maxWidth: g.options.labelMaxWidth ? g.options.labelMaxWidth : h,
          maxHeight: y,
          angle: this.labelAngle,
          text: g.labelFormatter
            ? g.labelFormatter({ chart: this.chart, axis: this, stripLine: g })
            : g.label,
          horizontalAlign: "left",
          fontSize:
            "outside" === g.labelPlacement
              ? g.options.labelFontSize
                ? g.labelFontSize
                : this.labelFontSize
              : g.labelFontSize,
          fontFamily:
            "outside" === g.labelPlacement
              ? g.options.labelFontFamily
                ? g.labelFontFamily
                : this.labelFontFamily
              : g.labelFontFamily,
          fontWeight:
            "outside" === g.labelPlacement
              ? g.options.labelFontWeight
                ? g.labelFontWeight
                : this.labelFontWeight
              : g.labelFontWeight,
          fontColor: g.labelFontColor || g.color,
          fontStyle:
            "outside" === g.labelPlacement
              ? g.options.labelFontStyle
                ? g.labelFontStyle
                : this.fontWeight
              : g.labelFontStyle,
          textBaseline: "middle"
        });
        this._stripLineLabels.push({
          position: g.value,
          textBlock: a,
          effectiveHeight: null,
          stripLine: g
        });
      }
    };
    y.prototype.createLabelsAndCalculateWidth = function() {
      var a = 0,
        d = 0;
      this._labels = [];
      this._stripLineLabels = [];
      var b = this.chart.isNavigator ? 0 : 5;
      if ("left" === this._position || "right" === this._position) {
        this.createLabels();
        for (d = 0; d < this._labels.length; d++) {
          var c = this._labels[d].textBlock,
            e = c.measureText(),
            g = 0,
            g =
              0 === this.labelAngle
                ? e.width
                : e.width * Math.cos((Math.PI / 180) * Math.abs(this.labelAngle)) +
                  (e.height - c.fontSize / 2) *
                    Math.sin((Math.PI / 180) * Math.abs(this.labelAngle));
          a < g && (a = g);
          this._labels[d].effectiveWidth = g;
        }
        for (d = 0; d < this._stripLineLabels.length; d++)
          "outside" === this._stripLineLabels[d].stripLine.labelPlacement &&
            this._stripLineLabels[d].stripLine.value >= this.viewportMinimum &&
              this._stripLineLabels[d].stripLine.value <= this.viewportMaximum &&
            ((c = this._stripLineLabels[d].textBlock),
            (e = c.measureText()),
            (g =
              0 === this.labelAngle
                ? e.width
                : e.width * Math.cos((Math.PI / 180) * Math.abs(this.labelAngle)) +
                  (e.height - c.fontSize / 2) *
                    Math.sin((Math.PI / 180) * Math.abs(this.labelAngle))),
            a < g && (a = g),
            (this._stripLineLabels[d].effectiveWidth = g));
      }
      d = this.title ? this._titleTextBlock.measureText().height + 2 : 0;
      return (c = "inside" === this.labelPlacement ? (c = d + b) : d + a + this.tickLength + b);
    };
    y.prototype.createLabelsAndCalculateHeight = function() {
      var a = 0;
      this._labels = [];
      this._stripLineLabels = [];
      var d,
        b = 0,
        c = this.chart.isNavigator ? 0 : 5;
      this.createLabels();
      if ("bottom" === this._position || "top" === this._position) {
        for (b = 0; b < this._labels.length; b++) {
          d = this._labels[b].textBlock;
          var e = d.measureText(),
            g = 0,
            g =
              0 === this.labelAngle
                ? e.height
                : e.width * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
                  (e.height - d.fontSize / 2) *
                    Math.cos((Math.PI / 180) * Math.abs(this.labelAngle));
          a < g && (a = g);
          this._labels[b].effectiveHeight = g;
        }
        for (b = 0; b < this._stripLineLabels.length; b++)
          "outside" === this._stripLineLabels[b].stripLine.labelPlacement &&
            this._stripLineLabels[b].stripLine.value >= this.viewportMinimum &&
              this._stripLineLabels[b].stripLine.value <= this.viewportMaximum &&
            ((d = this._stripLineLabels[b].textBlock),
            (e = d.measureText()),
            (g =
              0 === this.labelAngle
                ? e.height
                : e.width * Math.sin((Math.PI / 180) * Math.abs(this.labelAngle)) +
                  (e.height - d.fontSize / 2) *
                    Math.cos((Math.PI / 180) * Math.abs(this.labelAngle))),
            a < g && (a = g),
            (this._stripLineLabels[b].effectiveHeight = g));
      }
      d = this.title ? this._titleTextBlock.measureText().height + 2 : 0;
      return (b = "inside" === this.labelPlacement ? (b = d + c) : d + a + this.tickLength + c);
    };
    y.setLayout = function(a, d, b, c, e, g) {
      var h,
        k,
        l,
        m,
        p = a[0] ? a[0].chart : d[0].chart,
        q = p.isNavigator ? 0 : 10,
        n = p._axes;
      if (a && 0 < a.length)
        for (var f = 0; f < a.length; f++) a[f] && a[f].calculateAxisParameters();
      if (d && 0 < d.length) for (f = 0; f < d.length; f++) d[f].calculateAxisParameters();
      if (b && 0 < b.length) for (f = 0; f < b.length; f++) b[f].calculateAxisParameters();
      if (c && 0 < c.length) for (f = 0; f < c.length; f++) c[f].calculateAxisParameters();
      for (f = 0; f < n.length; f++)
        if (n[f] && n[f].scaleBreaks && n[f].scaleBreaks._appliedBreaks.length)
          for (
            var s = n[f].scaleBreaks._appliedBreaks, w = 0;
            w < s.length && !(s[w].startValue > n[f].viewportMaximum);
            w++
          )
            s[w].endValue < n[f].viewportMinimum ||
              (u(n[f].scaleBreaks.firstBreakIndex) && (n[f].scaleBreaks.firstBreakIndex = w),
              s[w].startValue >= n[f].viewPortMinimum && (n[f].scaleBreaks.lastBreakIndex = w));
      for (
        var y = (w = 0),
          t = 0,
          D = 0,
          x = 0,
          C = 0,
          z = 0,
          B,
          E,
          G = (k = 0),
          H,
          I,
          L,
          s = (H = I = L = !1),
          f = 0;
        f < n.length;
        f++
      )
        n[f] &&
          n[f].title &&
          (n[f]._titleTextBlock = new ja(n[f].ctx, {
            text: n[f].title,
            horizontalAlign: "center",
            fontSize: n[f].titleFontSize,
            fontFamily: n[f].titleFontFamily,
            fontWeight: n[f].titleFontWeight,
            fontColor: n[f].titleFontColor,
            fontStyle: n[f].titleFontStyle,
            borderColor: n[f].titleBorderColor,
            borderThickness: n[f].titleBorderThickness,
            backgroundColor: n[f].titleBackgroundColor,
            cornerRadius: n[f].titleCornerRadius,
            textBaseline: "top"
          }));
      for (f = 0; f < n.length; f++)
        if (n[f].title)
          switch (n[f]._position) {
            case "left":
              n[f]._titleTextBlock.maxWidth = n[f].titleMaxWidth || g.height;
              n[f]._titleTextBlock.maxHeight = n[f].titleWrap
                ? 0.8 * g.width
                : 1.5 * n[f].titleFontSize;
              n[f]._titleTextBlock.angle = -90;
              break;
            case "right":
              n[f]._titleTextBlock.maxWidth = n[f].titleMaxWidth || g.height;
              n[f]._titleTextBlock.maxHeight = n[f].titleWrap
                ? 0.8 * g.width
                : 1.5 * n[f].titleFontSize;
              n[f]._titleTextBlock.angle = 90;
              break;
            default:
              (n[f]._titleTextBlock.maxWidth = n[f].titleMaxWidth || g.width),
                (n[f]._titleTextBlock.maxHeight = n[f].titleWrap
                  ? 0.8 * g.height
                  : 1.5 * n[f].titleFontSize),
                (n[f]._titleTextBlock.angle = 0);
          }
      if ("normal" === e) {
        for (var D = [], x = [], C = [], z = [], M = [], N = [], P = [], Q = []; 4 > w; ) {
          var F = 0,
            S = 0,
            R = 0,
            U = 0,
            W = (e = 0),
            K = 0,
            aa = 0,
            V = 0,
            Y = 0,
            O = 0,
            ba = 0;
          if (b && 0 < b.length)
            for (C = [], f = O = 0; f < b.length; f++)
              C.push(Math.ceil(b[f] ? b[f].createLabelsAndCalculateWidth() : 0)),
                (O += C[f]),
                (K += b[f] && !p.isNavigator ? b[f].margin : 0);
          else C.push(Math.ceil(b[0] ? b[0].createLabelsAndCalculateWidth() : 0));
          P.push(C);
          if (c && 0 < c.length)
            for (z = [], f = ba = 0; f < c.length; f++)
              z.push(Math.ceil(c[f] ? c[f].createLabelsAndCalculateWidth() : 0)),
                (ba += z[f]),
                (aa += c[f] ? c[f].margin : 0);
          else z.push(Math.ceil(c[0] ? c[0].createLabelsAndCalculateWidth() : 0));
          Q.push(z);
          h = Math.round(g.x1 + O + K);
          l = Math.round(g.x2 - ba - aa > p.width - q ? p.width - q : g.x2 - ba - aa);
          if (a && 0 < a.length)
            for (D = [], f = V = 0; f < a.length; f++)
              a[f] && (a[f].lineCoordinates = {}),
                (a[f].lineCoordinates.width = Math.abs(l - h)),
                a[f].title &&
                  (a[f]._titleTextBlock.maxWidth =
                    0 < a[f].titleMaxWidth && a[f].titleMaxWidth < a[f].lineCoordinates.width
                      ? a[f].titleMaxWidth
                      : a[f].lineCoordinates.width),
                D.push(Math.ceil(a[f] ? a[f].createLabelsAndCalculateHeight() : 0)),
                (V += D[f]),
                (e += a[f] && !p.isNavigator ? a[f].margin : 0);
          else D.push(Math.ceil(a[0] ? a[0].createLabelsAndCalculateHeight() : 0));
          M.push(D);
          if (d && 0 < d.length)
            for (x = [], f = Y = 0; f < d.length; f++)
              d[f] && (d[f].lineCoordinates = {}),
                (d[f].lineCoordinates.width = Math.abs(l - h)),
                d[f].title &&
                  (d[f]._titleTextBlock.maxWidth =
                    0 < d[f].titleMaxWidth && d[f].titleMaxWidth < d[f].lineCoordinates.width
                      ? d[f].titleMaxWidth
                      : d[f].lineCoordinates.width),
                x.push(Math.ceil(d[f] ? d[f].createLabelsAndCalculateHeight() : 0)),
                (Y += x[f]),
                (W += d[f] && !p.isNavigator ? d[f].margin : 0);
          else x.push(Math.ceil(d[0] ? d[0].createLabelsAndCalculateHeight() : 0));
          N.push(x);
          if (a && 0 < a.length)
            for (f = 0; f < a.length; f++)
              a[f] &&
                ((a[f].lineCoordinates.x1 = h),
                (l = Math.round(g.x2 - ba - aa > p.width - q ? p.width - q : g.x2 - ba - aa)),
                a[f]._labels &&
                  1 < a[f]._labels.length &&
                  ((k = m = 0),
                  (m = a[f]._labels[1]),
                  (k =
                    "dateTime" === a[f].valueType
                      ? a[f]._labels[a[f]._labels.length - 2]
                      : a[f]._labels[a[f]._labels.length - 1]),
                  (y =
                    m.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
                    (m.textBlock.height - k.textBlock.fontSize / 2) *
                      Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
                  (t =
                    k.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(k.textBlock.angle)) +
                    (k.textBlock.height - k.textBlock.fontSize / 2) *
                      Math.sin((Math.PI / 180) * Math.abs(k.textBlock.angle)))),
                !a[f] ||
                  !a[f].labelAutoFit || u(B) || u(E) || p.isNavigator ||
                  ((k = 0),
                  0 < a[f].labelAngle
                    ? E + t > l && (k += 0 < a[f].labelAngle ? E + t - l - ba : 0)
                    : 0 > a[f].labelAngle
                    ? B - y < h &&
                      B - y < a[f].viewportMinimum &&
                      (G = h - (K + a[f].tickLength + C + B - y + a[f].labelFontSize / 2))
                    : 0 === a[f].labelAngle &&
                      (E + t > l && (k = E + t / 2 - l - ba),
                      B - y < h &&
                        B - y < a[f].viewportMinimum &&
                        (G = h - K - a[f].tickLength - C - B + y / 2)),
                  a[f].viewportMaximum === a[f].maximum &&
                  a[f].viewportMinimum === a[f].minimum &&
                  0 < a[f].labelAngle &&
                  0 < k
                    ? (l -= k)
                    : a[f].viewportMaximum === a[f].maximum &&
                      a[f].viewportMinimum === a[f].minimum &&
                      0 > a[f].labelAngle &&
                      0 < G
                    ? (h += G)
                    : a[f].viewportMaximum === a[f].maximum &&
                      a[f].viewportMinimum === a[f].minimum &&
                      0 === a[f].labelAngle &&
                      (0 < G && (h += G), 0 < k && (l -= k))),
                p.panEnabled
                  ? (V = p.sessionVariables.axisX.height)
                  : (p.sessionVariables.axisX.height = V),
                (k = Math.round(g.y2 - V - e + F)),
                (m = Math.round(g.y2)),
                (a[f].lineCoordinates.x2 = l),
                (a[f].lineCoordinates.width = l - h),
                (a[f].lineCoordinates.y1 = k),
                (a[f].lineCoordinates.y2 = k),
                (a[f].bounds = {
                  x1: h,
                  y1: k,
                  x2: l,
                  y2: m - (V + e - D[f] - F),
                  width: l - h,
                  height: m - k
                })),
                (F += D[f] + a[f].margin);
          if (d && 0 < d.length)
            for (f = 0; f < d.length; f++)
              (d[f].lineCoordinates.x1 = Math.round(g.x1 + O + K)),
                (d[f].lineCoordinates.x2 = Math.round(
                  g.x2 - ba - aa > p.width - q ? p.width - q : g.x2 - ba - aa
                )),
                (d[f].lineCoordinates.width = Math.abs(l - h)),
                d[f]._labels &&
                  1 < d[f]._labels.length &&
                  ((m = d[f]._labels[1]),
                  (k =
                    "dateTime" === d[f].valueType
                      ? d[f]._labels[d[f]._labels.length - 2]
                      : d[f]._labels[d[f]._labels.length - 1]),
                  (y =
                    m.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
                    (m.textBlock.height - k.textBlock.fontSize / 2) *
                      Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
                  (t =
                    k.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(k.textBlock.angle)) +
                    (k.textBlock.height - k.textBlock.fontSize / 2) *
                      Math.sin((Math.PI / 180) * Math.abs(k.textBlock.angle)))),
                p.panEnabled
                  ? (Y = p.sessionVariables.axisX2.height)
                  : (p.sessionVariables.axisX2.height = Y),
                (k = Math.round(g.y1)),
                (m = Math.round(g.y2 + d[f].margin)),
                (d[f].lineCoordinates.y1 = k + Y + W - S),
                (d[f].lineCoordinates.y2 = k),
                (d[f].bounds = {
                  x1: h,
                  y1: k + (Y + W - x[f] - S),
                  x2: l,
                  y2: m,
                  width: l - h,
                  height: m - k
                }),
                (S += x[f] + d[f].margin);
          if (b && 0 < b.length)
            for (f = 0; f < b.length; f++)
              (K = p.isNavigator ? 0 : 10),
                b[f] &&
                  ((h = Math.round(a[0] ? a[0].lineCoordinates.x1 : d[0].lineCoordinates.x1)),
                  (K =
                    b[f]._labels && 0 < b[f]._labels.length
                      ? b[f]._labels[b[f]._labels.length - 1].textBlock.height / 2
                      : q),
                  (k = Math.round(g.y1 + Y + W < Math.max(K, q) ? Math.max(K, q) : g.y1 + Y + W)),
                  (l = Math.round(a[0] ? a[0].lineCoordinates.x1 : d[0].lineCoordinates.x1)),
                  (K =
                    0 < a.length
                      ? 0
                      : b[f]._labels && 0 < b[f]._labels.length
                      ? b[f]._labels[0].textBlock.height / 2
                      : q),
                  (m = Math.round(g.y2 - V - e - K)),
                  (b[f].lineCoordinates = {
                    x1: l - R,
                    y1: k,
                    x2: l - R,
                    y2: m,
                    height: Math.abs(m - k)
                  }),
                  (b[f].bounds = {
                    x1: h - (C[f] + R),
                    y1: k,
                    x2: l,
                    y2: m,
                    width: l - h,
                    height: m - k
                  }),
                  b[f].title &&
                    (b[f]._titleTextBlock.maxWidth =
                      0 < b[f].titleMaxWidth && b[f].titleMaxWidth < b[f].lineCoordinates.height
                        ? b[f].titleMaxWidth
                        : b[f].lineCoordinates.height),
                  (R += C[f] + b[f].margin));
          if (c && 0 < c.length)
            for (f = 0; f < c.length; f++)
              c[f] &&
                ((h = Math.round(a[0] ? a[0].lineCoordinates.x2 : d[0].lineCoordinates.x2)),
                (l = Math.round(h)),
                (K =
                  c[f]._labels && 0 < c[f]._labels.length
                    ? c[f]._labels[c[f]._labels.length - 1].textBlock.height / 2
                    : 0),
                (k = Math.round(g.y1 + Y + W < Math.max(K, q) ? Math.max(K, q) : g.y1 + Y + W)),
                (K =
                  0 < a.length
                    ? 0
                    : c[f]._labels && 0 < c[f]._labels.length
                    ? c[f]._labels[0].textBlock.height / 2
                    : 0),
                (m = Math.round(g.y2 - (V + e + K))),
                (c[f].lineCoordinates = {
                  x1: h + U,
                  y1: k,
                  x2: h + U,
                  y2: m,
                  height: Math.abs(m - k)
                }),
                (c[f].bounds = {
                  x1: h,
                  y1: k,
                  x2: l + (z[f] + U),
                  y2: m,
                  width: l - h,
                  height: m - k
                }),
                c[f].title &&
                  (c[f]._titleTextBlock.maxWidth =
                    0 < c[f].titleMaxWidth && c[f].titleMaxWidth < c[f].lineCoordinates.height
                      ? c[f].titleMaxWidth
                      : c[f].lineCoordinates.height),
                (U += z[f] + c[f].margin));
          if (a && 0 < a.length)
            for (f = 0; f < a.length; f++)
              a[f] &&
                (a[f].calculateValueToPixelConversionParameters(),
                a[f].calculateBreaksSizeInValues(),
                a[f]._labels &&
                  1 < a[f]._labels.length &&
                  ((B =
                    (a[f].logarithmic
                      ? Math.log(a[f]._labels[1].position / a[f].viewportMinimum) /
                        a[f].conversionParameters.lnLogarithmBase
                      : a[f]._labels[1].position - a[f].viewportMinimum) *
                      Math.abs(a[f].conversionParameters.pixelPerUnit) +
                    a[f].lineCoordinates.x1),
                  (h =
                    a[f]._labels[a[f]._labels.length - ("dateTime" === a[f].valueType ? 2 : 1)]
                      .position),
                  (h = a[f].getApparentDifference(a[f].viewportMinimum, h)),
                  (E = a[f].logarithmic
                    ? (1 < h
                        ? (Math.log(h) / a[f].conversionParameters.lnLogarithmBase) *
                          Math.abs(a[f].conversionParameters.pixelPerUnit)
                        : 0) + a[f].lineCoordinates.x1
                    : (0 < h ? h * Math.abs(a[f].conversionParameters.pixelPerUnit) : 0) +
                      a[f].lineCoordinates.x1)));
          if (d && 0 < d.length)
            for (f = 0; f < d.length; f++)
              d[f].calculateValueToPixelConversionParameters(),
                d[f].calculateBreaksSizeInValues(),
                d[f]._labels &&
                  1 < d[f]._labels.length &&
                  ((B =
                    (d[f].logarithmic
                      ? Math.log(d[f]._labels[1].position / d[f].viewportMinimum) /
                        d[f].conversionParameters.lnLogarithmBase
                      : d[f]._labels[1].position - d[f].viewportMinimum) *
                      Math.abs(d[f].conversionParameters.pixelPerUnit) +
                    d[f].lineCoordinates.x1),
                  (h =
                    d[f]._labels[d[f]._labels.length - ("dateTime" === d[f].valueType ? 2 : 1)]
                      .position),
                  (h = d[f].getApparentDifference(d[f].viewportMinimum, h)),
                  (E = d[f].logarithmic
                    ? (1 < h
                        ? (Math.log(h) / d[f].conversionParameters.lnLogarithmBase) *
                          Math.abs(d[f].conversionParameters.pixelPerUnit)
                        : 0) + d[f].lineCoordinates.x1
                    : (0 < h ? h * Math.abs(d[f].conversionParameters.pixelPerUnit) : 0) +
                      d[f].lineCoordinates.x1));
          for (f = 0; f < n.length; f++)
            "axisY" === n[f].type &&
              (n[f].calculateValueToPixelConversionParameters(),
              n[f].calculateBreaksSizeInValues());
          if (0 < w) {
            if (a && 0 < a.length)
              for (f = 0; f < a.length; f++) s = M[w - 1][f] === M[w][f] ? !0 : !1;
            else s = !0;
            if (d && 0 < d.length)
              for (f = 0; f < d.length; f++) H = N[w - 1][f] === N[w][f] ? !0 : !1;
            else H = !0;
            if (b && 0 < b.length)
              for (f = 0; f < b.length; f++) I = P[w - 1][f] === P[w][f] ? !0 : !1;
            else I = !0;
            if (c && 0 < c.length)
              for (f = 0; f < c.length; f++) L = Q[w - 1][f] === Q[w][f] ? !0 : !1;
            else L = !0;
          }
          if (s && H && I && L) break;
          w++;
        }
        if (a && 0 < a.length)
          for (f = 0; f < a.length; f++)
            a[f].calculateStripLinesThicknessInValues(), a[f].calculateBreaksInPixels();
        if (d && 0 < d.length)
          for (f = 0; f < d.length; f++)
            d[f].calculateStripLinesThicknessInValues(), d[f].calculateBreaksInPixels();
        if (b && 0 < b.length)
          for (f = 0; f < b.length; f++)
            b[f].calculateStripLinesThicknessInValues(), b[f].calculateBreaksInPixels();
        if (c && 0 < c.length)
          for (f = 0; f < c.length; f++)
            c[f].calculateStripLinesThicknessInValues(), c[f].calculateBreaksInPixels();
      } else {
        q = [];
        B = [];
        G = [];
        y = [];
        E = [];
        t = [];
        M = [];
        for (N = []; 4 > w; ) {
          V = U = S = R = aa = K = W = e = Q = P = F = Y = 0;
          if (a && 0 < a.length)
            for (G = [], f = U = 0; f < a.length; f++)
              G.push(Math.ceil(a[f] ? a[f].createLabelsAndCalculateWidth() : 0)),
                (U += G[f]),
                (e += a[f] && !p.isNavigator ? a[f].margin : 0);
          else G.push(Math.ceil(a[0] ? a[0].createLabelsAndCalculateWidth() : 0));
          M.push(G);
          if (d && 0 < d.length)
            for (y = [], f = V = 0; f < d.length; f++)
              y.push(Math.ceil(d[f] ? d[f].createLabelsAndCalculateWidth() : 0)),
                (V += y[f]),
                (W += d[f] ? d[f].margin : 0);
          else y.push(Math.ceil(d[0] ? d[0].createLabelsAndCalculateWidth() : 0));
          N.push(y);
          if (b && 0 < b.length)
            for (f = 0; f < b.length; f++)
              (b[f].lineCoordinates = {}),
                (h = Math.round(g.x1 + U + e)),
                (l = Math.round(g.x2 - V - W > p.width - 10 ? p.width - 10 : g.x2 - V - W)),
                b[f].labelAutoFit &&
                  !u(D) &&
                  (0 < !a.length &&
                    (h =
                      0 > b[f].labelAngle
                        ? Math.max(h, D)
                        : 0 === b[f].labelAngle
                        ? Math.max(h, D / 2)
                        : h),
                  0 < !d.length &&
                    (l = 0 < b[f].labelAngle ? l - x / 2 : 0 === b[f].labelAngle ? l - x / 2 : l)),
                (b[f].lineCoordinates.x1 = h),
                (b[f].lineCoordinates.x2 = l),
                (b[f].lineCoordinates.width = Math.abs(l - h)),
                b[f].title &&
                  (b[f]._titleTextBlock.maxWidth =
                    0 < b[f].titleMaxWidth && b[f].titleMaxWidth < b[f].lineCoordinates.width
                      ? b[f].titleMaxWidth
                      : b[f].lineCoordinates.width);
          if (c && 0 < c.length)
            for (f = 0; f < c.length; f++)
              (c[f].lineCoordinates = {}),
                (h = Math.round(g.x1 + U + e)),
                (l = Math.round(
                  g.x2 - V - W > c[f].chart.width - 10 ? c[f].chart.width - 10 : g.x2 - V - W
                )),
                c[f] &&
                  c[f].labelAutoFit &&
                  !u(C) &&
                  (0 < !a.length &&
                    (h =
                      0 < c[f].labelAngle
                        ? Math.max(h, C)
                        : 0 === c[f].labelAngle
                        ? Math.max(h, C / 2)
                        : h),
                  0 < !d.length && (l -= z / 2)),
                (c[f].lineCoordinates.x1 = h),
                (c[f].lineCoordinates.x2 = l),
                (c[f].lineCoordinates.width = Math.abs(l - h)),
                c[f].title &&
                  (c[f]._titleTextBlock.maxWidth =
                    0 < c[f].titleMaxWidth && c[f].titleMaxWidth < c[f].lineCoordinates.width
                      ? c[f].titleMaxWidth
                      : c[f].lineCoordinates.width);
          if (b && 0 < b.length)
            for (q = [], f = R = 0; f < b.length; f++)
              q.push(Math.ceil(b[f] ? b[f].createLabelsAndCalculateHeight() : 0)),
                (R += q[f] + b[f].margin),
                (K += b[f].margin);
          else q.push(Math.ceil(b[0] ? b[0].createLabelsAndCalculateHeight() : 0));
          E.push(q);
          if (c && 0 < c.length)
            for (B = [], f = S = 0; f < c.length; f++)
              B.push(Math.ceil(c[f] ? c[f].createLabelsAndCalculateHeight() : 0)),
                (S += B[f]),
                (aa += c[f].margin);
          else B.push(Math.ceil(c[0] ? c[0].createLabelsAndCalculateHeight() : 0));
          t.push(B);
          if (b && 0 < b.length)
            for (f = 0; f < b.length; f++)
              0 < b[f]._labels.length &&
                ((m = b[f]._labels[0]),
                (k = b[f]._labels[b[f]._labels.length - 1]),
                (D =
                  m.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
                  (m.textBlock.height - k.textBlock.fontSize / 2) *
                    Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
                (x =
                  k.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(k.textBlock.angle)) +
                  (k.textBlock.height - k.textBlock.fontSize / 2) *
                    Math.sin((Math.PI / 180) * Math.abs(k.textBlock.angle))));
          if (c && 0 < c.length)
            for (f = 0; f < c.length; f++)
              c[f] &&
                0 < c[f]._labels.length &&
                ((m = c[f]._labels[0]),
                (k = c[f]._labels[c[f]._labels.length - 1]),
                (C =
                  m.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(m.textBlock.angle)) +
                  (m.textBlock.height - k.textBlock.fontSize / 2) *
                    Math.sin((Math.PI / 180) * Math.abs(m.textBlock.angle))),
                (z =
                  k.textBlock.width * Math.cos((Math.PI / 180) * Math.abs(k.textBlock.angle)) +
                  (k.textBlock.height - k.textBlock.fontSize / 2) *
                    Math.sin((Math.PI / 180) * Math.abs(k.textBlock.angle))));
          if (p.panEnabled) for (f = 0; f < b.length; f++) q[f] = p.sessionVariables.axisY.height;
          else for (f = 0; f < b.length; f++) p.sessionVariables.axisY.height = q[f];
          if (b && 0 < b.length)
            for (f = b.length - 1; 0 <= f; f--)
              (k = Math.round(g.y2)),
                (m = Math.round(g.y2 > b[f].chart.height - 10 ? b[f].chart.height - 10 : g.y2)),
                (b[f].lineCoordinates.y1 = k - (q[f] + b[f].margin + Y)),
                (b[f].lineCoordinates.y2 = k - (q[f] + b[f].margin + Y)),
                (b[f].bounds = {
                  x1: h,
                  y1: k - (q[f] + Y + b[f].margin),
                  x2: l,
                  y2: m - (Y + b[f].margin),
                  width: l - h,
                  height: q[f]
                }),
                b[f].title &&
                  (b[f]._titleTextBlock.maxWidth =
                    0 < b[f].titleMaxWidth && b[f].titleMaxWidth < b[f].lineCoordinates.width
                      ? b[f].titleMaxWidth
                      : b[f].lineCoordinates.width),
                (Y += q[f] + b[f].margin);
          if (c && 0 < c.length)
            for (f = c.length - 1; 0 <= f; f--)
              c[f] &&
                ((k = Math.round(g.y1)),
                (m = Math.round(g.y1 + (B[f] + c[f].margin + F))),
                (c[f].lineCoordinates.y1 = m),
                (c[f].lineCoordinates.y2 = m),
                (c[f].bounds = {
                  x1: h,
                  y1: k + (c[f].margin + F),
                  x2: l,
                  y2: m,
                  width: l - h,
                  height: S
                }),
                c[f].title &&
                  (c[f]._titleTextBlock.maxWidth =
                    0 < c[f].titleMaxWidth && c[f].titleMaxWidth < c[f].lineCoordinates.width
                      ? c[f].titleMaxWidth
                      : c[f].lineCoordinates.width),
                (F += B[f] + c[f].margin));
          if (a && 0 < a.length)
            for (f = 0; f < a.length; f++) {
              K =
                a[f]._labels && 0 < a[f]._labels.length
                  ? a[f]._labels[0].textBlock.fontSize / 2
                  : 0;
              h = Math.round(g.x1 + e);
              k =
                c && 0 < c.length
                  ? Math.round(
                      c[0]
                        ? c[0].lineCoordinates.y2
                        : g.y1 < Math.max(K, 10)
                        ? Math.max(K, 10)
                        : g.y1
                    )
                  : g.y1 < Math.max(K, 10)
                  ? Math.max(K, 10)
                  : g.y1;
              l = Math.round(g.x1 + U + e);
              m =
                b && 0 < b.length
                  ? Math.round(
                      b[0]
                        ? b[0].lineCoordinates.y1
                        : g.y2 - R > p.height - Math.max(K, 10)
                        ? p.height - Math.max(K, 10)
                        : g.y2 - R
                    )
                  : g.y2 > p.height - Math.max(K, 10)
                  ? p.height - Math.max(K, 10)
                  : g.y2;
              if (b && 0 < b.length)
                for (K = 0; K < b.length; K++)
                  b[K] &&
                    b[K].labelAutoFit &&
                    ((l =
                      0 > b[K].labelAngle
                        ? Math.max(l, D)
                        : 0 === b[K].labelAngle
                        ? Math.max(l, D / 2)
                        : l),
                    (h = 0 > b[K].labelAngle || 0 === b[K].labelAngle ? l - U : h));
              if (c && 0 < c.length)
                for (K = 0; K < c.length; K++)
                  c[K] && c[K].labelAutoFit && ((l = c[K].lineCoordinates.x1), (h = l - U));
              a[f].lineCoordinates = {
                x1: l - P,
                y1: k,
                x2: l - P,
                y2: m,
                height: Math.abs(m - k)
              };
              a[f].bounds = {
                x1: l - (G[f] + P),
                y1: k,
                x2: l,
                y2: m,
                width: l - h,
                height: m - k
              };
              a[f].title &&
                (a[f]._titleTextBlock.maxWidth =
                  0 < a[f].titleMaxWidth && a[f].titleMaxWidth < a[f].lineCoordinates.height
                    ? a[f].titleMaxWidth
                    : a[f].lineCoordinates.height);
              a[f].calculateValueToPixelConversionParameters();
              a[f].calculateBreaksSizeInValues();
              P += G[f] + a[f].margin;
            }
          if (d && 0 < d.length)
            for (f = 0; f < d.length; f++) {
              K =
                d[f]._labels && 0 < d[f]._labels.length
                  ? d[f]._labels[0].textBlock.fontSize / 2
                  : 0;
              h = Math.round(g.x1 - e);
              k =
                c && 0 < c.length
                  ? Math.round(
                      c[0]
                        ? c[0].lineCoordinates.y2
                        : g.y1 < Math.max(K, 10)
                        ? Math.max(K, 10)
                        : g.y1
                    )
                  : g.y1 < Math.max(K, 10)
                  ? Math.max(K, 10)
                  : g.y1;
              l = Math.round(g.x2 - V - W);
              m =
                b && 0 < b.length
                  ? Math.round(
                      b[0]
                        ? b[0].lineCoordinates.y1
                        : g.y2 - R > p.height - Math.max(K, 10)
                        ? p.height - Math.max(K, 10)
                        : g.y2 - R
                    )
                  : g.y2 > p.height - Math.max(K, 10)
                  ? p.height - Math.max(K, 10)
                  : g.y2;
              if (b && 0 < b.length)
                for (K = 0; K < b.length; K++)
                  b[K] &&
                    b[K].labelAutoFit &&
                    ((l =
                      0 > b[K].labelAngle
                        ? Math.max(l, D)
                        : 0 === b[K].labelAngle
                        ? Math.max(l, D / 2)
                        : l),
                    (h = 0 > b[K].labelAngle || 0 === b[K].labelAngle ? l - V : h));
              if (c && 0 < c.length)
                for (K = 0; K < c.length; K++)
                  c[K] && c[K].labelAutoFit && ((l = c[K].lineCoordinates.x2), (h = l - V));
              d[f].lineCoordinates = {
                x1: l + Q,
                y1: k,
                x2: l + Q,
                y2: m,
                height: Math.abs(m - k)
              };
              d[f].bounds = { x1: h, y1: k, x2: l + y[f] + Q, y2: m, width: l - h, height: m - k };
              d[f].title &&
                (d[f]._titleTextBlock.maxWidth =
                  0 < d[f].titleMaxWidth && d[f].titleMaxWidth < d[f].lineCoordinates.height
                    ? d[f].titleMaxWidth
                    : d[f].lineCoordinates.height);
              d[f].calculateValueToPixelConversionParameters();
              d[f].calculateBreaksSizeInValues();
              Q += y[f] + d[f].margin;
            }
          for (f = 0; f < n.length; f++)
            "axisY" === n[f].type &&
              (n[f].calculateValueToPixelConversionParameters(),
              n[f].calculateBreaksSizeInValues());
          if (0 < w) {
            if (a && 0 < a.length)
              for (f = 0; f < a.length; f++) s = M[w - 1][f] === M[w][f] ? !0 : !1;
            else s = !0;
            if (d && 0 < d.length)
              for (f = 0; f < d.length; f++) H = N[w - 1][f] === N[w][f] ? !0 : !1;
            else H = !0;
            if (b && 0 < b.length)
              for (f = 0; f < b.length; f++) I = E[w - 1][f] === E[w][f] ? !0 : !1;
            else I = !0;
            if (c && 0 < c.length)
              for (f = 0; f < c.length; f++) L = t[w - 1][f] === t[w][f] ? !0 : !1;
            else L = !0;
          }
          if (s && H && I && L) break;
          w++;
        }
        if (b && 0 < b.length)
          for (f = 0; f < b.length; f++)
            b[f].calculateStripLinesThicknessInValues(), b[f].calculateBreaksInPixels();
        if (c && 0 < c.length)
          for (f = 0; f < c.length; f++)
            c[f].calculateStripLinesThicknessInValues(), c[f].calculateBreaksInPixels();
        if (a && 0 < a.length)
          for (f = 0; f < a.length; f++)
            a[f].calculateStripLinesThicknessInValues(), a[f].calculateBreaksInPixels();
        if (d && 0 < d.length)
          for (f = 0; f < d.length; f++)
            d[f].calculateStripLinesThicknessInValues(), d[f].calculateBreaksInPixels();
      }
    };
    y.render = function(a, d, b, c, e) {
      var g = a[0] ? a[0].chart : d[0].chart;
      e = g.ctx;
      var h = g._axes;
      g.alignVerticalAxes && g.alignVerticalAxes();
      e.save();
      e.beginPath();
      a[0] && e.rect(5, a[0].bounds.y1, a[0].chart.width - 10, a[0].bounds.height);
      d[0] && e.rect(5, d[d.length - 1].bounds.y1, d[0].chart.width - 10, d[0].bounds.height);
      e.clip();
      if (a && 0 < a.length) for (var k = 0; k < a.length; k++) a[k].renderLabelsTicksAndTitle();
      if (d && 0 < d.length) for (k = 0; k < d.length; k++) d[k].renderLabelsTicksAndTitle();
      e.restore();
      if (b && 0 < b.length) for (k = 0; k < b.length; k++) b[k].renderLabelsTicksAndTitle();
      if (c && 0 < c.length) for (k = 0; k < c.length; k++) c[k].renderLabelsTicksAndTitle();
      g.preparePlotArea();
      g = g.plotArea;
      e.save();
      e.beginPath();
      e.rect(g.x1, g.y1, Math.abs(g.x2 - g.x1), Math.abs(g.y2 - g.y1));
      e.clip();
      if (a && 0 < a.length)
        for (k = 0; k < h.length; k++) h[k].renderStripLinesOfThicknessType("value");
      if (d && 0 < d.length)
        for (k = 0; k < d.length; k++) d[k].renderStripLinesOfThicknessType("value");
      if (b && 0 < b.length)
        for (k = 0; k < b.length; k++) b[k].renderStripLinesOfThicknessType("value");
      if (c && 0 < c.length)
        for (k = 0; k < c.length; k++) c[k].renderStripLinesOfThicknessType("value");
      if (a && 0 < a.length) for (k = 0; k < a.length; k++) a[k].renderInterlacedColors();
      if (d && 0 < d.length) for (k = 0; k < d.length; k++) d[k].renderInterlacedColors();
      if (b && 0 < b.length) for (k = 0; k < b.length; k++) b[k].renderInterlacedColors();
      if (c && 0 < c.length) for (k = 0; k < c.length; k++) c[k].renderInterlacedColors();
      e.restore();
      if (a && 0 < a.length)
        for (k = 0; k < a.length; k++)
          a[k].renderGrid(), s && (a[k].createMask(), a[k].renderBreaksBackground());
      if (d && 0 < d.length)
        for (k = 0; k < d.length; k++)
          d[k].renderGrid(), s && (d[k].createMask(), d[k].renderBreaksBackground());
      if (b && 0 < b.length)
        for (k = 0; k < b.length; k++)
          b[k].renderGrid(), s && (b[k].createMask(), b[k].renderBreaksBackground());
      if (c && 0 < c.length)
        for (k = 0; k < c.length; k++)
          c[k].renderGrid(), s && (c[k].createMask(), c[k].renderBreaksBackground());
      if (a && 0 < a.length) for (k = 0; k < a.length; k++) a[k].renderAxisLine();
      if (d && 0 < d.length) for (k = 0; k < d.length; k++) d[k].renderAxisLine();
      if (b && 0 < b.length) for (k = 0; k < b.length; k++) b[k].renderAxisLine();
      if (c && 0 < c.length) for (k = 0; k < c.length; k++) c[k].renderAxisLine();
      if (a && 0 < a.length)
        for (k = 0; k < a.length; k++) a[k].renderStripLinesOfThicknessType("pixel");
      if (d && 0 < d.length)
        for (k = 0; k < d.length; k++) d[k].renderStripLinesOfThicknessType("pixel");
      if (b && 0 < b.length)
        for (k = 0; k < b.length; k++) b[k].renderStripLinesOfThicknessType("pixel");
      if (c && 0 < c.length)
        for (k = 0; k < c.length; k++) c[k].renderStripLinesOfThicknessType("pixel");
    };
    y.prototype.calculateStripLinesThicknessInValues = function() {
      for (var a = 0; a < this.stripLines.length; a++)
        if (null !== this.stripLines[a].startValue && null !== this.stripLines[a].endValue) {
          var d = Math.min(this.stripLines[a].startValue, this.stripLines[a].endValue),
            b = Math.max(this.stripLines[a].startValue, this.stripLines[a].endValue),
            d = this.getApparentDifference(d, b);
          this.stripLines[a].value = this.logarithmic
            ? this.stripLines[a].value *
              Math.sqrt(
                Math.log(this.stripLines[a].endValue / this.stripLines[a].startValue) / Math.log(d)
              )
            : this.stripLines[a].value +
              (Math.abs(this.stripLines[a].endValue - this.stripLines[a].startValue) - d) / 2;
          this.stripLines[a].thickness = d;
          this.stripLines[a]._thicknessType = "value";
        }
    };
    y.prototype.calculateBreaksSizeInValues = function() {
      for (
        var a =
            "left" === this._position || "right" === this._position
              ? this.lineCoordinates.height || this.chart.height
              : this.lineCoordinates.width || this.chart.width,
          d = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [],
          b =
            this.conversionParameters.pixelPerUnit ||
            a /
              (this.logarithmic
                ? this.conversionParameters.maximum / this.conversionParameters.minimum
                : this.conversionParameters.maximum - this.conversionParameters.minimum),
          c = this.scaleBreaks && !u(this.scaleBreaks.options.spacing),
          e,
          g = 0;
        g < d.length;
        g++
      )
        (e = c || !u(d[g].options.spacing)),
          (d[g].spacing = G(d[g].spacing, a, 8, e ? 0.1 * a : 8, e ? 0 : 3) << 0),
          (d[g].size = 0 > d[g].spacing ? 0 : Math.abs(d[g].spacing / b)),
          this.logarithmic && (d[g].size = Math.pow(this.logarithmBase, d[g].size));
    };
    y.prototype.calculateBreaksInPixels = function() {
      if (!(this.scaleBreaks && 0 >= this.scaleBreaks._appliedBreaks.length)) {
        var a = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [];
        a.length && (this.scaleBreaks.firstBreakIndex = this.scaleBreaks.lastBreakIndex = null);
        for (var d = 0; d < a.length && !(a[d].startValue > this.conversionParameters.maximum); d++)
          a[d].endValue < this.conversionParameters.minimum ||
            (u(this.scaleBreaks.firstBreakIndex) && (this.scaleBreaks.firstBreakIndex = d),
            a[d].startValue >= this.conversionParameters.minimum &&
              ((a[d].startPixel = this.convertValueToPixel(a[d].startValue)),
              (this.scaleBreaks.lastBreakIndex = d)),
            a[d].endValue <= this.conversionParameters.maximum &&
              (a[d].endPixel = this.convertValueToPixel(a[d].endValue)));
      }
    };
    y.prototype.renderLabelsTicksAndTitle = function() {
      var a = this,
        d = !1,
        b = 0,
        c = 0,
        e = 1,
        g = 0;
      0 !== this.labelAngle && 360 !== this.labelAngle && (e = 1.2);
      if ("undefined" === typeof this.options.interval) {
        if ("bottom" === this._position || "top" === this._position)
          if (this.logarithmic && !this.equidistantInterval && this.labelAutoFit) {
            for (
              var b = [],
                e = 0 !== this.labelAngle && 360 !== this.labelAngle ? 1 : 1.2,
                h,
                k = this.viewportMaximum,
                l = this.lineCoordinates.width / Math.log(this.range),
                m = this._labels.length - 1;
              0 <= m;
              m--
            ) {
              q = this._labels[m];
              if (q.position < this.viewportMinimum) break;
              q.position > this.viewportMaximum ||
                !(m === this._labels.length - 1 || h < (Math.log(k / q.position) * l) / e) ||
                (b.push(q),
                (k = q.position),
                (h =
                  q.textBlock.width * Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
                  q.textBlock.height * Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))));
            }
            this._labels = b;
          } else {
            for (m = 0; m < this._labels.length; m++)
              (q = this._labels[m]),
                q.position < this.viewportMinimum ||
                  ((h =
                    q.textBlock.width * Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
                    q.textBlock.height * Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))),
                  (b += h));
            b > this.lineCoordinates.width * e && this.labelAutoFit && (d = !0);
          }
        if ("left" === this._position || "right" === this._position)
          if (this.logarithmic && !this.equidistantInterval && this.labelAutoFit) {
            for (
              var b = [],
                p,
                k = this.viewportMaximum,
                l = this.lineCoordinates.height / Math.log(this.range),
                m = this._labels.length - 1;
              0 <= m;
              m--
            ) {
              q = this._labels[m];
              if (q.position < this.viewportMinimum) break;
              q.position > this.viewportMaximum ||
                !(m === this._labels.length - 1 || p < Math.log(k / q.position) * l) ||
                (b.push(q),
                (k = q.position),
                (p =
                  q.textBlock.height * Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
                  q.textBlock.width * Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))));
            }
            this._labels = b;
          } else {
            for (m = 0; m < this._labels.length; m++)
              (q = this._labels[m]),
                q.position < this.viewportMinimum ||
                  ((p =
                    q.textBlock.height * Math.abs(Math.cos((Math.PI / 180) * this.labelAngle)) +
                    q.textBlock.width * Math.abs(Math.sin((Math.PI / 180) * this.labelAngle))),
                  (c += p));
            c > this.lineCoordinates.height * e && this.labelAutoFit && (d = !0);
          }
      }
      this.logarithmic &&
        !this.equidistantInterval && this.labelAutoFit &&
        this._labels.sort(function(a, b) {
          return a.position - b.position;
        });
      var m = 0,
        q,
        n;
      if ("bottom" === this._position) {
        for (m = 0; m < this._labels.length; m++)
          (q = this._labels[m]),
            q.position < this.viewportMinimum ||
              q.position > this.viewportMaximum || (d && 0 !== g++ % 2 && this.labelAutoFit) ||
              ((n = this.getPixelCoordinatesOnAxis(q.position)),
              this.tickThickness &&
                "inside" != this.labelPlacement &&
                ((this.ctx.lineWidth = this.tickThickness),
                (this.ctx.strokeStyle = this.tickColor),
                (c = 1 === this.ctx.lineWidth % 2 ? (n.x << 0) + 0.5 : n.x << 0),
                this.ctx.beginPath(),
                this.ctx.moveTo(c, n.y << 0),
                this.ctx.lineTo(c, (n.y + this.tickLength) << 0),
                this.ctx.stroke()),
              0 === q.textBlock.angle
                ? ((n.x -= q.textBlock.width / 2),
                  (n.y =
                    "inside" === this.labelPlacement
                      ? n.y - (this.tickLength + q.textBlock.fontSize / 2)
                      : n.y + this.tickLength + q.textBlock.fontSize / 2))
                : ((n.x =
                    "inside" === this.labelPlacement
                      ? 0 > this.labelAngle
                        ? n.x
                        : n.x - q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle)
                      : n.x -
                        (0 > this.labelAngle
                          ? q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle)
                          : 0)),
                  (n.y =
                    "inside" === this.labelPlacement
                      ? 0 > this.labelAngle
                        ? n.y - this.tickLength - 5
                        : n.y -
                          this.tickLength -
                          Math.abs(
                            q.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle) + 5
                          )
                      : n.y +
                        this.tickLength +
                        Math.abs(
                          0 > this.labelAngle
                            ? q.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle) - 5
                            : 5
                        ))),
              (q.textBlock.x = n.x),
              (q.textBlock.y = n.y));
        "inside" === this.labelPlacement &&
          this.chart.addEventListener(
            "dataAnimationIterationEnd",
            function() {
              for (m = 0; m < a._labels.length; m++)
                if (
                  ((q = a._labels[m]),
                  !(
                    q.position < a.viewportMinimum ||
                    q.position > a.viewportMaximum ||
                    (d && 0 !== g++ % 2 && a.labelAutoFit)
                  ) && ((n = a.getPixelCoordinatesOnAxis(q.position)), a.tickThickness))
                ) {
                  a.ctx.lineWidth = a.tickThickness;
                  a.ctx.strokeStyle = a.tickColor;
                  var b = 1 === a.ctx.lineWidth % 2 ? (n.x << 0) + 0.5 : n.x << 0;
                  a.ctx.save();
                  a.ctx.beginPath();
                  a.ctx.moveTo(b, n.y << 0);
                  a.ctx.lineTo(b, (n.y - a.tickLength) << 0);
                  a.ctx.stroke();
                  a.ctx.restore();
                }
            },
            this
          );
        this.title &&
          (this._titleTextBlock.measureText(),
          (this._titleTextBlock.x =
            this.lineCoordinates.x1 +
            this.lineCoordinates.width / 2 -
            this._titleTextBlock.width / 2),
          (this._titleTextBlock.y = this.bounds.y2 - this._titleTextBlock.height - 3),
          (this.titleMaxWidth = this._titleTextBlock.maxWidth),
          this._titleTextBlock.render(!0));
      } else if ("top" === this._position) {
        for (m = 0; m < this._labels.length; m++)
          (q = this._labels[m]),
            q.position < this.viewportMinimum ||
              q.position > this.viewportMaximum || (d && 0 !== g++ % 2 && this.labelAutoFit) ||
              ((n = this.getPixelCoordinatesOnAxis(q.position)),
              this.tickThickness &&
                "inside" != this.labelPlacement &&
                ((this.ctx.lineWidth = this.tickThickness),
                (this.ctx.strokeStyle = this.tickColor),
                (c = 1 === this.ctx.lineWidth % 2 ? (n.x << 0) + 0.5 : n.x << 0),
                this.ctx.beginPath(),
                this.ctx.moveTo(c, n.y << 0),
                this.ctx.lineTo(c, (n.y - this.tickLength) << 0),
                this.ctx.stroke()),
              0 === q.textBlock.angle
                ? ((n.x -= q.textBlock.width / 2),
                  (n.y =
                    "inside" === this.labelPlacement
                      ? n.y + this.labelFontSize / 2 + this.tickLength + 5
                      : n.y - (this.tickLength + q.textBlock.height - q.textBlock.fontSize / 2)))
                : ((n.x =
                    "inside" === this.labelPlacement
                      ? 0 < this.labelAngle
                        ? n.x
                        : n.x - q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle)
                      : n.x +
                        (q.textBlock.height - this.tickLength - this.labelFontSize) *
                          Math.sin((Math.PI / 180) * this.labelAngle) -
                        (0 < this.labelAngle
                          ? q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle)
                          : 0)),
                  (n.y =
                    "inside" === this.labelPlacement
                      ? 0 < this.labelAngle
                        ? n.y + this.tickLength + 5
                        : n.y -
                          q.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle) +
                          this.tickLength +
                          5
                      : n.y -
                        (this.tickLength +
                          ((q.textBlock.height - q.textBlock.fontSize / 2) *
                            Math.cos((Math.PI / 180) * this.labelAngle) +
                            (0 < this.labelAngle
                              ? q.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle)
                              : 0))))),
              (q.textBlock.x = n.x),
              (q.textBlock.y = n.y));
        "inside" === this.labelPlacement &&
          this.chart.addEventListener(
            "dataAnimationIterationEnd",
            function() {
              for (m = 0; m < a._labels.length; m++)
                if (
                  ((q = a._labels[m]),
                  !(
                    q.position < a.viewportMinimum ||
                    q.position > a.viewportMaximum ||
                    (d && 0 !== g++ % 2 && a.labelAutoFit)
                  ) && ((n = a.getPixelCoordinatesOnAxis(q.position)), a.tickThickness))
                ) {
                  a.ctx.lineWidth = a.tickThickness;
                  a.ctx.strokeStyle = a.tickColor;
                  var b = 1 === this.ctx.lineWidth % 2 ? (n.x << 0) + 0.5 : n.x << 0;
                  a.ctx.save();
                  a.ctx.beginPath();
                  a.ctx.moveTo(b, n.y << 0);
                  a.ctx.lineTo(b, (n.y + a.tickLength) << 0);
                  a.ctx.stroke();
                  a.ctx.restore();
                }
            },
            this
          );
        this.title &&
          (this._titleTextBlock.measureText(),
          (this._titleTextBlock.x =
            this.lineCoordinates.x1 +
            this.lineCoordinates.width / 2 -
            this._titleTextBlock.width / 2),
          (this._titleTextBlock.y = this.bounds.y1 + 1),
          (this.titleMaxWidth = this._titleTextBlock.maxWidth),
          this._titleTextBlock.render(!0));
      } else if ("left" === this._position) {
        for (m = 0; m < this._labels.length; m++)
          (q = this._labels[m]),
            q.position < this.viewportMinimum ||
              q.position > this.viewportMaximum || (d && 0 !== g++ % 2 && this.labelAutoFit) ||
              ((n = this.getPixelCoordinatesOnAxis(q.position)),
              this.tickThickness &&
                "inside" != this.labelPlacement &&
                ((this.ctx.lineWidth = this.tickThickness),
                (this.ctx.strokeStyle = this.tickColor),
                (c = 1 === this.ctx.lineWidth % 2 ? (n.y << 0) + 0.5 : n.y << 0),
                this.ctx.beginPath(),
                this.ctx.moveTo(n.x << 0, c),
                this.ctx.lineTo((n.x - this.tickLength) << 0, c),
                this.ctx.stroke()),
              0 === this.labelAngle
                ? ((q.textBlock.y = n.y),
                  (q.textBlock.x =
                    "inside" === this.labelPlacement
                      ? n.x + this.tickLength + 5
                      : n.x -
                        q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) -
                        this.tickLength -
                        5))
                : ((q.textBlock.y =
                    "inside" === this.labelPlacement
                      ? n.y
                      : n.y - q.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle)),
                  (q.textBlock.x =
                    "inside" === this.labelPlacement
                      ? n.x + this.tickLength + 5
                      : 0 < this.labelAngle
                      ? n.x -
                        q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) -
                        this.tickLength -
                        5
                      : n.x -
                        q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) +
                        (q.textBlock.height - q.textBlock.fontSize / 2 - 5) *
                          Math.sin((Math.PI / 180) * this.labelAngle) -
                        this.tickLength)));
        "inside" === this.labelPlacement &&
          this.chart.addEventListener(
            "dataAnimationIterationEnd",
            function() {
              for (m = 0; m < a._labels.length; m++)
                if (
                  ((q = a._labels[m]),
                  !(
                    q.position < a.viewportMinimum ||
                    q.position > a.viewportMaximum ||
                    (d && 0 !== g++ % 2 && a.labelAutoFit)
                  ) && ((n = a.getPixelCoordinatesOnAxis(q.position)), a.tickThickness))
                ) {
                  a.ctx.lineWidth = a.tickThickness;
                  a.ctx.strokeStyle = a.tickColor;
                  var b = 1 === a.ctx.lineWidth % 2 ? (n.y << 0) + 0.5 : n.y << 0;
                  a.ctx.save();
                  a.ctx.beginPath();
                  a.ctx.moveTo(n.x << 0, b);
                  a.ctx.lineTo((n.x + a.tickLength) << 0, b);
                  a.ctx.stroke();
                  a.ctx.restore();
                }
            },
            this
          );
        this.title &&
          (this._titleTextBlock.measureText(),
          (this._titleTextBlock.x = this.bounds.x1 + 1),
          (this._titleTextBlock.y =
            this.lineCoordinates.height / 2 +
            this._titleTextBlock.width / 2 +
            this.lineCoordinates.y1),
          (this.titleMaxWidth = this._titleTextBlock.maxWidth),
          this._titleTextBlock.render(!0));
      } else if ("right" === this._position) {
        for (m = 0; m < this._labels.length; m++)
          (q = this._labels[m]),
            q.position < this.viewportMinimum ||
              q.position > this.viewportMaximum || (d && 0 !== g++ % 2 && this.labelAutoFit) ||
              ((n = this.getPixelCoordinatesOnAxis(q.position)),
              this.tickThickness &&
                "inside" != this.labelPlacement &&
                ((this.ctx.lineWidth = this.tickThickness),
                (this.ctx.strokeStyle = this.tickColor),
                (c = 1 === this.ctx.lineWidth % 2 ? (n.y << 0) + 0.5 : n.y << 0),
                this.ctx.beginPath(),
                this.ctx.moveTo(n.x << 0, c),
                this.ctx.lineTo((n.x + this.tickLength) << 0, c),
                this.ctx.stroke()),
              0 === this.labelAngle
                ? ((q.textBlock.y = n.y),
                  (q.textBlock.x =
                    "inside" === this.labelPlacement
                      ? n.x - q.textBlock.width - this.tickLength - 5
                      : n.x + this.tickLength + 5))
                : ((q.textBlock.y =
                    "inside" === this.labelPlacement
                      ? n.y - q.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle)
                      : 0 > this.labelAngle
                      ? n.y
                      : n.y -
                        (q.textBlock.height - q.textBlock.fontSize / 2 - 5) *
                          Math.cos((Math.PI / 180) * this.labelAngle)),
                  (q.textBlock.x =
                    "inside" === this.labelPlacement
                      ? n.x -
                        q.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) -
                        this.tickLength -
                        5
                      : 0 < this.labelAngle
                      ? n.x +
                        (q.textBlock.height - q.textBlock.fontSize / 2 - 5) *
                          Math.sin((Math.PI / 180) * this.labelAngle) +
                        this.tickLength
                      : n.x + this.tickLength + 5)));
        "inside" === this.labelPlacement &&
          this.chart.addEventListener(
            "dataAnimationIterationEnd",
            function() {
              for (m = 0; m < a._labels.length; m++)
                if (
                  ((q = a._labels[m]),
                  !(
                    q.position < a.viewportMinimum ||
                    q.position > a.viewportMaximum ||
                    (d && 0 !== g++ % 2 && a.labelAutoFit)
                  ) && ((n = a.getPixelCoordinatesOnAxis(q.position)), a.tickThickness))
                ) {
                  a.ctx.lineWidth = a.tickThickness;
                  a.ctx.strokeStyle = a.tickColor;
                  var b = 1 === a.ctx.lineWidth % 2 ? (n.y << 0) + 0.5 : n.y << 0;
                  a.ctx.save();
                  a.ctx.beginPath();
                  a.ctx.moveTo(n.x << 0, b);
                  a.ctx.lineTo((n.x - a.tickLength) << 0, b);
                  a.ctx.stroke();
                  a.ctx.restore();
                }
            },
            this
          );
        this.title &&
          (this._titleTextBlock.measureText(),
          (this._titleTextBlock.x = this.bounds.x2 - 1),
          (this._titleTextBlock.y =
            this.lineCoordinates.height / 2 -
            this._titleTextBlock.width / 2 +
            this.lineCoordinates.y1),
          (this.titleMaxWidth = this._titleTextBlock.maxWidth),
          this._titleTextBlock.render(!0));
      }
      g = 0;
      if ("inside" === this.labelPlacement)
        this.chart.addEventListener(
          "dataAnimationIterationEnd",
          function() {
            for (m = 0; m < a._labels.length; m++)
              (q = a._labels[m]),
                q.position < a.viewportMinimum ||
                  q.position > a.viewportMaximum || (d && 0 !== g++ % 2 && a.labelAutoFit) ||
                  (a.ctx.save(), a.ctx.beginPath(), q.textBlock.render(!0), a.ctx.restore());
          },
          this
        );
      else
        for (m = 0; m < this._labels.length; m++)
          (q = this._labels[m]),
            q.position < this.viewportMinimum ||
              q.position > this.viewportMaximum || (d && 0 !== g++ % 2 && this.labelAutoFit) ||
              q.textBlock.render(!0);
    };
    y.prototype.renderInterlacedColors = function() {
      var a = this.chart.plotArea.ctx,
        d,
        b,
        c = this.chart.plotArea,
        e = 0;
      d = !0;
      if (("bottom" === this._position || "top" === this._position) && this.interlacedColor)
        for (a.fillStyle = this.interlacedColor, e = 0; e < this._labels.length; e++)
          d
            ? ((d = this.getPixelCoordinatesOnAxis(this._labels[e].position)),
              (b =
                e + 1 > this._labels.length - 1
                  ? this.getPixelCoordinatesOnAxis(this.viewportMaximum)
                  : this.getPixelCoordinatesOnAxis(this._labels[e + 1].position)),
              a.fillRect(Math.min(b.x, d.x), c.y1, Math.abs(b.x - d.x), Math.abs(c.y1 - c.y2)),
              (d = !1))
            : (d = !0);
      else if (("left" === this._position || "right" === this._position) && this.interlacedColor)
        for (a.fillStyle = this.interlacedColor, e = 0; e < this._labels.length; e++)
          d
            ? ((b = this.getPixelCoordinatesOnAxis(this._labels[e].position)),
              (d =
                e + 1 > this._labels.length - 1
                  ? this.getPixelCoordinatesOnAxis(this.viewportMaximum)
                  : this.getPixelCoordinatesOnAxis(this._labels[e + 1].position)),
              a.fillRect(c.x1, Math.min(b.y, d.y), Math.abs(c.x1 - c.x2), Math.abs(d.y - b.y)),
              (d = !1))
            : (d = !0);
      a.beginPath();
    };
    y.prototype.renderStripLinesOfThicknessType = function(a) {
      if (this.stripLines && 0 < this.stripLines.length && a) {
        for (
          var d = this, b, c = 0, e = 0, g = !1, h = !1, k = [], l = [], h = !1, c = 0;
          c < this.stripLines.length;
          c++
        ) {
          var m = this.stripLines[c];
          m._thicknessType === a &&
            (("pixel" === a &&
              (m.value < this.viewportMinimum ||
                m.value > this.viewportMaximum ||
                u(m.value) ||
                isNaN(this.range))) ||
              k.push(m));
        }
        for (c = 0; c < this._stripLineLabels.length; c++)
          if (
            ((m = this.stripLines[c]),
            (b = this._stripLineLabels[c]),
            !(
              b.position < this.viewportMinimum ||
              b.position > this.viewportMaximum ||
              isNaN(this.range)
            ))
          ) {
            a = this.getPixelCoordinatesOnAxis(b.position);
            if ("outside" === b.stripLine.labelPlacement)
              if (
                (m &&
                  ((this.ctx.strokeStyle = m.color),
                  "pixel" === m._thicknessType && (this.ctx.lineWidth = m.thickness)),
                "bottom" === this._position)
              ) {
                var p = 1 === this.ctx.lineWidth % 2 ? (a.x << 0) + 0.5 : a.x << 0;
                this.ctx.beginPath();
                this.ctx.moveTo(p, a.y << 0);
                this.ctx.lineTo(p, (a.y + this.tickLength) << 0);
                this.ctx.stroke();
                0 === this.labelAngle
                  ? ((a.x -= b.textBlock.width / 2),
                    (a.y += this.tickLength + b.textBlock.fontSize / 2))
                  : ((a.x -=
                      0 > this.labelAngle
                        ? b.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle)
                        : 0),
                    (a.y +=
                      this.tickLength +
                      Math.abs(
                        0 > this.labelAngle
                          ? b.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle) - 5
                          : 5
                      )));
              } else
                "top" === this._position
                  ? ((p = 1 === this.ctx.lineWidth % 2 ? (a.x << 0) + 0.5 : a.x << 0),
                    this.ctx.beginPath(),
                    this.ctx.moveTo(p, a.y << 0),
                    this.ctx.lineTo(p, (a.y - this.tickLength) << 0),
                    this.ctx.stroke(),
                    0 === this.labelAngle
                      ? ((a.x -= b.textBlock.width / 2),
                        (a.y -= this.tickLength + b.textBlock.height))
                      : ((a.x +=
                          (b.textBlock.height - this.tickLength - this.labelFontSize / 2) *
                            Math.sin((Math.PI / 180) * this.labelAngle) -
                          (0 < this.labelAngle
                            ? b.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle)
                            : 0)),
                        (a.y -=
                          this.tickLength +
                          (b.textBlock.height * Math.cos((Math.PI / 180) * this.labelAngle) +
                            (0 < this.labelAngle
                              ? b.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle)
                              : 0)))))
                  : "left" === this._position
                  ? ((p = 1 === this.ctx.lineWidth % 2 ? (a.y << 0) + 0.5 : a.y << 0),
                    this.ctx.beginPath(),
                    this.ctx.moveTo(a.x << 0, p),
                    this.ctx.lineTo((a.x - this.tickLength) << 0, p),
                    this.ctx.stroke(),
                    0 === this.labelAngle
                      ? (a.x =
                          a.x -
                          b.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) -
                          this.tickLength -
                          5)
                      : ((a.y -= b.textBlock.width * Math.sin((Math.PI / 180) * this.labelAngle)),
                        (a.x =
                          0 < this.labelAngle
                            ? a.x -
                              b.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) -
                              this.tickLength -
                              5
                            : a.x -
                              b.textBlock.width * Math.cos((Math.PI / 180) * this.labelAngle) +
                              (b.textBlock.height - b.textBlock.fontSize / 2 - 5) *
                                Math.sin((Math.PI / 180) * this.labelAngle) -
                              this.tickLength)))
                  : "right" === this._position &&
                    ((p = 1 === this.ctx.lineWidth % 2 ? (a.y << 0) + 0.5 : a.y << 0),
                    this.ctx.beginPath(),
                    this.ctx.moveTo(a.x << 0, p),
                    this.ctx.lineTo((a.x + this.tickLength) << 0, p),
                    this.ctx.stroke(),
                    0 === this.labelAngle
                      ? (a.x = a.x + this.tickLength + 5)
                      : ((a.y =
                          0 > this.labelAngle
                            ? a.y
                            : a.y -
                              (b.textBlock.height - b.textBlock.fontSize / 2 - 5) *
                                Math.cos((Math.PI / 180) * this.labelAngle)),
                        (a.x =
                          0 < this.labelAngle
                            ? a.x +
                              (b.textBlock.height - b.textBlock.fontSize / 2 - 5) *
                                Math.sin((Math.PI / 180) * this.labelAngle) +
                              this.tickLength
                            : a.x + this.tickLength + 5)));
            else
              (b.textBlock.angle = -90),
                "bottom" === this._position
                  ? ((b.textBlock.maxWidth = this.options.stripLines[c].labelMaxWidth
                      ? this.options.stripLines[c].labelMaxWidth
                      : this.chart.plotArea.height - 3),
                    b.textBlock.measureText(),
                    a.x - b.textBlock.height > this.chart.plotArea.x1
                      ? u(m.startValue)
                        ? (a.x -= b.textBlock.height - b.textBlock.fontSize / 2)
                        : (a.x -= b.textBlock.height / 2 - b.textBlock.fontSize / 2 + 3)
                      : ((b.textBlock.angle = 90),
                        u(m.startValue)
                          ? (a.x += b.textBlock.height - b.textBlock.fontSize / 2)
                          : (a.x += b.textBlock.height / 2 - b.textBlock.fontSize / 2 + 3)),
                    (a.y =
                      -90 === b.textBlock.angle
                        ? "near" === b.stripLine.labelAlign
                          ? this.chart.plotArea.y2 - 3
                          : "center" === b.stripLine.labelAlign
                          ? (this.chart.plotArea.y2 + this.chart.plotArea.y1 + b.textBlock.width) /
                            2
                          : this.chart.plotArea.y1 + b.textBlock.width + 3
                        : "near" === b.stripLine.labelAlign
                        ? this.chart.plotArea.y2 - b.textBlock.width - 3
                        : "center" === b.stripLine.labelAlign
                        ? (this.chart.plotArea.y2 + this.chart.plotArea.y1 - b.textBlock.width) / 2
                        : this.chart.plotArea.y1 + 3))
                  : "top" === this._position
                  ? ((b.textBlock.maxWidth = this.options.stripLines[c].labelMaxWidth
                      ? this.options.stripLines[c].labelMaxWidth
                      : this.chart.plotArea.height - 3),
                    b.textBlock.measureText(),
                    a.x - b.textBlock.height > this.chart.plotArea.x1
                      ? u(m.startValue)
                        ? (a.x -= b.textBlock.height - b.textBlock.fontSize / 2)
                        : (a.x -= b.textBlock.height / 2 - b.textBlock.fontSize / 2 + 3)
                      : ((b.textBlock.angle = 90),
                        u(m.startValue)
                          ? (a.x += b.textBlock.height - b.textBlock.fontSize / 2)
                          : (a.x += b.textBlock.height / 2 - b.textBlock.fontSize / 2 + 3)),
                    (a.y =
                      -90 === b.textBlock.angle
                        ? "near" === b.stripLine.labelAlign
                          ? this.chart.plotArea.y1 + b.textBlock.width + 3
                          : "center" === b.stripLine.labelAlign
                          ? (this.chart.plotArea.y2 + this.chart.plotArea.y1 + b.textBlock.width) /
                            2
                          : this.chart.plotArea.y2 - 3
                        : "near" === b.stripLine.labelAlign
                        ? this.chart.plotArea.y1 + 3
                        : "center" === b.stripLine.labelAlign
                        ? (this.chart.plotArea.y2 + this.chart.plotArea.y1 - b.textBlock.width) / 2
                        : this.chart.plotArea.y2 - b.textBlock.width - 3))
                  : "left" === this._position
                  ? ((b.textBlock.maxWidth = this.options.stripLines[c].labelMaxWidth
                      ? this.options.stripLines[c].labelMaxWidth
                      : this.chart.plotArea.width - 3),
                    (b.textBlock.angle = 0),
                    b.textBlock.measureText(),
                    a.y - b.textBlock.height > this.chart.plotArea.y1
                      ? u(m.startValue)
                        ? (a.y -= b.textBlock.height - b.textBlock.fontSize / 2)
                        : (a.y -= b.textBlock.height / 2 - b.textBlock.fontSize + 3)
                      : a.y - b.textBlock.height < this.chart.plotArea.y2
                      ? (a.y += b.textBlock.fontSize / 2 + 3)
                      : u(m.startValue)
                      ? (a.y -= b.textBlock.height - b.textBlock.fontSize / 2)
                      : (a.y -= b.textBlock.height / 2 - b.textBlock.fontSize + 3),
                    (a.x =
                      "near" === b.stripLine.labelAlign
                        ? this.chart.plotArea.x1 + 3
                        : "center" === b.stripLine.labelAlign
                        ? (this.chart.plotArea.x2 + this.chart.plotArea.x1) / 2 -
                          b.textBlock.width / 2
                        : this.chart.plotArea.x2 - b.textBlock.width - 3))
                  : "right" === this._position &&
                    ((b.textBlock.maxWidth = this.options.stripLines[c].labelMaxWidth
                      ? this.options.stripLines[c].labelMaxWidth
                      : this.chart.plotArea.width - 3),
                    (b.textBlock.angle = 0),
                    b.textBlock.measureText(),
                    a.y - +b.textBlock.height > this.chart.plotArea.y1
                      ? u(m.startValue)
                        ? (a.y -= b.textBlock.height - b.textBlock.fontSize / 2)
                        : (a.y -= b.textBlock.height / 2 - b.textBlock.fontSize / 2 - 3)
                      : a.y - b.textBlock.height < this.chart.plotArea.y2
                      ? (a.y += b.textBlock.fontSize / 2 + 3)
                      : u(m.startValue)
                      ? (a.y -= b.textBlock.height - b.textBlock.fontSize / 2)
                      : (a.y -= b.textBlock.height / 2 - b.textBlock.fontSize / 2 + 3),
                    (a.x =
                      "near" === b.stripLine.labelAlign
                        ? this.chart.plotArea.x2 - b.textBlock.width - 3
                        : "center" === b.stripLine.labelAlign
                        ? (this.chart.plotArea.x2 + this.chart.plotArea.x1) / 2 -
                          b.textBlock.width / 2
                        : this.chart.plotArea.x1 + 3));
            b.textBlock.x = a.x;
            b.textBlock.y = a.y;
            l.push(b);
          }
        if (!h) {
          h = !1;
          this.ctx.save();
          this.ctx.beginPath();
          this.ctx.rect(
            this.chart.plotArea.x1,
            this.chart.plotArea.y1,
            this.chart.plotArea.width,
            this.chart.plotArea.height
          );
          this.ctx.clip();
          for (c = 0; c < k.length; c++)
            (m = k[c]),
              m.showOnTop
                ? g ||
                  ((g = !0),
                  this.chart.addEventListener(
                    "dataAnimationIterationEnd",
                    function() {
                      this.ctx.save();
                      this.ctx.beginPath();
                      this.ctx.rect(
                        this.chart.plotArea.x1,
                        this.chart.plotArea.y1,
                        this.chart.plotArea.width,
                        this.chart.plotArea.height
                      );
                      this.ctx.clip();
                      for (e = 0; e < k.length; e++) (m = k[e]), m.showOnTop && m.render();
                      this.ctx.restore();
                    },
                    m
                  ))
                : m.render();
          for (c = 0; c < l.length; c++)
            (b = l[c]),
              b.stripLine.showOnTop
                ? h ||
                  ((h = !0),
                  this.chart.addEventListener(
                    "dataAnimationIterationEnd",
                    function() {
                      for (e = 0; e < l.length; e++)
                        (b = l[e]),
                          "inside" === b.stripLine.labelPlacement &&
                            b.stripLine.showOnTop &&
                            (d.ctx.save(),
                            d.ctx.beginPath(),
                            d.ctx.rect(
                              d.chart.plotArea.x1,
                              d.chart.plotArea.y1,
                              d.chart.plotArea.width,
                              d.chart.plotArea.height
                            ),
                            d.ctx.clip(),
                            b.textBlock.render(!0),
                            d.ctx.restore());
                    },
                    b.textBlock
                  ))
                : "inside" === b.stripLine.labelPlacement && b.textBlock.render(!0);
          this.ctx.restore();
          h = !0;
        }
        if (h)
          for (h = !1, c = 0; c < l.length; c++)
            (b = l[c]),
              b.stripLine.showOnTop
                ? h ||
                  ((h = !0),
                  this.chart.addEventListener(
                    "dataAnimationIterationEnd",
                    function() {
                      for (e = 0; e < l.length; e++)
                        (b = l[e]),
                          "outside" === b.stripLine.labelPlacement &&
                            b.stripLine.showOnTop &&
                            b.textBlock.render(!0);
                    },
                    b.textBlock
                  ))
                : "outside" === b.stripLine.labelPlacement && b.textBlock.render(!0);
      }
    };
    y.prototype.renderBreaksBackground = function() {
      this.chart._breaksCanvas &&
        this.scaleBreaks && 0 < this.scaleBreaks._appliedBreaks.length && this.maskCanvas &&
        (this.chart._breaksCanvasCtx.save(),
        this.chart._breaksCanvasCtx.beginPath(),
        this.chart._breaksCanvasCtx.rect(
          this.chart.plotArea.x1,
          this.chart.plotArea.y1,
          this.chart.plotArea.width,
          this.chart.plotArea.height
        ),
        this.chart._breaksCanvasCtx.clip(),
        this.chart._breaksCanvasCtx.drawImage(
          this.maskCanvas,
          0,
          0,
          this.chart.width,
          this.chart.height
        ),
        this.chart._breaksCanvasCtx.restore());
    };
    y.prototype.createMask = function() {
      if (this.scaleBreaks && 0 < this.scaleBreaks._appliedBreaks.length) {
        var a = this.scaleBreaks._appliedBreaks;
        s
          ? ((this.maskCanvas = ua(this.chart.width, this.chart.height)),
            (this.maskCtx = this.maskCanvas.getContext("2d")))
          : ((this.maskCanvas = this.chart.plotArea.canvas),
            (this.maskCtx = this.chart.plotArea.ctx));
        this.maskCtx.save();
        this.maskCtx.beginPath();
        this.maskCtx.rect(
          this.chart.plotArea.x1,
          this.chart.plotArea.y1,
          this.chart.plotArea.width,
          this.chart.plotArea.height
        );
        this.maskCtx.clip();
        for (var d = 0; d < a.length; d++)
          a[d].endValue < this.viewportMinimum ||
            a[d].startValue > this.viewportMaximum || isNaN(this.range) ||
            a[d].render(this.maskCtx);
        this.maskCtx.restore();
      }
    };
    y.prototype.renderCrosshair = function(a, d) {
      this.crosshair.render(a, d);
    };
    y.prototype.renderGrid = function() {
      if (this.gridThickness && 0 < this.gridThickness) {
        var a = this.chart.ctx;
        a.save();
        var d,
          b = this.chart.plotArea;
        a.lineWidth = this.gridThickness;
        a.strokeStyle = this.gridColor;
        a.setLineDash && a.setLineDash(Q(this.gridDashType, this.gridThickness));
        if ("bottom" === this._position || "top" === this._position)
          for (c = 0; c < this._labels.length; c++)
            this._labels[c].position < this.viewportMinimum ||
              this._labels[c].position > this.viewportMaximum || this._labels[c].breaksLabelType ||
              (a.beginPath(),
              (d = this.getPixelCoordinatesOnAxis(this._labels[c].position)),
              (d = 1 === a.lineWidth % 2 ? (d.x << 0) + 0.5 : d.x << 0),
              a.moveTo(d, b.y1 << 0),
              a.lineTo(d, b.y2 << 0),
              a.stroke());
        else if ("left" === this._position || "right" === this._position)
          for (var c = 0; c < this._labels.length; c++)
            this._labels[c].position < this.viewportMinimum ||
              this._labels[c].position > this.viewportMaximum || this._labels[c].breaksLabelType ||
              (a.beginPath(),
              (d = this.getPixelCoordinatesOnAxis(this._labels[c].position)),
              (d = 1 === a.lineWidth % 2 ? (d.y << 0) + 0.5 : d.y << 0),
              a.moveTo(b.x1 << 0, d),
              a.lineTo(b.x2 << 0, d),
              a.stroke());
        a.restore();
      }
    };
    y.prototype.renderAxisLine = function() {
      var a = this.chart.ctx,
        d = s ? this.chart._preRenderCtx : a,
        b = Math.ceil(this.tickThickness / (this.reversed ? -2 : 2)),
        c = Math.ceil(this.tickThickness / (this.reversed ? 2 : -2)),
        e,
        g;
      d.save();
      if ("bottom" === this._position || "top" === this._position) {
        if (this.lineThickness) {
          this.reversed
            ? ((e = this.lineCoordinates.x2), (g = this.lineCoordinates.x1))
            : ((e = this.lineCoordinates.x1), (g = this.lineCoordinates.x2));
          d.lineWidth = this.lineThickness;
          d.strokeStyle = this.lineColor ? this.lineColor : "black";
          d.setLineDash && d.setLineDash(Q(this.lineDashType, this.lineThickness));
          var h =
            1 === this.lineThickness % 2
              ? (this.lineCoordinates.y1 << 0) + 0.5
              : this.lineCoordinates.y1 << 0;
          d.beginPath();
          if (this.scaleBreaks && !u(this.scaleBreaks.firstBreakIndex))
            if (u(this.scaleBreaks.lastBreakIndex))
              e = this.scaleBreaks._appliedBreaks[this.scaleBreaks.firstBreakIndex].endPixel + c;
            else
              for (
                var k = this.scaleBreaks.firstBreakIndex;
                k <= this.scaleBreaks.lastBreakIndex;
                k++
              )
                d.moveTo(e, h),
                  d.lineTo(this.scaleBreaks._appliedBreaks[k].startPixel + b, h),
                  (e = this.scaleBreaks._appliedBreaks[k].endPixel + c);
          e && (d.moveTo(e, h), d.lineTo(g, h));
          d.stroke();
        }
      } else if (("left" === this._position || "right" === this._position) && this.lineThickness) {
        this.reversed
          ? ((e = this.lineCoordinates.y1), (g = this.lineCoordinates.y2))
          : ((e = this.lineCoordinates.y2), (g = this.lineCoordinates.y1));
        d.lineWidth = this.lineThickness;
        d.strokeStyle = this.lineColor;
        d.setLineDash && d.setLineDash(Q(this.lineDashType, this.lineThickness));
        h =
          1 === this.lineThickness % 2
            ? (this.lineCoordinates.x1 << 0) + 0.5
            : this.lineCoordinates.x1 << 0;
        d.beginPath();
        if (this.scaleBreaks && !u(this.scaleBreaks.firstBreakIndex))
          if (u(this.scaleBreaks.lastBreakIndex))
            e = this.scaleBreaks._appliedBreaks[this.scaleBreaks.firstBreakIndex].endPixel + b;
          else
            for (k = this.scaleBreaks.firstBreakIndex; k <= this.scaleBreaks.lastBreakIndex; k++)
              d.moveTo(h, e),
                d.lineTo(h, this.scaleBreaks._appliedBreaks[k].startPixel + c),
                (e = this.scaleBreaks._appliedBreaks[k].endPixel + b);
        e && (d.moveTo(h, e), d.lineTo(h, g));
        d.stroke();
      }
      s &&
        (a.drawImage(this.chart._preRenderCanvas, 0, 0, this.chart.width, this.chart.height),
        this.chart._breaksCanvasCtx &&
          this.chart._breaksCanvasCtx.drawImage(
            this.chart._preRenderCanvas,
            0,
            0,
            this.chart.width,
            this.chart.height
          ),
        d.clearRect(0, 0, this.chart.width, this.chart.height));
      d.restore();
    };
    y.prototype.getPixelCoordinatesOnAxis = function(a) {
      var d = {};
      if ("bottom" === this._position || "top" === this._position)
        (d.x = this.convertValueToPixel(a)), (d.y = this.lineCoordinates.y1);
      if ("left" === this._position || "right" === this._position)
        (d.y = this.convertValueToPixel(a)), (d.x = this.lineCoordinates.x2);
      return d;
    };
    y.prototype.convertPixelToValue = function(a) {
      if ("undefined" === typeof a) return null;
      var d = 0,
        b = 0,
        c,
        d = !0,
        e = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [],
        b =
          "number" === typeof a
            ? a
            : "left" === this._position || "right" === this._position
            ? a.y
            : a.x;
      if (this.logarithmic) {
        a = c = Math.pow(
          this.logarithmBase,
          (b - this.conversionParameters.reference) / this.conversionParameters.pixelPerUnit
        );
        if (
          (b <= this.conversionParameters.reference ===
            ("left" === this._position || "right" === this._position)) !==
          this.reversed
        )
          for (b = 0; b < e.length; b++) {
            if (!(e[b].endValue < this.conversionParameters.minimum))
              if (d)
                if (e[b].startValue < this.conversionParameters.minimum) {
                  if (
                    1 < e[b].size &&
                    this.conversionParameters.minimum *
                      Math.pow(e[b].endValue / e[b].startValue, Math.log(c) / Math.log(e[b].size)) <
                      e[b].endValue
                  ) {
                    a = Math.pow(
                      e[b].endValue / e[b].startValue,
                      Math.log(c) / Math.log(e[b].size)
                    );
                    break;
                  } else
                    (a *=
                      e[b].endValue /
                      this.conversionParameters.minimum /
                      Math.pow(
                        e[b].size,
                        Math.log(e[b].endValue / this.conversionParameters.minimum) /
                          Math.log(e[b].endValue / e[b].startValue)
                      )),
                      (c /= Math.pow(
                        e[b].size,
                        Math.log(e[b].endValue / this.conversionParameters.minimum) /
                          Math.log(e[b].endValue / e[b].startValue)
                      ));
                  d = !1;
                } else if (c > e[b].startValue / this.conversionParameters.minimum) {
                  c /= e[b].startValue / this.conversionParameters.minimum;
                  if (c < e[b].size) {
                    a *=
                      Math.pow(
                        e[b].endValue / e[b].startValue,
                        1 === e[b].size ? 1 : Math.log(c) / Math.log(e[b].size)
                      ) / c;
                    break;
                  } else a *= e[b].endValue / e[b].startValue / e[b].size;
                  c /= e[b].size;
                  d = !1;
                } else break;
              else if (c > e[b].startValue / e[b - 1].endValue) {
                c /= e[b].startValue / e[b - 1].endValue;
                if (c < e[b].size) {
                  a *=
                    Math.pow(
                      e[b].endValue / e[b].startValue,
                      1 === e[b].size ? 1 : Math.log(c) / Math.log(e[b].size)
                    ) / c;
                  break;
                } else a *= e[b].endValue / e[b].startValue / e[b].size;
                c /= e[b].size;
              } else break;
          }
        else
          for (b = e.length - 1; 0 <= b; b--)
            if (!(e[b].startValue > this.conversionParameters.minimum))
              if (d)
                if (e[b].endValue > this.conversionParameters.minimum) {
                  if (
                    1 < e[b].size &&
                    this.conversionParameters.minimum *
                      Math.pow(e[b].endValue / e[b].startValue, Math.log(c) / Math.log(e[b].size)) >
                      e[b].startValue
                  ) {
                    a = Math.pow(
                      e[b].endValue / e[b].startValue,
                      Math.log(c) / Math.log(e[b].size)
                    );
                    break;
                  } else
                    (a *=
                      (e[b].startValue / this.conversionParameters.minimum) *
                      Math.pow(
                        e[b].size,
                        Math.log(e[b].startValue / this.conversionParameters.minimum) /
                          Math.log(e[b].endValue / e[b].startValue)
                      ) *
                      c),
                      (c *= Math.pow(
                        e[b].size,
                        Math.log(this.conversionParameters.minimum / e[b].startValue) /
                          Math.log(e[b].endValue / e[b].startValue)
                      ));
                  d = !1;
                } else if (c < e[b].endValue / this.conversionParameters.minimum) {
                  c /= e[b].endValue / this.conversionParameters.minimum;
                  if (c > 1 / e[b].size) {
                    a *=
                      Math.pow(
                        e[b].endValue / e[b].startValue,
                        1 >= e[b].size ? 1 : Math.log(c) / Math.log(e[b].size)
                      ) * c;
                    break;
                  } else a /= e[b].endValue / e[b].startValue / e[b].size;
                  c *= e[b].size;
                  d = !1;
                } else break;
              else if (c < e[b].endValue / e[b + 1].startValue) {
                c /= e[b].endValue / e[b + 1].startValue;
                if (c > 1 / e[b].size) {
                  a *=
                    Math.pow(
                      e[b].endValue / e[b].startValue,
                      1 >= e[b].size ? 1 : Math.log(c) / Math.log(e[b].size)
                    ) * c;
                  break;
                } else a /= e[b].endValue / e[b].startValue / e[b].size;
                c *= e[b].size;
              } else break;
        d = a * this.viewportMinimum;
      } else {
        a = c = (b - this.conversionParameters.reference) / this.conversionParameters.pixelPerUnit;
        if (
          (b <= this.conversionParameters.reference ===
            ("left" === this._position || "right" === this._position)) !==
          this.reversed
        )
          for (b = 0; b < e.length; b++) {
            if (!(e[b].endValue < this.conversionParameters.minimum))
              if (d)
                if (e[b].startValue < this.conversionParameters.minimum) {
                  if (
                    e[b].size &&
                    this.conversionParameters.minimum +
                      (c * (e[b].endValue - e[b].startValue)) / e[b].size <
                      e[b].endValue
                  ) {
                    a = 0 >= e[b].size ? 0 : (c * (e[b].endValue - e[b].startValue)) / e[b].size;
                    break;
                  } else
                    (a +=
                      e[b].endValue -
                      this.conversionParameters.minimum -
                      (e[b].size * (e[b].endValue - this.conversionParameters.minimum)) /
                        (e[b].endValue - e[b].startValue)),
                      (c -=
                        (e[b].size * (e[b].endValue - this.conversionParameters.minimum)) /
                        (e[b].endValue - e[b].startValue));
                  d = !1;
                } else if (c > e[b].startValue - this.conversionParameters.minimum) {
                  c -= e[b].startValue - this.conversionParameters.minimum;
                  if (c < e[b].size) {
                    a +=
                      (e[b].endValue - e[b].startValue) * (0 === e[b].size ? 1 : c / e[b].size) - c;
                    break;
                  } else a += e[b].endValue - e[b].startValue - e[b].size;
                  c -= e[b].size;
                  d = !1;
                } else break;
              else if (c > e[b].startValue - e[b - 1].endValue) {
                c -= e[b].startValue - e[b - 1].endValue;
                if (c < e[b].size) {
                  a +=
                    (e[b].endValue - e[b].startValue) * (0 === e[b].size ? 1 : c / e[b].size) - c;
                  break;
                } else a += e[b].endValue - e[b].startValue - e[b].size;
                c -= e[b].size;
              } else break;
          }
        else
          for (b = e.length - 1; 0 <= b; b--)
            if (!(e[b].startValue > this.conversionParameters.minimum))
              if (d)
                if (e[b].endValue > this.conversionParameters.minimum)
                  if (
                    e[b].size &&
                    this.conversionParameters.minimum +
                      (c * (e[b].endValue - e[b].startValue)) / e[b].size >
                      e[b].startValue
                  ) {
                    a = 0 >= e[b].size ? 0 : (c * (e[b].endValue - e[b].startValue)) / e[b].size;
                    break;
                  } else
                    (a +=
                      e[b].startValue -
                      this.conversionParameters.minimum +
                      (e[b].size * (this.conversionParameters.minimum - e[b].startValue)) /
                        (e[b].endValue - e[b].startValue)),
                      (c +=
                        (e[b].size * (this.conversionParameters.minimum - e[b].startValue)) /
                        (e[b].endValue - e[b].startValue)),
                      (d = !1);
                else if (c < e[b].endValue - this.conversionParameters.minimum) {
                  c -= e[b].endValue - this.conversionParameters.minimum;
                  if (c > -1 * e[b].size) {
                    a +=
                      (e[b].endValue - e[b].startValue) * (0 === e[b].size ? 1 : c / e[b].size) + c;
                    break;
                  } else a -= e[b].endValue - e[b].startValue - e[b].size;
                  c += e[b].size;
                  d = !1;
                } else break;
              else if (c < e[b].endValue - e[b + 1].startValue) {
                c -= e[b].endValue - e[b + 1].startValue;
                if (c > -1 * e[b].size) {
                  a +=
                    (e[b].endValue - e[b].startValue) * (0 === e[b].size ? 1 : c / e[b].size) + c;
                  break;
                } else a -= e[b].endValue - e[b].startValue - e[b].size;
                c += e[b].size;
              } else break;
        d = this.conversionParameters.minimum + a;
      }
      return d;
    };
    y.prototype.convertValueToPixel = function(a) {
      a = this.getApparentDifference(this.conversionParameters.minimum, a, a);
      return this.logarithmic
        ? (this.conversionParameters.reference +
            (this.conversionParameters.pixelPerUnit *
              Math.log(a / this.conversionParameters.minimum)) /
              this.conversionParameters.lnLogarithmBase +
            0.5) <<
            0
        : "axisX" === this.type
        ? (this.conversionParameters.reference +
            this.conversionParameters.pixelPerUnit * (a - this.conversionParameters.minimum) +
            0.5) <<
          0
        : this.conversionParameters.reference +
          this.conversionParameters.pixelPerUnit * (a - this.conversionParameters.minimum) +
          0.5;
    };
    y.prototype.getApparentDifference = function(a, d, b, c) {
      var e = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [];
      if (this.logarithmic) {
        b = u(b) ? d / a : b;
        for (var g = 0; g < e.length && !(d < e[g].startValue); g++)
          a > e[g].endValue ||
            (a <= e[g].startValue && d >= e[g].endValue
              ? (b = (b / e[g].endValue) * e[g].startValue * e[g].size)
              : a >= e[g].startValue && d >= e[g].endValue
              ? (b =
                  (b / e[g].endValue) *
                  a *
                  Math.pow(
                    e[g].size,
                    Math.log(e[g].endValue / a) / Math.log(e[g].endValue / e[g].startValue)
                  ))
              : a <= e[g].startValue && d <= e[g].endValue
              ? (b =
                  (b / d) *
                  e[g].startValue *
                  Math.pow(
                    e[g].size,
                    Math.log(d / e[g].startValue) / Math.log(e[g].endValue / e[g].startValue)
                  ))
              : !c &&
                a > e[g].startValue && d < e[g].endValue &&
                (b =
                  a *
                  Math.pow(
                    e[g].size,
                    Math.log(d / a) / Math.log(e[g].endValue / e[g].startValue)
                  )));
      } else
        for (b = u(b) ? Math.abs(d - a) : b, g = 0; g < e.length && !(d < e[g].startValue); g++)
          a > e[g].endValue ||
            (a <= e[g].startValue && d >= e[g].endValue
              ? (b = b - e[g].endValue + e[g].startValue + e[g].size)
              : a > e[g].startValue && d >= e[g].endValue
              ? (b =
                  b -
                  e[g].endValue +
                  a +
                  (e[g].size * (e[g].endValue - a)) / (e[g].endValue - e[g].startValue))
              : a <= e[g].startValue && d < e[g].endValue
              ? (b =
                  b -
                  d +
                  e[g].startValue +
                  (e[g].size * (d - e[g].startValue)) / (e[g].endValue - e[g].startValue))
              : !c &&
                a > e[g].startValue && d < e[g].endValue &&
                (b = a + (e[g].size * (d - a)) / (e[g].endValue - e[g].startValue)));
      return b;
    };
    y.prototype.setViewPortRange = function(a, d) {
      this.sessionVariables.newViewportMinimum = this.viewportMinimum = Math.min(a, d);
      this.sessionVariables.newViewportMaximum = this.viewportMaximum = Math.max(a, d);
    };
    y.prototype.getXValueAt = function(a) {
      if (!a) return null;
      var d = null;
      "left" === this._position
        ? (d = this.convertPixelToValue(a.y))
        : "bottom" === this._position && (d = this.convertPixelToValue(a.x));
      return d;
    };
    y.prototype.calculateValueToPixelConversionParameters = function(a) {
      a = this.scaleBreaks ? this.scaleBreaks._appliedBreaks : [];
      var d = { pixelPerUnit: null, minimum: null, reference: null },
        b = this.lineCoordinates.width,
        c = this.lineCoordinates.height,
        b = "bottom" === this._position || "top" === this._position ? b : c,
        c = Math.abs(this.range);
      if (this.logarithmic)
        for (var e = 0; e < a.length && !(this.viewportMaximum < a[e].startValue); e++)
          this.viewportMinimum > a[e].endValue ||
            (this.viewportMinimum >= a[e].startValue && this.viewportMaximum <= a[e].endValue
              ? (b = 0)
              : this.viewportMinimum <= a[e].startValue && this.viewportMaximum >= a[e].endValue
              ? ((c = (c / a[e].endValue) * a[e].startValue),
                (b =
                  0 < a[e].spacing.toString().indexOf("%")
                    ? b * (1 - parseFloat(a[e].spacing) / 100)
                    : b - Math.min(a[e].spacing, 0.1 * b)))
              : this.viewportMinimum > a[e].startValue && this.viewportMaximum >= a[e].endValue
              ? ((c = (c / a[e].endValue) * this.viewportMinimum),
                (b =
                  0 < a[e].spacing.toString().indexOf("%")
                    ? b *
                      (1 -
                        ((parseFloat(a[e].spacing) / 100) *
                          Math.log(a[e].endValue / this.viewportMinimum)) /
                          Math.log(a[e].endValue / a[e].startValue))
                    : b -
                      (Math.min(a[e].spacing, 0.1 * b) *
                        Math.log(a[e].endValue / this.viewportMinimum)) /
                        Math.log(a[e].endValue / a[e].startValue)))
              : this.viewportMinimum <= a[e].startValue &&
                this.viewportMaximum < a[e].endValue &&
                ((c = (c / this.viewportMaximum) * a[e].startValue),
                (b =
                  0 < a[e].spacing.toString().indexOf("%")
                    ? b *
                      (1 -
                        ((parseFloat(a[e].spacing) / 100) *
                          Math.log(this.viewportMaximum / a[e].startValue)) /
                          Math.log(a[e].endValue / a[e].startValue))
                    : b -
                      (Math.min(a[e].spacing, 0.1 * b) *
                        Math.log(this.viewportMaximum / a[e].startValue)) /
                        Math.log(a[e].endValue / a[e].startValue))));
      else
        for (e = 0; e < a.length && !(this.viewportMaximum < a[e].startValue); e++)
          this.viewportMinimum > a[e].endValue ||
            (this.viewportMinimum >= a[e].startValue && this.viewportMaximum <= a[e].endValue
              ? (b = 0)
              : this.viewportMinimum <= a[e].startValue && this.viewportMaximum >= a[e].endValue
              ? ((c = c - a[e].endValue + a[e].startValue),
                (b =
                  0 < a[e].spacing.toString().indexOf("%")
                    ? b * (1 - parseFloat(a[e].spacing) / 100)
                    : b - Math.min(a[e].spacing, 0.1 * b)))
              : this.viewportMinimum > a[e].startValue && this.viewportMaximum >= a[e].endValue
              ? ((c = c - a[e].endValue + this.viewportMinimum),
                (b =
                  0 < a[e].spacing.toString().indexOf("%")
                    ? b *
                      (1 -
                        ((parseFloat(a[e].spacing) / 100) *
                          (a[e].endValue - this.viewportMinimum)) /
                          (a[e].endValue - a[e].startValue))
                    : b -
                      (Math.min(a[e].spacing, 0.1 * b) * (a[e].endValue - this.viewportMinimum)) /
                        (a[e].endValue - a[e].startValue)))
              : this.viewportMinimum <= a[e].startValue &&
                this.viewportMaximum < a[e].endValue &&
                ((c = c - this.viewportMaximum + a[e].startValue),
                (b =
                  0 < a[e].spacing.toString().indexOf("%")
                    ? b *
                      (1 -
                        ((parseFloat(a[e].spacing) / 100) *
                          (this.viewportMaximum - a[e].startValue)) /
                          (a[e].endValue - a[e].startValue))
                    : b -
                      (Math.min(a[e].spacing, 0.1 * b) * (this.viewportMaximum - a[e].startValue)) /
                        (a[e].endValue - a[e].startValue))));
      d.minimum = this.viewportMinimum;
      d.maximum = this.viewportMaximum;
      d.range = c;
      if ("bottom" === this._position || "top" === this._position)
        this.logarithmic
          ? ((d.lnLogarithmBase = Math.log(this.logarithmBase)),
            (d.pixelPerUnit =
              ((this.reversed ? -1 : 1) * b * d.lnLogarithmBase) / Math.log(Math.abs(c))))
          : (d.pixelPerUnit = ((this.reversed ? -1 : 1) * b) / Math.abs(c)),
          (d.reference = this.reversed ? this.lineCoordinates.x2 : this.lineCoordinates.x1);
      if ("left" === this._position || "right" === this._position)
        this.logarithmic
          ? ((d.lnLogarithmBase = Math.log(this.logarithmBase)),
            (d.pixelPerUnit =
              ((this.reversed ? 1 : -1) * b * d.lnLogarithmBase) / Math.log(Math.abs(c))))
          : (d.pixelPerUnit = ((this.reversed ? 1 : -1) * b) / Math.abs(c)),
          (d.reference = this.reversed ? this.lineCoordinates.y1 : this.lineCoordinates.y2);
      this.conversionParameters = d;
    };
    y.prototype.calculateAxisParameters = function() {
      if (this.logarithmic) this.calculateLogarithmicAxisParameters();
      else {
        var a = this.chart.layoutManager.getFreeSpace(),
          d = !1,
          b = !1;
        "bottom" === this._position || "top" === this._position
          ? ((this.maxWidth = a.width), (this.maxHeight = a.height))
          : ((this.maxWidth = a.height), (this.maxHeight = a.width));
        var a =
            "axisX" === this.type
              ? "xySwapped" === this.chart.plotInfo.axisPlacement
                ? 62
                : 70
              : "xySwapped" === this.chart.plotInfo.axisPlacement
              ? 50
              : 40,
          c = 4;
        "axisX" === this.type && (c = 600 > this.maxWidth ? 8 : 6);
        var a = Math.max(c, Math.floor(this.maxWidth / a)),
          e,
          g,
          h,
          c = 0;
        !u(this.options.viewportMinimum) &&
          !u(this.options.viewportMaximum) &&
            this.options.viewportMinimum >= this.options.viewportMaximum &&
          (this.viewportMinimum = this.viewportMaximum = null);
        if (
          u(this.options.viewportMinimum) &&
          !u(this.sessionVariables.newViewportMinimum) &&
          !isNaN(this.sessionVariables.newViewportMinimum)
        )
          this.viewportMinimum = this.sessionVariables.newViewportMinimum;
        else if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
          this.viewportMinimum = this.minimum;
        if (
          u(this.options.viewportMaximum) &&
          !u(this.sessionVariables.newViewportMaximum) &&
          !isNaN(this.sessionVariables.newViewportMaximum)
        )
          this.viewportMaximum = this.sessionVariables.newViewportMaximum;
        else if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
          this.viewportMaximum = this.maximum;
        if (this.scaleBreaks)
          for (c = 0; c < this.scaleBreaks._appliedBreaks.length; c++)
            if (
              ((!u(this.sessionVariables.newViewportMinimum) &&
                this.sessionVariables.newViewportMinimum >=
                  this.scaleBreaks._appliedBreaks[c].startValue) ||
                (!u(this.options.minimum) &&
                  this.options.minimum >= this.scaleBreaks._appliedBreaks[c].startValue) ||
                (!u(this.options.viewportMinimum) &&
                  this.viewportMinimum >= this.scaleBreaks._appliedBreaks[c].startValue)) &&
              ((!u(this.sessionVariables.newViewportMaximum) &&
                this.sessionVariables.newViewportMaximum <=
                  this.scaleBreaks._appliedBreaks[c].endValue) ||
                (!u(this.options.maximum) &&
                  this.options.maximum <= this.scaleBreaks._appliedBreaks[c].endValue) ||
                (!u(this.options.viewportMaximum) &&
                  this.viewportMaximum <= this.scaleBreaks._appliedBreaks[c].endValue))
            ) {
              this.scaleBreaks._appliedBreaks.splice(c, 1);
              break;
            }
        if ("axisX" === this.type) {
          if (this.dataSeries && 0 < this.dataSeries.length)
            for (e = 0; e < this.dataSeries.length; e++)
              "dateTime" === this.dataSeries[e].xValueType && (b = !0);
          e = null !== this.viewportMinimum ? this.viewportMinimum : this.dataInfo.viewPortMin;
          g = null !== this.viewportMaximum ? this.viewportMaximum : this.dataInfo.viewPortMax;
          0 === g - e &&
            ((c = "undefined" === typeof this.options.interval ? 0.4 : this.options.interval),
            (g += c),
            (e -= c));
          Infinity !== this.dataInfo.minDiff
            ? (h = this.dataInfo.minDiff)
            : 1 < g - e
            ? (h = 0.5 * Math.abs(g - e))
            : ((h = 1), b && (d = !0));
        } else
          "axisY" === this.type &&
            ((e = null !== this.viewportMinimum ? this.viewportMinimum : this.dataInfo.viewPortMin),
            (g = null !== this.viewportMaximum ? this.viewportMaximum : this.dataInfo.viewPortMax),
            isFinite(e) || isFinite(g)
              ? isFinite(e)
                ? isFinite(g) || (g = e)
                : (e = g)
              : ((g =
                  "undefined" === typeof this.options.interval ? -Infinity : this.options.interval),
                (e =
                  "undefined" !== typeof this.options.interval || isFinite(this.dataInfo.minDiff)
                    ? 0
                    : Infinity)),
            0 === e && 0 === g
              ? ((g += 9), (e = 0))
              : 0 === g - e
              ? ((c = Math.min(Math.abs(0.01 * Math.abs(g)), 5)), (g += c), (e -= c))
              : e > g
              ? ((c = Math.min(0.01 * Math.abs(this.getApparentDifference(g, e, null, !0)), 5)),
                0 <= g ? (e = g - c) : (g = isFinite(e) ? e + c : 0))
              : ((c = Math.min(0.01 * Math.abs(this.getApparentDifference(e, g, null, !0)), 0.05)),
                0 !== g && (g += c),
                0 !== e && (e -= c)),
            (h =
              Infinity !== this.dataInfo.minDiff
                ? this.dataInfo.minDiff
                : 1 < g - e
                ? 0.5 * Math.abs(g - e)
                : 1),
            this.includeZero &&
              (null === this.viewportMinimum || isNaN(this.viewportMinimum)) &&
              0 < e &&
              (e = 0),
            this.includeZero &&
              (null === this.viewportMaximum || isNaN(this.viewportMaximum)) &&
              0 > g &&
              (g = 0));
        c = this.getApparentDifference(
          isNaN(this.viewportMinimum) || null === this.viewportMinimum ? e : this.viewportMinimum,
          isNaN(this.viewportMaximum) || null === this.viewportMaximum ? g : this.viewportMaximum,
          null,
          !0
        );
        if ("axisX" === this.type && b) {
          this.intervalType ||
            (c / 1 <= a
              ? ((this.interval = 1), (this.intervalType = "millisecond"))
              : c / 2 <= a
              ? ((this.interval = 2), (this.intervalType = "millisecond"))
              : c / 5 <= a
              ? ((this.interval = 5), (this.intervalType = "millisecond"))
              : c / 10 <= a
              ? ((this.interval = 10), (this.intervalType = "millisecond"))
              : c / 20 <= a
              ? ((this.interval = 20), (this.intervalType = "millisecond"))
              : c / 50 <= a
              ? ((this.interval = 50), (this.intervalType = "millisecond"))
              : c / 100 <= a
              ? ((this.interval = 100), (this.intervalType = "millisecond"))
              : c / 200 <= a
              ? ((this.interval = 200), (this.intervalType = "millisecond"))
              : c / 250 <= a
              ? ((this.interval = 250), (this.intervalType = "millisecond"))
              : c / 300 <= a
              ? ((this.interval = 300), (this.intervalType = "millisecond"))
              : c / 400 <= a
              ? ((this.interval = 400), (this.intervalType = "millisecond"))
              : c / 500 <= a
              ? ((this.interval = 500), (this.intervalType = "millisecond"))
              : c / (1 * R.secondDuration) <= a
              ? ((this.interval = 1), (this.intervalType = "second"))
              : c / (2 * R.secondDuration) <= a
              ? ((this.interval = 2), (this.intervalType = "second"))
              : c / (5 * R.secondDuration) <= a
              ? ((this.interval = 5), (this.intervalType = "second"))
              : c / (10 * R.secondDuration) <= a
              ? ((this.interval = 10), (this.intervalType = "second"))
              : c / (15 * R.secondDuration) <= a
              ? ((this.interval = 15), (this.intervalType = "second"))
              : c / (20 * R.secondDuration) <= a
              ? ((this.interval = 20), (this.intervalType = "second"))
              : c / (30 * R.secondDuration) <= a
              ? ((this.interval = 30), (this.intervalType = "second"))
              : c / (1 * R.minuteDuration) <= a
              ? ((this.interval = 1), (this.intervalType = "minute"))
              : c / (2 * R.minuteDuration) <= a
              ? ((this.interval = 2), (this.intervalType = "minute"))
              : c / (5 * R.minuteDuration) <= a
              ? ((this.interval = 5), (this.intervalType = "minute"))
              : c / (10 * R.minuteDuration) <= a
              ? ((this.interval = 10), (this.intervalType = "minute"))
              : c / (15 * R.minuteDuration) <= a
              ? ((this.interval = 15), (this.intervalType = "minute"))
              : c / (20 * R.minuteDuration) <= a
              ? ((this.interval = 20), (this.intervalType = "minute"))
              : c / (30 * R.minuteDuration) <= a
              ? ((this.interval = 30), (this.intervalType = "minute"))
              : c / (1 * R.hourDuration) <= a
              ? ((this.interval = 1), (this.intervalType = "hour"))
              : c / (2 * R.hourDuration) <= a
              ? ((this.interval = 2), (this.intervalType = "hour"))
              : c / (3 * R.hourDuration) <= a
              ? ((this.interval = 3), (this.intervalType = "hour"))
              : c / (6 * R.hourDuration) <= a
              ? ((this.interval = 6), (this.intervalType = "hour"))
              : c / (1 * R.dayDuration) <= a
              ? ((this.interval = 1), (this.intervalType = "day"))
              : c / (2 * R.dayDuration) <= a
              ? ((this.interval = 2), (this.intervalType = "day"))
              : c / (4 * R.dayDuration) <= a
              ? ((this.interval = 4), (this.intervalType = "day"))
              : c / (1 * R.weekDuration) <= a
              ? ((this.interval = 1), (this.intervalType = "week"))
              : c / (2 * R.weekDuration) <= a
              ? ((this.interval = 2), (this.intervalType = "week"))
              : c / (3 * R.weekDuration) <= a
              ? ((this.interval = 3), (this.intervalType = "week"))
              : c / (1 * R.monthDuration) <= a
              ? ((this.interval = 1), (this.intervalType = "month"))
              : c / (2 * R.monthDuration) <= a
              ? ((this.interval = 2), (this.intervalType = "month"))
              : c / (3 * R.monthDuration) <= a
              ? ((this.interval = 3), (this.intervalType = "month"))
              : c / (6 * R.monthDuration) <= a
              ? ((this.interval = 6), (this.intervalType = "month"))
              : ((this.interval =
                  c / (1 * R.yearDuration) <= a
                    ? 1
                    : c / (2 * R.yearDuration) <= a
                    ? 2
                    : c / (4 * R.yearDuration) <= a
                    ? 4
                    : Math.floor(y.getNiceNumber(c / (a - 1), !0) / R.yearDuration)),
                (this.intervalType = "year")));
          if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
            this.viewportMinimum = e - h / 2;
          if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
            this.viewportMaximum = g + h / 2;
          d
            ? (this.autoValueFormatString = "MMM DD YYYY HH:mm")
            : "year" === this.intervalType
            ? (this.autoValueFormatString = "YYYY")
            : "month" === this.intervalType
            ? (this.autoValueFormatString = "MMM YYYY")
            : "week" === this.intervalType
            ? (this.autoValueFormatString = "MMM DD YYYY")
            : "day" === this.intervalType
            ? (this.autoValueFormatString = "MMM DD YYYY")
            : "hour" === this.intervalType
            ? (this.autoValueFormatString = "hh:mm TT")
            : "minute" === this.intervalType
            ? (this.autoValueFormatString = "hh:mm TT")
            : "second" === this.intervalType
            ? (this.autoValueFormatString = "hh:mm:ss TT")
            : "millisecond" === this.intervalType && (this.autoValueFormatString = "fff'ms'");
          this.valueFormatString || (this.valueFormatString = this.autoValueFormatString);
        } else {
          this.intervalType = "number";
          c = y.getNiceNumber(c, !1);
          this.interval =
            this.options && 0 < this.options.interval
              ? this.options.interval
              : y.getNiceNumber(c / (a - 1), !0);
          if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
            this.viewportMinimum =
              "axisX" === this.type ? e - h / 2 : Math.floor(e / this.interval) * this.interval;
          if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
            this.viewportMaximum =
              "axisX" === this.type ? g + h / 2 : Math.ceil(g / this.interval) * this.interval;
          0 === this.viewportMaximum &&
            0 === this.viewportMinimum &&
            (0 === this.options.viewportMinimum
              ? (this.viewportMaximum += 10)
              : 0 === this.options.viewportMaximum && (this.viewportMinimum -= 10),
            this.options &&
              "undefined" === typeof this.options.interval &&
              (this.interval = y.getNiceNumber(
                (this.viewportMaximum - this.viewportMinimum) / (a - 1),
                !0
              )));
        }
        if (null === this.minimum || null === this.maximum)
          if (
            ("axisX" === this.type
              ? ((e = null !== this.minimum ? this.minimum : this.dataInfo.min),
                (g = null !== this.maximum ? this.maximum : this.dataInfo.max),
                0 === g - e &&
                  ((c = "undefined" === typeof this.options.interval ? 0.4 : this.options.interval),
                  (g += c),
                  (e -= c)),
                (h =
                  Infinity !== this.dataInfo.minDiff
                    ? this.dataInfo.minDiff
                    : 1 < g - e
                    ? 0.5 * Math.abs(g - e)
                    : 1))
              : "axisY" === this.type &&
                ((e = null !== this.minimum ? this.minimum : this.dataInfo.min),
                (g = null !== this.maximum ? this.maximum : this.dataInfo.max),
                isFinite(e) || isFinite(g)
                  ? 0 === e && 0 === g
                    ? ((g += 9), (e = 0))
                    : 0 === g - e
                    ? ((c = Math.min(Math.abs(0.01 * Math.abs(g)), 5)), (g += c), (e -= c))
                    : e > g
                    ? ((c = Math.min(
                        0.01 * Math.abs(this.getApparentDifference(g, e, null, !0)),
                        5
                      )),
                      0 <= g ? (e = g - c) : (g = isFinite(e) ? e + c : 0))
                    : ((c = Math.min(
                        0.01 * Math.abs(this.getApparentDifference(e, g, null, !0)),
                        0.05
                      )),
                      0 !== g && (g += c),
                      0 !== e && (e -= c))
                  : ((g =
                      "undefined" === typeof this.options.interval
                        ? -Infinity
                        : this.options.interval),
                    (e =
                      "undefined" !== typeof this.options.interval ||
                      isFinite(this.dataInfo.minDiff)
                        ? 0
                        : Infinity)),
                (h =
                  Infinity !== this.dataInfo.minDiff
                    ? this.dataInfo.minDiff
                    : 1 < g - e
                    ? 0.5 * Math.abs(g - e)
                    : 1),
                this.includeZero &&
                  (null === this.minimum || isNaN(this.minimum)) &&
                  0 < e &&
                  (e = 0),
                this.includeZero &&
                  (null === this.maximum || isNaN(this.maximum)) &&
                  0 > g &&
                  (g = 0)),
            Math.abs(this.getApparentDifference(e, g, null, !0)),
            "axisX" === this.type && b)
          ) {
            this.valueType = "dateTime";
            if (null === this.minimum || isNaN(this.minimum)) this.minimum = e - h / 2;
            if (null === this.maximum || isNaN(this.maximum)) this.maximum = g + h / 2;
          } else
            (this.intervalType = this.valueType = "number"),
              null === this.minimum &&
                ((this.minimum =
                  "axisX" === this.type
                    ? e - h / 2
                    : Math.floor(e / this.interval) * this.interval),
                (this.minimum = Math.min(
                  this.minimum,
                  null === this.sessionVariables.viewportMinimum ||
                    isNaN(this.sessionVariables.viewportMinimum)
                    ? Infinity
                    : this.sessionVariables.viewportMinimum
                ))),
              null === this.maximum &&
                ((this.maximum =
                  "axisX" === this.type ? g + h / 2 : Math.ceil(g / this.interval) * this.interval),
                (this.maximum = Math.max(
                  this.maximum,
                  null === this.sessionVariables.viewportMaximum ||
                    isNaN(this.sessionVariables.viewportMaximum)
                    ? -Infinity
                    : this.sessionVariables.viewportMaximum
                ))),
              0 === this.maximum &&
                0 === this.minimum &&
                (0 === this.options.minimum
                  ? (this.maximum += 10)
                  : 0 === this.options.maximum && (this.minimum -= 10));
        u(this.sessionVariables.newViewportMinimum) &&
          (this.viewportMinimum = Math.max(this.viewportMinimum, this.minimum));
        u(this.sessionVariables.newViewportMaximum) &&
          (this.viewportMaximum = Math.min(this.viewportMaximum, this.maximum));
        this.range = this.viewportMaximum - this.viewportMinimum;
        this.intervalStartPosition =
          "axisX" === this.type && b
            ? this.getLabelStartPoint(
                new Date(this.viewportMinimum),
                this.intervalType,
                this.interval
              )
            : Math.floor((this.viewportMinimum + 0.2 * this.interval) / this.interval) *
              this.interval;
        this.valueFormatString ||
          (this.valueFormatString = y.generateValueFormatString(this.range, 2));
      }
    };
    y.prototype.calculateLogarithmicAxisParameters = function() {
      var a = this.chart.layoutManager.getFreeSpace(),
        d = Math.log(this.logarithmBase),
        b;
      "bottom" === this._position || "top" === this._position
        ? ((this.maxWidth = a.width), (this.maxHeight = a.height))
        : ((this.maxWidth = a.height), (this.maxHeight = a.width));
      var a =
          "axisX" === this.type
            ? 500 > this.maxWidth
              ? 7
              : Math.max(7, Math.floor(this.maxWidth / 100))
            : Math.max(Math.floor(this.maxWidth / 50), 3),
        c,
        e,
        g,
        h;
      h = 1;
      if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
        this.viewportMinimum = this.minimum;
      if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
        this.viewportMaximum = this.maximum;
      if (this.scaleBreaks)
        for (h = 0; h < this.scaleBreaks._appliedBreaks.length; h++)
          if (
            ((!u(this.sessionVariables.newViewportMinimum) &&
              this.sessionVariables.newViewportMinimum >=
                this.scaleBreaks._appliedBreaks[h].startValue) ||
              (!u(this.options.minimum) &&
                this.options.minimum >= this.scaleBreaks._appliedBreaks[h].startValue) ||
              (!u(this.options.viewportMinimum) &&
                this.viewportMinimum >= this.scaleBreaks._appliedBreaks[h].startValue)) &&
            ((!u(this.sessionVariables.newViewportMaximum) &&
              this.sessionVariables.newViewportMaximum <=
                this.scaleBreaks._appliedBreaks[h].endValue) ||
              (!u(this.options.maximum) &&
                this.options.maximum <= this.scaleBreaks._appliedBreaks[h].endValue) ||
              (!u(this.options.viewportMaximum) &&
                this.viewportMaximum <= this.scaleBreaks._appliedBreaks[h].endValue))
          ) {
            this.scaleBreaks._appliedBreaks.splice(h, 1);
            break;
          }
      "axisX" === this.type
        ? ((c = null !== this.viewportMinimum ? this.viewportMinimum : this.dataInfo.viewPortMin),
          (e = null !== this.viewportMaximum ? this.viewportMaximum : this.dataInfo.viewPortMax),
          1 === e / c &&
            ((h = Math.pow(
              this.logarithmBase,
              "undefined" === typeof this.options.interval ? 0.4 : this.options.interval
            )),
            (e *= h),
            (c /= h)),
          (g =
            Infinity !== this.dataInfo.minDiff
              ? this.dataInfo.minDiff
              : e / c > this.logarithmBase
              ? (e / c) * Math.pow(this.logarithmBase, 0.5)
              : this.logarithmBase))
        : "axisY" === this.type &&
          ((c = null !== this.viewportMinimum ? this.viewportMinimum : this.dataInfo.viewPortMin),
          (e = null !== this.viewportMaximum ? this.viewportMaximum : this.dataInfo.viewPortMax),
          0 >= c && !isFinite(e)
            ? ((e = "undefined" === typeof this.options.interval ? 0 : this.options.interval),
              (c = 1))
            : 0 >= c
            ? (c = e)
            : isFinite(e) || (e = c),
          1 === c && 1 === e
            ? ((e *= this.logarithmBase - 1 / this.logarithmBase), (c = 1))
            : 1 === e / c
            ? ((h = Math.min(
                e * Math.pow(this.logarithmBase, 0.01),
                Math.pow(this.logarithmBase, 5)
              )),
              (e *= h),
              (c /= h))
            : c > e
            ? ((h = Math.min(
                (c / e) * Math.pow(this.logarithmBase, 0.01),
                Math.pow(this.logarithmBase, 5)
              )),
              1 <= e ? (c = e / h) : (e = c * h))
            : ((h = Math.min(
                (e / c) * Math.pow(this.logarithmBase, 0.01),
                Math.pow(this.logarithmBase, 0.04)
              )),
              1 !== e && (e *= h),
              1 !== c && (c /= h)),
          (g =
            Infinity !== this.dataInfo.minDiff
              ? this.dataInfo.minDiff
              : e / c > this.logarithmBase
              ? (e / c) * Math.pow(this.logarithmBase, 0.5)
              : this.logarithmBase),
          this.includeZero &&
            (null === this.viewportMinimum || isNaN(this.viewportMinimum)) &&
            1 < c &&
            (c = 1),
          this.includeZero &&
            (null === this.viewportMaximum || isNaN(this.viewportMaximum)) &&
            1 > e &&
            (e = 1));
      h =
        (isNaN(this.viewportMaximum) || null === this.viewportMaximum ? e : this.viewportMaximum) /
        (isNaN(this.viewportMinimum) || null === this.viewportMinimum ? c : this.viewportMinimum);
      var k =
        (isNaN(this.viewportMaximum) || null === this.viewportMaximum ? e : this.viewportMaximum) -
        (isNaN(this.viewportMinimum) || null === this.viewportMinimum ? c : this.viewportMinimum);
      this.intervalType = "number";
      h = Math.pow(this.logarithmBase, y.getNiceNumber(Math.abs(Math.log(h) / d), !1));
      this.options && 0 < this.options.interval
        ? (this.interval = this.options.interval)
        : ((this.interval = y.getNiceExponent(Math.log(h) / d / (a - 1), !0)),
          (b = y.getNiceNumber(k / (a - 1), !0)));
      if (null === this.viewportMinimum || isNaN(this.viewportMinimum))
        this.viewportMinimum =
          "axisX" === this.type
            ? c / Math.sqrt(g)
            : Math.pow(
                this.logarithmBase,
                this.interval * Math.floor(Math.log(c) / d / this.interval)
              );
      if (null === this.viewportMaximum || isNaN(this.viewportMaximum))
        this.viewportMaximum =
          "axisX" === this.type
            ? e * Math.sqrt(g)
            : Math.pow(
                this.logarithmBase,
                this.interval * Math.ceil(Math.log(e) / d / this.interval)
              );
      1 === this.viewportMaximum &&
        1 === this.viewportMinimum &&
        (1 === this.options.viewportMinimum
          ? (this.viewportMaximum *= this.logarithmBase - 1 / this.logarithmBase)
          : 1 === this.options.viewportMaximum &&
            (this.viewportMinimum /= this.logarithmBase - 1 / this.logarithmBase),
        this.options &&
          "undefined" === typeof this.options.interval &&
          ((this.interval = y.getNiceExponent(Math.ceil(Math.log(h) / d) / (a - 1))),
          (b = y.getNiceNumber((this.viewportMaximum - this.viewportMinimum) / (a - 1), !0))));
      if (null === this.minimum || null === this.maximum)
        "axisX" === this.type
          ? ((c = null !== this.minimum ? this.minimum : this.dataInfo.min),
            (e = null !== this.maximum ? this.maximum : this.dataInfo.max),
            1 === e / c &&
              ((h = Math.pow(
                this.logarithmBase,
                "undefined" === typeof this.options.interval ? 0.4 : this.options.interval
              )),
              (e *= h),
              (c /= h)),
            (g =
              Infinity !== this.dataInfo.minDiff
                ? this.dataInfo.minDiff
                : e / c > this.logarithmBase
                ? (e / c) * Math.pow(this.logarithmBase, 0.5)
                : this.logarithmBase))
          : "axisY" === this.type &&
            ((c = null !== this.minimum ? this.minimum : this.dataInfo.min),
            (e = null !== this.maximum ? this.maximum : this.dataInfo.max),
            isFinite(c) || isFinite(e)
              ? 1 === c && 1 === e
                ? ((e *= this.logarithmBase), (c /= this.logarithmBase))
                : 1 === e / c
                ? ((h = Math.pow(this.logarithmBase, this.interval)), (e *= h), (c /= h))
                : c > e
                ? ((h = Math.min(0.01 * (c / e), 5)), 1 <= e ? (c = e / h) : (e = c * h))
                : ((h = Math.min(
                    (e / c) * Math.pow(this.logarithmBase, 0.01),
                    Math.pow(this.logarithmBase, 0.04)
                  )),
                  1 !== e && (e *= h),
                  1 !== c && (c /= h))
              : ((e = "undefined" === typeof this.options.interval ? 0 : this.options.interval),
                (c = 1)),
            (g =
              Infinity !== this.dataInfo.minDiff
                ? this.dataInfo.minDiff
                : e / c > this.logarithmBase
                ? (e / c) * Math.pow(this.logarithmBase, 0.5)
                : this.logarithmBase),
            this.includeZero && (null === this.minimum || isNaN(this.minimum)) && 1 < c && (c = 1),
            this.includeZero && (null === this.maximum || isNaN(this.maximum)) && 1 > e && (e = 1)),
          (this.intervalType = "number"),
          null === this.minimum &&
            ((this.minimum =
              "axisX" === this.type
                ? c / Math.sqrt(g)
                : Math.pow(
                    this.logarithmBase,
                    this.interval * Math.floor(Math.log(c) / d / this.interval)
                  )),
            (this.minimum = Math.min(
              this.minimum,
              null === this.sessionVariables.viewportMinimum ||
                isNaN(this.sessionVariables.viewportMinimum)
                ? "undefined" === typeof this.sessionVariables.newViewportMinimum
                  ? Infinity
                  : this.sessionVariables.newViewportMinimum
                : this.sessionVariables.viewportMinimum
            ))),
          null === this.maximum &&
            ((this.maximum =
              "axisX" === this.type
                ? e * Math.sqrt(g)
                : Math.pow(
                    this.logarithmBase,
                    this.interval * Math.ceil(Math.log(e) / d / this.interval)
                  )),
            (this.maximum = Math.max(
              this.maximum,
              null === this.sessionVariables.viewportMaximum ||
                isNaN(this.sessionVariables.viewportMaximum)
                ? "undefined" === typeof this.sessionVariables.newViewportMaximum
                  ? 0
                  : this.sessionVariables.newViewportMaximum
                : this.sessionVariables.viewportMaximum
            ))),
          1 === this.maximum &&
            1 === this.minimum &&
            (1 === this.options.minimum
              ? (this.maximum *= this.logarithmBase - 1 / this.logarithmBase)
              : 1 === this.options.maximum &&
                (this.minimum /= this.logarithmBase - 1 / this.logarithmBase));
      this.viewportMinimum = Math.max(this.viewportMinimum, this.minimum);
      this.viewportMaximum = Math.min(this.viewportMaximum, this.maximum);
      this.viewportMinimum > this.viewportMaximum &&
        ((!this.options.viewportMinimum && !this.options.minimum) ||
        this.options.viewportMaximum ||
        this.options.maximum
          ? this.options.viewportMinimum ||
            this.options.minimum ||
            (!this.options.viewportMaximum && !this.options.maximum) ||
            (this.viewportMinimum = this.minimum =
              (this.options.viewportMaximum || this.options.maximum) /
              Math.pow(this.logarithmBase, 2 * Math.ceil(this.interval)))
          : (this.viewportMaximum = this.maximum =
              this.options.viewportMinimum || this.options.minimum));
      c = Math.pow(
        this.logarithmBase,
        Math.floor(Math.log(this.viewportMinimum) / (d * this.interval) + 0.2) * this.interval
      );
      this.range = this.viewportMaximum / this.viewportMinimum;
      this.noTicks = a;
      if (
        !this.options.interval &&
        this.range < Math.pow(this.logarithmBase, 8 > this.viewportMaximum || 3 > a ? 2 : 3)
      ) {
        for (d = Math.floor(this.viewportMinimum / b + 0.5) * b; d < this.viewportMinimum; ) d += b;
        this.equidistantInterval = !1;
        this.intervalStartPosition = d;
        this.interval = b;
      } else
        this.options.interval ||
          ((b = Math.ceil(this.interval)),
          this.range > this.interval &&
            ((this.interval = b),
            (c = Math.pow(
              this.logarithmBase,
              Math.floor(Math.log(this.viewportMinimum) / (d * this.interval) + 0.2) * this.interval
            )))),
          (this.equidistantInterval = !0),
          (this.intervalStartPosition = c);
      if (
        !this.valueFormatString &&
        ((this.valueFormatString = "#,##0.##"), 1 > this.viewportMinimum)
      ) {
        d = Math.floor(Math.abs(Math.log(this.viewportMinimum) / Math.LN10)) + 2;
        if (isNaN(d) || !isFinite(d)) d = 2;
        if (2 < d) for (h = 0; h < d - 2; h++) this.valueFormatString += "#";
      }
    };
    y.generateValueFormatString = function(a, d) {
      var b = "#,##0.",
        c = d;
      1 > a &&
        ((c += Math.floor(Math.abs(Math.log(a) / Math.LN10))), isNaN(c) || !isFinite(c)) &&
        (c = d);
      for (var e = 0; e < c; e++) b += "#";
      return b;
    };
    y.getNiceExponent = function(a, d) {
      var b = Math.floor(Math.log(a) / Math.LN10),
        c = a / Math.pow(10, b),
        c = 0 > b ? (1 >= c ? 1 : 5 >= c ? 5 : 10) : Math.max(Math.floor(c), 1);
      return -20 > b ? Number(c * Math.pow(10, b)) : Number((c * Math.pow(10, b)).toFixed(20));
    };
    y.getNiceNumber = function(a, d) {
      var b = Math.floor(Math.log(a) / Math.LN10),
        c = a / Math.pow(10, b),
        c = d
          ? 1.5 > c
            ? 1
            : 3 > c
            ? 2
            : 7 > c
            ? 5
            : 10
          : 1 >= c
          ? 1
          : 2 >= c
          ? 2
          : 5 >= c
          ? 5
          : 10;
      return -20 > b ? Number(c * Math.pow(10, b)) : Number((c * Math.pow(10, b)).toFixed(20));
    };
    y.prototype.getLabelStartPoint = function() {
      var a = R[this.intervalType + "Duration"] * this.interval,
        a = new Date(Math.floor(this.viewportMinimum / a) * a);
      if ("millisecond" !== this.intervalType)
        if ("second" === this.intervalType)
          0 < a.getMilliseconds() && (a.setSeconds(a.getSeconds() + 1), a.setMilliseconds(0));
        else if ("minute" === this.intervalType) {
          if (0 < a.getSeconds() || 0 < a.getMilliseconds())
            a.setMinutes(a.getMinutes() + 1), a.setSeconds(0), a.setMilliseconds(0);
        } else if ("hour" === this.intervalType) {
          if (0 < a.getMinutes() || 0 < a.getSeconds() || 0 < a.getMilliseconds())
            a.setHours(a.getHours() + 1), a.setMinutes(0), a.setSeconds(0), a.setMilliseconds(0);
        } else if ("day" === this.intervalType) {
          if (
            0 < a.getHours() ||
            0 < a.getMinutes() ||
            0 < a.getSeconds() ||
            0 < a.getMilliseconds()
          )
            a.setDate(a.getDate() + 1),
              a.setHours(0),
              a.setMinutes(0),
              a.setSeconds(0),
              a.setMilliseconds(0);
        } else if ("week" === this.intervalType) {
          if (
            0 < a.getDay() ||
            0 < a.getHours() ||
            0 < a.getMinutes() ||
            0 < a.getSeconds() ||
            0 < a.getMilliseconds()
          )
            a.setDate(a.getDate() + (7 - a.getDay())),
              a.setHours(0),
              a.setMinutes(0),
              a.setSeconds(0),
              a.setMilliseconds(0);
        } else if ("month" === this.intervalType) {
          if (
            1 < a.getDate() ||
            0 < a.getHours() ||
            0 < a.getMinutes() ||
            0 < a.getSeconds() ||
            0 < a.getMilliseconds()
          )
            a.setMonth(a.getMonth() + 1),
              a.setDate(1),
              a.setHours(0),
              a.setMinutes(0),
              a.setSeconds(0),
              a.setMilliseconds(0);
        } else
          "year" === this.intervalType &&
            (0 < a.getMonth() ||
              1 < a.getDate() ||
              0 < a.getHours() ||
              0 < a.getMinutes() ||
              0 < a.getSeconds() ||
              0 < a.getMilliseconds()) &&
            (a.setFullYear(a.getFullYear() + 1),
            a.setMonth(0),
            a.setDate(1),
            a.setHours(0),
            a.setMinutes(0),
            a.setSeconds(0),
            a.setMilliseconds(0));
      return a;
    };
    pa(S, U);
    pa(L, U);
    L.prototype.createUserOptions = function(a) {
      if ("undefined" !== typeof a || this.options._isPlaceholder) {
        var d = 0;
        this.parent.options._isPlaceholder && this.parent.createUserOptions();
        this.options._isPlaceholder ||
          (Ea(this.parent[this.optionsName]),
          (d = this.parent.options[this.optionsName].indexOf(this.options)));
        this.options = "undefined" === typeof a ? {} : a;
        this.parent.options[this.optionsName][d] = this.options;
      }
    };
    L.prototype.render = function(a) {
      if (
        0 !== this.spacing ||
        (0 !== this.options.lineThickness &&
          ("undefined" !== typeof this.options.lineThickness || 0 !== this.parent.lineThickness))
      ) {
        var d = this.ctx,
          b = this.ctx.globalAlpha;
        this.ctx = a || this.ctx;
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.rect(
          this.chart.plotArea.x1,
          this.chart.plotArea.y1,
          this.chart.plotArea.width,
          this.chart.plotArea.height
        );
        this.ctx.clip();
        var c = this.scaleBreaks.parent.getPixelCoordinatesOnAxis(this.startValue),
          e = this.scaleBreaks.parent.getPixelCoordinatesOnAxis(this.endValue);
        this.ctx.strokeStyle = this.lineColor;
        this.ctx.fillStyle = this.color;
        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;
        P(this.id);
        var g, h, k, l, m, p;
        a = Math.max(this.spacing, 3);
        var q = Math.max(0, this.lineThickness);
        this.ctx.lineWidth = q;
        this.ctx.setLineDash && this.ctx.setLineDash(Q(this.lineDashType, q));
        if (
          "bottom" === this.scaleBreaks.parent._position ||
          "top" === this.scaleBreaks.parent._position
        )
          if (
            ((c = 1 === q % 2 ? (c.x << 0) + 0.5 : c.x << 0),
            (h = 1 === q % 2 ? (e.x << 0) + 0.5 : e.x << 0),
            "top" === this.scaleBreaks.parent._position
              ? ((e = this.chart.plotArea.y1), (k = (this.chart.plotArea.y2 + q / 2 + 0.5) << 0))
              : ((e = this.chart.plotArea.y2),
                (k = (this.chart.plotArea.y1 - q / 2 + 0.5) << 0),
                (a *= -1)),
            (this.bounds = { x1: c - q / 2, y1: e, x2: h + q / 2, y2: k }),
            this.ctx.moveTo(c, e),
            "straight" === this.type ||
              ("top" === this.scaleBreaks.parent._position && 0 >= a) ||
              ("bottom" === this.scaleBreaks.parent._position && 0 <= a))
          )
            this.ctx.lineTo(c, k), this.ctx.lineTo(h, k), this.ctx.lineTo(h, e);
          else if ("wavy" === this.type) {
            l = c;
            m = e;
            g = 0.5;
            p = (k - m) / a / 3;
            for (var n = 0; n < p; n++)
              this.ctx.bezierCurveTo(l + g * a, m + a, l + g * a, m + 2 * a, l, m + 3 * a),
                (m += 3 * a),
                (g *= -1);
            this.ctx.bezierCurveTo(l + g * a, m + a, l + g * a, m + 2 * a, l, m + 3 * a);
            l = h;
            g *= -1;
            this.ctx.lineTo(l, m);
            for (n = 0; n < p; n++)
              this.ctx.bezierCurveTo(l + g * a, m - a, l + g * a, m - 2 * a, l, m - 3 * a),
                (m -= 3 * a),
                (g *= -1);
          } else {
            if ("zigzag" === this.type) {
              g = -1;
              m = e + a;
              l = c + a;
              p = (k - m) / a / 2;
              for (n = 0; n < p; n++)
                this.ctx.lineTo(l, m), (l += 2 * g * a), (m += 2 * a), (g *= -1);
              this.ctx.lineTo(l, m);
              l += h - c;
              for (n = 0; n < p + 1; n++)
                this.ctx.lineTo(l, m), (l += 2 * g * a), (m -= 2 * a), (g *= -1);
              this.ctx.lineTo(l + g * a, m + a);
            }
          }
        else if (
          "left" === this.scaleBreaks.parent._position ||
          "right" === this.scaleBreaks.parent._position
        )
          if (
            ((e = 1 === q % 2 ? (e.y << 0) + 0.5 : e.y << 0),
            (k = 1 === q % 2 ? (c.y << 0) + 0.5 : c.y << 0),
            "left" === this.scaleBreaks.parent._position
              ? ((c = this.chart.plotArea.x1), (h = (this.chart.plotArea.x2 + q / 2 + 0.5) << 0))
              : ((c = this.chart.plotArea.x2),
                (h = (this.chart.plotArea.x1 - q / 2 + 0.5) << 0),
                (a *= -1)),
            (this.bounds = { x1: c, y1: e - q / 2, x2: h, y2: k + q / 2 }),
            this.ctx.moveTo(c, e),
            "straight" === this.type ||
              ("left" === this.scaleBreaks.parent._position && 0 >= a) ||
              ("right" === this.scaleBreaks.parent._position && 0 <= a))
          )
            this.ctx.lineTo(h, e), this.ctx.lineTo(h, k), this.ctx.lineTo(c, k);
          else if ("wavy" === this.type) {
            l = c;
            m = e;
            g = 0.5;
            p = (h - l) / a / 3;
            for (n = 0; n < p; n++)
              this.ctx.bezierCurveTo(l + a, m + g * a, l + 2 * a, m + g * a, l + 3 * a, m),
                (l += 3 * a),
                (g *= -1);
            this.ctx.bezierCurveTo(l + a, m + g * a, l + 2 * a, m + g * a, l + 3 * a, m);
            m = k;
            g *= -1;
            this.ctx.lineTo(l, m);
            for (n = 0; n < p; n++)
              this.ctx.bezierCurveTo(l - a, m + g * a, l - 2 * a, m + g * a, l - 3 * a, m),
                (l -= 3 * a),
                (g *= -1);
          } else if ("zigzag" === this.type) {
            g = 1;
            m = e - a;
            l = c + a;
            p = (h - l) / a / 2;
            for (n = 0; n < p; n++)
              this.ctx.lineTo(l, m), (m += 2 * g * a), (l += 2 * a), (g *= -1);
            this.ctx.lineTo(l, m);
            m += k - e;
            for (n = 0; n < p + 1; n++)
              this.ctx.lineTo(l, m), (m += 2 * g * a), (l -= 2 * a), (g *= -1);
            this.ctx.lineTo(l + a, m + g * a);
          }
        0 < q && this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.globalAlpha = this.fillOpacity;
        this.ctx.globalCompositeOperation = "destination-over";
        this.ctx.fill();
        this.ctx.restore();
        this.ctx.globalAlpha = b;
        this.ctx = d;
      }
    };
    pa(ba, U);
    ba.prototype.createUserOptions = function(a) {
      if ("undefined" !== typeof a || this.options._isPlaceholder) {
        var d = 0;
        this.parent.options._isPlaceholder && this.parent.createUserOptions();
        this.options._isPlaceholder ||
          (Ea(this.parent.stripLines), (d = this.parent.options.stripLines.indexOf(this.options)));
        this.options = "undefined" === typeof a ? {} : a;
        this.parent.options.stripLines[d] = this.options;
      }
    };
    ba.prototype.render = function() {
      this.ctx.save();
      var a = this.parent.getPixelCoordinatesOnAxis(this.value),
        d = Math.abs(
          "pixel" === this._thicknessType
            ? this.thickness
            : this.parent.conversionParameters.pixelPerUnit * this.thickness
        );
      if (0 < d) {
        var b = null === this.opacity ? 1 : this.opacity;
        this.ctx.strokeStyle = this.color;
        this.ctx.beginPath();
        var c = this.ctx.globalAlpha;
        this.ctx.globalAlpha = b;
        P(this.id);
        var e, g, h, k;
        this.ctx.lineWidth = d;
        this.ctx.setLineDash && this.ctx.setLineDash(Q(this.lineDashType, d));
        if ("bottom" === this.parent._position || "top" === this.parent._position)
          (e = g = 1 === this.ctx.lineWidth % 2 ? (a.x << 0) + 0.5 : a.x << 0),
            (h = this.chart.plotArea.y1),
            (k = this.chart.plotArea.y2),
            (this.bounds = { x1: e - d / 2, y1: h, x2: g + d / 2, y2: k });
        else if ("left" === this.parent._position || "right" === this.parent._position)
          (h = k = 1 === this.ctx.lineWidth % 2 ? (a.y << 0) + 0.5 : a.y << 0),
            (e = this.chart.plotArea.x1),
            (g = this.chart.plotArea.x2),
            (this.bounds = { x1: e, y1: h - d / 2, x2: g, y2: k + d / 2 });
        this.ctx.moveTo(e, h);
        this.ctx.lineTo(g, k);
        this.ctx.stroke();
        this.ctx.globalAlpha = c;
      }
      this.ctx.restore();
    };
    pa(ga, U);
    ga.prototype.render = function(a, d) {
      var b,
        c,
        e,
        g,
        h = null,
        k = (h = null),
        l = "";
      if (!this.valueFormatString)
        if ("dateTime" === this.parent.valueType)
          this.valueFormatString = this.parent.valueFormatString;
        else {
          var m = 0,
            m =
              "xySwapped" === this.chart.plotInfo.axisPlacement
                ? 50 < this.parent.range
                  ? 0
                  : 500 < this.chart.width && 25 > this.parent.range
                  ? 2
                  : Math.floor(Math.abs(Math.log(this.parent.range) / Math.LN10)) +
                    (5 > this.parent.range ? 2 : 10 > this.parent.range ? 1 : 0)
                : 50 < this.parent.range
                ? 0
                : Math.floor(Math.abs(Math.log(this.parent.range) / Math.LN10)) +
                  (5 > this.parent.range ? 2 : 10 > this.parent.range ? 1 : 0);
          this.valueFormatString = y.generateValueFormatString(this.parent.range, m);
        }
      var k = null === this.opacity ? 1 : this.opacity,
        m = Math.abs(
          "pixel" === this._thicknessType
            ? this.thickness
            : this.parent.conversionParameters.pixelPerUnit * this.thickness
        ),
        p = this.chart.overlaidCanvasCtx,
        q = p.globalAlpha;
      p.globalAlpha = k;
      p.beginPath();
      p.strokeStyle = this.color;
      p.lineWidth = m;
      p.save();
      this.labelFontSize = u(this.options.labelFontSize)
        ? this.parent.labelFontSize
        : this.labelFontSize;
      if ("left" === this.parent._position || "right" === this.parent._position)
        (this.labelMaxWidth = u(this.options.labelMaxWidth)
          ? this.parent.bounds.x2 - this.parent.bounds.x1
          : this.labelMaxWidth),
          (this.labelMaxHeight =
            u(this.options.labelWrap) || this.labelWrap
              ? 3 * this.chart.height
              : 2 * this.labelFontSize);
      else if ("top" === this.parent._position || "bottom" === this.parent._position)
        (this.labelMaxWidth = u(this.options.labelMaxWidth)
          ? 3 * this.chart.width
          : this.labelMaxWidth),
          (this.labelMaxHeight =
            u(this.options.labelWrap) || this.labelWrap
              ? this.parent.bounds.height
              : 2 * this.labelFontSize);
      0 < m && p.setLineDash && p.setLineDash(Q(this.lineDashType, m));
      k = new ja(p, {
        x: 0,
        y: 0,
        padding: { top: 2, right: 3, bottom: 2, left: 4 },
        backgroundColor: this.labelBackgroundColor,
        borderColor: this.labelBorderColor,
        borderThickness: this.labelBorderThickness,
        cornerRadius: this.labelCornerRadius,
        maxWidth: this.labelMaxWidth,
        maxHeight: this.labelMaxHeight,
        angle: this.labelAngle,
        text: l,
        horizontalAlign: "left",
        fontSize: this.labelFontSize,
        fontFamily: this.labelFontFamily,
        fontWeight: this.labelFontWeight,
        fontColor: this.labelFontColor,
        fontStyle: this.labelFontStyle,
        textBaseline: "middle"
      });
      if (this.snapToDataPoint) {
        var n = 0,
          h = [];
        if ("xySwapped" === this.chart.plotInfo.axisPlacement) {
          var f = null;
          if ("bottom" === this.parent._position || "top" === this.parent._position)
            n = this.parent.dataSeries[0].axisX.convertPixelToValue({ y: d });
          else if ("left" === this.parent._position || "right" === this.parent._position)
            n = this.parent.convertPixelToValue({ y: d });
          for (var s = 0; s < this.parent.dataSeries.length; s++)
            (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
              0 <= f.index &&
              ((f.dataSeries = this.parent.dataSeries[s]), null !== f.dataPoint.y && h.push(f));
          f = null;
          if (0 === h.length) return;
          h.sort(function(a, b) {
            return a.distance - b.distance;
          });
          f = Math.abs(a - this.parent.convertValueToPixel(h[0].dataPoint.y));
          s = 0;
          if ("rangeBar" === h[0].dataSeries.type || "error" === h[0].dataSeries.type)
            for (
              var f = Math.abs(a - this.parent.convertValueToPixel(h[s].dataPoint.y[0])),
                w = 0,
                n = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(a - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (w = Math.abs(a - this.parent.convertValueToPixel(h[n].dataPoint.y))),
                  w < f && ((f = w), (s = n));
          else if ("stackedBar" === h[0].dataSeries.type)
            for (
              var f = Math.abs(a - this.parent.convertValueToPixel(h[0].dataPoint.y)),
                C = (w = 0),
                n = (s = 0);
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(a - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (C += h[n].dataPoint.y),
                  (w = Math.abs(a - this.parent.convertValueToPixel(C))),
                  w < f && ((f = w), (s = n));
          else if ("stackedBar100" === h[0].dataSeries.type)
            for (
              var f = Math.abs(a - this.parent.convertValueToPixel(h[0].dataPoint.y)),
                t = (C = w = 0),
                n = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(a - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (C += h[n].dataPoint.y),
                  (t = h[n].dataPoint.x.getTime ? h[n].dataPoint.x.getTime() : h[n].dataPoint.x),
                  (t = 100 * (C / h[n].dataSeries.plotUnit.dataPointYSums[t])),
                  (w = Math.abs(a - this.parent.convertValueToPixel(t))),
                  w < f && ((f = w), (s = n));
          else
            for (
              f = Math.abs(a - this.parent.convertValueToPixel(h[0].dataPoint.y)), n = s = w = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(a - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (w = Math.abs(a - this.parent.convertValueToPixel(h[n].dataPoint.y))),
                  w < f && ((f = w), (s = n));
          l = h[s];
          if ("bottom" === this.parent._position || "top" === this.parent._position) {
            b = 0;
            if (
              "rangeBar" === this.parent.dataSeries[s].type ||
              "error" === this.parent.dataSeries[s].type
            ) {
              f = Math.abs(a - this.parent.convertValueToPixel(l.dataPoint.y[0]));
              for (n = w = 0; n < l.dataPoint.y.length; n++)
                (w = Math.abs(a - this.parent.convertValueToPixel(l.dataPoint.y[n]))),
                  w < f && ((f = w), (b = n));
              h =
                1 === p.lineWidth % 2
                  ? (this.parent.convertValueToPixel(l.dataPoint.y[b]) << 0) + 0.5
                  : this.parent.convertValueToPixel(l.dataPoint.y[b]) << 0;
              k.text = this.labelFormatter
                ? this.labelFormatter({
                    chart: this.chart,
                    axis: this.parent.options,
                    crosshair: this.options,
                    value: l.dataPoint.y[b]
                  })
                : u(this.options.label)
                ? fa(l.dataPoint.y[b], this.valueFormatString, this.chart._cultureInfo)
                : this.label;
            } else if ("stackedBar" === this.parent.dataSeries[s].type) {
              f = Math.abs(a - this.parent.convertValueToPixel(h[0].dataPoint.y));
              C = w = 0;
              for (n = s; 0 <= n; n--)
                (C += h[n].dataPoint.y),
                  (w = Math.abs(a - this.parent.convertValueToPixel(C))),
                  w < f && ((f = w), (b = n));
              h =
                1 === p.lineWidth % 2
                  ? (this.parent.convertValueToPixel(C) << 0) + 0.5
                  : this.parent.convertValueToPixel(C) << 0;
              k.text = this.labelFormatter
                ? this.labelFormatter({
                    chart: this.chart,
                    axis: this.parent.options,
                    crosshair: this.options,
                    value: l.dataPoint.y
                  })
                : u(this.options.label)
                ? fa(l.dataPoint.y, this.valueFormatString, this.chart._cultureInfo)
                : this.label;
            } else if ("stackedBar100" === this.parent.dataSeries[s].type) {
              f = Math.abs(a - this.parent.convertValueToPixel(h[0].dataPoint.y));
              t = C = w = 0;
              for (n = s; 0 <= n; n--)
                (C += h[n].dataPoint.y),
                  (t = h[n].dataPoint.x.getTime ? h[n].dataPoint.x.getTime() : h[n].dataPoint.x),
                  (t = 100 * (C / h[n].dataSeries.plotUnit.dataPointYSums[t])),
                  (w = Math.abs(a - this.parent.convertValueToPixel(t))),
                  w < f && ((f = w), (b = n));
              h =
                1 === p.lineWidth % 2
                  ? (this.parent.convertValueToPixel(t) << 0) + 0.5
                  : this.parent.convertValueToPixel(t) << 0;
              k.text = this.labelFormatter
                ? this.labelFormatter({
                    chart: this.chart,
                    axis: this.parent.options,
                    crosshair: this.options,
                    value: t
                  })
                : u(this.options.label)
                ? fa(t, this.valueFormatString, this.chart._cultureInfo)
                : this.label;
            } else
              (h =
                1 === p.lineWidth % 2
                  ? (this.parent.convertValueToPixel(l.dataPoint.y) << 0) + 0.5
                  : this.parent.convertValueToPixel(l.dataPoint.y) << 0),
                (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value: l.dataPoint.y
                    })
                  : u(this.options.label)
                  ? fa(l.dataPoint.y, this.valueFormatString, this.chart._cultureInfo)
                  : this.label);
            b = c = h;
            e = this.chart.plotArea.y1;
            g = this.chart.plotArea.y2;
            this.bounds = { x1: b - m / 2, y1: e, x2: c + m / 2, y2: g };
            k.x = b - k.measureText().width / 2;
            k.x + k.width > this.chart.bounds.x2
              ? (k.x = this.chart.bounds.x2 - k.width)
              : k.x < this.chart.bounds.x1 && (k.x = this.chart.bounds.x1);
            k.y = this.parent.lineCoordinates.y2 + k.fontSize / 2 + 2;
          } else if ("left" === this.parent._position || "right" === this.parent._position) {
            e = g = h =
              1 === p.lineWidth % 2
                ? (this.parent.convertValueToPixel(l.dataPoint.x) << 0) + 0.5
                : this.parent.convertValueToPixel(l.dataPoint.x) << 0;
            b = this.chart.plotArea.x1;
            c = this.chart.plotArea.x2;
            this.bounds = { x1: b, y1: e - m / 2, x2: c, y2: g + m / 2 };
            t = !1;
            if (this.parent.labels)
              for (
                h = Math.ceil(this.parent.interval), n = 0;
                n < this.parent.viewportMaximum;
                n += h
              )
                if (this.parent.labels[n]) t = !0;
                else {
                  t = !1;
                  break;
                }
            if (t) {
              if ("axisX" === this.parent.type)
                for (
                  n = this.parent.convertPixelToValue({ y: d }), f = null, s = 0;
                  s < this.parent.dataSeries.length;
                  s++
                )
                  (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
                    0 <= f.index &&
                    (k.text = this.labelFormatter
                      ? this.labelFormatter({
                          chart: this.chart,
                          axis: this.parent.options,
                          crosshair: this.options,
                          value: l.dataPoint.x
                        })
                      : u(this.options.label)
                      ? f.dataPoint.label
                      : this.label);
            } else
              "dateTime" === this.parent.valueType
                ? (k.text = this.labelFormatter
                    ? this.labelFormatter({
                        chart: this.chart,
                        axis: this.parent.options,
                        crosshair: this.options,
                        value: l.dataPoint.x
                      })
                    : u(this.options.label)
                    ? Ba(l.dataPoint.x, this.valueFormatString, this.chart._cultureInfo)
                    : this.label)
                : "number" === this.parent.valueType &&
                  (k.text = this.labelFormatter
                    ? this.labelFormatter({
                        chart: this.chart,
                        axis: this.parent.options,
                        crosshair: this.options,
                        value: l.dataPoint.x
                      })
                    : u(this.options.label)
                    ? fa(l.dataPoint.x, this.valueFormatString, this.chart._cultureInfo)
                    : this.label);
            k.y = g + k.fontSize / 2 - k.measureText().height / 2 + 2;
            k.y - k.fontSize / 2 < this.chart.bounds.y1
              ? (k.y = this.chart.bounds.y1 + k.fontSize / 2 + 2)
              : k.y + k.measureText().height - k.fontSize / 2 > this.chart.bounds.y2 &&
                (k.y = this.chart.bounds.y2 - k.measureText().height + k.fontSize / 2);
            "left" === this.parent._position
              ? (k.x = this.parent.lineCoordinates.x2 - k.measureText().width)
              : "right" === this.parent._position && (k.x = this.parent.lineCoordinates.x2);
          }
        } else if ("bottom" === this.parent._position || "top" === this.parent._position) {
          n = this.parent.convertPixelToValue({ x: a });
          for (s = 0; s < this.parent.dataSeries.length; s++)
            (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
              0 <= f.index &&
              ((f.dataSeries = this.parent.dataSeries[s]), null !== f.dataPoint.y && h.push(f));
          if (0 === h.length) return;
          h.sort(function(a, b) {
            return a.distance - b.distance;
          });
          l = h[0];
          b = c = h =
            1 === p.lineWidth % 2
              ? (this.parent.convertValueToPixel(l.dataPoint.x) << 0) + 0.5
              : this.parent.convertValueToPixel(l.dataPoint.x) << 0;
          e = this.chart.plotArea.y1;
          g = this.chart.plotArea.y2;
          this.bounds = { x1: b - m / 2, y1: e, x2: c + m / 2, y2: g };
          t = !1;
          if (this.parent.labels)
            for (
              h = Math.ceil(this.parent.interval), n = 0;
              n < this.parent.viewportMaximum;
              n += h
            )
              if (this.parent.labels[n]) t = !0;
              else {
                t = !1;
                break;
              }
          if (t) {
            if ("axisX" === this.parent.type)
              for (
                n = this.parent.convertPixelToValue({ x: a }), f = null, s = 0;
                s < this.parent.dataSeries.length;
                s++
              )
                (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
                  0 <= f.index &&
                  (k.text = this.labelFormatter
                    ? this.labelFormatter({
                        chart: this.chart,
                        axis: this.parent.options,
                        crosshair: this.options,
                        value: l.dataPoint.x
                      })
                    : u(this.options.label)
                    ? f.dataPoint.label
                    : this.label);
          } else
            "dateTime" === this.parent.valueType
              ? (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value: l.dataPoint.x
                    })
                  : u(this.options.label)
                  ? Ba(l.dataPoint.x, this.valueFormatString, this.chart._cultureInfo)
                  : this.label)
              : "number" === this.parent.valueType &&
                (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value: l.dataPoint.x
                    })
                  : u(this.options.label)
                  ? fa(l.dataPoint.x, this.valueFormatString, this.chart._cultureInfo)
                  : this.label);
          k.x = b - k.measureText().width / 2;
          k.x + k.width > this.chart.bounds.x2 && (k.x = this.chart.bounds.x2 - k.width);
          k.x < this.chart.bounds.x1 && (k.x = this.chart.bounds.x1);
          "bottom" === this.parent._position
            ? (k.y = this.parent.lineCoordinates.y2 + k.fontSize / 2 + 2)
            : "top" === this.parent._position &&
              (k.y = this.parent.lineCoordinates.y1 - k.height + k.fontSize / 2 + 2);
        } else if ("left" === this.parent._position || "right" === this.parent._position) {
          !u(this.parent.dataSeries) &&
            0 < this.parent.dataSeries.length &&
            (n = this.parent.dataSeries[0].axisX.convertPixelToValue({ x: a }));
          for (s = 0; s < this.parent.dataSeries.length; s++)
            (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
              0 <= f.index &&
              ((f.dataSeries = this.parent.dataSeries[s]), null !== f.dataPoint.y && h.push(f));
          if (0 === h.length) return;
          h.sort(function(a, b) {
            return a.distance - b.distance;
          });
          s = 0;
          if (
            "rangeColumn" === h[0].dataSeries.type ||
            "rangeArea" === h[0].dataSeries.type ||
            "error" === h[0].dataSeries.type ||
            "rangeSplineArea" === h[0].dataSeries.type ||
            "candlestick" === h[0].dataSeries.type ||
            "ohlc" === h[0].dataSeries.type ||
            "boxAndWhisker" === h[0].dataSeries.type
          )
            for (
              f = Math.abs(d - this.parent.convertValueToPixel(h[0].dataPoint.y[0])), n = w = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(d - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (w = Math.abs(d - this.parent.convertValueToPixel(h[n].dataPoint.y))),
                  w < f && ((f = w), (s = n));
          else if (
            "stackedColumn" === h[0].dataSeries.type ||
            "stackedArea" === h[0].dataSeries.type
          )
            for (
              f = Math.abs(d - this.parent.convertValueToPixel(h[0].dataPoint.y)), n = C = w = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(d - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (C += h[n].dataPoint.y),
                  (w = Math.abs(d - this.parent.convertValueToPixel(C))),
                  w < f && ((f = w), (s = n));
          else if (
            "stackedColumn100" === h[0].dataSeries.type ||
            "stackedArea100" === h[0].dataSeries.type
          )
            for (
              f = Math.abs(d - this.parent.convertValueToPixel(h[0].dataPoint.y)),
                n = t = C = w = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(d - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (C += h[n].dataPoint.y),
                  (t = h[n].dataPoint.x.getTime ? h[n].dataPoint.x.getTime() : h[n].dataPoint.x),
                  (t = 100 * (C / h[n].dataSeries.plotUnit.dataPointYSums[t])),
                  (w = Math.abs(d - this.parent.convertValueToPixel(t))),
                  w < f && ((f = w), (s = n));
          else
            for (
              f = Math.abs(d - this.parent.convertValueToPixel(h[0].dataPoint.y)), n = w = 0;
              n < h.length;
              n++
            )
              if (h[n].dataPoint.y && h[n].dataPoint.y.length)
                for (l = 0; l < h[n].dataPoint.y.length; l++)
                  (w = Math.abs(d - this.parent.convertValueToPixel(h[n].dataPoint.y[l]))),
                    w < f && ((f = w), (s = n));
              else
                (w = Math.abs(d - this.parent.convertValueToPixel(h[n].dataPoint.y))),
                  w < f && ((f = w), (s = n));
          l = h[s];
          b = 0;
          if (
            "rangeColumn" === this.parent.dataSeries[s].type ||
            "rangeArea" === this.parent.dataSeries[s].type ||
            "error" === this.parent.dataSeries[s].type ||
            "rangeSplineArea" === this.parent.dataSeries[s].type ||
            "candlestick" === this.parent.dataSeries[s].type ||
            "ohlc" === this.parent.dataSeries[s].type ||
            "boxAndWhisker" === this.parent.dataSeries[s].type
          ) {
            f = Math.abs(d - this.parent.convertValueToPixel(l.dataPoint.y[0]));
            for (n = w = 0; n < l.dataPoint.y.length; n++)
              (w = Math.abs(d - this.parent.convertValueToPixel(l.dataPoint.y[n]))),
                w < f && ((f = w), (b = n));
            h =
              1 === p.lineWidth % 2
                ? (this.parent.convertValueToPixel(l.dataPoint.y[b]) << 0) + 0.5
                : this.parent.convertValueToPixel(l.dataPoint.y[b]) << 0;
            k.text = this.labelFormatter
              ? this.labelFormatter({
                  chart: this.chart,
                  axis: this.parent.options,
                  crosshair: this.options,
                  value: l.dataPoint.y[b]
                })
              : u(this.options.label)
              ? fa(l.dataPoint.y[b], this.valueFormatString, this.chart._cultureInfo)
              : this.label;
          } else if (
            "stackedColumn" === this.parent.dataSeries[s].type ||
            "stackedArea" === this.parent.dataSeries[s].type
          ) {
            f = Math.abs(d - this.parent.convertValueToPixel(h[0].dataPoint.y));
            C = w = 0;
            for (n = s; 0 <= n; n--)
              (C += h[n].dataPoint.y),
                (w = Math.abs(d - this.parent.convertValueToPixel(C))),
                w < f && ((f = w), (b = n));
            h =
              1 === p.lineWidth % 2
                ? (this.parent.convertValueToPixel(C) << 0) + 0.5
                : this.parent.convertValueToPixel(C) << 0;
            k.text = this.labelFormatter
              ? this.labelFormatter({
                  chart: this.chart,
                  axis: this.parent.options,
                  crosshair: this.options,
                  value: l.dataPoint.y
                })
              : u(this.options.label)
              ? fa(l.dataPoint.y, this.valueFormatString, this.chart._cultureInfo)
              : this.label;
          } else if (
            "stackedColumn100" === this.parent.dataSeries[s].type ||
            "stackedArea100" === this.parent.dataSeries[s].type
          ) {
            f = Math.abs(d - this.parent.convertValueToPixel(h[0].dataPoint.y));
            C = w = 0;
            for (n = s; 0 <= n; n--)
              (C += h[n].dataPoint.y),
                (t = h[n].dataPoint.x.getTime ? h[n].dataPoint.x.getTime() : h[n].dataPoint.x),
                (t = 100 * (C / h[n].dataSeries.plotUnit.dataPointYSums[t])),
                (w = Math.abs(d - this.parent.convertValueToPixel(t))),
                w < f && ((f = w), (b = n));
            h =
              1 === p.lineWidth % 2
                ? (this.parent.convertValueToPixel(t) << 0) + 0.5
                : this.parent.convertValueToPixel(t) << 0;
            k.text = this.labelFormatter
              ? this.labelFormatter({
                  chart: this.chart,
                  axis: this.parent.options,
                  crosshair: this.options,
                  value: t
                })
              : u(this.options.label)
              ? fa(t, this.valueFormatString, this.chart._cultureInfo)
              : this.label;
          } else
            "waterfall" === this.parent.dataSeries[s].type
              ? ((h =
                  1 === p.lineWidth % 2
                    ? (this.parent.convertValueToPixel(
                        l.dataSeries.dataPointEOs[l.index].cumulativeSum
                      ) <<
                        0) +
                      0.5
                    : this.parent.convertValueToPixel(
                        l.dataSeries.dataPointEOs[l.index].cumulativeSum
                      ) << 0),
                (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value: l.dataSeries.dataPointEOs[l.index].cumulativeSum
                    })
                  : u(this.options.label)
                  ? fa(
                      l.dataSeries.dataPointEOs[l.index].cumulativeSum,
                      this.valueFormatString,
                      this.chart._cultureInfo
                    )
                  : this.label))
              : ((h =
                  1 === p.lineWidth % 2
                    ? (this.parent.convertValueToPixel(l.dataPoint.y) << 0) + 0.5
                    : this.parent.convertValueToPixel(l.dataPoint.y) << 0),
                (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value: l.dataPoint.y
                    })
                  : u(this.options.label)
                  ? fa(l.dataPoint.y, this.valueFormatString, this.chart._cultureInfo)
                  : this.label));
          e = g = h;
          b = this.chart.plotArea.x1;
          c = this.chart.plotArea.x2;
          this.bounds = { x1: b, y1: e - m / 2, x2: c, y2: g + m / 2 };
          k.y = g + k.fontSize / 2 - k.measureText().height / 2 + 2;
          k.y - k.fontSize / 2 < this.chart.bounds.y1
            ? (k.y = this.chart.bounds.y1 + k.fontSize / 2 + 2)
            : k.y + k.measureText().height - k.fontSize / 2 > this.chart.bounds.y2 &&
              (k.y = this.chart.bounds.y2 - k.measureText().height + k.fontSize / 2);
          "left" === this.parent._position
            ? (k.x = this.parent.lineCoordinates.x2 - k.measureText().width)
            : "right" === this.parent._position && (k.x = this.parent.lineCoordinates.x2);
        }
        h = null;
        ("bottom" === this.parent._position || "top" === this.parent._position) &&
          b >= this.parent.convertValueToPixel(this.parent.viewportMinimum) &&
            c <= this.parent.convertValueToPixel(this.parent.viewportMaximum) &&
          (0 < m && (p.moveTo(b, e), p.lineTo(c, g), p.stroke()),
          p.restore(),
          !u(k.text) &&
            ("number" === typeof k.text.valueOf() || 0 < k.text.length) &&
            k.render(!0));
        ("left" === this.parent._position || "right" === this.parent._position) &&
          g >= this.parent.convertValueToPixel(this.parent.viewportMaximum) &&
            e <= this.parent.convertValueToPixel(this.parent.viewportMinimum) &&
          (0 < m && (p.moveTo(b, e), p.lineTo(c, g), p.stroke()),
          p.restore(),
          !u(k.text) &&
            ("number" === typeof k.text.valueOf() || 0 < k.text.length) &&
            k.render(!0));
      } else {
        if ("bottom" === this.parent._position || "top" === this.parent._position)
          (b = c = h = 1 === p.lineWidth % 2 ? (a << 0) + 0.5 : a << 0),
            (e = this.chart.plotArea.y1),
            (g = this.chart.plotArea.y2),
            (this.bounds = { x1: b - m / 2, y1: e, x2: c + m / 2, y2: g });
        else if ("left" === this.parent._position || "right" === this.parent._position)
          (e = g = h = 1 === p.lineWidth % 2 ? (d << 0) + 0.5 : d << 0),
            (b = this.chart.plotArea.x1),
            (c = this.chart.plotArea.x2),
            (this.bounds = { x1: b, y1: e - m / 2, x2: c, y2: g + m / 2 });
        if ("xySwapped" === this.chart.plotInfo.axisPlacement)
          if ("left" === this.parent._position || "right" === this.parent._position) {
            t = !1;
            if (this.parent.labels)
              for (
                h = Math.ceil(this.parent.interval), n = 0;
                n < this.parent.viewportMaximum;
                n += h
              )
                if (this.parent.labels[n]) t = !0;
                else {
                  t = !1;
                  break;
                }
            if (t) {
              if ("axisX" === this.parent.type)
                for (
                  n = this.parent.convertPixelToValue({ y: d }), f = null, s = 0;
                  s < this.parent.dataSeries.length;
                  s++
                )
                  (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
                    0 <= f.index &&
                    (k.text = this.labelFormatter
                      ? this.labelFormatter({
                          chart: this.chart,
                          axis: this.parent.options,
                          crosshair: this.options,
                          value: this.parent.convertPixelToValue(a)
                        })
                      : u(this.options.label)
                      ? f.dataPoint.label
                      : this.label);
            } else
              "dateTime" === this.parent.valueType
                ? (k.text = this.labelFormatter
                    ? this.labelFormatter({
                        chart: this.chart,
                        axis: this.parent.options,
                        crosshair: this.options,
                        value: this.parent.convertPixelToValue(d)
                      })
                    : u(this.options.label)
                    ? Ba(
                        this.parent.convertPixelToValue(d),
                        this.valueFormatString,
                        this.chart._cultureInfo
                      )
                    : this.label)
                : "number" === this.parent.valueType &&
                  (k.text = this.labelFormatter
                    ? this.labelFormatter({
                        chart: this.chart,
                        axis: this.parent.options,
                        crosshair: this.options,
                        value: this.parent.convertPixelToValue(d)
                      })
                    : u(this.options.label)
                    ? fa(
                        this.parent.convertPixelToValue(d),
                        this.valueFormatString,
                        this.chart._cultureInfo
                      )
                    : this.label);
            k.y = d + k.fontSize / 2 - k.measureText().height / 2 + 2;
            k.y - k.fontSize / 2 < this.chart.bounds.y1
              ? (k.y = this.chart.bounds.y1 + k.fontSize / 2 + 2)
              : k.y + k.measureText().height - k.fontSize / 2 > this.chart.bounds.y2 &&
                (k.y = this.chart.bounds.y2 - k.measureText().height + k.fontSize / 2);
            "left" === this.parent._position
              ? (k.x = this.parent.lineCoordinates.x1 - k.measureText().width)
              : "right" === this.parent._position && (k.x = this.parent.lineCoordinates.x2);
          } else {
            if ("bottom" === this.parent._position || "top" === this.parent._position)
              (k.text = this.labelFormatter
                ? this.labelFormatter({
                    chart: this.chart,
                    axis: this.parent.options,
                    crosshair: this.options,
                    value: this.parent.convertPixelToValue(a)
                  })
                : u(this.options.label)
                ? fa(
                    this.parent.convertPixelToValue(a),
                    this.valueFormatString,
                    this.chart._cultureInfo
                  )
                : this.label),
                (k.x = b - k.measureText().width / 2),
                k.x + k.width > this.chart.bounds.x2 && (k.x = this.chart.bounds.x2 - k.width),
                k.x < this.chart.bounds.x1 && (k.x = this.chart.bounds.x1),
                "bottom" === this.parent._position &&
                  (k.y = this.parent.lineCoordinates.y2 + k.fontSize / 2 + 2),
                "top" === this.parent._position &&
                  (k.y = this.parent.lineCoordinates.y1 - k.height + k.fontSize / 2 + 2);
          }
        else if ("bottom" === this.parent._position || "top" === this.parent._position) {
          t = !1;
          l = "";
          if (this.parent.labels)
            for (
              h = Math.ceil(this.parent.interval), n = 0;
              n < this.parent.viewportMaximum;
              n += h
            )
              if (this.parent.labels[n]) t = !0;
              else {
                t = !1;
                break;
              }
          if (t) {
            if ("axisX" === this.parent.type)
              for (
                n = this.parent.convertPixelToValue({ x: a }), f = null, s = 0;
                s < this.parent.dataSeries.length;
                s++
              )
                (f = this.parent.dataSeries[s].getDataPointAtX(n, !0)) &&
                  0 <= f.index &&
                  (k.text = this.labelFormatter
                    ? this.labelFormatter({
                        chart: this.chart,
                        axis: this.parent.options,
                        crosshair: this.options,
                        value: this.parent.convertPixelToValue(a)
                      })
                    : u(this.options.label)
                    ? f.dataPoint.label
                    : this.label);
          } else
            "dateTime" === this.parent.valueType
              ? (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value: this.parent.convertPixelToValue(a)
                    })
                  : u(this.options.label)
                  ? Ba(
                      this.parent.convertPixelToValue(a),
                      this.valueFormatString,
                      this.chart._cultureInfo
                    )
                  : this.label)
              : "number" === this.parent.valueType &&
                (k.text = this.labelFormatter
                  ? this.labelFormatter({
                      chart: this.chart,
                      axis: this.parent.options,
                      crosshair: this.options,
                      value:
                        0 < this.parent.dataSeries.length ? this.parent.convertPixelToValue(a) : ""
                    })
                  : u(this.options.label)
                  ? fa(
                      this.parent.convertPixelToValue(a),
                      this.valueFormatString,
                      this.chart._cultureInfo
                    )
                  : this.label);
          k.x = b - k.measureText().width / 2;
          k.x + k.width > this.chart.bounds.x2 && (k.x = this.chart.bounds.x2 - k.width);
          k.x < this.chart.bounds.x1 && (k.x = this.chart.bounds.x1);
          "bottom" === this.parent._position
            ? (k.y = this.parent.lineCoordinates.y2 + k.fontSize / 2 + 2)
            : "top" === this.parent._position &&
              (k.y = this.parent.lineCoordinates.y1 - k.height + k.fontSize / 2 + 2);
        } else if ("left" === this.parent._position || "right" === this.parent._position)
          (k.text = this.labelFormatter
            ? this.labelFormatter({
                chart: this.chart,
                axis: this.parent.options,
                crosshair: this.options,
                value: this.parent.convertPixelToValue(d)
              })
            : u(this.options.label)
            ? fa(
                this.parent.convertPixelToValue(d),
                this.valueFormatString,
                this.chart._cultureInfo
              )
            : this.label),
            (k.y = d + k.fontSize / 2 - k.measureText().height / 2 + 2),
            k.y - k.fontSize / 2 < this.chart.bounds.y1
              ? (k.y = this.chart.bounds.y1 + k.fontSize / 2 + 2)
              : k.y + k.measureText().height - k.fontSize / 2 > this.chart.bounds.y2 &&
                (k.y = this.chart.bounds.y2 - k.measureText().height + k.fontSize / 2),
            "left" === this.parent._position
              ? (k.x = this.parent.lineCoordinates.x2 - k.measureText().width)
              : "right" === this.parent._position && (k.x = this.parent.lineCoordinates.x2);
        0 < m && (p.moveTo(b, e), p.lineTo(c, g), p.stroke());
        p.restore();
        !u(k.text) && ("number" === typeof k.text.valueOf() || 0 < k.text.length) && k.render(!0);
      }
      p.globalAlpha = q;
    };
    pa(aa, U);
    aa.prototype._initialize = function() {
      if (this.enabled) {
        this.container = document.createElement("div");
        this.container.setAttribute("class", "canvasjs-chart-tooltip");
        this.container.style.position = "absolute";
        this.container.style.height = "auto";
        this.container.style.boxShadow = "1px 1px 2px 2px rgba(0,0,0,0.1)";
        this.container.style.zIndex = "1000";
        this.container.style.pointerEvents = "none";
        this.container.style.display = "none";
        var a;
        a = '<div style=" width: auto;height: auto;min-width: 50px;';
        a += "line-height: auto;";
        a += "margin: 0px 0px 0px 0px;";
        a += "padding: 5px;";
        a += "font-family: Calibri, Arial, Georgia, serif;";
        a += "font-weight: normal;";
        a += "font-style: " + (s ? "italic;" : "normal;");
        a += "font-size: 14px;";
        a += "color: #000000;";
        a += "text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);";
        a += "text-align: left;";
        a += "border: 2px solid gray;";
        a += s ? "background: rgba(255,255,255,.9);" : "background: rgb(255,255,255);";
        a += "text-indent: 0px;";
        a += "white-space: nowrap;";
        a += "border-radius: 5px;";
        a += "-moz-user-select:none;";
        a += "-khtml-user-select: none;";
        a += "-webkit-user-select: none;";
        a += "-ms-user-select: none;";
        a += "user-select: none;";
        s ||
          ((a += "filter: alpha(opacity = 90);"),
          (a +=
            "filter: progid:DXImageTransform.Microsoft.Shadow(Strength=3, Direction=135, Color='#666666');"));
        a += '} "> Sample Tooltip</div>';
        this.container.innerHTML = a;
        this.contentDiv = this.container.firstChild;
        this.container.style.borderRadius = this.contentDiv.style.borderRadius;
        this.chart._canvasJSContainer.appendChild(this.container);
      }
    };
    aa.prototype.mouseMoveHandler = function(a, d) {
      (this._lastUpdated && 4 > new Date().getTime() - this._lastUpdated) ||
        ((this._lastUpdated = new Date().getTime()),
        this.chart.resetOverlayedCanvas(),
        this._updateToolTip(a, d));
    };
    aa.prototype._updateToolTip = function(a, d, b) {
      b = "undefined" === typeof b ? !0 : b;
      this.container || this._initialize();
      this.enabled || this.hide();
      if (!this.chart.disableToolTip) {
        if ("undefined" === typeof a || "undefined" === typeof d) {
          if (isNaN(this._prevX) || isNaN(this._prevY)) return;
          a = this._prevX;
          d = this._prevY;
        } else (this._prevX = a), (this._prevY = d);
        var c = null,
          e = null,
          g = [],
          h = 0;
        if (this.shared && this.enabled && "none" !== this.chart.plotInfo.axisPlacement) {
          if ("xySwapped" === this.chart.plotInfo.axisPlacement) {
            var k = [];
            if (this.chart.axisX)
              for (var l = 0; l < this.chart.axisX.length; l++) {
                for (
                  var h = this.chart.axisX[l].convertPixelToValue({ y: d }), m = null, c = 0;
                  c < this.chart.axisX[l].dataSeries.length;
                  c++
                )
                  (m = this.chart.axisX[l].dataSeries[c].getDataPointAtX(h, b)) &&
                    0 <= m.index &&
                    ((m.dataSeries = this.chart.axisX[l].dataSeries[c]),
                    null !== m.dataPoint.y && k.push(m));
                m = null;
              }
            if (this.chart.axisX2)
              for (l = 0; l < this.chart.axisX2.length; l++) {
                h = this.chart.axisX2[l].convertPixelToValue({ y: d });
                m = null;
                for (c = 0; c < this.chart.axisX2[l].dataSeries.length; c++)
                  (m = this.chart.axisX2[l].dataSeries[c].getDataPointAtX(h, b)) &&
                    0 <= m.index &&
                    ((m.dataSeries = this.chart.axisX2[l].dataSeries[c]),
                    null !== m.dataPoint.y && k.push(m));
                m = null;
              }
          } else {
            k = [];
            if (this.chart.axisX)
              for (l = 0; l < this.chart.axisX.length; l++)
                for (
                  h = this.chart.axisX[l].convertPixelToValue({ x: a }), m = null, c = 0;
                  c < this.chart.axisX[l].dataSeries.length;
                  c++
                )
                  (m = this.chart.axisX[l].dataSeries[c].getDataPointAtX(h, b)) &&
                    0 <= m.index &&
                    ((m.dataSeries = this.chart.axisX[l].dataSeries[c]), k.push(m));
                    // null !== m.dataPoint.y && k.push(m));
            if (this.chart.axisX2)
              for (l = 0; l < this.chart.axisX2.length; l++)
                for (
                  h = this.chart.axisX2[l].convertPixelToValue({ x: a }), m = null, c = 0;
                  c < this.chart.axisX2[l].dataSeries.length;
                  c++
                )
                  (m = this.chart.axisX2[l].dataSeries[c].getDataPointAtX(h, b)) &&
                    0 <= m.index &&
                    ((m.dataSeries = this.chart.axisX2[l].dataSeries[c]), k.push(m));
                    // null !== m.dataPoint.y && k.push(m));
          }
          if (0 === k.length) return;
          k.sort(function(a, b) {
            return a.distance - b.distance;
          });
          b = k[0];
          for (c = 0; c < k.length; c++) {
            // k[c].dataPoint.x.valueOf() === b.dataPoint.x.valueOf() && g.push(k[c]);
            (k[c].dataSeries.type.indexOf("step") !== -1 || k[c].dataPoint.x.valueOf() === b.dataPoint.x.valueOf()) && g.push(k[c]);
          }
          k = null;
        } else {
          if ((m = this.chart.getDataPointAtXY(a, d, b)))
            (this.currentDataPointIndex = m.dataPointIndex),
              (this.currentSeriesIndex = m.dataSeries.index);
          else if (s)
            if (
              ((m = ab(a, d, this.chart._eventManager.ghostCtx)),
              0 < m && "undefined" !== typeof this.chart._eventManager.objectMap[m])
            ) {
              m = this.chart._eventManager.objectMap[m];
              if ("legendItem" === m.objectType) return;
              this.currentSeriesIndex = m.dataSeriesIndex;
              this.currentDataPointIndex = 0 <= m.dataPointIndex ? m.dataPointIndex : -1;
            } else this.currentDataPointIndex = -1;
          else this.currentDataPointIndex = -1;
          if (0 <= this.currentSeriesIndex) {
            e = this.chart.data[this.currentSeriesIndex];
            m = {};
            if (0 <= this.currentDataPointIndex)
              (c = e.dataPoints[this.currentDataPointIndex]),
                (m.dataSeries = e),
                (m.dataPoint = c),
                (m.index = this.currentDataPointIndex),
                (m.distance = Math.abs(c.x - h)),
                "waterfall" === e.type &&
                  ((m.cumulativeSumYStartValue =
                    e.dataPointEOs[this.currentDataPointIndex].cumulativeSumYStartValue),
                  (m.cumulativeSum = e.dataPointEOs[this.currentDataPointIndex].cumulativeSum));
            else {
              if (
                !this.enabled ||
                ("line" !== e.type &&
                  "stepLine" !== e.type &&
                  "spline" !== e.type &&
                  "area" !== e.type &&
                  "stepArea" !== e.type &&
                  "splineArea" !== e.type &&
                  "stackedArea" !== e.type &&
                  "stackedArea100" !== e.type &&
                  "rangeArea" !== e.type &&
                  "rangeSplineArea" !== e.type &&
                  "candlestick" !== e.type &&
                  "ohlc" !== e.type &&
                  "boxAndWhisker" !== e.type)
              )
                return;
              h = e.axisX.convertPixelToValue({ x: a });
              m = e.getDataPointAtX(h, b);
              u(m) ||
                ((m.dataSeries = e), (this.currentDataPointIndex = m.index), (c = m.dataPoint));
            }
            if (!u(m) && !u(m.dataPoint) && !u(m.dataPoint.y))
              if (m.dataSeries.axisY)
                if (0 < m.dataPoint.y.length) {
                  for (c = b = 0; c < m.dataPoint.y.length; c++)
                    m.dataPoint.y[c] < m.dataSeries.axisY.viewportMinimum
                      ? b--
                      : m.dataPoint.y[c] > m.dataSeries.axisY.viewportMaximum && b++;
                  b < m.dataPoint.y.length && b > -m.dataPoint.y.length && g.push(m);
                } else
                  "column" === e.type || "bar" === e.type
                    ? 0 > m.dataPoint.y
                      ? 0 > m.dataSeries.axisY.viewportMinimum &&
                        m.dataSeries.axisY.viewportMaximum >= m.dataPoint.y &&
                        g.push(m)
                      : m.dataSeries.axisY.viewportMinimum <= m.dataPoint.y &&
                        0 <= m.dataSeries.axisY.viewportMaximum &&
                        g.push(m)
                    : "bubble" === e.type
                    ? ((b = this.chart._eventManager.objectMap[e.dataPointIds[m.index]].size / 2),
                      m.dataPoint.y >= m.dataSeries.axisY.viewportMinimum - b &&
                        m.dataPoint.y <= m.dataSeries.axisY.viewportMaximum + b &&
                        g.push(m))
                    : "waterfall" === e.type
                    ? ((b = 0),
                      m.cumulativeSumYStartValue < m.dataSeries.axisY.viewportMinimum
                        ? b--
                        : m.cumulativeSumYStartValue > m.dataSeries.axisY.viewportMaximum && b++,
                      m.cumulativeSum < m.dataSeries.axisY.viewportMinimum
                        ? b--
                        : m.cumulativeSum > m.dataSeries.axisY.viewportMaximum && b++,
                      2 > b && -2 < b && g.push(m))
                    : (0 <= m.dataSeries.type.indexOf("100") ||
                        "stackedColumn" === e.type ||
                        "stackedBar" === e.type ||
                        (m.dataPoint.y >= m.dataSeries.axisY.viewportMinimum &&
                          m.dataPoint.y <= m.dataSeries.axisY.viewportMaximum)) &&
                      g.push(m);
              else g.push(m);
          }
        }
        if (0 < g.length) {
          if ((this.highlightObjects(g), this.enabled))
            if (((b = ""), (b = this.getToolTipInnerHTML({ entries: g })), null !== b)) {
              this.contentDiv.innerHTML = b;
              b = !1;
              "none" === this.container.style.display &&
                ((b = !0), (this.container.style.display = "block"));
              try {
                (this.contentDiv.style.background = this.backgroundColor
                  ? this.backgroundColor
                  : s
                  ? "rgba(255,255,255,.9)"
                  : "rgb(255,255,255)"),
                  (this.borderColor =
                    "waterfall" === g[0].dataSeries.type
                      ? (this.contentDiv.style.borderRightColor = this.contentDiv.style.borderLeftColor = this.contentDiv.style.borderColor = this
                          .options.borderColor
                          ? this.options.borderColor
                          : g[0].dataPoint.color
                          ? g[0].dataPoint.color
                          : 0 < g[0].dataPoint.y
                          ? g[0].dataSeries.risingColor
                          : g[0].dataSeries.fallingColor)
                      : "error" === g[0].dataSeries.type
                      ? (this.contentDiv.style.borderRightColor = this.contentDiv.style.borderLeftColor = this.contentDiv.style.borderColor = this
                          .options.borderColor
                          ? this.options.borderColor
                          : g[0].dataSeries.color
                          ? g[0].dataSeries.color
                          : g[0].dataSeries._colorSet[e.index % g[0].dataSeries._colorSet.length])
                      : (this.contentDiv.style.borderRightColor = this.contentDiv.style.borderLeftColor = this.contentDiv.style.borderColor = this
                          .options.borderColor
                          ? this.options.borderColor
                          : g[0].dataPoint.color
                          ? g[0].dataPoint.color
                          : g[0].dataSeries.color
                          ? g[0].dataSeries.color
                          : g[0].dataSeries._colorSet[
                              g[0].index % g[0].dataSeries._colorSet.length
                            ])),
                  (this.contentDiv.style.borderWidth =
                    this.borderThickness || 0 === this.borderThickness
                      ? this.borderThickness + "px"
                      : "2px"),
                  (this.contentDiv.style.borderRadius =
                    this.cornerRadius || 0 === this.cornerRadius
                      ? this.cornerRadius + "px"
                      : "5px"),
                  (this.container.style.borderRadius = this.contentDiv.style.borderRadius),
                  (this.contentDiv.style.fontSize =
                    this.fontSize || 0 === this.fontSize ? this.fontSize + "px" : "14px"),
                  (this.contentDiv.style.color = this.fontColor ? this.fontColor : "#000000"),
                  (this.contentDiv.style.fontFamily = this.fontFamily
                    ? this.fontFamily
                    : "Calibri, Arial, Georgia, serif;"),
                  (this.contentDiv.style.fontWeight = this.fontWeight ? this.fontWeight : "normal"),
                  (this.contentDiv.style.fontStyle = this.fontStyle
                    ? this.fontStyle
                    : s
                    ? "italic"
                    : "normal");
              } catch (p) {}
              "pie" === g[0].dataSeries.type ||
              "doughnut" === g[0].dataSeries.type ||
              "funnel" === g[0].dataSeries.type ||
              "pyramid" === g[0].dataSeries.type ||
              "bar" === g[0].dataSeries.type ||
              "rangeBar" === g[0].dataSeries.type ||
              "stackedBar" === g[0].dataSeries.type ||
              "stackedBar100" === g[0].dataSeries.type
                ? (a = a - 10 - this.container.clientWidth)
                : ((a =
                    (g[0].dataSeries.axisX.convertValueToPixel(g[0].dataPoint.x) -
                      this.container.clientWidth) <<
                    0),
                  (a -= 10));
              0 > a && (a += this.container.clientWidth + 20);
              a + this.container.clientWidth >
                Math.max(this.chart.container.clientWidth, this.chart.width) &&
                (a = Math.max(
                  0,
                  Math.max(this.chart.container.clientWidth, this.chart.width) -
                    this.container.clientWidth
                ));
              d =
                1 !== g.length ||
                this.shared ||
                ("line" !== g[0].dataSeries.type &&
                  "stepLine" !== g[0].dataSeries.type &&
                  "spline" !== g[0].dataSeries.type &&
                  "area" !== g[0].dataSeries.type &&
                  "stepArea" !== g[0].dataSeries.type &&
                  "splineArea" !== g[0].dataSeries.type)
                  ? "bar" === g[0].dataSeries.type ||
                    "rangeBar" === g[0].dataSeries.type ||
                    "stackedBar" === g[0].dataSeries.type ||
                    "stackedBar100" === g[0].dataSeries.type
                    ? g[0].dataSeries.axisX.convertValueToPixel(g[0].dataPoint.x)
                    : d
                  : g[0].dataSeries.axisY.convertValueToPixel(g[0].dataPoint.y);
              d = -d + 10;
              0 < d + this.container.clientHeight + 5 &&
                (d -= d + this.container.clientHeight + 5 - 0);
              this.fixMozTransitionDelay(a, d);
              !this.animationEnabled || b
                ? this.disableAnimation()
                : (this.enableAnimation(),
                  (this.container.style.MozTransition = this.mozContainerTransition));
              this.container.style.left = a + "px";
              this.container.style.bottom = d + "px";
            } else this.hide(!1);
        } else this.hide();
      }
    };
    aa.prototype.highlightObjects = function(a) {
      var d = this.chart.overlaidCanvasCtx;
      this.chart.resetOverlayedCanvas();
      d.clearRect(0, 0, this.chart.width, this.chart.height);
      d.save();
      var b = this.chart.plotArea,
        c = 0;
      d.beginPath();
      d.rect(b.x1, b.y1, b.x2 - b.x1, b.y2 - b.y1);
      d.clip();
      for (b = 0; b < a.length; b++) {
        var e = a[b];
        if (
          (e = this.chart._eventManager.objectMap[e.dataSeries.dataPointIds[e.index]]) &&
          e.objectType &&
          "dataPoint" === e.objectType
        ) {
          var c = this.chart.data[e.dataSeriesIndex],
            g = c.dataPoints[e.dataPointIndex],
            h = e.dataPointIndex;
          !1 === g.highlightEnabled ||
            (!0 !== c.highlightEnabled && !0 !== g.highlightEnabled) ||
            ("line" === c.type ||
            "stepLine" === c.type ||
            "spline" === c.type ||
            "scatter" === c.type ||
            "area" === c.type ||
            "stepArea" === c.type ||
            "splineArea" === c.type ||
            "stackedArea" === c.type ||
            "stackedArea100" === c.type ||
            "rangeArea" === c.type ||
            "rangeSplineArea" === c.type
              ? ((g = c.getMarkerProperties(h, e.x1, e.y1, this.chart.overlaidCanvasCtx)),
                (g.size = Math.max((1.5 * g.size) << 0, 10)),
                (g.borderColor = g.borderColor || "#FFFFFF"),
                (g.borderThickness = g.borderThickness || Math.ceil(0.1 * g.size)),
                Y.drawMarkers([g]),
                "undefined" !== typeof e.y2 &&
                  ((g = c.getMarkerProperties(h, e.x1, e.y2, this.chart.overlaidCanvasCtx)),
                  (g.size = Math.max((1.5 * g.size) << 0, 10)),
                  (g.borderColor = g.borderColor || "#FFFFFF"),
                  (g.borderThickness = g.borderThickness || Math.ceil(0.1 * g.size)),
                  Y.drawMarkers([g])))
              : "bubble" === c.type
              ? ((g = c.getMarkerProperties(h, e.x1, e.y1, this.chart.overlaidCanvasCtx)),
                (g.size = e.size),
                (g.color = "white"),
                (g.borderColor = "white"),
                (d.globalAlpha = 0.3),
                Y.drawMarkers([g]),
                (d.globalAlpha = 1))
              : "column" === c.type ||
                "stackedColumn" === c.type ||
                "stackedColumn100" === c.type ||
                "bar" === c.type ||
                "rangeBar" === c.type ||
                "stackedBar" === c.type ||
                "stackedBar100" === c.type ||
                "rangeColumn" === c.type ||
                "waterfall" === c.type
              ? V(d, e.x1, e.y1, e.x2, e.y2, "white", 0, null, !1, !1, !1, !1, 0.3)
              : "pie" === c.type || "doughnut" === c.type
              ? ia(
                  d,
                  e.center,
                  e.radius,
                  "white",
                  c.type,
                  e.startAngle,
                  e.endAngle,
                  0.3,
                  e.percentInnerRadius
                )
              : "funnel" === c.type || "pyramid" === c.type
              ? sa(d, e.funnelSection, 0.3, "white")
              : "candlestick" === c.type
              ? ((d.globalAlpha = 1),
                (d.strokeStyle = e.color),
                (d.lineWidth = 2 * e.borderThickness),
                (c = 0 === d.lineWidth % 2 ? 0 : 0.5),
                d.beginPath(),
                d.moveTo(e.x3 - c, Math.min(e.y2, e.y3)),
                d.lineTo(e.x3 - c, Math.min(e.y1, e.y4)),
                d.stroke(),
                d.beginPath(),
                d.moveTo(e.x3 - c, Math.max(e.y1, e.y4)),
                d.lineTo(e.x3 - c, Math.max(e.y2, e.y3)),
                d.stroke(),
                V(
                  d,
                  e.x1,
                  Math.min(e.y1, e.y4),
                  e.x2,
                  Math.max(e.y1, e.y4),
                  "transparent",
                  2 * e.borderThickness,
                  e.color,
                  !1,
                  !1,
                  !1,
                  !1
                ),
                (d.globalAlpha = 1))
              : "ohlc" === c.type
              ? ((d.globalAlpha = 1),
                (d.strokeStyle = e.color),
                (d.lineWidth = 2 * e.borderThickness),
                (c = 0 === d.lineWidth % 2 ? 0 : 0.5),
                d.beginPath(),
                d.moveTo(e.x3 - c, e.y2),
                d.lineTo(e.x3 - c, e.y3),
                d.stroke(),
                d.beginPath(),
                d.moveTo(e.x3, e.y1),
                d.lineTo(e.x1, e.y1),
                d.stroke(),
                d.beginPath(),
                d.moveTo(e.x3, e.y4),
                d.lineTo(e.x2, e.y4),
                d.stroke(),
                (d.globalAlpha = 1))
              : "boxAndWhisker" === c.type
              ? (d.save(),
                (d.globalAlpha = 1),
                (d.strokeStyle = e.stemColor),
                (d.lineWidth = 2 * e.stemThickness),
                0 < e.stemThickness &&
                  (d.beginPath(),
                  d.moveTo(e.x3, e.y2 + e.borderThickness / 2),
                  d.lineTo(e.x3, e.y1 + e.whiskerThickness / 2),
                  d.stroke(),
                  d.beginPath(),
                  d.moveTo(e.x3, e.y4 - e.whiskerThickness / 2),
                  d.lineTo(e.x3, e.y3 - e.borderThickness / 2),
                  d.stroke()),
                d.beginPath(),
                V(
                  d,
                  e.x1 - e.borderThickness / 2,
                  Math.max(e.y2 + e.borderThickness / 2, e.y3 + e.borderThickness / 2),
                  e.x2 + e.borderThickness / 2,
                  Math.min(e.y2 - e.borderThickness / 2, e.y3 - e.borderThickness / 2),
                  "transparent",
                  e.borderThickness,
                  e.color,
                  !1,
                  !1,
                  !1,
                  !1
                ),
                (d.globalAlpha = 1),
                (d.strokeStyle = e.whiskerColor),
                (d.lineWidth = 2 * e.whiskerThickness),
                0 < e.whiskerThickness &&
                  (d.beginPath(),
                  d.moveTo(Math.floor(e.x3 - e.whiskerLength / 2), e.y4),
                  d.lineTo(Math.ceil(e.x3 + e.whiskerLength / 2), e.y4),
                  d.stroke(),
                  d.beginPath(),
                  d.moveTo(Math.floor(e.x3 - e.whiskerLength / 2), e.y1),
                  d.lineTo(Math.ceil(e.x3 + e.whiskerLength / 2), e.y1),
                  d.stroke()),
                (d.globalAlpha = 1),
                (d.strokeStyle = e.lineColor),
                (d.lineWidth = 2 * e.lineThickness),
                0 < e.lineThickness &&
                  (d.beginPath(), d.moveTo(e.x1, e.y5), d.lineTo(e.x2, e.y5), d.stroke()),
                d.restore(),
                (d.globalAlpha = 1))
              : "error" === c.type &&
                H(
                  d,
                  e.x1,
                  e.y1,
                  e.x2,
                  e.y2,
                  "white",
                  e.whiskerProperties,
                  e.stemProperties,
                  e.isXYSwapped,
                  0.3
                ));
        }
      }
      d.restore();
      d.globalAlpha = 1;
      d.beginPath();
    };
    aa.prototype.getToolTipInnerHTML = function(a) {
      a = a.entries;
      for (var d = null, b = null, c = null, e = 0, g = "", h = !0, k = 0; k < a.length; k++)
        if (a[k].dataSeries.toolTipContent || a[k].dataPoint.toolTipContent) {
          h = !1;
          break;
        }
      if (h && ((this.content && "function" === typeof this.content) || this.contentFormatter))
        (a = { chart: this.chart, toolTip: this.options, entries: a }),
          (d = this.contentFormatter ? this.contentFormatter(a) : this.content(a));
      else if (this.shared && "none" !== this.chart.plotInfo.axisPlacement) {
        for (var l = null, m = "", k = 0; k < a.length; k++)
          (b = a[k].dataSeries),
            (c = a[k].dataPoint),
            (e = a[k].index),
            (g = ""),
            0 === k &&
              h && !this.content &&
              (this.chart.axisX && 0 < this.chart.axisX.length
                ? (m +=
                    "undefined" !== typeof this.chart.axisX[0].labels[c.x]
                      ? this.chart.axisX[0].labels[c.x]
                      : "{x}")
                : this.chart.axisX2 &&
                  0 < this.chart.axisX2.length &&
                  (m +=
                    "undefined" !== typeof this.chart.axisX2[0].labels[c.x]
                      ? this.chart.axisX2[0].labels[c.x]
                      : "{x}"),
              (m += "</br>"),
              (m = this.chart.replaceKeywordsWithValue(m, c, b, e))),
            null === c.toolTipContent ||
              ("undefined" === typeof c.toolTipContent && null === b.options.toolTipContent) ||
              ("line" === b.type ||
              "stepLine" === b.type ||
              "spline" === b.type ||
              "area" === b.type ||
              "stepArea" === b.type ||
              "splineArea" === b.type ||
              "column" === b.type ||
              "bar" === b.type ||
              "scatter" === b.type ||
              "stackedColumn" === b.type ||
              "stackedColumn100" === b.type ||
              "stackedBar" === b.type ||
              "stackedBar100" === b.type ||
              "stackedArea" === b.type ||
              "stackedArea100" === b.type ||
              "waterfall" === b.type
                ? (this.chart.axisX &&
                    1 < this.chart.axisX.length &&
                    (g +=
                      l != b.axisXIndex
                        ? b.axisX.title
                          ? b.axisX.title + "<br/>"
                          : "X:{axisXIndex}<br/>"
                        : ""),
                  (g += c.toolTipContent
                    ? c.toolTipContent
                    : b.toolTipContent
                    ? b.toolTipContent
                    : this.content && "function" !== typeof this.content
                    ? this.content
                    : "<span style='\"" +
                      (this.options.fontColor ? "" : "'color:{color};'") +
                      "\"'>{name}:</span>&nbsp;&nbsp;{y}"),
                  (l = b.axisXIndex))
                : "bubble" === b.type
                ? (this.chart.axisX &&
                    1 < this.chart.axisX.length &&
                    (g +=
                      l != b.axisXIndex
                        ? b.axisX.title
                          ? b.axisX.title + "<br/>"
                          : "X:{axisXIndex}<br/>"
                        : ""),
                  (g += c.toolTipContent
                    ? c.toolTipContent
                    : b.toolTipContent
                    ? b.toolTipContent
                    : this.content && "function" !== typeof this.content
                    ? this.content
                    : "<span style='\"" +
                      (this.options.fontColor ? "" : "'color:{color};'") +
                      "\"'>{name}:</span>&nbsp;&nbsp;{y}, &nbsp;&nbsp;{z}"))
                : "rangeColumn" === b.type ||
                  "rangeBar" === b.type ||
                  "rangeArea" === b.type ||
                  "rangeSplineArea" === b.type ||
                  "error" === b.type
                ? (this.chart.axisX &&
                    1 < this.chart.axisX.length &&
                    (g +=
                      l != b.axisXIndex
                        ? b.axisX.title
                          ? b.axisX.title + "<br/>"
                          : "X:{axisXIndex}<br/>"
                        : ""),
                  (g += c.toolTipContent
                    ? c.toolTipContent
                    : b.toolTipContent
                    ? b.toolTipContent
                    : this.content && "function" !== typeof this.content
                    ? this.content
                    : "<span style='\"" +
                      (this.options.fontColor ? "" : "'color:{color};'") +
                      "\"'>{name}:</span>&nbsp;&nbsp;{y[0]},&nbsp;{y[1]}"))
                : "candlestick" === b.type || "ohlc" === b.type
                ? (this.chart.axisX &&
                    1 < this.chart.axisX.length &&
                    (g +=
                      l != b.axisXIndex
                        ? b.axisX.title
                          ? b.axisX.title + "<br/>"
                          : "X:{axisXIndex}<br/>"
                        : ""),
                  (g += c.toolTipContent
                    ? c.toolTipContent
                    : b.toolTipContent
                    ? b.toolTipContent
                    : this.content && "function" !== typeof this.content
                    ? this.content
                    : "<span style='\"" +
                      (this.options.fontColor ? "" : "'color:{color};'") +
                      "\"'>{name}:</span><br/>Open: &nbsp;&nbsp;{y[0]}<br/>High: &nbsp;&nbsp;&nbsp;{y[1]}<br/>Low:&nbsp;&nbsp;&nbsp;{y[2]}<br/>Close: &nbsp;&nbsp;{y[3]}"))
                : "boxAndWhisker" === b.type &&
                  (this.chart.axisX &&
                    1 < this.chart.axisX.length &&
                    (g +=
                      l != b.axisXIndex
                        ? b.axisX.title
                          ? b.axisX.title + "<br/>"
                          : "X:{axisXIndex}<br/>"
                        : ""),
                  (g += c.toolTipContent
                    ? c.toolTipContent
                    : b.toolTipContent
                    ? b.toolTipContent
                    : this.content && "function" !== typeof this.content
                    ? this.content
                    : "<span style='\"" +
                      (this.options.fontColor ? "" : "'color:{color};'") +
                      "\"'>{name}:</span><br/>Minimum: &nbsp;&nbsp;{y[0]}<br/>Q1: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[1]}<br/>Q2: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[4]}<br/>Q3: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[2]}<br/>Maximum: &nbsp;{y[3]}")),
              null === d && (d = ""),
              !0 === this.reversed
                ? ((d = this.chart.replaceKeywordsWithValue(g, c, b, e) + d),
                  k < a.length - 1 && (d = "</br>" + d))
                : ((d += this.chart.replaceKeywordsWithValue(g, c, b, e)),
                  k < a.length - 1 && (d += "</br>")));
        null !== d && (d = m + d);
      } else {
        b = a[0].dataSeries;
        c = a[0].dataPoint;
        e = a[0].index;
        if (
          null === c.toolTipContent ||
          ("undefined" === typeof c.toolTipContent && null === b.options.toolTipContent)
        )
          return null;
        "line" === b.type ||
        "stepLine" === b.type ||
        "spline" === b.type ||
        "area" === b.type ||
        "stepArea" === b.type ||
        "splineArea" === b.type ||
        "column" === b.type ||
        "bar" === b.type ||
        "scatter" === b.type ||
        "stackedColumn" === b.type ||
        "stackedColumn100" === b.type ||
        "stackedBar" === b.type ||
        "stackedBar100" === b.type ||
        "stackedArea" === b.type ||
        "stackedArea100" === b.type ||
        "waterfall" === b.type
          ? (g = c.toolTipContent
              ? c.toolTipContent
              : b.toolTipContent
              ? b.toolTipContent
              : this.content && "function" !== typeof this.content
              ? this.content
              : "<span style='\"" +
                (this.options.fontColor ? "" : "'color:{color};'") +
                "\"'>" +
                (c.label ? "{label}" : "{x}") +
                ":</span>&nbsp;&nbsp;{y}")
          : "bubble" === b.type
          ? (g = c.toolTipContent
              ? c.toolTipContent
              : b.toolTipContent
              ? b.toolTipContent
              : this.content && "function" !== typeof this.content
              ? this.content
              : "<span style='\"" +
                (this.options.fontColor ? "" : "'color:{color};'") +
                "\"'>" +
                (c.label ? "{label}" : "{x}") +
                ":</span>&nbsp;&nbsp;{y}, &nbsp;&nbsp;{z}")
          : "pie" === b.type || "doughnut" === b.type || "funnel" === b.type || "pyramid" === b.type
          ? (g = c.toolTipContent
              ? c.toolTipContent
              : b.toolTipContent
              ? b.toolTipContent
              : this.content && "function" !== typeof this.content
              ? this.content
              : "<span style='\"" +
                (this.options.fontColor ? "" : "'color:{color};'") +
                "\"'>" +
                (c.name
                  ? "{name}:</span>&nbsp;&nbsp;"
                  : c.label
                  ? "{label}:</span>&nbsp;&nbsp;"
                  : "</span>") +
                "{y}")
          : "rangeColumn" === b.type ||
            "rangeBar" === b.type ||
            "rangeArea" === b.type ||
            "rangeSplineArea" === b.type ||
            "error" === b.type
          ? (g = c.toolTipContent
              ? c.toolTipContent
              : b.toolTipContent
              ? b.toolTipContent
              : this.content && "function" !== typeof this.content
              ? this.content
              : "<span style='\"" +
                (this.options.fontColor ? "" : "'color:{color};'") +
                "\"'>" +
                (c.label ? "{label}" : "{x}") +
                " :</span>&nbsp;&nbsp;{y[0]}, &nbsp;{y[1]}")
          : "candlestick" === b.type || "ohlc" === b.type
          ? (g = c.toolTipContent
              ? c.toolTipContent
              : b.toolTipContent
              ? b.toolTipContent
              : this.content && "function" !== typeof this.content
              ? this.content
              : "<span style='\"" +
                (this.options.fontColor ? "" : "'color:{color};'") +
                "\"'>" +
                (c.label ? "{label}" : "{x}") +
                "</span><br/>Open: &nbsp;&nbsp;{y[0]}<br/>High: &nbsp;&nbsp;&nbsp;{y[1]}<br/>Low: &nbsp;&nbsp;&nbsp;&nbsp;{y[2]}<br/>Close: &nbsp;&nbsp;{y[3]}")
          : "boxAndWhisker" === b.type &&
            (g = c.toolTipContent
              ? c.toolTipContent
              : b.toolTipContent
              ? b.toolTipContent
              : this.content && "function" !== typeof this.content
              ? this.content
              : "<span style='\"" +
                (this.options.fontColor ? "" : "'color:{color};'") +
                "\"'>" +
                (c.label ? "{label}" : "{x}") +
                "</span><br/>Minimum: &nbsp;&nbsp;{y[0]}<br/>Q1: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[1]}<br/>Q2: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[4]}<br/>Q3: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{y[2]}<br/>Maximum: &nbsp;{y[3]}");
        null === d && (d = "");
        d += this.chart.replaceKeywordsWithValue(g, c, b, e);
      }
      return d;
    };
    aa.prototype.enableAnimation = function() {
      if (!this.container.style.WebkitTransition) {
        var a = this.getContainerTransition(this.containerTransitionDuration);
        this.container.style.WebkitTransition = a;
        this.container.style.MsTransition = a;
        this.container.style.transition = a;
        this.container.style.MozTransition = this.mozContainerTransition;
      }
    };
    aa.prototype.disableAnimation = function() {
      this.container.style.WebkitTransition &&
        ((this.container.style.WebkitTransition = ""),
        (this.container.style.MozTransition = ""),
        (this.container.style.MsTransition = ""),
        (this.container.style.transition = ""));
    };
    aa.prototype.hide = function(a) {
      this.container &&
        ((this.container.style.display = "none"),
        (this.currentSeriesIndex = -1),
        (this._prevY = this._prevX = NaN),
        ("undefined" === typeof a || a) && this.chart.resetOverlayedCanvas());
    };
    aa.prototype.show = function(a, d, b) {
      this._updateToolTip(a, d, "undefined" === typeof b ? !1 : b);
    };
    aa.prototype.fixMozTransitionDelay = function(a, d) {
      if (20 < this.chart._eventManager.lastObjectId)
        this.mozContainerTransition = this.getContainerTransition(0);
      else {
        var b = parseFloat(this.container.style.left),
          b = isNaN(b) ? 0 : b,
          c = parseFloat(this.container.style.bottom),
          c = isNaN(c) ? 0 : c;
        10 < Math.sqrt(Math.pow(b - a, 2) + Math.pow(c - d, 2))
          ? (this.mozContainerTransition = this.getContainerTransition(0.1))
          : (this.mozContainerTransition = this.getContainerTransition(0));
      }
    };
    aa.prototype.getContainerTransition = function(a) {
      return "left " + a + "s ease-out 0s, bottom " + a + "s ease-out 0s";
    };
    ha.prototype.reset = function() {
      this.lastObjectId = 0;
      this.objectMap = [];
      this.rectangularRegionEventSubscriptions = [];
      this.previousDataPointEventObject = null;
      this.eventObjects = [];
      s &&
        (this.ghostCtx.clearRect(0, 0, this.chart.width, this.chart.height),
        this.ghostCtx.beginPath());
    };
    ha.prototype.getNewObjectTrackingId = function() {
      return ++this.lastObjectId;
    };
    ha.prototype.mouseEventHandler = function(a) {
      if ("mousemove" === a.type || "click" === a.type) {
        var d = [],
          b = Ra(a),
          c = null;
        if (
          (c = this.chart.getObjectAtXY(b.x, b.y, !1)) &&
          "undefined" !== typeof this.objectMap[c]
        )
          if (((c = this.objectMap[c]), "dataPoint" === c.objectType)) {
            var e = this.chart.data[c.dataSeriesIndex],
              g = e.dataPoints[c.dataPointIndex],
              h = c.dataPointIndex;
            c.eventParameter = {
              x: b.x,
              y: b.y,
              dataPoint: g,
              dataSeries: e.options,
              dataPointIndex: h,
              dataSeriesIndex: e.index,
              chart: this.chart
            };
            c.eventContext = {
              context: g,
              userContext: g,
              mouseover: "mouseover",
              mousemove: "mousemove",
              mouseout: "mouseout",
              click: "click"
            };
            d.push(c);
            c = this.objectMap[e.id];
            c.eventParameter = {
              x: b.x,
              y: b.y,
              dataPoint: g,
              dataSeries: e.options,
              dataPointIndex: h,
              dataSeriesIndex: e.index,
              chart: this.chart
            };
            c.eventContext = {
              context: e,
              userContext: e.options,
              mouseover: "mouseover",
              mousemove: "mousemove",
              mouseout: "mouseout",
              click: "click"
            };
            d.push(this.objectMap[e.id]);
          } else
            "legendItem" === c.objectType &&
              ((e = this.chart.data[c.dataSeriesIndex]),
              (g = null !== c.dataPointIndex ? e.dataPoints[c.dataPointIndex] : null),
              (c.eventParameter = {
                x: b.x,
                y: b.y,
                dataSeries: e.options,
                dataPoint: g,
                dataPointIndex: c.dataPointIndex,
                dataSeriesIndex: c.dataSeriesIndex,
                chart: this.chart
              }),
              (c.eventContext = {
                context: this.chart.legend,
                userContext: this.chart.legend.options,
                mouseover: "itemmouseover",
                mousemove: "itemmousemove",
                mouseout: "itemmouseout",
                click: "itemclick"
              }),
              d.push(c));
        e = [];
        for (b = 0; b < this.mouseoveredObjectMaps.length; b++) {
          g = !0;
          for (c = 0; c < d.length; c++)
            if (d[c].id === this.mouseoveredObjectMaps[b].id) {
              g = !1;
              break;
            }
          g
            ? this.fireEvent(this.mouseoveredObjectMaps[b], "mouseout", a)
            : e.push(this.mouseoveredObjectMaps[b]);
        }
        this.mouseoveredObjectMaps = e;
        for (b = 0; b < d.length; b++) {
          e = !1;
          for (c = 0; c < this.mouseoveredObjectMaps.length; c++)
            if (d[b].id === this.mouseoveredObjectMaps[c].id) {
              e = !0;
              break;
            }
          e || (this.fireEvent(d[b], "mouseover", a), this.mouseoveredObjectMaps.push(d[b]));
          "click" === a.type
            ? this.fireEvent(d[b], "click", a)
            : "mousemove" === a.type && this.fireEvent(d[b], "mousemove", a);
        }
      }
    };
    ha.prototype.fireEvent = function(a, d, b) {
      if (a && d) {
        var c = a.eventParameter,
          e = a.eventContext,
          g = a.eventContext.userContext;
        g && e && g[e[d]] && g[e[d]].call(g, c);
        "mouseout" !== d
          ? g.cursor && g.cursor !== b.target.style.cursor && (b.target.style.cursor = g.cursor)
          : ((b.target.style.cursor = this.chart._defaultCursor),
            delete a.eventParameter,
            delete a.eventContext);
        "click" === d &&
          "dataPoint" === a.objectType && this.chart.pieDoughnutClickHandler &&
          this.chart.pieDoughnutClickHandler.call(this.chart.data[a.dataSeriesIndex], c);
        "click" === d &&
          "dataPoint" === a.objectType && this.chart.funnelPyramidClickHandler &&
          this.chart.funnelPyramidClickHandler.call(this.chart.data[a.dataSeriesIndex], c);
      }
    };
    W.prototype.animate = function(a, d, b, c, e) {
      var g = this;
      this.chart.isAnimating = !0;
      e = e || M.easing.linear;
      b &&
        this.animations.push({
          startTime: new Date().getTime() + (a ? a : 0),
          duration: d,
          animationCallback: b,
          onComplete: c
        });
      for (a = []; 0 < this.animations.length; )
        if (
          ((d = this.animations.shift()),
          (b = new Date().getTime()),
          (c = 0),
          d.startTime <= b &&
            ((c = e(Math.min(b - d.startTime, d.duration), 0, 1, d.duration)),
            (c = Math.min(c, 1)),
            isNaN(c) || !isFinite(c)) &&
            (c = 1),
          1 > c && a.push(d),
          d.animationCallback(c),
          1 <= c && d.onComplete)
        )
          d.onComplete();
      this.animations = a;
      0 < this.animations.length
        ? (this.animationRequestId = this.chart.requestAnimFrame.call(window, function() {
            g.animate.call(g);
          }))
        : (this.chart.isAnimating = !1);
    };
    W.prototype.cancelAllAnimations = function() {
      this.animations = [];
      this.animationRequestId &&
        this.chart.cancelRequestAnimFrame.call(window, this.animationRequestId);
      this.animationRequestId = null;
      this.chart.isAnimating = !1;
    };
    var M = {
        yScaleAnimation: function(a, d) {
          if (0 !== a) {
            var b = d.dest,
              c = d.source.canvas,
              e = d.animationBase;
            b.drawImage(
              c,
              0,
              0,
              c.width,
              c.height,
              0,
              e - e * a,
              b.canvas.width / ka,
              (a * b.canvas.height) / ka
            );
          }
        },
        xScaleAnimation: function(a, d) {
          if (0 !== a) {
            var b = d.dest,
              c = d.source.canvas,
              e = d.animationBase;
            b.drawImage(
              c,
              0,
              0,
              c.width,
              c.height,
              e - e * a,
              0,
              (a * b.canvas.width) / ka,
              b.canvas.height / ka
            );
          }
        },
        xClipAnimation: function(a, d) {
          if (0 !== a) {
            var b = d.dest,
              c = d.source.canvas;
            b.save();
            0 < a &&
              b.drawImage(c, 0, 0, c.width * a, c.height, 0, 0, (c.width * a) / ka, c.height / ka);
            b.restore();
          }
        },
        fadeInAnimation: function(a, d) {
          if (0 !== a) {
            var b = d.dest,
              c = d.source.canvas;
            b.save();
            b.globalAlpha = a;
            b.drawImage(
              c,
              0,
              0,
              c.width,
              c.height,
              0,
              0,
              b.canvas.width / ka,
              b.canvas.height / ka
            );
            b.restore();
          }
        },
        easing: {
          linear: function(a, d, b, c) {
            return (b * a) / c + d;
          },
          easeOutQuad: function(a, d, b, c) {
            return -b * (a /= c) * (a - 2) + d;
          },
          easeOutQuart: function(a, d, b, c) {
            return -b * ((a = a / c - 1) * a * a * a - 1) + d;
          },
          easeInQuad: function(a, d, b, c) {
            return b * (a /= c) * a + d;
          },
          easeInQuart: function(a, d, b, c) {
            return b * (a /= c) * a * a * a + d;
          }
        }
      },
      Y = {
        drawMarker: function(a, d, b, c, e, g, h, k) {
          if (b) {
            var l = 1;
            b.fillStyle = g ? g : "#000000";
            b.strokeStyle = h ? h : "#000000";
            b.lineWidth = k ? k : 0;
            b.setLineDash && b.setLineDash(Q("solid", k));
            "circle" === c
              ? (b.moveTo(a, d),
                b.beginPath(),
                b.arc(a, d, e / 2, 0, 2 * Math.PI, !1),
                g && b.fill(),
                k &&
                  (h
                    ? b.stroke()
                    : ((l = b.globalAlpha),
                      (b.globalAlpha = 0.15),
                      (b.strokeStyle = "black"),
                      b.stroke(),
                      (b.globalAlpha = l))))
              : "square" === c
              ? (b.beginPath(),
                b.rect(a - e / 2, d - e / 2, e, e),
                g && b.fill(),
                k &&
                  (h
                    ? b.stroke()
                    : ((l = b.globalAlpha),
                      (b.globalAlpha = 0.15),
                      (b.strokeStyle = "black"),
                      b.stroke(),
                      (b.globalAlpha = l))))
              : "triangle" === c
              ? (b.beginPath(),
                b.moveTo(a - e / 2, d + e / 2),
                b.lineTo(a + e / 2, d + e / 2),
                b.lineTo(a, d - e / 2),
                b.closePath(),
                g && b.fill(),
                k &&
                  (h
                    ? b.stroke()
                    : ((l = b.globalAlpha),
                      (b.globalAlpha = 0.15),
                      (b.strokeStyle = "black"),
                      b.stroke(),
                      (b.globalAlpha = l))),
                b.beginPath())
              : "cross" === c &&
                ((b.strokeStyle = g),
                (b.lineWidth = e / 4),
                b.beginPath(),
                b.moveTo(a - e / 2, d - e / 2),
                b.lineTo(a + e / 2, d + e / 2),
                b.stroke(),
                b.moveTo(a + e / 2, d - e / 2),
                b.lineTo(a - e / 2, d + e / 2),
                b.stroke());
          }
        },
        drawMarkers: function(a) {
          for (var d = 0; d < a.length; d++) {
            var b = a[d];
            Y.drawMarker(
              b.x,
              b.y,
              b.ctx,
              b.type,
              b.size,
              b.color,
              b.borderColor,
              b.borderThickness
            );
          }
        }
      };
    return p;
  })();
  Na.Chart.version = "v2.3.2 GA";
})();

/*
  excanvas is used to support IE678 which do not implement HTML5 Canvas Element. You can safely remove the following excanvas code if you don't need to support older browsers.

  Copyright 2006 Google Inc. https://code.google.com/p/explorercanvas/
  Licensed under the Apache License, Version 2.0
*/
document.createElement("canvas").getContext ||
  (function() {
    function V() {
      return this.context_ || (this.context_ = new C(this));
    }
    function W(a, b, c) {
      var g = M.call(arguments, 2);
      return function() {
        return a.apply(b, g.concat(M.call(arguments)));
      };
    }
    function N(a) {
      return String(a)
        .replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;");
    }
    function O(a) {
      a.namespaces.g_vml_ ||
        a.namespaces.add("g_vml_", "urn:schemas-microsoft-com:vml", "#default#VML");
      a.namespaces.g_o_ ||
        a.namespaces.add("g_o_", "urn:schemas-microsoft-com:office:office", "#default#VML");
      a.styleSheets.ex_canvas_ ||
        ((a = a.createStyleSheet()),
        (a.owningElement.id = "ex_canvas_"),
        (a.cssText =
          "canvas{display:inline-block;overflow:hidden;text-align:left;width:300px;height:150px}"));
    }
    function X(a) {
      var b = a.srcElement;
      switch (a.propertyName) {
        case "width":
          b.getContext().clearRect();
          b.style.width = b.attributes.width.nodeValue + "px";
          b.firstChild.style.width = b.clientWidth + "px";
          break;
        case "height":
          b.getContext().clearRect(),
            (b.style.height = b.attributes.height.nodeValue + "px"),
            (b.firstChild.style.height = b.clientHeight + "px");
      }
    }
    function Y(a) {
      a = a.srcElement;
      a.firstChild &&
        ((a.firstChild.style.width = a.clientWidth + "px"),
        (a.firstChild.style.height = a.clientHeight + "px"));
    }
    function D() {
      return [
        [1, 0, 0],
        [0, 1, 0],
        [0, 0, 1]
      ];
    }
    function t(a, b) {
      for (var c = D(), g = 0; 3 > g; g++)
        for (var e = 0; 3 > e; e++) {
          for (var f = 0, d = 0; 3 > d; d++) f += a[g][d] * b[d][e];
          c[g][e] = f;
        }
      return c;
    }
    function P(a, b) {
      b.fillStyle = a.fillStyle;
      b.lineCap = a.lineCap;
      b.lineJoin = a.lineJoin;
      b.lineWidth = a.lineWidth;
      b.miterLimit = a.miterLimit;
      b.shadowBlur = a.shadowBlur;
      b.shadowColor = a.shadowColor;
      b.shadowOffsetX = a.shadowOffsetX;
      b.shadowOffsetY = a.shadowOffsetY;
      b.strokeStyle = a.strokeStyle;
      b.globalAlpha = a.globalAlpha;
      b.font = a.font;
      b.textAlign = a.textAlign;
      b.textBaseline = a.textBaseline;
      b.arcScaleX_ = a.arcScaleX_;
      b.arcScaleY_ = a.arcScaleY_;
      b.lineScale_ = a.lineScale_;
    }
    function Q(a) {
      var b = a.indexOf("(", 3),
        c = a.indexOf(")", b + 1),
        b = a.substring(b + 1, c).split(",");
      if (4 != b.length || "a" != a.charAt(3)) b[3] = 1;
      return b;
    }
    function E(a, b, c) {
      return Math.min(c, Math.max(b, a));
    }
    function F(a, b, c) {
      0 > c && c++;
      1 < c && c--;
      return 1 > 6 * c
        ? a + 6 * (b - a) * c
        : 1 > 2 * c
        ? b
        : 2 > 3 * c
        ? a + 6 * (b - a) * (2 / 3 - c)
        : a;
    }
    function G(a) {
      if (a in H) return H[a];
      var b,
        c = 1;
      a = String(a);
      if ("#" == a.charAt(0)) b = a;
      else if (/^rgb/.test(a)) {
        c = Q(a);
        b = "#";
        for (var g, e = 0; 3 > e; e++)
          (g = -1 != c[e].indexOf("%") ? Math.floor(255 * (parseFloat(c[e]) / 100)) : +c[e]),
            (b += v[E(g, 0, 255)]);
        c = +c[3];
      } else if (/^hsl/.test(a)) {
        e = c = Q(a);
        b = (parseFloat(e[0]) / 360) % 360;
        0 > b && b++;
        g = E(parseFloat(e[1]) / 100, 0, 1);
        e = E(parseFloat(e[2]) / 100, 0, 1);
        if (0 == g) g = e = b = e;
        else {
          var f = 0.5 > e ? e * (1 + g) : e + g - e * g,
            d = 2 * e - f;
          g = F(d, f, b + 1 / 3);
          e = F(d, f, b);
          b = F(d, f, b - 1 / 3);
        }
        b = "#" + v[Math.floor(255 * g)] + v[Math.floor(255 * e)] + v[Math.floor(255 * b)];
        c = c[3];
      } else b = Z[a] || a;
      return (H[a] = { color: b, alpha: c });
    }
    function C(a) {
      this.m_ = D();
      this.mStack_ = [];
      this.aStack_ = [];
      this.currentPath_ = [];
      this.fillStyle = this.strokeStyle = "#000";
      this.lineWidth = 1;
      this.lineJoin = "miter";
      this.lineCap = "butt";
      this.miterLimit = 1 * q;
      this.globalAlpha = 1;
      this.font = "10px sans-serif";
      this.textAlign = "left";
      this.textBaseline = "alphabetic";
      this.canvas = a;
      var b =
          "width:" +
          a.clientWidth +
          "px;height:" +
          a.clientHeight +
          "px;overflow:hidden;position:absolute",
        c = a.ownerDocument.createElement("div");
      c.style.cssText = b;
      a.appendChild(c);
      b = c.cloneNode(!1);
      b.style.backgroundColor = "red";
      b.style.filter = "alpha(opacity=0)";
      a.appendChild(b);
      this.element_ = c;
      this.lineScale_ = this.arcScaleY_ = this.arcScaleX_ = 1;
    }
    function R(a, b, c, g) {
      a.currentPath_.push({
        type: "bezierCurveTo",
        cp1x: b.x,
        cp1y: b.y,
        cp2x: c.x,
        cp2y: c.y,
        x: g.x,
        y: g.y
      });
      a.currentX_ = g.x;
      a.currentY_ = g.y;
    }
    function S(a, b) {
      var c = G(a.strokeStyle),
        g = c.color,
        c = c.alpha * a.globalAlpha,
        e = a.lineScale_ * a.lineWidth;
      1 > e && (c *= e);
      b.push(
        "<g_vml_:stroke",
        ' opacity="',
        c,
        '"',
        ' joinstyle="',
        a.lineJoin,
        '"',
        ' miterlimit="',
        a.miterLimit,
        '"',
        ' endcap="',
        $[a.lineCap] || "square",
        '"',
        ' weight="',
        e,
        'px"',
        ' color="',
        g,
        '" />'
      );
    }
    function T(a, b, c, g) {
      var e = a.fillStyle,
        f = a.arcScaleX_,
        d = a.arcScaleY_,
        k = g.x - c.x,
        n = g.y - c.y;
      if (e instanceof w) {
        var h = 0,
          l = (g = 0),
          u = 0,
          m = 1;
        if ("gradient" == e.type_) {
          h = e.x1_ / f;
          c = e.y1_ / d;
          var p = s(a, e.x0_ / f, e.y0_ / d),
            h = s(a, h, c),
            h = (180 * Math.atan2(h.x - p.x, h.y - p.y)) / Math.PI;
          0 > h && (h += 360);
          1e-6 > h && (h = 0);
        } else
          (p = s(a, e.x0_, e.y0_)),
            (g = (p.x - c.x) / k),
            (l = (p.y - c.y) / n),
            (k /= f * q),
            (n /= d * q),
            (m = x.max(k, n)),
            (u = (2 * e.r0_) / m),
            (m = (2 * e.r1_) / m - u);
        f = e.colors_;
        f.sort(function(a, b) {
          return a.offset - b.offset;
        });
        d = f.length;
        p = f[0].color;
        c = f[d - 1].color;
        k = f[0].alpha * a.globalAlpha;
        a = f[d - 1].alpha * a.globalAlpha;
        for (var n = [], r = 0; r < d; r++) {
          var t = f[r];
          n.push(t.offset * m + u + " " + t.color);
        }
        b.push(
          '<g_vml_:fill type="',
          e.type_,
          '"',
          ' method="none" focus="100%"',
          ' color="',
          p,
          '"',
          ' color2="',
          c,
          '"',
          ' colors="',
          n.join(","),
          '"',
          ' opacity="',
          a,
          '"',
          ' g_o_:opacity2="',
          k,
          '"',
          ' angle="',
          h,
          '"',
          ' focusposition="',
          g,
          ",",
          l,
          '" />'
        );
      } else
        e instanceof I
          ? k &&
            n &&
            b.push(
              "<g_vml_:fill",
              ' position="',
              (-c.x / k) * f * f,
              ",",
              (-c.y / n) * d * d,
              '"',
              ' type="tile"',
              ' src="',
              e.src_,
              '" />'
            )
          : ((e = G(a.fillStyle)),
            b.push(
              '<g_vml_:fill color="',
              e.color,
              '" opacity="',
              e.alpha * a.globalAlpha,
              '" />'
            ));
    }
    function s(a, b, c) {
      a = a.m_;
      return {
        x: q * (b * a[0][0] + c * a[1][0] + a[2][0]) - r,
        y: q * (b * a[0][1] + c * a[1][1] + a[2][1]) - r
      };
    }
    function z(a, b, c) {
      isFinite(b[0][0]) &&
        isFinite(b[0][1]) &&
          isFinite(b[1][0]) &&
          isFinite(b[1][1]) &&
          isFinite(b[2][0]) &&
          isFinite(b[2][1]) &&
        ((a.m_ = b), c && (a.lineScale_ = aa(ba(b[0][0] * b[1][1] - b[0][1] * b[1][0]))));
    }
    function w(a) {
      this.type_ = a;
      this.r1_ = this.y1_ = this.x1_ = this.r0_ = this.y0_ = this.x0_ = 0;
      this.colors_ = [];
    }
    function I(a, b) {
      if (!a || 1 != a.nodeType || "IMG" != a.tagName) throw new A("TYPE_MISMATCH_ERR");
      if ("complete" != a.readyState) throw new A("INVALID_STATE_ERR");
      switch (b) {
        case "repeat":
        case null:
        case "":
          this.repetition_ = "repeat";
          break;
        case "repeat-x":
        case "repeat-y":
        case "no-repeat":
          this.repetition_ = b;
          break;
        default:
          throw new A("SYNTAX_ERR");
      }
      this.src_ = a.src;
      this.width_ = a.width;
      this.height_ = a.height;
    }
    function A(a) {
      this.code = this[a];
      this.message = a + ": DOM Exception " + this.code;
    }
    var x = Math,
      k = x.round,
      J = x.sin,
      K = x.cos,
      ba = x.abs,
      aa = x.sqrt,
      q = 10,
      r = q / 2;
    navigator.userAgent.match(/MSIE ([\d.]+)?/);
    var M = Array.prototype.slice;
    O(document);
    var U = {
      init: function(a) {
        a = a || document;
        a.createElement("canvas");
        a.attachEvent("onreadystatechange", W(this.init_, this, a));
      },
      init_: function(a) {
        a = a.getElementsByTagName("canvas");
        for (var b = 0; b < a.length; b++) this.initElement(a[b]);
      },
      initElement: function(a) {
        if (!a.getContext) {
          a.getContext = V;
          O(a.ownerDocument);
          a.innerHTML = "";
          a.attachEvent("onpropertychange", X);
          a.attachEvent("onresize", Y);
          var b = a.attributes;
          b.width && b.width.specified
            ? (a.style.width = b.width.nodeValue + "px")
            : (a.width = a.clientWidth);
          b.height && b.height.specified
            ? (a.style.height = b.height.nodeValue + "px")
            : (a.height = a.clientHeight);
        }
        return a;
      }
    };
    U.init();
    for (var v = [], d = 0; 16 > d; d++)
      for (var B = 0; 16 > B; B++) v[16 * d + B] = d.toString(16) + B.toString(16);
    var Z = {
        aliceblue: "#F0F8FF",
        antiquewhite: "#FAEBD7",
        aquamarine: "#7FFFD4",
        azure: "#F0FFFF",
        beige: "#F5F5DC",
        bisque: "#FFE4C4",
        black: "#000000",
        blanchedalmond: "#FFEBCD",
        blueviolet: "#8A2BE2",
        brown: "#A52A2A",
        burlywood: "#DEB887",
        cadetblue: "#5F9EA0",
        chartreuse: "#7FFF00",
        chocolate: "#D2691E",
        coral: "#FF7F50",
        cornflowerblue: "#6495ED",
        cornsilk: "#FFF8DC",
        crimson: "#DC143C",
        cyan: "#00FFFF",
        darkblue: "#00008B",
        darkcyan: "#008B8B",
        darkgoldenrod: "#B8860B",
        darkgray: "#A9A9A9",
        darkgreen: "#006400",
        darkgrey: "#A9A9A9",
        darkkhaki: "#BDB76B",
        darkmagenta: "#8B008B",
        darkolivegreen: "#556B2F",
        darkorange: "#FF8C00",
        darkorchid: "#9932CC",
        darkred: "#8B0000",
        darksalmon: "#E9967A",
        darkseagreen: "#8FBC8F",
        darkslateblue: "#483D8B",
        darkslategray: "#2F4F4F",
        darkslategrey: "#2F4F4F",
        darkturquoise: "#00CED1",
        darkviolet: "#9400D3",
        deeppink: "#FF1493",
        deepskyblue: "#00BFFF",
        dimgray: "#696969",
        dimgrey: "#696969",
        dodgerblue: "#1E90FF",
        firebrick: "#B22222",
        floralwhite: "#FFFAF0",
        forestgreen: "#228B22",
        gainsboro: "#DCDCDC",
        ghostwhite: "#F8F8FF",
        gold: "#FFD700",
        goldenrod: "#DAA520",
        grey: "#808080",
        greenyellow: "#ADFF2F",
        honeydew: "#F0FFF0",
        hotpink: "#FF69B4",
        indianred: "#CD5C5C",
        indigo: "#4B0082",
        ivory: "#FFFFF0",
        khaki: "#F0E68C",
        lavender: "#E6E6FA",
        lavenderblush: "#FFF0F5",
        lawngreen: "#7CFC00",
        lemonchiffon: "#FFFACD",
        lightblue: "#ADD8E6",
        lightcoral: "#F08080",
        lightcyan: "#E0FFFF",
        lightgoldenrodyellow: "#FAFAD2",
        lightgreen: "#90EE90",
        lightgrey: "#D3D3D3",
        lightpink: "#FFB6C1",
        lightsalmon: "#FFA07A",
        lightseagreen: "#20B2AA",
        lightskyblue: "#87CEFA",
        lightslategray: "#778899",
        lightslategrey: "#778899",
        lightsteelblue: "#B0C4DE",
        lightyellow: "#FFFFE0",
        limegreen: "#32CD32",
        linen: "#FAF0E6",
        magenta: "#FF00FF",
        mediumaquamarine: "#66CDAA",
        mediumblue: "#0000CD",
        mediumorchid: "#BA55D3",
        mediumpurple: "#9370DB",
        mediumseagreen: "#3CB371",
        mediumslateblue: "#7B68EE",
        mediumspringgreen: "#00FA9A",
        mediumturquoise: "#48D1CC",
        mediumvioletred: "#C71585",
        midnightblue: "#191970",
        mintcream: "#F5FFFA",
        mistyrose: "#FFE4E1",
        moccasin: "#FFE4B5",
        navajowhite: "#FFDEAD",
        oldlace: "#FDF5E6",
        olivedrab: "#6B8E23",
        orange: "#FFA500",
        orangered: "#FF4500",
        orchid: "#DA70D6",
        palegoldenrod: "#EEE8AA",
        palegreen: "#98FB98",
        paleturquoise: "#AFEEEE",
        palevioletred: "#DB7093",
        papayawhip: "#FFEFD5",
        peachpuff: "#FFDAB9",
        peru: "#CD853F",
        pink: "#FFC0CB",
        plum: "#DDA0DD",
        powderblue: "#B0E0E6",
        rosybrown: "#BC8F8F",
        royalblue: "#4169E1",
        saddlebrown: "#8B4513",
        salmon: "#FA8072",
        sandybrown: "#F4A460",
        seagreen: "#2E8B57",
        seashell: "#FFF5EE",
        sienna: "#A0522D",
        skyblue: "#87CEEB",
        slateblue: "#6A5ACD",
        slategray: "#708090",
        slategrey: "#708090",
        snow: "#FFFAFA",
        springgreen: "#00FF7F",
        steelblue: "#4682B4",
        tan: "#D2B48C",
        thistle: "#D8BFD8",
        tomato: "#FF6347",
        turquoise: "#40E0D0",
        violet: "#EE82EE",
        wheat: "#F5DEB3",
        whitesmoke: "#F5F5F5",
        yellowgreen: "#9ACD32"
      },
      H = {},
      L = {},
      $ = { butt: "flat", round: "round" },
      d = C.prototype;
    d.clearRect = function() {
      this.textMeasureEl_ && (this.textMeasureEl_.removeNode(!0), (this.textMeasureEl_ = null));
      this.element_.innerHTML = "";
    };
    d.beginPath = function() {
      this.currentPath_ = [];
    };
    d.moveTo = function(a, b) {
      var c = s(this, a, b);
      this.currentPath_.push({ type: "moveTo", x: c.x, y: c.y });
      this.currentX_ = c.x;
      this.currentY_ = c.y;
    };
    d.lineTo = function(a, b) {
      var c = s(this, a, b);
      this.currentPath_.push({ type: "lineTo", x: c.x, y: c.y });
      this.currentX_ = c.x;
      this.currentY_ = c.y;
    };
    d.bezierCurveTo = function(a, b, c, g, e, f) {
      e = s(this, e, f);
      a = s(this, a, b);
      c = s(this, c, g);
      R(this, a, c, e);
    };
    d.quadraticCurveTo = function(a, b, c, g) {
      a = s(this, a, b);
      c = s(this, c, g);
      g = {
        x: this.currentX_ + (2 / 3) * (a.x - this.currentX_),
        y: this.currentY_ + (2 / 3) * (a.y - this.currentY_)
      };
      R(this, g, { x: g.x + (c.x - this.currentX_) / 3, y: g.y + (c.y - this.currentY_) / 3 }, c);
    };
    d.arc = function(a, b, c, g, e, f) {
      c *= q;
      var d = f ? "at" : "wa",
        k = a + K(g) * c - r,
        n = b + J(g) * c - r;
      g = a + K(e) * c - r;
      e = b + J(e) * c - r;
      k != g || f || (k += 0.125);
      a = s(this, a, b);
      k = s(this, k, n);
      g = s(this, g, e);
      this.currentPath_.push({
        type: d,
        x: a.x,
        y: a.y,
        radius: c,
        xStart: k.x,
        yStart: k.y,
        xEnd: g.x,
        yEnd: g.y
      });
    };
    d.rect = function(a, b, c, g) {
      this.moveTo(a, b);
      this.lineTo(a + c, b);
      this.lineTo(a + c, b + g);
      this.lineTo(a, b + g);
      this.closePath();
    };
    d.strokeRect = function(a, b, c, g) {
      var e = this.currentPath_;
      this.beginPath();
      this.moveTo(a, b);
      this.lineTo(a + c, b);
      this.lineTo(a + c, b + g);
      this.lineTo(a, b + g);
      this.closePath();
      this.stroke();
      this.currentPath_ = e;
    };
    d.fillRect = function(a, b, c, g) {
      var e = this.currentPath_;
      this.beginPath();
      this.moveTo(a, b);
      this.lineTo(a + c, b);
      this.lineTo(a + c, b + g);
      this.lineTo(a, b + g);
      this.closePath();
      this.fill();
      this.currentPath_ = e;
    };
    d.createLinearGradient = function(a, b, c, g) {
      var e = new w("gradient");
      e.x0_ = a;
      e.y0_ = b;
      e.x1_ = c;
      e.y1_ = g;
      return e;
    };
    d.createRadialGradient = function(a, b, c, g, e, f) {
      var d = new w("gradientradial");
      d.x0_ = a;
      d.y0_ = b;
      d.r0_ = c;
      d.x1_ = g;
      d.y1_ = e;
      d.r1_ = f;
      return d;
    };
    d.drawImage = function(a, b) {
      var c, g, e, d, r, y, n, h;
      e = a.runtimeStyle.width;
      d = a.runtimeStyle.height;
      a.runtimeStyle.width = "auto";
      a.runtimeStyle.height = "auto";
      var l = a.width,
        u = a.height;
      a.runtimeStyle.width = e;
      a.runtimeStyle.height = d;
      if (3 == arguments.length)
        (c = arguments[1]), (g = arguments[2]), (r = y = 0), (n = e = l), (h = d = u);
      else if (5 == arguments.length)
        (c = arguments[1]),
          (g = arguments[2]),
          (e = arguments[3]),
          (d = arguments[4]),
          (r = y = 0),
          (n = l),
          (h = u);
      else if (9 == arguments.length)
        (r = arguments[1]),
          (y = arguments[2]),
          (n = arguments[3]),
          (h = arguments[4]),
          (c = arguments[5]),
          (g = arguments[6]),
          (e = arguments[7]),
          (d = arguments[8]);
      else throw Error("Invalid number of arguments");
      var m = s(this, c, g),
        p = [];
      p.push(
        " <g_vml_:group",
        ' coordsize="',
        10 * q,
        ",",
        10 * q,
        '"',
        ' coordorigin="0,0"',
        ' style="width:',
        10,
        "px;height:",
        10,
        "px;position:absolute;"
      );
      if (1 != this.m_[0][0] || this.m_[0][1] || 1 != this.m_[1][1] || this.m_[1][0]) {
        var t = [];
        t.push(
          "M11=",
          this.m_[0][0],
          ",",
          "M12=",
          this.m_[1][0],
          ",",
          "M21=",
          this.m_[0][1],
          ",",
          "M22=",
          this.m_[1][1],
          ",",
          "Dx=",
          k(m.x / q),
          ",",
          "Dy=",
          k(m.y / q),
          ""
        );
        var v = s(this, c + e, g),
          w = s(this, c, g + d);
        c = s(this, c + e, g + d);
        m.x = x.max(m.x, v.x, w.x, c.x);
        m.y = x.max(m.y, v.y, w.y, c.y);
        p.push(
          "padding:0 ",
          k(m.x / q),
          "px ",
          k(m.y / q),
          "px 0;filter:progid:DXImageTransform.Microsoft.Matrix(",
          t.join(""),
          ", sizingmethod='clip');"
        );
      } else p.push("top:", k(m.y / q), "px;left:", k(m.x / q), "px;");
      p.push(
        ' ">',
        '<g_vml_:image src="',
        a.src,
        '"',
        ' style="width:',
        q * e,
        "px;",
        " height:",
        q * d,
        'px"',
        ' cropleft="',
        r / l,
        '"',
        ' croptop="',
        y / u,
        '"',
        ' cropright="',
        (l - r - n) / l,
        '"',
        ' cropbottom="',
        (u - y - h) / u,
        '"',
        " />",
        "</g_vml_:group>"
      );
      this.element_.insertAdjacentHTML("BeforeEnd", p.join(""));
    };
    d.stroke = function(a) {
      var b = [];
      b.push(
        "<g_vml_:shape",
        ' filled="',
        !!a,
        '"',
        ' style="position:absolute;width:',
        10,
        "px;height:",
        10,
        'px;"',
        ' coordorigin="0,0"',
        ' coordsize="',
        10 * q,
        ",",
        10 * q,
        '"',
        ' stroked="',
        !a,
        '"',
        ' path="'
      );
      for (
        var c = { x: null, y: null }, d = { x: null, y: null }, e = 0;
        e < this.currentPath_.length;
        e++
      ) {
        var f = this.currentPath_[e];
        switch (f.type) {
          case "moveTo":
            b.push(" m ", k(f.x), ",", k(f.y));
            break;
          case "lineTo":
            b.push(" l ", k(f.x), ",", k(f.y));
            break;
          case "close":
            b.push(" x ");
            f = null;
            break;
          case "bezierCurveTo":
            b.push(
              " c ",
              k(f.cp1x),
              ",",
              k(f.cp1y),
              ",",
              k(f.cp2x),
              ",",
              k(f.cp2y),
              ",",
              k(f.x),
              ",",
              k(f.y)
            );
            break;
          case "at":
          case "wa":
            b.push(
              " ",
              f.type,
              " ",
              k(f.x - this.arcScaleX_ * f.radius),
              ",",
              k(f.y - this.arcScaleY_ * f.radius),
              " ",
              k(f.x + this.arcScaleX_ * f.radius),
              ",",
              k(f.y + this.arcScaleY_ * f.radius),
              " ",
              k(f.xStart),
              ",",
              k(f.yStart),
              " ",
              k(f.xEnd),
              ",",
              k(f.yEnd)
            );
        }
        if (f) {
          if (null == c.x || f.x < c.x) c.x = f.x;
          if (null == d.x || f.x > d.x) d.x = f.x;
          if (null == c.y || f.y < c.y) c.y = f.y;
          if (null == d.y || f.y > d.y) d.y = f.y;
        }
      }
      b.push(' ">');
      a ? T(this, b, c, d) : S(this, b);
      b.push("</g_vml_:shape>");
      this.element_.insertAdjacentHTML("beforeEnd", b.join(""));
    };
    d.fill = function() {
      this.stroke(!0);
    };
    d.closePath = function() {
      this.currentPath_.push({ type: "close" });
    };
    d.save = function() {
      var a = {};
      P(this, a);
      this.aStack_.push(a);
      this.mStack_.push(this.m_);
      this.m_ = t(D(), this.m_);
    };
    d.restore = function() {
      this.aStack_.length && (P(this.aStack_.pop(), this), (this.m_ = this.mStack_.pop()));
    };
    d.translate = function(a, b) {
      z(
        this,
        t(
          [
            [1, 0, 0],
            [0, 1, 0],
            [a, b, 1]
          ],
          this.m_
        ),
        !1
      );
    };
    d.rotate = function(a) {
      var b = K(a);
      a = J(a);
      z(
        this,
        t(
          [
            [b, a, 0],
            [-a, b, 0],
            [0, 0, 1]
          ],
          this.m_
        ),
        !1
      );
    };
    d.scale = function(a, b) {
      this.arcScaleX_ *= a;
      this.arcScaleY_ *= b;
      z(
        this,
        t(
          [
            [a, 0, 0],
            [0, b, 0],
            [0, 0, 1]
          ],
          this.m_
        ),
        !0
      );
    };
    d.transform = function(a, b, c, d, e, f) {
      z(
        this,
        t(
          [
            [a, b, 0],
            [c, d, 0],
            [e, f, 1]
          ],
          this.m_
        ),
        !0
      );
    };
    d.setTransform = function(a, b, c, d, e, f) {
      z(
        this,
        [
          [a, b, 0],
          [c, d, 0],
          [e, f, 1]
        ],
        !0
      );
    };
    d.drawText_ = function(a, b, c, d, e) {
      var f = this.m_;
      d = 0;
      var r = 1e3,
        t = 0,
        n = [],
        h;
      h = this.font;
      if (L[h]) h = L[h];
      else {
        var l = document.createElement("div").style;
        try {
          l.font = h;
        } catch (u) {}
        h = L[h] = {
          style: l.fontStyle || "normal",
          variant: l.fontVariant || "normal",
          weight: l.fontWeight || "normal",
          size: l.fontSize || 10,
          family: l.fontFamily || "sans-serif"
        };
      }
      var l = h,
        m = this.element_;
      h = {};
      for (var p in l) h[p] = l[p];
      p = parseFloat(m.currentStyle.fontSize);
      m = parseFloat(l.size);
      "number" == typeof l.size
        ? (h.size = l.size)
        : -1 != l.size.indexOf("px")
        ? (h.size = m)
        : -1 != l.size.indexOf("em")
        ? (h.size = p * m)
        : -1 != l.size.indexOf("%")
        ? (h.size = (p / 100) * m)
        : -1 != l.size.indexOf("pt")
        ? (h.size = m / 0.75)
        : (h.size = p);
      h.size *= 0.981;
      p = h.style + " " + h.variant + " " + h.weight + " " + h.size + "px " + h.family;
      m = this.element_.currentStyle;
      l = this.textAlign.toLowerCase();
      switch (l) {
        case "left":
        case "center":
        case "right":
          break;
        case "end":
          l = "ltr" == m.direction ? "right" : "left";
          break;
        case "start":
          l = "rtl" == m.direction ? "right" : "left";
          break;
        default:
          l = "left";
      }
      switch (this.textBaseline) {
        case "hanging":
        case "top":
          t = h.size / 1.75;
          break;
        case "middle":
          break;
        default:
        case null:
        case "alphabetic":
        case "ideographic":
        case "bottom":
          t = -h.size / 2.25;
      }
      switch (l) {
        case "right":
          d = 1e3;
          r = 0.05;
          break;
        case "center":
          d = r = 500;
      }
      b = s(this, b + 0, c + t);
      n.push(
        '<g_vml_:line from="',
        -d,
        ' 0" to="',
        r,
        ' 0.05" ',
        ' coordsize="100 100" coordorigin="0 0"',
        ' filled="',
        !e,
        '" stroked="',
        !!e,
        '" style="position:absolute;width:1px;height:1px;">'
      );
      e ? S(this, n) : T(this, n, { x: -d, y: 0 }, { x: r, y: h.size });
      e =
        f[0][0].toFixed(3) +
        "," +
        f[1][0].toFixed(3) +
        "," +
        f[0][1].toFixed(3) +
        "," +
        f[1][1].toFixed(3) +
        ",0,0";
      b = k(b.x / q) + "," + k(b.y / q);
      n.push(
        '<g_vml_:skew on="t" matrix="',
        e,
        '" ',
        ' offset="',
        b,
        '" origin="',
        d,
        ' 0" />',
        '<g_vml_:path textpathok="true" />',
        '<g_vml_:textpath on="true" string="',
        N(a),
        '" style="v-text-align:',
        l,
        ";font:",
        N(p),
        '" /></g_vml_:line>'
      );
      this.element_.insertAdjacentHTML("beforeEnd", n.join(""));
    };
    d.fillText = function(a, b, c, d) {
      this.drawText_(a, b, c, d, !1);
    };
    d.strokeText = function(a, b, c, d) {
      this.drawText_(a, b, c, d, !0);
    };
    d.measureText = function(a) {
      this.textMeasureEl_ ||
        (this.element_.insertAdjacentHTML(
          "beforeEnd",
          '<span style="position:absolute;top:-20000px;left:0;padding:0;margin:0;border:none;white-space:pre;"></span>'
        ),
        (this.textMeasureEl_ = this.element_.lastChild));
      var b = this.element_.ownerDocument;
      this.textMeasureEl_.innerHTML = "";
      this.textMeasureEl_.style.font = this.font;
      this.textMeasureEl_.appendChild(b.createTextNode(a));
      return { width: this.textMeasureEl_.offsetWidth };
    };
    d.clip = function() {};
    d.arcTo = function() {};
    d.createPattern = function(a, b) {
      return new I(a, b);
    };
    w.prototype.addColorStop = function(a, b) {
      b = G(b);
      this.colors_.push({ offset: a, color: b.color, alpha: b.alpha });
    };
    d = A.prototype = Error();
    d.INDEX_SIZE_ERR = 1;
    d.DOMSTRING_SIZE_ERR = 2;
    d.HIERARCHY_REQUEST_ERR = 3;
    d.WRONG_DOCUMENT_ERR = 4;
    d.INVALID_CHARACTER_ERR = 5;
    d.NO_DATA_ALLOWED_ERR = 6;
    d.NO_MODIFICATION_ALLOWED_ERR = 7;
    d.NOT_FOUND_ERR = 8;
    d.NOT_SUPPORTED_ERR = 9;
    d.INUSE_ATTRIBUTE_ERR = 10;
    d.INVALID_STATE_ERR = 11;
    d.SYNTAX_ERR = 12;
    d.INVALID_MODIFICATION_ERR = 13;
    d.NAMESPACE_ERR = 14;
    d.INVALID_ACCESS_ERR = 15;
    d.VALIDATION_ERR = 16;
    d.TYPE_MISMATCH_ERR = 17;
    G_vmlCanvasManager = U;
    CanvasRenderingContext2D = C;
    CanvasGradient = w;
    CanvasPattern = I;
    DOMException = A;
  })();
/*eslint-enable*/
/*jshint ignore:end*/
