import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { parseUrl } from "query-string";
import axios from "axios";

import ZenGraph from "./ZenGraph";
import Loading from "./ZenGraph/components/Loading";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(245, 166, 35)"
    }
  }
});

class App extends React.Component {
  api = process.env.REACT_APP_API;
  state = {
    ready: false,
    data: {},
    error: ""
  };
  getData = async () => {
    try {
      const { query } = parseUrl(window.location.search);
      const { asin, rank, marketplace, token } = query;
      const category = decodeURIComponent(query.category);
      const url = [this.api, asin, category, rank, marketplace].join("/");
      const options = {
        url,
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "az-mobile-jwt-token": token
        },
        mode: "no-cors"
      };
      const resp = await axios(options);
      const data = resp.data;
      this.setState({
        ready: true,
        data
      });
    } catch (e) {
      let status = null;
      if (e && e.response) {
        status = e.response.status;
      }

      let error = "Unknow error";
      switch (status) {
        case 401:
          error = "unauthorized";
          break;
        case 400:
          error = "Bad request. Please check you params";
          break;
        case 500:
          error = "Something went wrong. Please try again later";
          break;
        default:
          break;
      }
      this.setState({
        ready: true,
        error
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { ready, error, data } = this.state;
    return (
      <ThemeProvider theme={theme}>
        {ready ? (
          error ? (
            <h3 style={{ color: "red" }}>{error}</h3>
          ) : (
            <ZenGraph data={data} />
          )
        ) : (
          <Loading />
        )}
      </ThemeProvider>
    );
  }
}

export default App;
