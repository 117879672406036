import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import merge from "lodash/merge";
import numeral from "numeral";
import ID from "./../../vendors/id";
let CanvasJS = require("./../../vendors/canvasjs.min");
CanvasJS = CanvasJS.Chart ? CanvasJS : window["CanvasJS"];

class Keepa extends React.Component {
  chart = null;
  elm = React.createRef();
  id = `chart${ID()}`;
  types = {
    AMZ: {
      type: "stepArea",
      options: {
        lineColor: "#F5A431",
        color: "rgba(245, 164, 49, 0.3)",
        markerType: "none",
      },
    },
    USED: {
      type: "stepLine",
      options: {
        lineColor: "#444",
        color: "#444",
        markerType: "none",
      },
    },
    SALESRANK: {
      numberFormat: "0,0",
      type: "stepLine",
      options: {
        lineColor: "rgb(141, 185, 141)",
        color: "rgb(141, 185, 141)",
        axisYType: "secondary",
        markerType: "none",
      },
    },
    BUYBOX: {
      type: "scatter",
      options: {
        lineColor: "#EC54B2",
        color: "#EC54B2",
        markerType: "triangle",
      },
    },
    NEW: {
      type: "stepLine",
      options: {
        lineColor: "#8888DD",
        color: "#8888DD",
        markerType: "none",
      },
    },
    COUNTNEW: {
      numberFormat: "0,0",
      legendText: "NEW OFFER COUNT",
      type: "stepLine",
      options: {
        lineColor: "#293990",
        color: "#293990",
        markerType: "none",
      },
    },
  };
  defaultDateRanges = [
    {
      label: "1d",
      value: 1,
    },
    {
      label: "30d",
      value: 30,
    },
    {
      label: "60d",
      value: 60,
    },
    {
      label: "90d",
      value: 90,
    },
    {
      label: "365d",
      value: 365,
    },
  ];
  state = {
    filter: -1,
    dateRanges: [],
  };

  convertTime = (value) => {
    return (value + 21564000) * 60000;
  };

  getSeries() {
    const { filter } = this.state;
    const { data, params } = this.props;
    const series = [];
    let max = moment().valueOf();
    let xaxisMax = max;
    let min =
      filter !== -1
        ? moment(xaxisMax).subtract(filter, "day").valueOf()
        : moment(xaxisMax)
            .subtract(params.totalDays, "day")
            .startOf("day")
            .valueOf();
    let xaxisMin = min ? min : max;
    let rankMax;
    let priceMax;

    for (let name in this.types) {
      const { type, legendText, options } = this.types[name];
      const chart = {
        name,
        showInLegend: true,
        legendText: legendText || name,
        dataPoints: [],
        type,
        ...options,
      };
      const length = data[name] ? data[name].length : 0;
      let startPointValue = null;
      if (length >= 2) {
        for (let i = 0; i < length; i++) {
          const time = this.convertTime(data[name][i]);
          let value = data[name][i + 1];
          if (xaxisMin && time < xaxisMin) {
            startPointValue = value < 0 ? null : value;
            i++;
            continue;
          }

          if (name === "SALESRANK") {
            rankMax = !rankMax || rankMax < value ? value : rankMax;
          } else {
            value = name === "COUNTNEW" ? value : value / 100;
            priceMax = !priceMax || priceMax < value ? value : priceMax;
          }

          if (xaxisMin && !chart.dataPoints.length) {
            const point = {
              x: moment(xaxisMin).toDate(),
              y:
                startPointValue && name !== "SALESRANK" && name !== "COUNTNEW"
                  ? startPointValue / 100
                  : startPointValue,
            };
            if (name === "SALESRANK") {
              rankMax = point.y;
            } else {
              priceMax = point.y;
            }
            chart.dataPoints.push(point);
          }

          const x = moment(time).toDate();

          if (type.indexOf("step") !== -1) {
            if (value < 0) {
              if (chart.dataPoints.length) {
                chart.dataPoints.push({
                  x,
                  y: chart.dataPoints[chart.dataPoints.length - 1].y,
                });
                chart.dataPoints.push({
                  x: moment(time).add(1, "minute").toDate(),
                  y: null,
                });
              } else {
                chart.dataPoints.push({
                  x: moment(time).add(1, "minute").toDate(),
                  y: null,
                });
              }
            } else {
              if (!chart.dataPoints[chart.dataPoints.length - 1]) {
                chart.dataPoints.push({
                  x: moment(time).subtract(1, "minute").toDate(),
                  y: null,
                });
              } else {
                chart.dataPoints.push({
                  x: moment(time).subtract(1, "minute").toDate(),
                  y: chart.dataPoints[chart.dataPoints.length - 1].y,
                });
              }
              chart.dataPoints.push({
                x,
                y: value,
              });
            }
          } else {
            chart.dataPoints.push({
              x: moment(time).subtract(1, "minute").toDate(),
              y: null,
            });
            chart.dataPoints.push({
              x,
              y: value,
            });
          }
          i++;
        }
      }

      if (!chart.dataPoints.length) {
        const point = {
          x: moment(xaxisMin).toDate(),
          y:
            startPointValue && name !== "SALESRANK" && name !== "COUNTNEW"
              ? startPointValue / 100
              : startPointValue,
        };
        if (name === "SALESRANK") {
          rankMax = point.y;
        } else {
          priceMax = point.y;
        }
        chart.dataPoints.push(point);
      }

      chart.dataPoints.push({
        x: moment(xaxisMax).toDate(),
        y: chart.dataPoints[chart.dataPoints.length - 1].y,
      });

      series.push(chart);
    }

    return {
      series,
      xaxisMax,
      xaxisMin,
      rankMax,
      priceMax,
    };
  }

  getInterval = (value) => {
    return Math.ceil(value / 50) * 10;
  };

  renderChart = () => {
    const { series, xaxisMax, xaxisMin, rankMax, priceMax } = this.getSeries();
    const chartContainer = document.getElementById(this.id);
    let maximumPrice = Math.ceil(priceMax / 4) * 4;
    let maximumRank = Math.ceil(rankMax / 4) * 4;
    maximumPrice = isNaN(maximumPrice) || !maximumPrice ? 100 : maximumPrice;
    maximumRank = isNaN(maximumRank) || !maximumRank ? 100 : maximumRank;

    const __options = {
      axisY: {
        title: "Price",
        labelFontSize: 11,
        minimum: 0,
        // maximum: maximumPrice,
        prefix: "$",
        interval: this.getInterval(maximumPrice),
        gridColor: "#ced4da",
      },
      axisY2: {
        title: "Rank",
        labelFontSize: 11,
        minimum: 0,
        // maximum: maximumRank,
        prefix: "#",
        interval: this.getInterval(maximumRank),
        gridColor: "#ced4da",
      },
      axisX: {
        labelFontSize: 11,
        tickSize: 15,
        minimum: xaxisMin ? moment(xaxisMin).toDate() : null,
        maximum: xaxisMax ? moment(xaxisMax).toDate() : null,
        gridDashType: "solid",
        gridThickness: 1,
        gridColor: "#ced4da",
        crosshair: {
          enabled: true,
          lineDashType: "solid",
        },
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
        fontSize: 11,
        // verticalAlign: "center", // "top" , "bottom"
        horizontalAlign: "center",
      },
      toolTip: {
        shared: true,
        fontSize: 11,
        borderColor: "#111",
        contentFormatter: (e) => {
          const tooltips = [];
          for (let i = 0; i < e.entries.length; i++) {
            const point = e.entries[i];
            if (point.dataSeries.visible && point.dataPoint.y > 0) {
              const str = `${
                e.entries[i].dataSeries.legendText ||
                e.entries[i].dataSeries.name
              } <strong>${numeral(point.dataPoint.y).format(
                this.types[e.entries[i].dataSeries.name].numberFormat ||
                  "0,0.00"
              )}</strong>`;
              tooltips.push(str);
            }
          }
          const tooltipElm = chartContainer.getElementsByClassName(
            "canvasjs-chart-tooltip"
          );
          tooltipElm[0].classList.remove("hidden");
          if (!tooltips.length) {
            tooltipElm[0].classList.add("hidden");
          }
          return tooltips.join("<br />");
        },
      },
      data: series,
      zoomEnabled: true,
      animationEnabled: true,
    };
    const options = merge(__options, this.props.options);
    this.chart = new CanvasJS.Chart(this.id, options);
    this.chart.container.addEventListener(
      "dblclick",
      () => {
        console.log("debug double click");
        for (var i = 0; i < this.chart.axisX.length; i++) {
          this.chart.axisX[i].set("viewportMinimum", null, false);
          this.chart.axisX[i].set("viewportMaximum", null, true);
        }
        for (var j = 0; j < this.chart.axisY.length; j++) {
          this.chart.axisY[j].set("viewportMinimum", null, false);
          this.chart.axisY[j].set("viewportMaximum", null, true);
        }
      },
      false
    );
    this.chart.render();
  };

  setCanvasSize = () => {
    // console.log(this.chart);
    // this.chart && this.chart.render();
  };

  componentDidUpdate() {
    this.renderChart();
  }

  componentDidMount() {
    const { totalDays } = this.props.params;
    let filter = totalDays && totalDays > 90 ? 90 : -1;
    const dateRanges = this.defaultDateRanges.reduce((result, item) => {
      if (item.value < totalDays) {
        result.push(item);
      }
      return result;
    }, []);
    this.setState({
      dateRanges,
      filter,
    });
    window.addEventListener("resize", this.setCanvasSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setCanvasSize);
  }

  changeFilter = (filter) => {
    this.setState({
      filter,
    });
  };

  toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };

  render() {
    const id = this.id;
    const { filter, dateRanges } = this.state;
    const { params } = this.props;

    return (
      <div
        className="KeepaChart"
        ref={this.elm}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          id={id}
          style={{
            position: "absolute",
            top: 0,
            bottom: 40,
            left: 0,
            right: 0,
          }}
        />
        <div className="filter">
          {dateRanges
            .filter((item) => item.value < params.totalDays)
            .map(({ label, value }) => (
              <span
                key={value}
                className={filter === value ? "active" : ""}
                onClick={() => this.changeFilter(value)}
              >
                {label}
              </span>
            ))}
          {params.totalDays ? (
            <span
              className={filter === -1 ? "active" : ""}
              onClick={() => this.changeFilter(-1)}
            >
              All ({params.totalDays}d)
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

Keepa.defaultProps = {
  data: {
    AMZ: [],
    BUYBOX: [],
    NEW: [],
    SALESRANK: [],
    USED: [],
  },
  params: {
    totalDays: 0,
  },
  options: {},
};

Keepa.propTypes = {
  data: PropTypes.shape({
    AMZ: PropTypes.array,
    BUYBOX: PropTypes.array,
    NEW: PropTypes.array,
    SALESRANK: PropTypes.array,
    USED: PropTypes.array,
  }),
  params: PropTypes.shape({
    totalDays: PropTypes.number,
  }),
  options: PropTypes.object,
};

export default Keepa;
