import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import merge from "lodash/merge";
import ID from "./../../vendors/id";
let CanvasJS = require("./../../vendors/canvasjs.min");
CanvasJS = CanvasJS.Chart ? CanvasJS : window["CanvasJS"];

class RankDips extends React.Component {
  elm = React.createRef();
  id = `chart${ID()}`;
  defaultDateRanges = [
    {
      label: "1d",
      value: 1,
    },
    {
      label: "30d",
      value: 30,
    },
    {
      label: "60d",
      value: 60,
    },
    {
      label: "90d",
      value: 90,
    },
    {
      label: "365d",
      value: 365,
    },
  ];
  state = {
    filter: -1,
    dateRanges: [],
  };
  convertTime = (value) => {
    return (value + 21564000) * 60000;
  };

  changeBorderColor(chart) {
    let dataSeries;
    for (let i = 0; i < chart.options.data.length; i++) {
      dataSeries = chart.options.data[i];
      for (let j = 0; j < dataSeries.dataPoints.length; j++) {
        dataSeries.dataPoints[j].color =
          dataSeries.dataPoints[j].y[0] <= dataSeries.dataPoints[j].y[3]
            ? dataSeries.risingColor
              ? dataSeries.risingColor
              : dataSeries.color
            : dataSeries.fallingColor
            ? dataSeries.fallingColor
            : dataSeries.color;
      }
    }
  }

  getSeries() {
    const { filter } = this.state;
    const series = [];
    const { SALESRANK } = this.props.data;
    const { totalDays } = this.props.params;
    const chart = {
      type: "candlestick",
      risingColor: "rgb(141, 185, 141)",
      fallingColor: "rgb(141, 185, 141)",
      borderColor: "rgba(0, 0, 0, 0)",
      dataPoints: [],
    };
    let max = moment().valueOf();
    let xaxisMax = max;
    let min =
      filter !== -1
        ? moment(xaxisMax).subtract(filter, "day").valueOf()
        : moment(xaxisMax).subtract(totalDays, "day").startOf("day").valueOf();
    let xaxisMin = min ? min : max;
    let yAxisMax = 0;

    for (let i = SALESRANK.length - 1; i >= 0; i--) {
      let time = this.convertTime(SALESRANK[i - 1]);
      if (min && time < min) break;
      const x = moment(time).toDate();
      if (i === 1) xaxisMin = time;
      if (SALESRANK[i - 2]) {
        const from = SALESRANK[i - 2];
        const to = SALESRANK[i];
        if (from > to && to > 0) {
          yAxisMax = from > yAxisMax ? from : yAxisMax;
          chart.dataPoints.push({
            x,
            y: [from, from, to, to],
          });
        }
      }
      i--;
    }
    series.push(chart);
    return {
      series,
      xaxisMin,
      xaxisMax,
      yAxisMax,
    };
  }

  renderChart = () => {
    const { series, xaxisMax, xaxisMin, yAxisMax } = this.getSeries();
    let maximum = Math.ceil(yAxisMax * 4) / 4;
    maximum = isNaN(maximum) || !maximum ? 100 : maximum;
    if (!series[0].dataPoints.length) {
      series[0].dataPoints.push({
        x: moment(xaxisMin).toDate(),
        y: [null, null, null, null],
      });
    }
    const __options = {
      axisY: {
        title: "Rank",
        minimum: 0,
        labelFontSize: 11,
        prefix: "#",
        minimum: 0,
        // maximum: maximum,
        interval: Math.ceil(maximum / 50) * 10,
      },
      axisX: {
        labelFontSize: 11,
        minimum: xaxisMin ? moment(xaxisMin).toDate() : null,
        maximum: xaxisMax ? moment(xaxisMax).toDate() : null,
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      toolTip: {
        //shared: true,
        borderColor: "#111",
        contentFormatter: (e) => {
          const point = e.entries[0].dataPoint;
          // const x = `<strong>${moment(point.x).format("DD MMM YYYY")}</strong>`;
          const from = `From <strong>${point.y[0]}</strong>`;
          const to = `To <strong>${point.y[3]}</strong>`;
          const diff = `Difference <strong>${point.y[3] - point.y[0]}</strong>`;
          return [from, to, diff].join("<br />");
        },
      },
      data: series,
      zoomEnabled: true,
      animationEnabled: true,
    };
    const options = merge(__options, this.props.options);
    const chart = new CanvasJS.Chart(this.id, options);
    chart.container.addEventListener(
      "dblclick",
      function () {
        for (var i = 0; i < chart.axisX.length; i++) {
          chart.axisX[i].set("viewportMinimum", null, false);
          chart.axisX[i].set("viewportMaximum", null, true);
        }
        for (var j = 0; j < chart.axisY.length; j++) {
          chart.axisY[j].set("viewportMinimum", null, false);
          chart.axisY[j].set("viewportMaximum", null, true);
        }
      },
      false
    );
    this.changeBorderColor(chart);
    chart.render();
  };

  componentDidUpdate() {
    this.renderChart();
  }

  componentDidMount() {
    const { totalDays } = this.props.params;
    let filter = totalDays && totalDays > 90 ? 90 : -1;
    const dateRanges = this.defaultDateRanges.reduce((result, item) => {
      if (item.value < totalDays) {
        result.push(item);
      }
      return result;
    }, []);
    this.setState({
      dateRanges,
      filter,
    });
  }

  toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };

  changeFilter = (filter) => {
    this.setState({
      filter,
    });
  };

  render() {
    const id = this.id;
    const { filter, dateRanges } = this.state;
    const { params } = this.props;
    return (
      <div
        className="RankDipsChart"
        ref={this.elm}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div className="filter">
          {dateRanges
            .filter((item) => item.value < params.totalDays)
            .map(({ label, value }) => (
              <span
                key={value}
                className={filter === value ? "active" : ""}
                onClick={() => this.changeFilter(value)}
              >
                {label}
              </span>
            ))}
          {params.totalDays ? (
            <span
              className={filter === -1 ? "active" : ""}
              onClick={() => this.changeFilter(-1)}
            >
              All ({params.totalDays}d)
            </span>
          ) : null}
        </div>
        <div
          id={id}
          style={{
            position: "absolute",
            top: 0,
            bottom: 40,
            left: 0,
            right: 0,
          }}
        />
      </div>
    );
  }
}

RankDips.defaultProps = {
  data: {
    SALESRANK: [],
  },
};

RankDips.propTypes = {
  data: PropTypes.shape({
    SALESRANK: PropTypes.array,
  }),
};

export default RankDips;
