import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./style.css";

import Keepa from "./components/Keepa";
import RankDips from "./components/RankDips";
import Average from "./components/Average";

class ZenGraph extends React.Component {
  state = {
    index: 0
  };
  changeTab = (e, index) => {
    this.setState({
      index
    });
    this.props.onTabChange && this.props.onTabChange(index);
  };

  componentDidUpdate(prevProps) {
    if (this.props.defaultTab !== prevProps.defaultTab) {
      this.setState({
        index: this.props.defaultTab
      });
    }
  }

  componentDidMount() {
    this.setState({
      index: this.props.defaultTab || 0
    });
  }
  
  render() {
    const { index } = this.state;
    const { data, options } = this.props;
    return (
      <div className="ZenGraph">
        <div className="ZenGraph-Content">
          <div className={`ZenGraph-Content-Tab ${index === 0 || "hidden"}`}>
            <Keepa {...data.keepaChart} options={options.keepa} />
          </div>
          <div className={`ZenGraph-Content-Tab ${index === 1 || "hidden"}`}>
            <RankDips {...data.keepaChart} options={options.rankDips} />
          </div>
          <div className={`ZenGraph-Content-Tab ${index === 2 || "hidden"}`}>
            <Average {...data.avg} options={options.average} />
          </div>
        </div>
        <Tabs value={index} onChange={this.changeTab}>
          <Tab key="keepa" label="Historical Sales Chart" />
          <Tab key="rank-dips" label="Rank Drops Chart" />
          <Tab key="average" label="Average Chart" />
        </Tabs>
      </div>
    );
  }
}

ZenGraph.defaultProps = {
  data: {},
  defaultTab: 0,
  onTabChange: null,
  options: {
    keepa: {},
    average: {},
    rankDips: {}
  }
};

ZenGraph.propTypes = {
  data: PropTypes.object,
  defaultTab: PropTypes.number,
  onTabChange: PropTypes.func,
  options: PropTypes.shape({
    keepa: PropTypes.object,
    average: PropTypes.object,
    rankDips: PropTypes.object
  })
};

export default ZenGraph;
