import React from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import ID from "./../../vendors/id";
let CanvasJS = require("./../../vendors/canvasjs.min");
CanvasJS = CanvasJS.Chart ? CanvasJS : window["CanvasJS"];

class Average extends React.Component {
  elm = React.createRef();
  id = `chart${ID()}`;
  labels = {
    current: "Current",
    thirty: "30 days",
    ninety: "90 days",
    doubleNinety: "180 days"
  };

  options = {
    amzPrice: {
      type: "column",
      name: "AMZ",
      options: {
        color: "rgba(245, 164, 49, 0.3)"
      }
    },
    ["3rdPartyFBA"]: {
      type: "column",
      name: "3rd FBA",
      options: {
        color: "#EE5330"
      }
    },
    ["3rdPartyFBM"]: {
      type: "column",
      name: "3rd FBM",
      options: {
        color: "#339CE6"
      }
    },
    bbPrice: {
      type: "column",
      name: "BuyBox",
      options: {
        color: "#EC54B2"
      }
    },
    rank: {
      type: "line",
      name: "Sales Rank",
      options: {
        color: "rgb(141, 185, 141)",
        axisYType: "secondary"
      }
    }
  };

  state = {
    filter: 1
  };

  getSeries() {
    const series = [];
    for (let field in this.options) {
      const { type, name, options } = this.options[field];
      const chart = {
        type,
        name,
        dataPoints: [],
        showInLegend: true,
        ...options
      };
      for (let key in this.labels) {
        const label = this.labels[key];
        chart.dataPoints.push({
          label,
          y: this.props[key][field]
        });
      }
      series.push(chart);
    }
    return {
      series
    };
  }

  renderChart = () => {
    const { series } = this.getSeries();
    const __options = {
      axisY: {
        title: "Price",
        minimum: 0,
        labelFontSize: 11
      },
      axisY2: {
        title: "Rank",
        minimum: 0,
        labelFontSize: 11,
        prefix: "#"
      },
      axisX: {
        labelFontSize: 11,
        crosshair: {
          enabled: true
        }
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
        fontSize: 11
      },
      toolTip: {
        shared: true,
        fontSize: 11,
        borderColor: "#111",
        contentFormatter: e => {
          const tooltips = [];
          for (let i = 0; i < e.entries.length; i++) {
            const point = e.entries[i];
            if (point.dataSeries.visible && point.dataPoint.y > 0) {
              const str = `${e.entries[i].dataSeries.name} <strong>${point.dataPoint.y}</strong>`;
              tooltips.push(str);
            }
          }
          return tooltips.join("<br />");
        }
      },
      data: series,
      zoomEnabled: true,
      animationEnabled: true
    };
    const options = merge(__options, this.props.options);
    const chart = new CanvasJS.Chart(this.id, options);
    chart.container.addEventListener(
      "dblclick",
      function() {
        for (var i = 0; i < chart.axisX.length; i++) {
          chart.axisX[i].set("viewportMinimum", null, false);
          chart.axisX[i].set("viewportMaximum", null, true);
        }
        for (var j = 0; j < chart.axisY.length; j++) {
          chart.axisY[j].set("viewportMinimum", null, false);
          chart.axisY[j].set("viewportMaximum", null, true);
        }
      },
      false
    );
    chart.render();
  };

  componentDidUpdate() {
    this.renderChart();
  }

  componentDidMount() {
    this.renderChart();
  }

  toggleDataSeries = e => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };

  render() {
    const id = this.id;
    return (
      <div
        className="AverageChart"
        ref={this.elm}
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        <div
          id={id}
          style={{
            position: "absolute",
            top: 0,
            bottom: 40,
            left: 0,
            right: 0
          }}
        />
      </div>
    );
  }
}

Average.defaultProps = {};

Average.propTypes = {
  current: PropTypes.object,
  thirty: PropTypes.object,
  ninety: PropTypes.object,
  doubleNinety: PropTypes.object,
  options: PropTypes.object
};

export default Average;
