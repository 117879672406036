import React from "react";
import "./style.css";

class Loading extends React.Component {
  render() {
    return (
      <svg
        className="spinner"
        width="40px"
        height="40px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    );
  }
}

export default Loading;
